import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import ParkingLossReason from "../../model/ParkingLossReason";

export default class TaskParkingLossReasonDetail extends BaseGenericRequest<{}, TaskResponse<ParkingLossReason>> {

    public constructor(parkingLossId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_PARKING_LOSS_REASON_DETAIL, {uuid: parkingLossId}));
    }
}
