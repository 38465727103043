import Vehicle from '../../commons/model/Vehicle';
import { AutocompleteOption } from '../form/FormAutocomplete';
import Law from '../../commons/model/Law';
import User, { UserSimply } from '../../commons/model/User';
import Street from '../../commons/model/Street';
import Route from '../../commons/model/Route';
import UserRole from '../../commons/model/UserRole';
import UserType from '../../commons/model/UserType';

const vehicles = (data: Vehicle[]): Array<AutocompleteOption<Vehicle>> => {
    return data.map((vehicle) => ( {
        name: `${vehicle.license_plate}`,
        value: vehicle.id,
        data: vehicle
    } ));
};

const routes = (data: Route[]): Array<AutocompleteOption<Route>> => {
    return data.map((route) => ( {
        name: `${route.name}`,
        value: route.id,
        data: route
    } ));
};

const laws = (data: Law[]): Array<AutocompleteOption<Law>> => {
    return data.map((law) => ( {
        name: `${law.municipal_code} - ${law.screen_text}`,
        value: law.id,
        data: law
    } ));
};

const users = (data: User[]): Array<AutocompleteOption<User | UserSimply>> => {
    return data.map((user) => ( {
        name: `${user.name} ${user.last_name}`,
        value: user.id,
        data: user
    } ));
};

const streets = (data: Street[]): Array<AutocompleteOption<Street>> => {
    return data.map((street) => ( {
        name: `${street.name}`,
        value: street.id,
        data: street
    } ));
};

// - - - - - TODO: PIC: Refactorización - Sustituir

export class UserRoleAutocompleteBuilder {
    public static buildOption(role: UserRole): AutocompleteOption<UserRole> {
        return {name: role.name, value: role.id, data: role};
    }
}

export class UserTypeAutocompleteBuilder {
    public static buildOption(userType: UserType): AutocompleteOption<UserType> {
        return ({name: `${userType.name}`, value: userType.id, data: userType});
    }
}

// - - - - -

export const typeaheadOptionsBuilder = {
    vehicles,
    routes,
    laws,
    users,
    streets,
};
