import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskParkingMeterCollectionDetail from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionDetail";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS, ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import ParkingMeterCollectionCardFormCompanyUser, { ParkingMeterCollectionCardFormCompanyUserData } from "../formdetail/ParkingMeterCollectionCardFormCompanyUser";
import ParkingMeterCollectionFormCompanyUserScreenActions from "./ParkingMeterCollectionFormCompanyUserScreenActions";
import ParkingMeterCollectionFormScreenCompanyUserReducer from "./ParkingMeterCollectionFormScreenCompanyUserReducer";

const mapStateToProps = ParkingMeterCollectionFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = ParkingMeterCollectionFormCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class ParkingMeterCollectionFormCompanyUserScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskParkingMeterCollectionDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const parkingMeterCollectionId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.RECAUDACIONES_PARQUIMETROS),
      url: ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS,
      icon: AppIcon.PARKING_METER_COLLECTION,
    }];

    if (parkingMeterCollectionId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.RECAUDACION_PARQUIMETRO),
        url: generateRoute(ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL, {
          id: parkingMeterCollectionId,
        } ),
        icon: AppIcon.PARKING_METER_COLLECTION,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderParkingMeterForm()}
      </ScreenCard>
    );
  }

  private renderParkingMeterForm(): React.ReactNode {
    const {parkingMeterCollectionLoading, parkingMeterCollection} = this.props;
    let initialValues: ParkingMeterCollectionCardFormCompanyUserData | undefined;

    if (parkingMeterCollection) {
      initialValues = {
        parking_meter: parkingMeterCollection.parking_meter ? parkingMeterCollection.parking_meter.code : "",
        cash: parkingMeterCollection.cash ? parkingMeterCollection.cash.toString() : "",
        date: parkingMeterCollection.date ? DateFormatter.formatDatetime(parkingMeterCollection.date) : "",
        collect_id: parkingMeterCollection.collect_id,
        assistant: parkingMeterCollection.assistant ? parkingMeterCollection.assistant : "",
        collection_strip_image: parkingMeterCollection.collection_strip_image ? parkingMeterCollection.collection_strip_image : "",
        controller: parkingMeterCollection.controller ?
          `${parkingMeterCollection.controller.name} ${parkingMeterCollection.controller.last_name}`
          : "",
        flange_number: parkingMeterCollection.flange_number ? parkingMeterCollection.flange_number : "",
        observations: parkingMeterCollection.observations ? parkingMeterCollection.observations : "",
      };
    }
    
    return (
      <ParkingMeterCollectionCardFormCompanyUser
        initialValues={initialValues}
        parentLoading={parkingMeterCollectionLoading}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterCollectionFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
