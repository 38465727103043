
import I18n from "../../../commons/I18n/I18n";
import ScreenCard from "../../../components/screen/ScreenCard";
import { AppIcon } from "../../../config/Config";
import I18nKeys from "../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_SENDTOPROCESS } from "../../../routing/Routes";
import SendToProcessDataContainer from "./SendToProcessDataContainer";

const SendToProcessScreen = () =>(
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.ENVIO_A_TRAMITE),
      url: ROUTE_COMPANY_USER_SENDTOPROCESS,
      icon: AppIcon.SEND,
    },
  ]}>
    <SendToProcessDataContainer/>
  </ScreenCard>
);

export default SendToProcessScreen;
