import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import Vehicle, {VehicleData} from "../../model/Vehicle";

export default class TaskVehicleEdit extends BaseGenericRequest<VehicleData, TaskResponse<Vehicle>> {

    private readonly data: VehicleData;

    public constructor(vehicleId: string, vehicleData: VehicleData) {
        super(METHOD.PUT, generateRoute(Urls.URL_VEHICLE_EDIT, {uuid: vehicleId}));
        this.data = vehicleData;
    }

    protected getRequest(): VehicleData {
        return this.data;
    }
}
