import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { ParkingAreaTypeData } from "../../../../commons/model/ParkingAreaType";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskParkingAreaTypeAdd from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeAdd";
import TaskParkingAreaTypeDetail from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeDetail";
import TaskParkingAreaTypeEdit from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_COMPANY_USER_PARKING_AREA_TYPES, ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD,
  ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import ParkingAreaTypeCompanyUserFormCard, { ParkingAreaTypeCardFormAdminData } from "../formdetail/ParkingAreaTypeCompanyUserFormCard";
import ParkingAreaTypeCompanyUserFormScreenActions from "./ParkingAreaTypeCompanyUserFormScreenActions";
import ParkingAreaTypeCompanyUserFormScreenReducer from "./ParkingAreaTypeCompanyUserFormScreenReducer";

const mapActionsToProps = ParkingAreaTypeCompanyUserFormScreenActions.autoMapToProps();
const mapStateToProps = ParkingAreaTypeCompanyUserFormScreenReducer.autoMapToProps();

type Props = RouteComponentProps<{id: string}> & typeof mapActionsToProps & typeof mapStateToProps;

class ParkingAreaTypeCompanyUserFormScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskParkingAreaTypeDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const parkingAreaTypeId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.TIPOS_ZONAS_APARCAMIENTOS),
      url: ROUTE_COMPANY_USER_PARKING_AREA_TYPES,
      icon: AppIcon.PARKING,
    }];

    if (parkingAreaTypeId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.TIPO_ZONA_APARCAMIENTO),
        url: generateRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL, {
          id: parkingAreaTypeId,
        } ),
        icon: AppIcon.PARKING,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVO_TIPO_ZONA_APARCAMIENTO),
        url: ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD,
        icon: AppIcon.PARKING,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderParkingAreaTypeForm()}
      </ScreenCard>
    );
  }

  private onSubmit = (values: ParkingAreaTypeCardFormAdminData): void => {
    const existParkingAreaType = this.props.match.params.id !== undefined;
    const submitData: ParkingAreaTypeData = {
      name: values.name,
      enabled: values.enabled,
    };

    if (existParkingAreaType) {
      executeItemTask(
        new TaskParkingAreaTypeEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPES),
        I18n.tr(I18nKeys.TIPO_ZONA_APARCAMIENTO_MODIFICADO_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskParkingAreaTypeAdd(submitData),
        () => goToRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPES),
        I18n.tr(I18nKeys.TIPO_ZONA_APARCAMIENTO_CREADO_CORRECTAMENTE),
      );
    }
  };

  private renderParkingAreaTypeForm(): React.ReactNode {
    const {parkingAreaTypeFormScreenLoading, parkingAreaType} = this.props;
    let initialValues: ParkingAreaTypeCardFormAdminData | undefined;

    if (parkingAreaType) {
      initialValues = {
        name: parkingAreaType.name,
        enabled: parkingAreaType.enabled,
      };
    }

    return (
      <ParkingAreaTypeCompanyUserFormCard
        parentLoading={parkingAreaTypeFormScreenLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingAreaTypeCompanyUserFormScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
