import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import VehicleColor from "../../../../commons/model/VehicleColor";
import TaskVehicleColorDetail from "../../../../commons/ws/vehicleColor/TaskVehicleColorDetail";
import VehicleColorFormAdminScreenActions from "./VehicleColorFormAdminScreenActions";

interface InitialState {
    vehicleColorLoading: boolean,
    vehicleColor?: VehicleColor,
}

const INITIAL_STATE: InitialState = {
    vehicleColorLoading: false,
    vehicleColor: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskVehicleColorDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        vehicleColorLoading: true
    }))
    .onEvent(TaskVehicleColorDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        vehicleColorLoading: false
    }))
    .onEvent(TaskVehicleColorDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, vehicleColor: payload.data};
        })

    .onEvent(VehicleColorFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
