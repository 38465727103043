import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { ParkingMeterData } from "../../../../commons/model/ParkingMeter";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskParkingMeterAdd from "../../../../commons/ws/parkingMeter/TaskParkingMeterAdd";
import TaskParkingMeterDetail from "../../../../commons/ws/parkingMeter/TaskParkingMeterDetail";
import TaskParkingMeterEdit from "../../../../commons/ws/parkingMeter/TaskParkingMeterEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_COMPANY_USER_PARKING_METERS, ROUTE_COMPANY_USER_PARKING_METER_ADD,
  ROUTE_COMPANY_USER_PARKING_METER_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import ParkingMeterCardFormCompanyUser, { ParkingMeterCardFormCompanyUserData } from "../formdetail/ParkingMeterCardFormCompanyUser";
import ParkingMeterFormCompanyUserScreenActions from "./ParkingMeterFormCompanyUserScreenActions";
import ParkingMeterFormScreenCompanyUserReducer from "./ParkingMeterFormScreenCompanyUserReducer";

const mapStateToProps = ParkingMeterFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = ParkingMeterFormCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class ParkingMeterFormCompanyUserScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskParkingMeterDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: ParkingMeterCardFormCompanyUserData): void => {
    const existParkingMeter = this.props.match.params.id !== undefined;
    const submitData: ParkingMeterData = {
      code: values.code,
      description: values.description,
      type: values.type,
      latitude: values.coord.lat,
      longitude: values.coord.lng,
      places: values.places,
    };

    if (existParkingMeter) {
      executeItemTask(
        new TaskParkingMeterEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_COMPANY_USER_PARKING_METERS),
        I18n.tr(I18nKeys.PARQUIMETRO_MODIFICADO_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskParkingMeterAdd(submitData),
        () => goToRoute(ROUTE_COMPANY_USER_PARKING_METERS),
        I18n.tr(I18nKeys.PARQUIMETRO_CREADO_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const parkingMeterId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.PARQUIMETROS),
      url: ROUTE_COMPANY_USER_PARKING_METERS,
      icon: AppIcon.PARKING_METER,
    }];

    if (parkingMeterId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.PARQUIMETRO),
        url: generateRoute(ROUTE_COMPANY_USER_PARKING_METER_DETAIL, {
          id: parkingMeterId,
        } ),
        icon: AppIcon.PARKING_METER,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVO_PARQUIMETRO),
        url: ROUTE_COMPANY_USER_PARKING_METER_ADD,
        icon: AppIcon.PARKING_METER,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderParkingMeterForm()}
      </ScreenCard>
    );
  }

  private renderParkingMeterForm(): React.ReactNode {
    const {parkingMeterLoading, parkingMeter} = this.props;
    let initialValues: Partial<ParkingMeterCardFormCompanyUserData> | undefined;

    if (parkingMeter) {
      initialValues = {
        code: parkingMeter.code,
        description: parkingMeter.description,
        type: parkingMeter.type,
        coord: {
          lat: parkingMeter.latitude,
          lng: parkingMeter.longitude,
        },
        places: parkingMeter.places || 0,
      };
    }

    return (
      <ParkingMeterCardFormCompanyUser
        parentLoading={parkingMeterLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        editMode={!!this.props.match.params.id}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)
(ParkingMeterFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
