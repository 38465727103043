import BaseGenericRequest from "../BaseGenericRequest";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskDeleteResponse} from "../TaskDeleteResponse";
import {TaskDeleteMultipleRequest} from "../TaskDeleteMultipleRequest";
import {generateRoute} from "../../utils/Router";

export default class TaskParkingLossReasonDelete extends BaseGenericRequest<TaskDeleteMultipleRequest, TaskDeleteResponse> {

    private readonly data: TaskDeleteMultipleRequest;

    public constructor(itemIdOrIds: string | string[]) {
        if (typeof itemIdOrIds === 'string') {
            super(METHOD.DELETE, generateRoute(Urls.URL_PARKING_LOSS_REASON_DELETE, {uuid: itemIdOrIds}));
        } else {
            super(METHOD.DELETE, generateRoute(Urls.URL_PARKING_LOSS_REASON_DELETE_MULTIPLE));
            this.data = {ids: itemIdOrIds}
        }
    }

    protected getRequest(): TaskDeleteMultipleRequest {
        return this.data;
    }
}
