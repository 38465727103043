/* eslint-disable max-classes-per-file */
import { Component, ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import { Field, FormErrors, InjectedFormProps } from "redux-form";
import BaseFormFieldsHelpers from "../../../../../base/form/BaseFormFieldsHelpers";
import I18n from "../../../../../commons/I18n/I18n";
import Exploitation from "../../../../../commons/model/Exploitation";
import CardCollapsed from "../../../../../components/card/CardCollapsed";
import FormInput from "../../../../../components/form/FormInput";
import FormSwitch from "../../../../../components/form/FormSwitch";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { formStateAutoMapToPropsFactory } from "../../../../../utils/FormUtils";

const FORM_NAME = "ExploitationAppConfigurationSectionAdmin";

export interface ExploitationAppConfigurationSectionAdminData {
    inactivityReportAppTime: number;
    inactivityPositionAppTime: number;
    dataDriverScreen: boolean;
    reportRatificationRadius: number;
    workdayFinishTime: number;
    reportCreationTimeLimit: number;
}

interface ExploitationAppConfigurationSectionProps {
    className?: string;
    invalid: boolean;
    existErrorsHandler: (isValid: boolean)=> void;
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationAppConfigurationSectionAdminData>(FORM_NAME);
const mapStateToProps = mapFormStateToProps;

type Props =
    ExploitationAppConfigurationSectionProps & InjectedFormProps<ExploitationAppConfigurationSectionAdminData> & typeof mapStateToProps;

interface State {
    collapsed: boolean;
}

export enum ExploitationAppConfigurationSectionFields {
    INACTIVITY_REPORT_APP_TIME = "inactivityReportAppTime",
    INACTIVITY_POSITION_APP_TIME = "inactivityPositionAppTime",
    DATA_DRIVER_SCREEN = "dataDriverScreen",
    REPORT_RATIFICATION_RADIUS = "reportRatificationRadius",
    WORKDAY_FINISH_TIME = "workdayFinishTime",
    REPORT_CREATION_TIME_LIMIT = "reportCreationTimeLimit",
}

class ExploitationAppConfigurationSectionAdmin extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  public render(): ReactNode {
    const {invalid, className} = this.props;
    const {collapsed} = this.state;

    return (
      <CardCollapsed
        title={I18n.tr(I18nKeys.CONFIGURACION_DE_LA_APP)}
        collapsedHandler={this.onCollapsedCard}
        collapsed={collapsed}
        invalid={invalid}
        className={className}
      >
        <Row>
          <Field
            label={I18n.tr(I18nKeys.TIEMPO_INACTIVIDAD_DENUNCIA_MINUTOS)}
            name={ExploitationAppConfigurationSectionFields.INACTIVITY_REPORT_APP_TIME}
            component={FormInput}
            type={"number"}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.TIEMPO_INACTIVIDAD_MOVIMIENTO_MINUTOS)}
            name={ExploitationAppConfigurationSectionFields.INACTIVITY_POSITION_APP_TIME}
            component={FormInput}
            type={"number"}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.PANTALLA_DATOS_DENUNCIADO)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationAppConfigurationSectionFields.DATA_DRIVER_SCREEN}
            component={FormSwitch}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.RADIO_DENUNCIA_ADVERACION_METROS)}
            name={ExploitationAppConfigurationSectionFields.REPORT_RATIFICATION_RADIUS}
            component={FormInput}
            type={"number"}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.TIEMPO_FINALIZACION_JORNADA_MINUTOS)}
            name={ExploitationAppConfigurationSectionFields.WORKDAY_FINISH_TIME}
            component={FormInput}
            type={"number"}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_MINUTOS)}
            name={ExploitationAppConfigurationSectionFields.REPORT_CREATION_TIME_LIMIT}
            component={FormInput}
            type={"number"}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
      </CardCollapsed>
    );
  }

  private onCollapsedCard = () => {
    this.setState( {
      collapsed: !this.state.collapsed,
    } );
  };
}
// @ts-ignore
// eslint-disable-next-line no-undef
export default connect(mapStateToProps)(ExploitationAppConfigurationSectionAdmin) as ComponentType<ExploitationConnectionnAppSectionProps>;

export class ExploitationAppConfigurationSectionAdminHelpers extends BaseFormFieldsHelpers {
  public static validate(values: ExploitationAppConfigurationSectionAdminData) {
    const errors: FormErrors<ExploitationAppConfigurationSectionAdminData> = {
    };

    if (values.inactivityReportAppTime === null || values.inactivityReportAppTime === undefined)
      errors.inactivityReportAppTime = I18n.tr(I18nKeys.TIEMPO_INACTIVIDAD_DENUNCIA_OBLIGATORIO);
    else if (values.inactivityReportAppTime < 0)
      errors.inactivityReportAppTime = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_POSITIVO);

    if (values.inactivityPositionAppTime === null || values.inactivityPositionAppTime === undefined)
      errors.inactivityPositionAppTime = I18n.tr(I18nKeys.TIEMPO_INACTIVIDAD_MOVIMIENTO_OBLIGATORIO);
    else if (values.inactivityPositionAppTime < 0)
      errors.inactivityPositionAppTime = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_POSITIVO);

    if (values.reportRatificationRadius === null || values.reportRatificationRadius === undefined)
      errors.reportRatificationRadius = I18n.tr(I18nKeys.RADIO_DENUNCIA_ADVERACION_OBLIGATORIO);
    else if (values.reportRatificationRadius < 0)
      errors.reportRatificationRadius = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_POSITIVO);

    if (values.workdayFinishTime === null || values.workdayFinishTime === undefined)
      errors.workdayFinishTime = I18n.tr(I18nKeys.TIEMPO_FINALIZACION_JORNADA_OBLIGATORIO);
    else if (values.workdayFinishTime < 0)
      errors.workdayFinishTime = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_POSITIVO);

    if (values.reportCreationTimeLimit === null || values.reportCreationTimeLimit === undefined)
      errors.reportCreationTimeLimit = I18n.tr(I18nKeys.TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_OBLIGATORIO);
    else if (values.reportCreationTimeLimit < 0)
      errors.reportCreationTimeLimit = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_POSITIVO);

    return errors;
  }

  public static getFormData(formData: Partial<Exploitation>): ExploitationAppConfigurationSectionAdminData {
    return {
      inactivityReportAppTime: formData.inactivity_report_app_time || 0,
      inactivityPositionAppTime: formData.inactivity_position_app_time || 0,
      dataDriverScreen: formData.data_driver_screen || false,
      reportRatificationRadius: formData.report_ratification_radius || 0,
      workdayFinishTime: formData.workday_finish_time || 0,
      reportCreationTimeLimit: formData.report_creation_time_limit || 0,
    };
  }
}
