import { FunctionComponent, SyntheticEvent } from 'react';
import { Checkbox } from '@material/react-checkbox';
import { CardBodyCheckboxProps } from './typings';

const onClick = (event: SyntheticEvent<HTMLInputElement>) => event.stopPropagation();

export const CardBodyCheckbox: FunctionComponent<CardBodyCheckboxProps> = (
    {
        checked,
        disabled,
        onChange,
    }
) => {
    const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        event.stopPropagation();
        onChange();
    };

    return (
        <td className='td-checkbox'>
            <Checkbox
                initRipple={() => null}
                unbounded={false}
                checked={checked}
                onChange={onChangeHandler}
                // @ts-ignore
                onClick={onClick}
                disabled={disabled}
            />
        </td>
    );
};
