import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskRouteDelete from "../../../../commons/ws/route/TaskRouteDelete";
import TaskRouteList from "../../../../commons/ws/route/TaskRouteList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_ROUTE_ADD } from "../../../../routing/Routes";
import RouteCompanyUserActions from "./RouteCompanyUserActions";
import RouteCompanyUserReducer from "./RouteCompanyUserReducer";
import RouteListTable from "./RouteListTable";

const mapActionsToProps = RouteCompanyUserActions.autoMapToProps();
const mapStateToProps = RouteCompanyUserReducer.autoMapToProps();

class RouteCardCompanyUser extends React.Component<typeof mapActionsToProps & typeof mapStateToProps> {
  public componentWillMount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const {loading, routes, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.RUTAS)}
        sort={{
          column: "name",
        }}
        TaskList={TaskRouteList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_RUTAS),
        }}
        data={routes}
        error={error}
        ItemsTable={RouteListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_RUTAS),
          Task: TaskRouteDelete,
        }}
        addItem={ROUTE_COMPANY_USER_ROUTE_ADD}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(RouteCardCompanyUser as unknown as React.ComponentType<void>);
