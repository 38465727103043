import BaseGenericRequest from '../BaseGenericRequest';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import TaskResponse, { BatchResponseCode } from '../TaskResponse';

interface IData {
    ids: string[]
}

export default class TaskReportSentToProcessStateClaim extends BaseGenericRequest<IData, TaskResponse<BatchResponseCode>> {

    private readonly data: IData;

    public constructor(reportIds: string[]) {
        super(METHOD.POST, Urls.URL_REPORT_SENT_TO_PROCESS_STATE_CLAIM);

        this.data = {
            ids: reportIds,
        };
    }

    protected getRequest(): IData {
        return this.data;
    }
}
