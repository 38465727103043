import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import ReportDetailsCompanyUserModalActions from './ReportDetailsCompanyUserModalActions';
import TaskReportInvalidationReasonList from '../../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonList';
import ReportInvalidationReason from '../../../../../commons/model/ReportInvalidationReason';
import ReportCancellationMethod from '../../../../../commons/model/ReportCancellationMethod';
import TaskReportCancellationMethodList from '../../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodList';

interface InitialState {
    reportOperationReasonId: string
    cancellationMethodReasons: ReportCancellationMethod[]
    cancellationMethodReasonsLoading: boolean
    invalidationReasons: ReportInvalidationReason[]
    invalidationReasonsLoading: boolean
}

const INITIAL_STATE: InitialState = {
    reportOperationReasonId: '-1',
    cancellationMethodReasons: [],
    cancellationMethodReasonsLoading: false,
    invalidationReasons: [],
    invalidationReasonsLoading: false,
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)

    .onEvent(
        ReportDetailsCompanyUserModalActions.dispatchResetModalReducer,
        () => ( {...INITIAL_STATE} )
    )
    .onEvent(
        ReportDetailsCompanyUserModalActions.dispatchChangeReportOperationReason,
        (oldState, payload) => ( {...oldState, reportOperationReasonId: payload} )
    )

    // - - - CANCELLATION METHOD REASONS

    .onEvent(
        TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, cancellationMethodReasonsLoading: true} )
    )
    .onEvent(
        TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, cancellationMethodReasonsLoading: false} )
    )
    .onEvent(
        TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, cancellationMethodReasons: payload.data} )
    )

    // - - - INVALIDATION REASONS

    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, invalidationReasonsLoading: true} )
    )
    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, invalidationReasonsLoading: false} )
    )
    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, invalidationReasons: payload.data} )
    )

    .build();
