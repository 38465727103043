
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_BRANDS } from "../../../../routing/Routes";
import VehicleBrandListCardAdmin from "./VehicleBrandListCardAdmin";

const VehicleBrandListAdminScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.MARCAS_DE_VEHICULOS),
      url: ROUTE_ADMIN_VEHICLE_BRANDS,
      icon: AppIcon.CAR,
    },
  ]}>
    <VehicleBrandListCardAdmin/>
  </ScreenCard>
);

export default VehicleBrandListAdminScreen;
