import { Component, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { BaseModalProps } from "../../../../base/modal/BaseModal";
import I18n from "../../../../commons/I18n/I18n";
import Button from "../../../../components/buttons/Button";
import Col from "../../../../components/Col";
import FormCol from "../../../../components/form/FormCol";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";

interface CollectionProcessBaseModalProps extends BaseModalProps {
    title: string;
    message: string;
    error?: boolean;
    realCash?: number;
    inputType?: string;
    onConfirmAction: (data: number | string)=> void;
}

interface CollectionProcessBaseModalState {
    value: number | string;
}

export default class CollectionProcessBaseModal extends Component<CollectionProcessBaseModalProps, CollectionProcessBaseModalState> {
  constructor(props: Readonly<CollectionProcessBaseModalProps>) {
    super(props);
    this.state = {
      value: props.realCash ? props.realCash : "",
    };
  }

  public componentDidUpdate(prevProps: Readonly<CollectionProcessBaseModalProps>) {
    if (this.props.realCash !== prevProps.realCash && this.props.realCash)
    {this.setState( {
      value: this.props.realCash,
    } );}

    else if (this.props.realCash !== prevProps.realCash && !this.props.realCash)
    {this.setState( {
      value: "",
    } );}
  }

  private handleOnKeyPressed = (key: string) => {
    if (key === "Enter" && this.state.value)
      this.props.onConfirmAction(Number(this.state.value));
  };

  public render(): ReactNode {
    const {title, message, show, onClose, error, onConfirmAction, inputType} = this.props;

    return (
      <Modal onHide={onClose} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {message}
          {
            error
              ? <p>{I18n.tr(I18nKeys.REVISE_IMPORTES_INTRODUCIDOS)}</p>
              :
              <FormCol>
                <div className="form-group">
                  <div className={"form-line"}>

                    {
                      inputType === "number" ?
                        <input
                          className={"form-control"}
                          type={inputType}
                          placeholder={I18n.tr(I18nKeys.IMPORTE)}
                          value={this.state.value}
                          onChange={(e) => this.setState( {
                            value: e.target.value,
                          } )}
                          onKeyPress={(e) => this.handleOnKeyPressed(e.key)}
                        />

                        :

                        <textarea
                          className={"form-control no-resize"}
                          placeholder={I18n.tr(I18nKeys.DETALLE_MOTIVO_DESFASE)}
                          rows={5}
                          value={this.state.value}
                          onChange={(e) => this.setState( {
                            value: e.target.value,
                          } )}
                        />
                    }

                  </div>
                </div>
              </FormCol>

          }
        </Modal.Body>

        <Modal.Footer>
          <Row>
            <Col sm={9} md={9} lg={9} xs={9}/>
            <Col sm={3} md={3} lg={3} xs={3}>
              <Button
                className={"btn btn-primary"}
                text={I18n.tr(I18nKeys.ACEPTAR).toUpperCase()}
                block={true}
                onClick={() => {
                  this.setState( {
                    value: "",
                  } );
                  onConfirmAction(this.state.value);
                  onClose();
                }}
                disabled={!this.state.value && !error}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
