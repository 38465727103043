import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import Route from "../../model/Route";

export default class TaskRouteDetail extends BaseGenericRequest<{}, TaskResponse<Route>> {

    public constructor(routeId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_ROUTE_DETAIL, {uuid: routeId}));
    }
}
