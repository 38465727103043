import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_EXPLOITATIONS } from "../../../../routing/Routes";
import ExploitationListCardAdmin from "./ExploitationListCardAdmin";

const ExploitationListAdminScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.EXPLOTACIONES),
      url: ROUTE_ADMIN_EXPLOITATIONS,
      icon: AppIcon.EXPLOITATION,
    },
  ]}>
    <ExploitationListCardAdmin/>
  </ScreenCard>
);

export default ExploitationListAdminScreen;
