import { Component, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { generateRoute } from "../../../../commons/utils/Router";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_COLLECTION_PROCESS, ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL } from "../../../../routing/Routes";
import CollectionProcessDetailDataContainer from "./CollectionProcessDetailDataContainer";

export default class CollectionProcessDetailScreen extends Component<RouteComponentProps<{id: string}>> {
  public render(): ReactNode {
    const {id} = this.props.match.params;

    return (
      <ScreenCard breadcrumbs={[
        {
          name: I18n.tr(I18nKeys.PROCESOS_RECAUDACION),
          url: ROUTE_ADMIN_COLLECTION_PROCESS,
          icon: AppIcon.EURO,
        },
        {
          name: I18n.tr(I18nKeys.PROCESO_RECAUDACION),
          url: generateRoute(ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL, {
            id,
          } ),
          icon: AppIcon.EURO,
        },
      ]}>
        <CollectionProcessDetailDataContainer id={id}/>
      </ScreenCard>
    );
  }
}
