import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_REPORT_INVALIDATION_REASONS } from "../../../../routing/Routes";
import ReportInvalidationReasonAdminListCard from "./ReportInvalidationReasonAdminListCard";

const ReportInvalidationReasonAdminListScreen = ()=>(
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.RAZONES_DE_INVALIDACION),
      url: ROUTE_ADMIN_REPORT_INVALIDATION_REASONS,
      icon: AppIcon.DASHBOARD,
    },
  ]}>
    <ReportInvalidationReasonAdminListCard/>
  </ScreenCard>
);

export default ReportInvalidationReasonAdminListScreen;
