import React from "react";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../../commons/I18n/I18n";
import ScreenCard from "../../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../../config/Config";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS } from "../../../../../routing/Routes";
import ReportCancelLogListCard from "./ReportCancelLogListCard";

export default class ReportCancelLogListScreen extends React.Component<RouteComponentProps<{ date: string }>> {
  public render(): React.ReactNode {
    const {date} = this.props.match.params;

    return (
      <ScreenCard breadcrumbs={[
        {
          name: I18n.tr(I18nKeys.LOGS_CANCELACION_DENUNCIAS),
          url: ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS,
          icon: AppIcon.REPORT,
        },
      ]}>
        <ReportCancelLogListCard date={date}/>
      </ScreenCard>
    );
  }
}
