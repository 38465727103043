import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { generateRoute } from '../../utils/Router';
import ReportCancellationMethod from '../../model/ReportCancellationMethod';

export default class TaskReportCancellationMethodDetail extends BaseGenericRequest<{}, TaskResponse<ReportCancellationMethod>> {

    public constructor(id: string) {
        super(METHOD.GET, generateRoute(Urls.URL_REPORT_CANCELLATION_METHOD_DETAIL, {uuid: id}));
    }
}
