import { METHOD } from "@cuatroochenta/co-generic-request";
import { sha256 } from "js-sha256";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import User, { UserData } from "../../model/User";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskUserAdd extends BaseGenericRequest<UserData, TaskResponse<User>> {
  private readonly data: UserData;

  public constructor(data: UserData) {
    super(METHOD.POST, Urls.URL_USER_ADD);
    this.data = data;

    this.setErrorMessage(488, I18n.tr(I18nKeys.YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF));
  }

  protected getRequest(): UserData {
    return {
      ...this.data,
      password: this.data.password ? sha256(this.data.password) : "",
    };
  }
}
