import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import ParkingLossReason from "../../../../commons/model/ParkingLossReason";
import TaskParkingLossReasonDetail from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonDetail";
import ParkingLossReasonFormAdminScreenActions from "./ParkingLossReasonFormAdminScreenActions";

interface InitialState {
    parkingLossReasonLoading: boolean,
    parkingLossReason?: ParkingLossReason,
}

const INITIAL_STATE: InitialState = {
    parkingLossReasonLoading: false,
    parkingLossReason: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskParkingLossReasonDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        parkingLossReasonLoading: true
    }))
    .onEvent(TaskParkingLossReasonDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        parkingLossReasonLoading: false
    }))
    .onEvent(TaskParkingLossReasonDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, parkingLossReason: payload.data};
        })

    .onEvent(ParkingLossReasonFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
