export enum ReportStateIds {
    PUESTA = '1',
    INVALIDADA = '2',
    ANULADA = '3',
}

export default interface ReportState {
    id: string
    name: string
}
