import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import Street from "../../model/Street";

export default class TaskStreetDetail extends BaseGenericRequest<{}, TaskResponse<Street>> {

    public constructor(streetId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_STREET_DETAIL, {uuid: streetId}));
    }
}
