import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import TaskUserDetail from "../../../../commons/ws/user/TaskUserDetail";
import UserFormAdminScreenActions from "./UserFormAdminScreenActions";
import User from "../../../../commons/model/User";

interface InitialState {
    userFormScreenLoading: boolean,
    user?: User,
}

const INITIAL_STATE: InitialState = {
    userFormScreenLoading: false,
    user: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUserDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        userFormScreenLoading: true
    }))
    .onEvent(TaskUserDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        userFormScreenLoading: false
    }))
    .onEvent(TaskUserDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, user: payload.data};
        })

    .onEvent(UserFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
