
import { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { StyleProps } from '../../components-new/typings';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - PROPS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export interface DetailsTextProps {
    label: string;
    imageWidth?: string;
    imageHeight?: string;
    images: string[];
}

type Props = DetailsTextProps & StyleProps;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default class DetailsImage extends Component<Props> {

    public render(): ReactNode {
        const {label, imageWidth, imageHeight, images, className, style} = this.props;
        const classComponent = classNames('details-image', className);
        const noImages = images.length === 0;
        const oneImage = images.length === 1;

        return (
            <div className={classComponent} style={style}>
                <div className='dt-label'>
                    {label}
                </div>
                {
                    noImages ? null :
                        oneImage ?
                            <div className='dt-image'>
                                <img
                                    width={'100%'}
                                    height={'100%'}
                                    src={images[0]}
                                />
                            </div>
                            :
                            <div className='dt-images'>
                                {
                                    images.map((image: string, index: number) => {
                                        return (
                                            <img alt={''}
                                                 key={index}
                                                 width={imageWidth}
                                                 height={imageHeight}
                                                 src={image}
                                            /> );
                                    })
                                }
                            </div>
                }
            </div>
        );
    }

}
