import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {PagerBackendProps} from "../../../../components/Pager";
import User from "../../../../commons/model/User";
import TaskUserList from "../../../../commons/ws/user/TaskUserList";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";
import ParkingMeter from "../../../../commons/model/ParkingMeter";
import TaskParkingMeterCollectionList
    from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionList";
import TaskParkingMeterList from "../../../../commons/ws/parkingMeter/TaskParkingMeterList";

interface InitialState {
    loading: boolean,
    parkingMeterCollections: ParkingMeterCollection[],
    error: string,
    pager: PagerBackendProps,

    parkingMeterList: ParkingMeter[],
    userList: User[],
}

const INITIAL_STATE: InitialState = {
    loading: false,
    parkingMeterCollections: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },

    parkingMeterList: [],
    userList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskParkingMeterCollectionList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskParkingMeterCollectionList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskParkingMeterCollectionList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskParkingMeterCollectionList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            parkingMeterCollections: payload.data,
            pager: payload.pager || oldState.pager
        }
    })

    .onEvent(TaskParkingMeterList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            parkingMeterList: payload.data,
        }
    })

    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            userList: payload.data,
        }
    })

    .build()
