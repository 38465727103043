/* eslint-disable max-classes-per-file */
import { Component } from "react";
import { Field, FormErrors, WrappedFieldArrayProps } from "redux-form";
import BaseFormFieldsHelpers from "../../../../../base/form/BaseFormFieldsHelpers";
import I18n from "../../../../../commons/I18n/I18n";
import Exploitation from "../../../../../commons/model/Exploitation";
import Weekday, { Weekdays } from "../../../../../commons/model/Weekday";
import WorkingSchedule, {WorkingScheduleData,
  WorkingScheduleReduxForm} from "../../../../../commons/model/WorkingSchedule";
import { ButtonRoundAdd, ButtonRoundRemove } from "../../../../../components/buttons/ButtonRound";
import CardCollapsed from "../../../../../components/card/CardCollapsed";
import Col from "../../../../../components/Col";
import FormCheckboxGroup from "../../../../../components/form/FormCheckboxGroup";
import FormInputOption from "../../../../../components/form/FormInputOption";
import FormTime from "../../../../../components/form/FormTime";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
// eslint-disable-next-line import/no-cycle
import { ExploitationCardFormAdminData } from "../ExploitationCardFormAdmin";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - AUXILIARY
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface ExploitationWorkingScheduleSectionAdminData {
    workingSchedules: WorkingScheduleReduxForm[];
}

type ExploitationWorkingScheduleSectionAdminErrors =
    FormErrors<{hidden: string}, string>;

enum ExploitationWorkingScheduleSectionAdminFields {
    MORNING_START_TIME = "morningStartTime",
    MORNING_END_TIME = "morningEndTime",
    AFTERNOON_START_TIME = "afternoonStartTime",
    AFTERNOON_END_TIME = "afternoonEndTime",
    WEEKDAYS = "weekdays",
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PROPERTIES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface InnerProps {
    workingScheduleValues: WorkingScheduleReduxForm[];
}

type Props = InnerProps & WrappedFieldArrayProps<WorkingScheduleReduxForm>;

interface State {
    collapsed: boolean;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class ExploitationWorkingScheduleSectionAdmin extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  public render() {
    const {collapsed} = this.state;
    const {meta,
      fields,
      workingScheduleValues} = this.props;
    let itemErrors: string[][] = [];

    if (meta.submitFailed)
      itemErrors = ExploitationWorkingScheduleSectionAdminHelper.validateArray(workingScheduleValues);

    const generalErrors: string[] = fields.length + 1 === itemErrors.length
      ? itemErrors[itemErrors.length - 1]
      : [];

    return (
      <CardCollapsed
        title={I18n.tr(I18nKeys.CONTROL_DE_LA_JORNADA)}
        collapsedHandler={this.handleCollapse}
        collapsed={collapsed}
        invalid={false}
        className={"exploitation-form-section"}
      >
        {
          fields.map((member: string, index: number) =>
            this.renderArrayItem(member, index, itemErrors[index]))
        }
        {
          generalErrors.map((error: string, index: number) =>
            <Row key={index} style={{
              paddingRight: 15,
              paddingLeft: 15,
            }}>
              <div className={"wsi-error"}>{error}</div>
            </Row>,
          )
        }
        <div className={"wsi-buttons"}>
          <ButtonRoundAdd onClick={this.handleAdd}/>
        </div>
      </CardCollapsed>
    );
  }

  private renderArrayItem = (member: string, index: number, errors: string[] | undefined) => {
    const {fields} = this.props;
    const weekdaysOptions: FormInputOption[] = getWeekdayOptions();
    const names = {
      morningStartTime: `${member}.${ExploitationWorkingScheduleSectionAdminFields.MORNING_START_TIME}`,
      morningEndTime: `${member}.${ExploitationWorkingScheduleSectionAdminFields.MORNING_END_TIME}`,
      afternoonStartTime: `${member}.${ExploitationWorkingScheduleSectionAdminFields.AFTERNOON_START_TIME}`,
      afternoonEndTime: `${member}.${ExploitationWorkingScheduleSectionAdminFields.AFTERNOON_END_TIME}`,
      weekdays: `${member}.${ExploitationWorkingScheduleSectionAdminFields.WEEKDAYS}`,
    };

    return (
      <div key={index} className={"wsi-empty"}>
        <Row>
          <Col className={"wsi-col"} md={10} lg={10}>
            <Row>I18n
              <Field
                label={I18n.tr(I18nKeys.MANANA_HORA_INICIO)}
                name={names.morningStartTime}
                component={FormTime}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />I18n
              <Field
                label={I18n.tr(I18nKeys.MANANA_HORA_FIN)}
                name={names.morningEndTime}
                component={FormTime}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />I18n
              <Field
                label={I18n.tr(I18nKeys.TARDE_HORA_INICIO)}
                name={names.afternoonStartTime}
                component={FormTime}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />I18n
              <Field
                label={I18n.tr(I18nKeys.TARDE_HORA_FIN)}
                name={names.afternoonEndTime}
                component={FormTime}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />
            </Row>
            <Row>I18n
              <Field
                label={I18n.tr(I18nKeys.DIAS_DE_LA_SEMANA)}
                name={names.weekdays}
                component={FormCheckboxGroup}
                col={{
                  md: 12,
                  lg: 12,
                }}
                options={weekdaysOptions}
              />
            </Row>
          </Col>
          <Col className={"wsi-col"} md={2} lg={2}>
            <div className={"wsi-buttons"}>
              <ButtonRoundRemove onClick={() => fields.remove(index)}/>
            </div>
          </Col>
        </Row>
        {
          errors && errors.map((error: string, index2: number) =>
            <Row key={index2} style={{
              paddingRight: 15,
              paddingLeft: 15,
            }}>
              <div className={"wsi-error"}>{error}</div>
            </Row>,
          )
        }
      </div>
    );
  };

  private handleCollapse = () => {
    this.setState((prevState: State) => ( {
      collapsed: !prevState.collapsed,
    } ));
  };

  private handleAdd = () => {
    this.props.fields.push( {
      morningStartTime: "",
      morningEndTime: "",
      afternoonStartTime: "",
      afternoonEndTime: "",
      weekdays: [],
    } );
  };
}

function getWeekdayOptions(): FormInputOption[] {
  return [
    {
      name: I18n.tr(I18nKeys.LUNES),
      value: Weekdays.MONDAY,
    },
    {
      name: I18n.tr(I18nKeys.MARTES),
      value: Weekdays.TUESDAY,
    },
    {
      name: I18n.tr(I18nKeys.MIERCOLES),
      value: Weekdays.WEDNESDAY,
    },
    {
      name: I18n.tr(I18nKeys.JUEVES),
      value: Weekdays.THURSDAY,
    },
    {
      name: I18n.tr(I18nKeys.VIERNES),
      value: Weekdays.FRIDAY,
    },
    {
      name: I18n.tr(I18nKeys.SABADO),
      value: Weekdays.SATURDAY,
    },
    {
      name: I18n.tr(I18nKeys.DOMINGO),
      value: Weekdays.SUNDAY,
    },
  ];
}

export default ExploitationWorkingScheduleSectionAdmin;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - FIELDS HELPER
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export class ExploitationWorkingScheduleSectionAdminHelper extends BaseFormFieldsHelpers {
  public static validate(values: ExploitationCardFormAdminData): ExploitationWorkingScheduleSectionAdminErrors {
    const errors: string[][] = ExploitationWorkingScheduleSectionAdminHelper
      .validateArray(values.workingSchedules);

    return {
      hidden: errors[0] && errors[0].length > 0 ? "Error en horarios de trabajo" : undefined,
    };
  }

  public static getFormData(values: Partial<Exploitation>): ExploitationWorkingScheduleSectionAdminData {
    return {
      workingSchedules: values.working_schedules
        ? values
          .working_schedules
          .map((ws: WorkingSchedule) => ( {
            morningStartTime: ws.morning_start_time ? ws.morning_start_time : "",
            morningEndTime: ws.morning_end_time ? ws.morning_end_time : "",
            afternoonStartTime: ws.afternoon_start_time ? ws.afternoon_start_time : "",
            afternoonEndTime: ws.afternoon_end_time ? ws.afternoon_end_time : "",
            weekdays: ws.weekdays.map((weekday: Weekday) => weekday.id),
          } ))
        : [],
    };
  }

  public static getSubmitData(values: ExploitationCardFormAdminData): WorkingScheduleData[] {
    if (values.workingSchedules) {
      return values
        .workingSchedules
        .map((wsrf: WorkingScheduleReduxForm) => ( {
          morning_start_time: wsrf.morningStartTime !== ""
            ? ExploitationWorkingScheduleSectionAdminHelper.addZeros(wsrf.morningStartTime)
            : null,
          morning_end_time: wsrf.morningEndTime !== ""
            ? ExploitationWorkingScheduleSectionAdminHelper.addZeros(wsrf.morningEndTime)
            : null,
          afternoon_start_time: wsrf.afternoonStartTime !== ""
            ? ExploitationWorkingScheduleSectionAdminHelper.addZeros(wsrf.afternoonStartTime)
            : null,
          afternoon_end_time: wsrf.afternoonEndTime !== ""
            ? ExploitationWorkingScheduleSectionAdminHelper.addZeros(wsrf.afternoonEndTime)
            : null,
          weekdays: wsrf.weekdays,
        } ));
    }

    return [];
  };

  // En backend espera un formato 00:00:00, pero en frontend se genera 00:00 -> Parseador
  public static addZeros(time: string): string {
    return time.length === 5 ? `${time}:00` : time;
  }

  public static validateArray(wsrfArray?: WorkingScheduleReduxForm[]): string[][] {
    const errors: string[][] = [];

    if (wsrfArray) {
      wsrfArray.forEach((wsrf: WorkingScheduleReduxForm) => {
        errors.push(ExploitationWorkingScheduleSectionAdminHelper.validateItem(wsrf));
      } );

      // Comprueba que ningún día se repite
      let hasNoDuplicatedDays: boolean = true;
      const weekdays: string[] = [];

      wsrfArray.forEach((wsrf: WorkingScheduleReduxForm) => {
        weekdays.push(...wsrf.weekdays);
      } );
      weekdays.sort();
      weekdays.forEach((weekday: string, index: number) => {
        if (index > 0) {
          hasNoDuplicatedDays = hasNoDuplicatedDays
                        && (weekday !== weekdays[index - 1]); // Comprueba duplicados
        }
      } );

      if (!hasNoDuplicatedDays)
        errors.push([I18n.tr(I18nKeys.EXISTEN_DIAS_REPETIDOS)]);
    }

    return errors;
  }

  private static validateItem(wsrf: WorkingScheduleReduxForm): string[] {
    const {morningStartTime,
      morningEndTime,
      afternoonStartTime,
      afternoonEndTime,
      weekdays} = wsrf;
    const errors: string[] = [];
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // - - - HORARIOS
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Comprueba existen horarios
    const noMorningTimes: boolean = !morningStartTime && !morningEndTime;
    const noAfternoonTimes: boolean = !afternoonStartTime && !afternoonEndTime;
    const noTimes: boolean = noMorningTimes && noAfternoonTimes;

    if (noTimes)
      errors.push(I18n.tr(I18nKeys.NO_HAY_HORARIOS_INTRODUCIDOS));

    if (!noTimes) {
      // Comprueba horario mañana incompleto
      const isMorningIncomplete: boolean =
                (!morningStartTime && !!morningEndTime) ||
                (!!morningStartTime && !morningEndTime);

      if (isMorningIncomplete)
        errors.push(I18n.tr(I18nKeys.HORARIO_DE_MANANA_INCOMPLETO));

      // Comprueba horario mañana correcto
      if (!noMorningTimes && !isMorningIncomplete) {
        if (morningStartTime >= morningEndTime)
          errors.push(I18n.tr(I18nKeys.EL_HORARIO_DE_INICIO_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_DIA_DE_FIN_DE_MANANA));
      }

      // Comprueba horario tarde incompleto
      const isAfternoonIncomplete: boolean =
                (!afternoonStartTime && !!afternoonEndTime) ||
                (!!afternoonStartTime && !afternoonEndTime);

      if (isAfternoonIncomplete)
        errors.push(I18n.tr(I18nKeys.HORARIO_DE_TARDE_INCOMPLETO));

      // Comprueba horario tarde correcto
      if (!noAfternoonTimes && !isAfternoonIncomplete) {
        if (afternoonStartTime >= afternoonEndTime)
          errors.push(I18n.tr(I18nKeys.EL_HORARIO_DE_INICIO_DE_TARDE_DEBE_SER_INFERIOR_AL_DE_FIN_DE_TARDE));
      }

      // Comprueba que no se solapan horarios
      if (!noMorningTimes && !isMorningIncomplete && !noAfternoonTimes && !isAfternoonIncomplete) {
        if (morningEndTime >= afternoonStartTime)
          errors.push(I18n.tr(I18nKeys.EL_HORARIO_DE_FIN_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_INICIO_DE_TARDE));
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // - - - DÍAS DE LA SEMANA
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // Comprueba existen días de la semana
    const noWeekdays: boolean = !weekdays || weekdays.length === 0;

    if (noWeekdays)
      errors.push(I18n.tr(I18nKeys.HAY_DIAS_DE_LA_SEMANA_SELECCIONADA));

    return errors;
  }
}
