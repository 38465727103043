import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import Route, { RouteData } from "../../model/Route";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskRouteAdd extends BaseGenericRequest<RouteData, TaskResponse<Route>> {
  private readonly data: RouteData;

  public constructor(data: RouteData) {
    super(METHOD.POST, Urls.URL_ROUTE_ADD);
    this.data = data;

    this.setErrorMessage(488, I18n.tr(I18nKeys.YA_EXISTE_UN_ELEMENTO_SIMILAR));
  }

  protected getRequest(): RouteData {
    return this.data;
  }
}
