
import React from "react";
import { Modal } from "react-bootstrap";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import { BaseModalProps } from "../../../base/modal/BaseModal";
import I18n from "../../../commons/I18n/I18n";
import { FILE_CERT_EXTENSION, FILE_CERT_TYPE } from "../../../commons/model/UserCert";
import FormCardFooter from "../../../components/form/FormCardFooter";
import FormInput from "../../../components/form/FormInput";
import FormInputFile from "../../../components/form/FormInputFile";
import Row from "../../../components/Row";
import I18nKeys from "../../../I18n/I18nKeys";

const FORM_NAME = "UserCertUploadForm";

export interface UserCertCompanyUserUploadModalData {
    files: File | FileList;
    password: string;
}

interface UserCertCompanyUserUploadModalExtProps {
    show: boolean;
    onSubmit: (data: UserCertCompanyUserUploadModalData)=> void;
    onClose: ()=> void;
    title?: string;
}

enum UserCertCompanyUserUploadModalFields {
    FILE = "files",
    PASSWORD = "password",
}

type Props = BaseModalProps & InjectedFormProps<UserCertCompanyUserUploadModalData> & UserCertCompanyUserUploadModalExtProps;

class UserCertCompanyUserUploadModal extends React.Component<Props> {
  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.show !== prevProps.show) {
      if (this.props.show)
        this.props.reset();
    }
  }

  public render(): React.ReactNode {
    const {show, onSubmit, onClose, handleSubmit, invalid, pristine, title} = this.props;

    return (
      <Modal onHide={onClose} show={show} bsSize={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>{title || I18n.tr(I18nKeys.CREAR_CERTIFICADO)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.CONTRASENA)}
                name={UserCertCompanyUserUploadModalFields.PASSWORD}
                component={FormInput}
                type="password"
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.CERTIFICADO)}
                name={UserCertCompanyUserUploadModalFields.FILE}
                component={FormInputFile}
                accept={FILE_CERT_TYPE}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>

            <FormCardFooter
              isUpdate={false}
              invalid={invalid}
              pristine={pristine}
              cancelHandler={onClose}/>
          </form>
        </Modal.Body>

      </Modal>
    );
  }
}

function validate(values: UserCertCompanyUserUploadModalData) {
  const errors: FormErrors<UserCertCompanyUserUploadModalData> = {
  };

  if (!values.password || values.password.length === 0)
    errors.password = I18n.tr(I18nKeys.CONTRASENA_OBLIGATORIA);

  if (!values.files || (values.files instanceof FileList && values.files.length === 0))
    errors.files = I18n.tr(I18nKeys.CERTIFICADO_OBLIGATORIO);
  else if (values.files instanceof File) {
    if (values.files && !!values.files.name && values.files.name !== FILE_CERT_TYPE) { // tipo File
      errors.files = `${I18n.tr(I18nKeys.SOLO_SE_ADMITEN_FICHEROS_DE_TIPO)} ${FILE_CERT_EXTENSION}`;
    }
  } else if (values.files instanceof FileList) {
    if (values.files && !!values.files.length && values.files[0].type !== FILE_CERT_TYPE) { // tipo FileList
      errors.files = `${I18n.tr(I18nKeys.SOLO_SE_ADMITEN_FICHEROS_DE_TIPO)} ${FILE_CERT_EXTENSION}`;
    }
  }

  return errors;
}

export default (reduxForm<UserCertCompanyUserUploadModalData>( {
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
} )(UserCertCompanyUserUploadModal) as any) as React.ComponentType<UserCertCompanyUserUploadModalExtProps>;
