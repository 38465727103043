export enum ReportSentToProcessStateIds {
    NO_ENVIADA = '1',
    PENDIENTE_DE_ENVIAR = '2',
    ENVIADA = '3',
    DUPLICADA = '5',
}

export default interface ReportSentToProcessState {
    id: string
    name: string
}
