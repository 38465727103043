import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {DashboardInformation} from "../../commons/model/Dashboard";
import TaskDashboardInformation from "../../commons/ws/dashboard/TaskDashboardInformation";

interface InitialState {
    information: DashboardInformation | null
    informationError: string
    informationLoading: boolean
}

const INITIAL_STATE: InitialState = {
    information: null,
    informationError: '',
    informationLoading: false,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskDashboardInformation.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => ({...oldState, informationLoading: true}))
    .onEvent(TaskDashboardInformation.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => ({...oldState, informationLoading: false}))
    .onEvent(TaskDashboardInformation.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, informationError: payload}))
    .onEvent(TaskDashboardInformation.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, information: payload.data}))

    .build()
