import * as  React from 'react';
import {ReactNode} from 'react';
import {RouterProps} from 'react-router';
import {Route, Router} from 'react-router-dom';
import {
    ROUTE_ADMIN_COLLECTION_PROCESS,
    ROUTE_ADMIN_COLLECTION_PROCESS_ADD,
    ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL,
    ROUTE_ADMIN_EXPLOITATION_ADD,
    ROUTE_ADMIN_EXPLOITATION_DETAIL,
    ROUTE_ADMIN_EXPLOITATIONS,
    ROUTE_ADMIN_LAW_TYPE_ADD,
    ROUTE_ADMIN_LAW_TYPE_DETAIL,
    ROUTE_ADMIN_LAW_TYPES,
    ROUTE_ADMIN_PARKING_LOSS_REASON_ADD,
    ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL,
    ROUTE_ADMIN_PARKING_LOSS_REASONS,
    ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD,
    ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL,
    ROUTE_ADMIN_REPORT_CANCELLATION_METHODS,
    ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD,
    ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL,
    ROUTE_ADMIN_REPORT_INVALIDATION_REASONS,
    ROUTE_ADMIN_SETTINGS_APP,
    ROUTE_ADMIN_USER_ADD,
    ROUTE_ADMIN_USER_DETAIL,
    ROUTE_ADMIN_USERS,
    ROUTE_ADMIN_VEHICLE_BRAND_ADD,
    ROUTE_ADMIN_VEHICLE_BRAND_DETAIL,
    ROUTE_ADMIN_VEHICLE_BRANDS,
    ROUTE_ADMIN_VEHICLE_COLOR_ADD,
    ROUTE_ADMIN_VEHICLE_COLOR_DETAIL,
    ROUTE_ADMIN_VEHICLE_COLORS,
    ROUTE_ADMIN_VEHICLE_MODEL_ADD,
    ROUTE_ADMIN_VEHICLE_MODEL_DETAIL,
    ROUTE_ADMIN_VEHICLE_MODELS,
    ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD,
    ROUTE_COMPANY_USER_BLACK_LIST_RULE_DETAIL,
    ROUTE_COMPANY_USER_BLACK_LIST_RULES,
    ROUTE_COMPANY_USER_BROADCAST,
    ROUTE_COMPANY_USER_BROADCAST_ADD,
    ROUTE_COMPANY_USER_CHAT,
    ROUTE_COMPANY_USER_CONTROLLERS,
    ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION,
    ROUTE_COMPANY_USER_DELTA_CAR_ADD,
    ROUTE_COMPANY_USER_DELTA_CAR_DETAIL,
    ROUTE_COMPANY_USER_DELTA_CARS,
    ROUTE_COMPANY_USER_LAW_ADD,
    ROUTE_COMPANY_USER_LAW_DETAIL,
    ROUTE_COMPANY_USER_LAWS,
    ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD,
    ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL,
    ROUTE_COMPANY_USER_PARKING_AREA_TYPES,
    ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL,
    ROUTE_COMPANY_USER_PARKING_LOSSES,
    ROUTE_COMPANY_USER_PARKING_METER_ADD,
    ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL,
    ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS,
    ROUTE_COMPANY_USER_PARKING_METER_DETAIL,
    ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL,
    ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES,
    ROUTE_COMPANY_USER_PARKING_METERS,
    ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL,
    ROUTE_COMPANY_USER_RATIFICATION_REPORTS,
    ROUTE_COMPANY_USER_REPORT_CANCELLATION_FILE,
    ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS,
    ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS_DATE,
    ROUTE_COMPANY_USER_REPORT_DETAIL,
    ROUTE_COMPANY_USER_REPORTS,
    ROUTE_COMPANY_USER_ROUTE_ADD,
    ROUTE_COMPANY_USER_ROUTE_DETAIL,
    ROUTE_COMPANY_USER_ROUTES,
    ROUTE_COMPANY_USER_SENDTOPROCESS,
    ROUTE_COMPANY_USER_SENDTOPROCESS_FILE,
    ROUTE_COMPANY_USER_STREET_ADD,
    ROUTE_COMPANY_USER_STREET_DETAIL,
    ROUTE_COMPANY_USER_STREETS,
    ROUTE_COMPANY_USER_VEHICLE_ADD,
    ROUTE_COMPANY_USER_VEHICLE_DETAIL,
    ROUTE_COMPANY_USER_VEHICLES,
    ROUTE_COMPLAINT_VIEWER_REPORT_DETAIL,
    ROUTE_COMPLAINT_VIEWER_REPORTS,
    ROUTE_COMPLAINT_VIEWER_SENT_REPORT_DETAIL,
    ROUTE_COMPLAINT_VIEWER_SENT_REPORTS,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_POLICE_REPORT_DETAIL,
    ROUTE_POLICE_REPORTS,
    ROUTE_SET_NEW_PASSWORD,
    ROUTE_USER_CERT,
    ROUTE_PROFILE
} from './Routes';
// Para mostrar los dialogos de alerta correctamente
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import Alert from '../base/alerts/Alert';
import LoginScreen from '../modules/auth/login/LoginScreen';
import HomeScreen from '../modules/home/HomeScreen';
import SetNewPasswordScreen from '../modules/auth/setNewPassword/SetNewPasswordScreen';
import ExploitationListAdminScreen from '../modules/admin/exploitations/list/ExploitationListAdminScreen';
import ExploitationFormAdminScreen from '../modules/admin/exploitations/form/ExploitationFormAdminScreen';
import UserListAdminScreen from '../modules/admin/users/list/UserListAdminScreen';
import UserFormAdminScreen from '../modules/admin/users/formscreen/UserFormAdminScreen';
import StreetCompanyUserScreen from '../modules/companyUser/street/list/StreetCompanyUserScreen';
import RouteCompanyUserScreen from '../modules/companyUser/routes/list/RouteCompanyUserScreen';
import RouteFormCompanyUserScreen from '../modules/companyUser/routes/formscreen/RouteFormCompanyUserScreen';
import StreetFormCompanyUserScreen from '../modules/companyUser/street/formscreen/StreetFormCompanyUserScreen';
import ParkingMeterListCompanyUserScreen
    from '../modules/companyUser/parkingMeter/list/ParkingMeterListCompanyUserScreen';
import ParkingMeterFormCompanyUserScreen
    from '../modules/companyUser/parkingMeter/formscreen/ParkingMeterFormCompanyUserScreen';
import ParkingMeterIncidenceListCompanyUserScreen
    from '../modules/companyUser/parkingMeterIncidence/list/ParkingMeterIncidenceListCompanyUserScreen';
import ParkingMeterIncidenceFormCompanyUserScreen
    from '../modules/companyUser/parkingMeterIncidence/formscreen/ParkingMeterIncidenceFormCompanyUserScreen';
import ParkingMeterCollectionListCompanyUserScreen
    from '../modules/companyUser/parkingMeterCollection/list/ParkingMeterCollectionListCompanyUserScreen';
import ParkingMeterCollectionFormCompanyUserScreen
    from '../modules/companyUser/parkingMeterCollection/formscreen/ParkingMeterCollectionFormCompanyUserScreen';
import ParkingLossListCompanyUserScreen from '../modules/companyUser/parkingLoss/list/ParkingLossListCompanyUserScreen';
import ParkingLossFormCompanyUserScreen
    from '../modules/companyUser/parkingLoss/formscreen/ParkingLossFormCompanyUserScreen';
import ReportListCompanyUserScreen from '../modules/companyUser/report/list/ReportListCompanyUserScreen';
import ReportDetailsCompanyUserScreen from '../modules/companyUser/report/details/ReportDetailsCompanyUserScreen';
import LawListCompanyUserScreen from "../modules/companyUser/law/list/LawListCompanyUserScreen";
import LawFormCompanyUserScreen from "../modules/companyUser/law/formscreen/LawFormCompanyUserScreen";
import BlackListRuleListCompanyUserScreen
    from "../modules/companyUser/blackListRule/list/BlackListRuleListCompanyUserScreen";
import BlackListRuleFormCompanyUserScreen
    from "../modules/companyUser/blackListRule/formscreen/BlackListRuleFormCompanyUserScreen";
import RatificationReportListCompanyUserScreen
    from "../modules/companyUser/ratificationReport/list/RatificationReportListCompanyUserScreen";
import ControllerSituationMapCompanyUserScreen
    from "../modules/companyUser/controller/controllerSituationMap/ControllerSituationMapCompanyUserScreen";
import RatificationReportDetailsCompanyUserScreen
    from "../modules/companyUser/ratificationReport/details/RatificationReportDetailsCompanyUserScreen";
import ControllerReportCompanyUserScreen
    from "../modules/companyUser/controller/controllerReport/list/ControllerReportCompanyUserScreen";
import ParkingAreaTypeCompanyUserFormScreen
    from '../modules/companyUser/parkingAreaType/formscreen/ParkingAreaTypeCompanyUserFormScreen';
import ParkingAreaTypeCompanyUserListScreen
    from '../modules/companyUser/parkingAreaType/list/ParkingAreaTypeCompanyUserListScreen';
import ReportCancellationMethodListAdminScreen
    from "../modules/admin/reportCancellationMethod/list/ReportCancellationMethodListAdminScreen";
import ReportCancellationMethodFormAdminScreen
    from "../modules/admin/reportCancellationMethod/formscreen/ReportCancellationMethodFormAdminScreen";
import VehicleColorListAdminScreen from "../modules/admin/vehicleColor/list/VehicleColorListAdminScreen";
import VehicleColorFormAdminScreen from "../modules/admin/vehicleColor/formscreen/VehicleColorFormAdminScreen";
import VehicleModelListAdminScreen from "../modules/admin/vehicleModel/list/VehicleModelListAdminScreen";
import VehicleBrandFormAdminScreen from "../modules/admin/vehicleBrand/formscreen/VehicleBrandFormAdminScreen";
import VehicleBrandListAdminScreen from "../modules/admin/vehicleBrand/list/VehicleBrandListAdminScreen";
import VehicleModelFormAdminScreen from "../modules/admin/vehicleModel/formscreen/VehicleModelFormAdminScreen";
import VehicleListCompanyUserScreen from "../modules/companyUser/vehicle/list/VehicleListCompanyUserScreen";
import VehicleFormCompanyUserScreen from "../modules/companyUser/vehicle/formscreen/VehicleFormCompanyUserScreen";
import ReportInvalidationReasonAdminListScreen
    from '../modules/admin/reportInvalidationReasons/list/ReportInvalidationReasonAdminListScreen';
import ReportInvalidationReasonAdminFormScreen
    from '../modules/admin/reportInvalidationReasons/formscreen/ReportInvalidationReasonAdminFormScreen';
import ChatCompanyUserScreen from "../modules/companyUser/chat/ChatCompanyUserScreen";
import ChatBroadcastCompanyUserScreen from "../modules/companyUser/chat/broadcast/list/ChatBroadcastCompanyUserScreen";
import ChatBroadcastFormCompanyUserScreen
    from "../modules/companyUser/chat/broadcast/formscreen/ChatBroadcastFormCompanyUserScreen";
import SendToProcessScreen from '../modules/companyUser/sendToProcess/SendToProcessScreen';
import SendToProcessFileScreen from '../modules/companyUser/sendToProcess/file/SendToProcessFileScreen';
import LawTypeListAdminScreen from "../modules/admin/lawType/list/LawTypeListAdminScreen";
import LawTypeFormAdminScreen from "../modules/admin/lawType/formscreen/LawTypeFormAdminScreen";
import ParkingLossReasonListAdminScreen from "../modules/admin/parkingLossReason/list/ParkingLossReasonListAdminScreen";
import ParkingLossReasonFormAdminScreen
    from "../modules/admin/parkingLossReason/formscreen/ParkingLossReasonFormAdminScreen";
import ReportListComplaintViewerSentScreen
    from "../modules/complaintViewerSent/report/list/ReportListComplaintViewerSentScreen";
import ReportCancellationByFileFormScreen
    from "../modules/companyUser/report/cancel/file/ReportCancellationByFileFormScreen";
import ReportCancelLogListScreen from "../modules/companyUser/report/cancel/log/ReportCancelLogListScreen";
import UserCertCompanyUserScreen from "../modules/companyUser/userCert/UserCertCompanyUserScreen";
import AppSettingsFormAdminScreen from '../modules/admin/appSettings/formdetail/AppSettingsFormAdminScreen';
import CollectionProcessAdminScreen from "../modules/admin/collectionprocessadmin/list/CollectionProcessAdminScreen";
import CollectionProcessDetailScreen
    from "../modules/admin/collectionprocessadmin/detail/CollectionProcessDetailScreen";
import CollectionProcessAddScreen from "../modules/admin/collectionprocessadmin/add/CollectionProcessAddScreen";
import DeltaCarListCompanyUserScreen from "../modules/companyUser/deltaCar/list/DeltaCarListCompanyUserScreen";
import DeltaCarFormCompanyUserScreen from "../modules/companyUser/deltaCar/formscreen/DeltaCarFormCompanyUserScreen";
import  Profile  from '../modules/profile/Profile';

export default class Routing extends React.Component<RouterProps> {

    public render(): ReactNode {
        return (
            <Router history={this.props.history}>
                <div>
                    <Route path="/" component={Alert}/>
                    <Route path={ROUTE_LOGIN} exact component={LoginScreen}/>
                    <Route path={ROUTE_SET_NEW_PASSWORD} exact component={SetNewPasswordScreen}/>

                    <Route path={ROUTE_HOME} exact component={HomeScreen}/>

                    <Route path={ROUTE_ADMIN_SETTINGS_APP} exact component={AppSettingsFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_EXPLOITATIONS} exact component={ExploitationListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_EXPLOITATION_ADD} exact component={ExploitationFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_EXPLOITATION_DETAIL} exact component={ExploitationFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_USERS} exact component={UserListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_USER_ADD} exact component={UserFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_USER_DETAIL} exact component={UserFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_REPORT_INVALIDATION_REASONS} exact component={ReportInvalidationReasonAdminListScreen}/>
                    <Route path={ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD} exact component={ReportInvalidationReasonAdminFormScreen}/>
                    <Route path={ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL} exact component={ReportInvalidationReasonAdminFormScreen}/>

                    <Route path={ROUTE_ADMIN_LAW_TYPES} exact component={LawTypeListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_LAW_TYPE_ADD} exact component={LawTypeFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_LAW_TYPE_DETAIL} exact component={LawTypeFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_PARKING_LOSS_REASONS} exact component={ParkingLossReasonListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_PARKING_LOSS_REASON_ADD} exact component={ParkingLossReasonFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL} exact component={ParkingLossReasonFormAdminScreen}/>

                    <Route path={ROUTE_COMPANY_USER_PARKING_AREA_TYPES} exact component={ParkingAreaTypeCompanyUserListScreen}/>
                    <Route path={ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD} exact component={ParkingAreaTypeCompanyUserFormScreen}/>
                    <Route path={ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL} exact component={ParkingAreaTypeCompanyUserFormScreen}/>

                    <Route path={ROUTE_COMPANY_USER_ROUTES} exact component={RouteCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_ROUTE_ADD} exact component={RouteFormCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_ROUTE_DETAIL} exact component={RouteFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_STREETS} exact component={StreetCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_STREET_ADD} exact component={StreetFormCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_STREET_DETAIL} exact component={StreetFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_PARKING_METERS} exact component={ParkingMeterListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_PARKING_METER_ADD} exact component={ParkingMeterFormCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_PARKING_METER_DETAIL} exact component={ParkingMeterFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES} exact component={ParkingMeterIncidenceListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL} exact component={ParkingMeterIncidenceFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS} exact component={ParkingMeterCollectionListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL} exact component={ParkingMeterCollectionFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_PARKING_LOSSES} exact component={ParkingLossListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL} exact component={ParkingLossFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_REPORTS} exact component={ReportListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_REPORT_DETAIL} exact component={ReportDetailsCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_REPORT_CANCELLATION_FILE} exact component={ReportCancellationByFileFormScreen}/>
                    <Route path={ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS} exact component={ReportCancelLogListScreen}/>
                    <Route path={ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS_DATE} exact component={ReportCancelLogListScreen}/>

                    <Route path={ROUTE_COMPANY_USER_RATIFICATION_REPORTS} exact component={RatificationReportListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL} exact component={RatificationReportDetailsCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_LAWS} exact component={LawListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_LAW_ADD} exact component={LawFormCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_LAW_DETAIL} exact component={LawFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_BLACK_LIST_RULES} exact component={BlackListRuleListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD} exact component={BlackListRuleFormCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_BLACK_LIST_RULE_DETAIL} exact component={BlackListRuleFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_CONTROLLERS} exact component={ControllerReportCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION} exact component={ControllerSituationMapCompanyUserScreen}/>

                    <Route path={ROUTE_ADMIN_REPORT_CANCELLATION_METHODS} exact component={ReportCancellationMethodListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD} exact component={ReportCancellationMethodFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL} exact component={ReportCancellationMethodFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_VEHICLE_COLORS} exact component={VehicleColorListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_VEHICLE_COLOR_ADD} exact component={VehicleColorFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_VEHICLE_COLOR_DETAIL} exact component={VehicleColorFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_VEHICLE_BRANDS} exact component={VehicleBrandListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_VEHICLE_BRAND_ADD} exact component={VehicleBrandFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_VEHICLE_BRAND_DETAIL} exact component={VehicleBrandFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_VEHICLE_MODELS} exact component={VehicleModelListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_VEHICLE_MODEL_ADD} exact component={VehicleModelFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_VEHICLE_MODEL_DETAIL} exact component={VehicleModelFormAdminScreen}/>

                    <Route path={ROUTE_COMPANY_USER_VEHICLES} exact component={VehicleListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_VEHICLE_ADD} exact component={VehicleFormCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_VEHICLE_DETAIL} exact component={VehicleFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_CHAT} exact component={ChatCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_BROADCAST} exact component={ChatBroadcastCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_BROADCAST_ADD} exact component={ChatBroadcastFormCompanyUserScreen}/>

                    <Route path={ROUTE_COMPANY_USER_SENDTOPROCESS} exact component={SendToProcessScreen}/>
                    <Route path={ROUTE_COMPANY_USER_SENDTOPROCESS_FILE} exact component={SendToProcessFileScreen}/>

                    <Route path={ROUTE_COMPLAINT_VIEWER_SENT_REPORTS} exact component={ReportListComplaintViewerSentScreen}/>
                    <Route path={ROUTE_COMPLAINT_VIEWER_SENT_REPORT_DETAIL} exact component={ReportDetailsCompanyUserScreen}/>

                    <Route path={ROUTE_COMPLAINT_VIEWER_REPORTS} exact component={ReportListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPLAINT_VIEWER_REPORT_DETAIL} exact component={ReportDetailsCompanyUserScreen}/>

                    <Route path={ROUTE_POLICE_REPORTS} exact component={ReportListCompanyUserScreen}/>
                    <Route path={ROUTE_POLICE_REPORT_DETAIL} exact component={ReportDetailsCompanyUserScreen}/>

                    <Route path={ROUTE_USER_CERT} exact component={UserCertCompanyUserScreen}/>

                    <Route path={ROUTE_ADMIN_COLLECTION_PROCESS} exact component={CollectionProcessAdminScreen}/>
                    <Route path={ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL} exact component={CollectionProcessDetailScreen}/>
                    <Route path={ROUTE_ADMIN_COLLECTION_PROCESS_ADD} exact component={CollectionProcessAddScreen}/>

                    <Route path={ROUTE_COMPANY_USER_DELTA_CARS} exact component={DeltaCarListCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_DELTA_CAR_DETAIL} exact component={DeltaCarFormCompanyUserScreen}/>
                    <Route path={ROUTE_COMPANY_USER_DELTA_CAR_ADD} exact component={DeltaCarFormCompanyUserScreen}/>

                    <Route path={ROUTE_PROFILE} exact component={Profile}/>
                </div>
            </Router>
        );
    }

}
