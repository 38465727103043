import React from "react";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../../../commons/I18n/I18n";
import Card from "../../../../../../components/card/Card";
import CardBody from "../../../../../../components/card/CardBody";
import CardHeader from "../../../../../../components/card/CardHeader";
import Col from "../../../../../../components/Col";
import FormInputFile from "../../../../../../components/form/FormInputFile";
import FormSubmit from "../../../../../../components/form/FormSubmit";
import Row from "../../../../../../components/Row";
import I18nKeys from "../../../../../../I18n/I18nKeys";

const FORM_NAME = "ReportCancellationByFileCardForm";

export interface ReportCancellationByFileCardFormData {
    // eslint-disable-next-line camelcase
    csv_file: File[];
}

interface ReportCancellationByFileCardFormExtProps {
    parkingMeterCollectionId?: string;
    initialValues?: Partial<ReportCancellationByFileCardFormData>;
    parentLoading: boolean;
    onSubmit: (data: ReportCancellationByFileCardFormData)=> void;
}

type Props = InjectedFormProps<ReportCancellationByFileCardFormData> & ReportCancellationByFileCardFormExtProps;

enum ReportCancellationByFileCardFormFields {
    CSV_FILE = "csv_file",
}

class ReportCancellationByFileCardForm extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, invalid, parentLoading, pristine, onSubmit} = this.props;

    return (
      <Card loading={parentLoading}>
        <CardHeader
          title={I18n.tr(I18nKeys.ANULACION_DENUNCIAS_DE_FICHERO)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.FICHERO_CSV)}
                name={ReportCancellationByFileCardFormFields.CSV_FILE}
                component={FormInputFile}
                accept={"text/csv"}
                col={{
                  md: 12,
                  lg: 12,
                }}
              />
            </Row>
            <Row>
              <Col sm={8} md={9} lg={10} />
              <Field
                label={I18n.tr(I18nKeys.ANULAR)}
                name="submit"
                component={FormSubmit}
                col={{
                  sm: 4,
                  md: 3,
                  lg: 2,
                }}
                disabled={invalid || pristine}
              />
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: ReportCancellationByFileCardFormData) {
  const errors: FormErrors<ReportCancellationByFileCardFormData> = {
  };

  if (!values.csv_file || values.csv_file.length === 0)
    errors.csv_file = I18n.tr(I18nKeys.FICHERO_CSV_OBLIGATORIO);
  else if (values.csv_file.length > 1)
    errors.csv_file = I18n.tr(I18nKeys.SOLO_ADMITE_UN_FICHERO);
  else if (values.csv_file[0] && !values.csv_file[0].name.endsWith(".csv"))
    errors.csv_file = I18n.tr(I18nKeys.FICHERO_CSV_OBLIGATORIO);

  return errors;
}

export default reduxForm<ReportCancellationByFileCardFormData, ReportCancellationByFileCardFormExtProps>( {
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
} )(ReportCancellationByFileCardForm as any) as React.ComponentType<ReportCancellationByFileCardFormExtProps>;
