import { ActionBuilder, ReducerBuilder } from "@cuatroochenta/co-redux-builders";

import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../../commons/I18n/I18n";
import TaskLawList from "../../../../../commons/ws/law/TaskLawList";
import TaskReportStateList from "../../../../../commons/ws/reportState/TaskReportStateList";
import TaskRouteList from "../../../../../commons/ws/route/TaskRouteList";
import TaskStreetList from "../../../../../commons/ws/street/TaskStreetList";
import TaskUserControllerList from "../../../../../commons/ws/user/TaskUserControllerList";
import TaskUserRatifierList from "../../../../../commons/ws/user/TaskUserRatifierList";
import TaskVehicleList from "../../../../../commons/ws/vehicle/TaskVehicleList";
import { CardSectionCollapsed } from "../../../../../components-new/CardSectionCollapsed";
import Button from "../../../../../components/buttons/Button";
import Col from "../../../../../components/Col";
import { FilterDate, FilterInputSearch, typeaheadOptionsBuilder } from "../../../../../components/filter";
import { FilterTypeahead } from "../../../../../components/filter/FilterTypeahead";
import FormCol from "../../../../../components/form/FormCol";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import RatificationReportListCompanyUserActions from "../RatificationReportListCompanyUserActions";
import RatificationReportListCompanyUserReducer from "../RatificationReportListCompanyUserReducer";
import RatificationReportListCompanyUserFilterActions from "./RatificationReportListCompanyUserFilterActions";
import RatificationReportListCompanyUserFilterReducer from "./RatificationReportListCompanyUserFilterReducer";

const mapActionsToProps = ActionBuilder.combineActionsAutoMaps(
  RatificationReportListCompanyUserFilterActions.autoMapToProps(),
  RatificationReportListCompanyUserActions.autoMapToProps(),
);
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  RatificationReportListCompanyUserFilterReducer.autoMapToProps(),
  RatificationReportListCompanyUserReducer.autoMapToProps(),
);

type Props = typeof mapActionsToProps & typeof mapStateToProps;

class ReportListCompanyUserFilter extends React.Component<Props> {
  private refReportNumber;

  private refStartDate;

  private refEndDate;

  private refFilterVehicle;

  private refFilterRoute;

  private refFilterUserController;

  private refFilterUserRatifier;

  private refFilterStreet;

  public constructor(props: Props) {
    super(props);
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidMount(): void {
    new TaskVehicleList( {
      limit: 0,
    } ).execute();
    new TaskRouteList( {
      limit: 0,
    } ).execute();
    new TaskLawList( {
      limit: 0,
    } ).execute();
    new TaskUserControllerList( {
      limit: 0,
    } ).execute();
    new TaskUserRatifierList( {
      limit: 0,
    } ).execute();
    new TaskStreetList( {
      limit: 0,
    } ).execute();
    new TaskReportStateList( {
      limit: 0,
    } ).execute();
  }

  public componentWillUnmount(): void {
    this.props.dispatchResetFilterReducer();
  }

  public render() {
    const {vehicles,
      vehiclesLoading,
      routes,
      routesLoading,
      controllers,
      controllersLoading,
      ratifiers,
      ratifiersLoading,
      streets,
      streetsLoading} = this.props;
    const vehiclesOptions = typeaheadOptionsBuilder.vehicles(vehicles);
    const routesOptions = typeaheadOptionsBuilder.routes(routes);
    const controllersOptions = typeaheadOptionsBuilder.users(controllers);
    const ratifiersOptions = typeaheadOptionsBuilder.users(ratifiers);
    const streetsOptions = typeaheadOptionsBuilder.streets(streets);

    return (
      <CardSectionCollapsed
        title={I18n.tr(I18nKeys.FILTROS)}
        className={"card-filters"}
      >
        <Row>
          <FilterInputSearch
            gridSize={3}
            label={I18n.tr(I18nKeys.NUMERO_DE_DENUNCIA)}
            onRef={(ref) => {
              this.refReportNumber = ref;

              return ref;}}
            onChange={this.handleSearchByReportNumber}
          />
          <FilterDate
            gridSize={3}
            label={I18n.tr(I18nKeys.FECHA_INICIO)}
            onRef={(ref) => {
              this.refStartDate = ref;

              return ref;}}
            onChange={this.handleSearchByStartDate}
          />
          <FilterDate
            gridSize={3}
            label={I18n.tr(I18nKeys.FECHA_FIN)}
            onRef={(ref) => {
              this.refEndDate = ref;

              return ref;}}
            onChange={this.handleSearchByEndDate}
          />
          <FilterTypeahead
            gridSize={3}
            label={I18n.tr(I18nKeys.MATRICULA)}
            options={vehiclesOptions}
            isLoading={vehiclesLoading}
            onRef={(ref) => {
              this.refFilterVehicle = ref;

              return ref;}}
            onChange={this.handleSearchByLicensePlate}
          />
        </Row>
        <Row>
          <FilterTypeahead
            gridSize={3}
            label={I18n.tr(I18nKeys.RUTA)}
            options={routesOptions}
            isLoading={routesLoading}
            onRef={(ref) => {
              this.refFilterRoute = ref;

              return ref;}}
            onChange={this.handleSearchByRoute}
          />
          <FilterTypeahead
            gridSize={3}
            label={I18n.tr(I18nKeys.CONTROLADOR)}
            options={controllersOptions}
            isLoading={controllersLoading}
            onRef={(ref) => {
              this.refFilterUserController = ref;

              return ref;}}
            onChange={this.handleSearchByUserController}
          />
          <FilterTypeahead
            gridSize={3}
            label={I18n.tr(I18nKeys.RATIFICADOR)}
            options={ratifiersOptions}
            isLoading={ratifiersLoading}
            onRef={(ref) => {
              this.refFilterUserRatifier = ref;

              return ref;}}
            onChange={this.handleSearchByUserRatifier}
          />
          <FilterTypeahead
            gridSize={3}
            label={I18n.tr(I18nKeys.CALLE)}
            options={streetsOptions}
            isLoading={streetsLoading}
            onRef={(ref) => {
              this.refFilterStreet = ref;

              return ref;}}
            onChange={this.handleSearchByStreet}
          />
        </Row>
        <Row>
          <Col sm={9} md={9} lg={9}/>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <Button
                text={I18n.tr(I18nKeys.BORRAR_FILTROS)}
                onClick={this.handleClearFilter}
                type={"button"}
                block={true}
                className={"btn-lg btn-default btn-export"}
              />
            </div>
          </FormCol>
        </Row>
      </CardSectionCollapsed>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleSearchByReportNumber = (reportNumber: string) => {
    this.props.dispatchAddCriteria( {
      search: reportNumber,
    } );
  };

  private handleSearchByStartDate = (date: Date): void => {
    this.props.dispatchAddCriteria( {
      start_date: date ? date.toISOString() : undefined,
    } );
  };

  private handleSearchByEndDate = (date: Date): void => {
    this.props.dispatchAddCriteria( {
      end_date: date ? date.toISOString() : undefined,
    } );
  };

  private handleSearchByLicensePlate = (vehicleId: string): void => {
    this.props.dispatchAddCriteria( {
      vehicle_ids: (vehicleId !== "-1") ? [vehicleId] : undefined,
    } );
  };

  private handleSearchByRoute = (routeId: string): void => {
    this.props.dispatchAddCriteria( {
      route_ids: (routeId !== "-1") ? [routeId] : undefined,
    } );
  };

  private handleSearchByUserController = (controllerId: string): void => {
    this.props.dispatchAddCriteria( {
      controller_ids: (controllerId !== "-1") ? [controllerId] : undefined,
    } );
  };

  private handleSearchByUserRatifier = (ratifierId: string): void => {
    this.props.dispatchAddCriteria( {
      ratifier_ids: (ratifierId !== "-1") ? [ratifierId] : undefined,
    } );
  };

  private handleSearchByStreet = (streetId: string): void => {
    this.props.dispatchAddCriteria( {
      street_ids: (streetId !== "-1") ? [streetId] : undefined,
    } );
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleClearFilter = () => {
    this.refReportNumber.value = "";
    this.refStartDate.value = "";
    this.refEndDate.value = "";
    this.refFilterVehicle.getInstance().clear();
    this.refFilterRoute.getInstance().clear();
    this.refFilterUserController.getInstance().clear();
    this.refFilterUserRatifier.getInstance().clear();
    this.refFilterStreet.getInstance().clear();

    this.props.dispatchClearCriteria();
  };
}

export default connect(mapStateToProps, mapActionsToProps)(ReportListCompanyUserFilter) as any;
