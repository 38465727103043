import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";
import {METHOD} from "@cuatroochenta/co-generic-request";
import {generateRoute} from "../../utils/Router";
import ParkingMeterCollection from "../../model/ParkingMeterCollection";

export default class TaskParkingMeterCollectionDetail extends BaseGenericRequest<{}, TaskResponse<ParkingMeterCollection>> {

    public constructor(parkingMeterCollectionId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_PARKING_METER_COLLECTION_DETAIL, {uuid: parkingMeterCollectionId}));
    }
}
