import { FunctionComponent } from 'react';
import { CardHeader } from './CardHeader';
import { CardBody } from './CardBody';
import { Card } from './Card';
import { CardListProps } from './typings';

export const CardListV3: FunctionComponent<CardListProps> = (
    {
        headerOptionIdSelected,
        itemIdsSelectedDisabled,
        itemIdsSelected,
        itemIdsDisabled,
        showCheckboxes,
        headerOptions,
        emptyOptions,
        dataLoading,
        ItemsTable,
        dataPager,
        elementsLabelPager,
        dataError,
        subtitle,
        dataSort,
        children,
        footer,
        search,
        title,
        data,
        onGoToItemDetails,
        onSelectAllItems,
        onChangeSearch,
        onSelectItem,
        onFetchData,
        onRefresh,
        onSetPage,
        onAssign,
        onSort,
        onAdd,
    }
) => {
    return (
        <Card loading={dataLoading}>
            <CardHeader
                headerOptionIdSelected={headerOptionIdSelected}
                headerOptions={headerOptions}
                searchText={onChangeSearch ? search : undefined}
                subtitle={subtitle}
                title={title}
                onChangeSearch={onChangeSearch}
                onRefresh={onRefresh}
                onAssign={onAssign}
                onAdd={onAdd}
            >
                {children}
            </CardHeader>
            <CardBody
                showCheckboxes={showCheckboxes}
                itemIdsSelectedDisabled={itemIdsSelectedDisabled}
                itemIdsSelected={itemIdsSelected}
                itemIdsDisabled={itemIdsDisabled}
                dataLoading={dataLoading}
                dataPager={dataPager}
                elementsLabelPager={elementsLabelPager}
                dataError={dataError}
                dataSort={dataSort}
                data={data}
                TableComponent={ItemsTable}
                emptyOptions={emptyOptions}
                footer={footer}
                onGoToItemDetails={onGoToItemDetails}
                onSelectAllItems={onSelectAllItems}
                onSelectItem={onSelectItem}
                onFetchData={onFetchData}
                onSetPage={onSetPage}
                onSort={onSort}
            />
        </Card>
    );
};
