import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import Law, {LawData} from "../../model/Law";

export default class TaskLawAdd extends BaseGenericRequest<LawData, TaskResponse<Law>> {

    private readonly data: LawData;

    public constructor(data: LawData) {
        super(METHOD.POST, Urls.URL_LAW_ADD);
        this.data = data;
    }

    protected getRequest(): LawData {
        return this.data;
    }
}
