import { Component, ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import Alert from "../../../../base/alerts/Alert";
import I18n from "../../../../commons/I18n/I18n";
import { ParkingMeterCollectionProcessSendData } from "../../../../commons/model/ParkingMeterCollectionProcess";
import TaskParkingMeterCollectionProcessList from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessList";
import TaskParkingMeterCollectionProcessSend from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessSend";
import I18nKeys from "../../../../I18n/I18nKeys";
import CollectionProcessAdminActions from "./CollectionProcessAdminActions";
import CollectionProcessAdminReducer from "./CollectionProcessAdminReducer";
import CollectionProcessAdminView from "./CollectionProcessAdminView";

const mapStateToProps = CollectionProcessAdminReducer.autoMapToProps();
const mapActionsToProps = CollectionProcessAdminActions.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

class CollectionProcessAdminDataContainer extends Component<Props> {
	 public componentWillUnmount(): void {
		 this.props.dispatchClearReducer();
	 }

  // eslint-disable-next-line class-methods-use-this
  public componentDidMount(): void {
    new TaskParkingMeterCollectionProcessList().execute();
  }

  private handleOnParkingMeterCollectionProcessSend = () => {
	  const submitData: ParkingMeterCollectionProcessSendData = {
	    parking_meter_collection_process_ids: this.props.parkingMeterCollectionProcessToSend,
	  };

	  new TaskParkingMeterCollectionProcessSend(submitData)
	    .onFail(() => setTimeout(() => {
	      Alert.error(I18n.tr(I18nKeys.SE_HA_PRODUCIDO_UN_ERROR_AL_ENVIAR_PROCESOS_RECAUDACION));
	    },1))

	    .onSuccess(() => setTimeout(() => {
	      Alert.success(I18n.tr(I18nKeys.PROCESOS_RECAUDACION_ENVIADOS_CORRECTAMENTE));
	      // Recargar
	      new TaskParkingMeterCollectionProcessList().execute();
	    },1))

	    .execute();
  };

  public render(): ReactNode {
	  const {parkingMeterCollectionProcessList, parkingMeterCollectionProcessToSend ,loading, errors, pager} = this.props;

	  return (
	    <CollectionProcessAdminView
	      parkingMeterCollectionProcessList={parkingMeterCollectionProcessList}
	      onParkingMeterCollectionProcessSend={this.handleOnParkingMeterCollectionProcessSend}
	      disabledSend={parkingMeterCollectionProcessToSend.length === 0}
	      loading={loading}
	      errors={errors}
	      pager={pager}
	    />
	  );
	 }
}

export default connect(mapStateToProps, mapActionsToProps)(CollectionProcessAdminDataContainer as unknown as ComponentType<{}>);
