import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_STREETS } from "../../../../routing/Routes";
import StreetCardCompanyUser from "./StreetCardCompanyUser";

const StreetCompanyUserScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.CALLES),
      url: ROUTE_COMPANY_USER_STREETS,
      icon: AppIcon.STREET,
    },
  ]}>
    <StreetCardCompanyUser/>
  </ScreenCard>
);

export default StreetCompanyUserScreen;
