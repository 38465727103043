import BaseGenericRequest from '../BaseGenericRequest';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import TaskResponse, { BatchResponseCode } from '../TaskResponse';

interface IData {
    ids: string[]
    report_invalidation_reason_id: string
    image?: string,
    observations?: string,
}

export default class TaskReportStateInvalidate extends BaseGenericRequest<IData, TaskResponse<BatchResponseCode>> {

    private readonly data: IData;

    public constructor(reportIds: string[], reasonId: string, image?: string, observations?: string) {
        super(METHOD.POST, Urls.URL_REPORT_STATE_INVALIDATE);

        this.data = {
            ids: reportIds,
            report_invalidation_reason_id: reasonId,
            image,
            observations
        };
    }

    protected getRequest(): IData {
        return this.data;
    }
}
