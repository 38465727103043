import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {PagerBackendProps} from "../../../../components/Pager";
import ReportCancellationMethod from "../../../../commons/model/ReportCancellationMethod";
import TaskReportCancellationMethodList
    from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodList";

interface InitialState {
    loading: boolean,
    reportCancellationMethods: ReportCancellationMethod[],
    error: string,
    pager: PagerBackendProps,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    reportCancellationMethods: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            reportCancellationMethods: payload.data,
            pager: payload.pager || oldState.pager
        }
    })

    .build()
