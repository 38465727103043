import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { VehicleColorData } from "../../../../commons/model/VehicleColor";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskVehicleColorAdd from "../../../../commons/ws/vehicleColor/TaskVehicleColorAdd";
import TaskVehicleColorDetail from "../../../../commons/ws/vehicleColor/TaskVehicleColorDetail";
import TaskVehicleColorEdit from "../../../../commons/ws/vehicleColor/TaskVehicleColorEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_ADMIN_VEHICLE_COLORS, ROUTE_ADMIN_VEHICLE_COLOR_ADD,
  ROUTE_ADMIN_VEHICLE_COLOR_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import VehicleColorCardFormAdmin, { VehicleColorAdminData } from "../formdetail/VehicleColorCardFormAdmin";
import VehicleColorFormAdminScreenActions from "./VehicleColorFormAdminScreenActions";
import VehicleColorFormScreenAdminReducer from "./VehicleColorFormScreenAdminReducer";

const mapStateToProps = VehicleColorFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = VehicleColorFormAdminScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class VehicleColorFormAdminScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id) 
      loadItemDetailsOrGoBack(new TaskVehicleColorDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: VehicleColorAdminData): void => {
    const existVehicleColor = this.props.match.params.id !== undefined;
    const submitData: VehicleColorData = {
      name: values.name,
      alzira_code: values.alziraCode,
    };

    if (existVehicleColor) {
      executeItemTask(
        new TaskVehicleColorEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_ADMIN_VEHICLE_COLORS),
        I18n.tr(I18nKeys.COLOR_DE_VEHICULO_MODIFICADO_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskVehicleColorAdd(submitData),
        () => goToRoute(ROUTE_ADMIN_VEHICLE_COLORS),
        I18n.tr(I18nKeys.COLOR_DE_VEHICULO_CREADO_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const vehicleColorId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.COLORES_DE_VEHICULOS),
      url: ROUTE_ADMIN_VEHICLE_COLORS,
      icon: AppIcon.CAR,
    }];

    if (vehicleColorId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.COLOR_DE_VEHICULO),
        url: generateRoute(ROUTE_ADMIN_VEHICLE_COLOR_DETAIL, {
          id: vehicleColorId,
        } ),
        icon: AppIcon.CAR,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVO_COLOR_DE_VEHICULO),
        url: ROUTE_ADMIN_VEHICLE_COLOR_ADD,
        icon: AppIcon.CAR,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderVehicleColorForm()}
      </ScreenCard>
    );
  }

  private renderVehicleColorForm(): React.ReactNode {
    const {vehicleColorLoading, vehicleColor} = this.props;
    let initialValues: Partial<VehicleColorAdminData> | undefined;

    if (vehicleColor) {
      initialValues = {
        name: vehicleColor.name ? vehicleColor.name : "",
        alziraCode: vehicleColor.alzira_code || "",
      };
    }

    return (
      <VehicleColorCardFormAdmin
        parentLoading={vehicleColorLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)
(VehicleColorFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
