import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskParkingMeterDelete from "../../../../commons/ws/parkingMeter/TaskParkingMeterDelete";
import TaskParkingMeterList from "../../../../commons/ws/parkingMeter/TaskParkingMeterList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_METER_ADD } from "../../../../routing/Routes";
import ParkingMeterCompanyUserActions from "./ParkingMeterListCompanyUserActions";
import ParkingMeterCompanyUserReducer from "./ParkingMeterListCompanyUserReducer";
import ParkingMeterListTable from "./ParkingMeterListTable";

const mapActionsToProps = ParkingMeterCompanyUserActions.autoMapToProps();
const mapStateToProps = ParkingMeterCompanyUserReducer.autoMapToProps();

class ParkingMeterListCardCompanyUser extends React.Component<typeof mapActionsToProps & typeof mapStateToProps> {
  public componentWillMount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const {loading, parkingMeters, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.PARQUIMETROS)}
        sort={{
          column: "code",
        }}
        TaskList={TaskParkingMeterList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_PARQUIMETROS),
        }}
        data={parkingMeters}
        error={error}
        ItemsTable={ParkingMeterListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_PARQUIMETROS),
          Task: TaskParkingMeterDelete,
        }}
        addItem={ROUTE_COMPANY_USER_PARKING_METER_ADD}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterListCardCompanyUser as unknown as React.ComponentType<void>);
