import { METHOD } from '@cuatroochenta/co-generic-request';
import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import BlacklistRule from '../../model/BlacklistRule';

type BlacklistRuleCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskBlacklistRuleList extends BaseGenericRequest<BlacklistRuleCriteriaProps, TaskResponse<BlacklistRule[]>> {

    private readonly data: BlacklistRuleCriteriaProps;

    public constructor(criteria: BlacklistRuleCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_BLACK_LIST_RULE_LIST);
        this.data = criteria;
    }

    protected getRequest(): BlacklistRuleCriteriaProps {
        return this.data;
    }
}
