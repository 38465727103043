import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import DeltaCar from "../../../../commons/model/DeltaCar";
import TaskDeltaCarDetail from "../../../../commons/ws/deltaCar/TaskDeltaCarDetail";
import DeltaCarFormAdminScreenActions from "./DeltaCarFormCompanyUserScreenActions";

interface InitialState {
    deltaCarFormScreenLoading: boolean,
    deltaCar?: DeltaCar,
}

const INITIAL_STATE: InitialState = {
    deltaCarFormScreenLoading: false,
    deltaCar: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskDeltaCarDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        deltaCarFormScreenLoading: true
    }))
    .onEvent(TaskDeltaCarDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        deltaCarFormScreenLoading: false
    }))
    .onEvent(TaskDeltaCarDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, deltaCar: payload.data};
        })

    .onEvent(DeltaCarFormAdminScreenActions.clearDeltaCarFormScreenAdminReducer, () => INITIAL_STATE)

    .build();
