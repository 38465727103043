import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import VehicleColor, {VehicleColorData} from "../../model/VehicleColor";

export default class TaskVehicleColorEdit extends BaseGenericRequest<VehicleColorData, TaskResponse<VehicleColor>> {

    private readonly data: VehicleColorData;

    public constructor(vehicleColorId: string, vehicleColorData: VehicleColorData) {
        super(METHOD.PUT, generateRoute(Urls.URL_VEHICLE_COLOR_EDIT, {uuid: vehicleColorId}));
        this.data = vehicleColorData;
    }

    protected getRequest(): VehicleColorData {
        return this.data;
    }
}
