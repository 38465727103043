export default interface UserCert {
    start_date: string,
    end_date: string,
}

export interface UserCertData {
    file: File,
    password: string,
    passphrase: string,
    override: boolean,
}

export const FILE_CERT_EXTENSION = 'p12';
export const FILE_CERT_TYPE = 'application/x-pkcs12';