import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import ParkingMeterType from "../../model/ParkingMeterType";

type ParkingMeterTypeListCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskParkingMeterTypeList extends BaseGenericRequest<ParkingMeterTypeListCriteriaProps, TaskResponse<ParkingMeterType[]>> {

    private readonly data: ParkingMeterTypeListCriteriaProps;

    public constructor(criteria: ParkingMeterTypeListCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_METER_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): ParkingMeterTypeListCriteriaProps {
        return this.data;
    }

}
