import React from "react";
import Icon from "./Icon";
import {AppIcon} from "../config/Config";
import Col from "./Col";
import {truncateMiddle} from "../utils/StringUtils";

export interface FileInfoProps {
    removeHandler: () => void,
    fileName: string,
    url: string,
    maxLength?: number,
    className?: string,
    icon?: string,
    fileInfoInline?: boolean,
}

export default class FileInfo extends React.Component<FileInfoProps> {

    public render() {
        const {
            fileName, removeHandler, className = '', maxLength = 20, url, icon = AppIcon.DOCUMENTATION,
            fileInfoInline = false
        } = this.props;

        const fileInfoClassName = fileInfoInline ? 'file-info-inline' : 'file-info';

        return (
            <Col xs={4} sm={4} md={4} lg={4} className={className}>
                <div className={fileInfoClassName}>
                    <Icon icon={icon}/>
                    <p onClick={(event) => {
                        event.stopPropagation();
                        window.open(url, "_blank");
                    }}>{truncateMiddle(fileName, maxLength)}</p>
                    <a onClick={(event) => {
                        event.stopPropagation();
                        removeHandler();
                    }}>
                        <Icon icon={'cancel'}/>
                    </a>
                </div>
            </Col>
        )
    }

}
