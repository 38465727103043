import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import TaskParkingAreaTypeList from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeList";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import FormSelect from "../../../../components/form/FormSelect";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_ROUTES } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import RouteFormScreenAdminReducer from "../formscreen/RouteFormScreenCompanyUserReducer";

const FORM_NAME = "RouteCardFormAdmin";

export interface RouteCardFormCompanyUserData {
    name: string;
    parkingAreaTypeId: string;
}

interface RouteCardFormCompanyUserProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: RouteCardFormCompanyUserData)=> void;
    initialValues?: Partial<RouteCardFormCompanyUserProps>;
    parentLoading?: boolean;
}

const mapStateToProps = RouteFormScreenAdminReducer.autoMapToProps();

type Props =
    InjectedFormProps<RouteCardFormCompanyUserData> & RouteCardFormCompanyUserProps & typeof mapStateToProps;

enum RouteFormAdminFields {
    NAME = "name",
    PARKING_AREA_TYPE_ID = "parkingAreaTypeId",
}

class RouteCardFormCompanyUser extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskParkingAreaTypeList().execute();
  }

  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, routeLoading, pristine, parentLoading, parkingAreaTypeList} = this.props;
    const parkingAreaTypeOptions = parkingAreaTypeList.map((parkingAreaType) => ( {
      value: parkingAreaType.id,
      name: parkingAreaType.name,
    } ));

    return (
      <Card loading={parentLoading || routeLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.RUTA : I18nKeys.NUEVA_RUTA)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={RouteFormAdminFields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.TIPO_DE_ZONA)}
                name={RouteFormAdminFields.PARKING_AREA_TYPE_ID}
                component={FormSelect}
                options={parkingAreaTypeOptions}
                blankOptionText={" "}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_ROUTES)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: RouteCardFormCompanyUserData) {
  const errors: FormErrors<RouteCardFormCompanyUserData> = {
  };

  if (!values.name || values.name.length === 0)
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  if (!values.parkingAreaTypeId || values.parkingAreaTypeId === "-1")
    errors.parkingAreaTypeId = I18n.tr(I18nKeys.TIPO_DE_ZONA_OBLIGATORIO);

  return errors;
}

export default compose(
  reduxForm<RouteCardFormCompanyUserData, RouteCardFormCompanyUserProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(RouteCardFormCompanyUser);
