export default interface UserRole {
    id: string,
    name: string
}

export class UserRoleValue {
    public static ADMIN = '1';
    public static COMPANY_USER = '2';
    public static USER_APP = '3';
    public static POLICE = '4';
    public static COMPLAINT_VIEWER = '5';
    public static COMPLAINT_VIEWER_SENT = '6';
}
