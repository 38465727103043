import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import ReportCancellationMethod from "../../../../commons/model/ReportCancellationMethod";
import TaskReportCancellationMethodDetail
    from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodDetail";
import ReportCancellationMethodFormAdminScreenActions from "./ReportCancellationMethodFormAdminScreenActions";

interface InitialState {
    reportCancellationMethodLoading: boolean,
    reportCancellationMethod?: ReportCancellationMethod,
}

const INITIAL_STATE: InitialState = {
    reportCancellationMethodLoading: false,
    reportCancellationMethod: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskReportCancellationMethodDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        reportCancellationMethodLoading: true
    }))
    .onEvent(TaskReportCancellationMethodDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        reportCancellationMethodLoading: false
    }))
    .onEvent(TaskReportCancellationMethodDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, reportCancellationMethod: payload.data};
        })

    .onEvent(ReportCancellationMethodFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
