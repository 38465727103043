import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import VehicleColor, {VehicleColorData} from "../../model/VehicleColor";

export default class TaskVehicleColorAdd extends BaseGenericRequest<VehicleColorData, TaskResponse<VehicleColor>> {

    private readonly data: VehicleColorData;

    public constructor(data: VehicleColorData) {
        super(METHOD.POST, Urls.URL_VEHICLE_COLOR_ADD);
        this.data = data;
    }

    protected getRequest(): VehicleColorData {
        return this.data;
    }
}
