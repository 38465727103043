import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskLawTypeDelete from "../../../../commons/ws/lawType/TaskLawTypeDelete";
import TaskLawTypeList from "../../../../commons/ws/lawType/TaskLawTypeList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_LAW_TYPE_ADD } from "../../../../routing/Routes";
import LawTypeListAdminReducer from "./LawTypeListAdminReducer";
import LawTypeListTable from "./LawTypeListTable";

const mapStateToProps = LawTypeListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class LawTypeListCardAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {loading, lawTypes, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.LEYES)}
        sort={{
          column: "name",
        }}
        TaskList={TaskLawTypeList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_LEYES),
        }}
        data={lawTypes}
        error={error}
        ItemsTable={LawTypeListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_LEYES),
          Task: TaskLawTypeDelete,
        }}
        addItem={ROUTE_ADMIN_LAW_TYPE_ADD}
      />
    );
  }
}

export default connect(mapStateToProps)(LawTypeListCardAdmin as unknown as React.ComponentType<void>);
