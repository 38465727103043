import React from "react";
import I18n from "../../../../commons/I18n/I18n";
import ParkingLoss from "../../../../commons/model/ParkingLoss";
import { generateRoute } from "../../../../commons/utils/Router";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface ParkingLostListTableProps {
    data: ParkingLoss[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

const goToItemDetail = (itemId: string): void => {
  goToRoute(generateRoute(ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL, {
    id: itemId,
  } ));
};

export default class ParkingLossListTable extends React.Component<ParkingLostListTableProps> {
  public render(): React.ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((parkingLoss: ParkingLoss) => (
            <tr key={parkingLoss.id}
              onClick={() => goToItemDetail(parkingLoss.id)}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(parkingLoss.id) : null}
              <td>
                {parkingLoss.date ? DateFormatter.formatDatetime(parkingLoss.date) : ""}
              </td>
              <td>
                {parkingLoss.street ? parkingLoss.street.name : ""}
              </td>
              <td>
                {parkingLoss.parking_loss_reason ? parkingLoss.parking_loss_reason.name : ""}
              </td>
              <td>
                {parkingLoss.controller ?
                  `${parkingLoss.controller.name} ${parkingLoss.controller.last_name}`
                  : ""}
              </td>
              <td>
                {parkingLoss.places ? parkingLoss.places : ""}
              </td>
              <td>
                {parkingLoss.occupied_hours ? parkingLoss.occupied_hours : ""}
              </td>
              <td>
                {parkingLoss.observations ? parkingLoss.observations : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.CALLE)}
          columnName={"street"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.MOTIVO)}
          columnName={"parking_loss_reason"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.CONTROLADOR)}
          columnName={"controller"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.PLAZAS_PERDIDAS)}
          columnName={"places"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.HORAS)}
          columnName={"occupied_hours"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.OBSERVACIONES)}
          columnName={"observations"}
          {...commonProps}
        />
      </tr>
    );
  }
}
