import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import VehicleColor from "../../model/VehicleColor";

type VehicleColorCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskVehicleColorList extends BaseGenericRequest<VehicleColorCriteriaProps, TaskResponse<VehicleColor[]>> {

    private readonly data: VehicleColorCriteriaProps;

    public constructor(criteria: VehicleColorCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_VEHICLE_COLOR_LIST);
        this.data = criteria;
    }

    protected getRequest(): VehicleColorCriteriaProps {
        return this.data;
    }

}
