import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPLAINT_VIEWER_SENT_REPORTS } from "../../../../routing/Routes";
import ReportListCompanyUserCard from "../../../companyUser/report/list/ReportListCompanyUserCard";

const ReportListComplaintViewerSentScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.DENUNCIAS_ENVIADAS),
      url: ROUTE_COMPLAINT_VIEWER_SENT_REPORTS,
      icon: AppIcon.REPORT,
    },
  ]}>
    <ReportListCompanyUserCard/>
  </ScreenCard>
);

export default ReportListComplaintViewerSentScreen;
