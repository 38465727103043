import cookie from "react-cookies";
import TaskResponse from "../commons/ws/TaskResponse";
import TaskUserPasswordEdit from "../commons/ws/user/TaskUserPasswordEdit";
import Config from "../config/Config";
import ReportListCompanyUserStorageManager from "../modules/companyUser/report/list/ReportListCompanyUserStorageManager";
import { executeItemTask } from "./FormUtils";

export default class AuthManager {
  private static COOKIE_NAME = Config.AUTH_COOKIE_APP_NAME;

  private static authToken: string;

  public static initialized() {
    this.authToken = cookie.load(this.COOKIE_NAME);
  }

  public static setAuthToken(authToken: string, rememberMe: boolean = false) {
    this.authToken = authToken;
    const cookieOptions: {path: string; maxAge?: number} = {
      path: "/",
    };

    if (!rememberMe) {
      // tiempo de vida de 4 horas si no tenemos la opción de recordar
      cookieOptions.maxAge = 60 * 60 * 4;
    }

    cookie.save(this.COOKIE_NAME, authToken, cookieOptions);
  }

  public static getAuthToken() {
    return this.authToken;
  }

  public static login(authToken: string) {
    this.setAuthToken(authToken);
    this.authToken = authToken;
  }

  public static logout() {
    this.login("");
    ReportListCompanyUserStorageManager.removeAll(); // Por seguridad hacemos borrado del local storage del módulo de denuncias
  }

  public static isLogged(): boolean {
    return this.authToken !== null && this.authToken !== undefined && this.authToken !== "";
  }
};

/* eslint @typescript-eslint/no-unused-vars: "warn" */
export function changeUserPassword(
  newPassword: string,
  userId?: string,
  onSuccess?: (response: TaskResponse<{}>)=> void,
  token?: string,
): void {
  executeItemTask(
    new TaskUserPasswordEdit( { password: newPassword, }, userId, token),
    onSuccess
  );
}
