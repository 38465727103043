import I18n from "../../../../commons/I18n/I18n";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_ADMIN_COLLECTION_PROCESS,
  ROUTE_ADMIN_COLLECTION_PROCESS_ADD,
  ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL, ROUTE_ADMIN_EXPLOITATIONS, ROUTE_ADMIN_EXPLOITATION_ADD,
  ROUTE_ADMIN_EXPLOITATION_DETAIL, ROUTE_ADMIN_LAW_TYPES, ROUTE_ADMIN_LAW_TYPE_ADD,
  ROUTE_ADMIN_LAW_TYPE_DETAIL, ROUTE_ADMIN_PARKING_LOSS_REASONS, ROUTE_ADMIN_PARKING_LOSS_REASON_ADD,
  ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL, ROUTE_ADMIN_REPORT_CANCELLATION_METHODS, ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD,
  ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL, ROUTE_ADMIN_REPORT_INVALIDATION_REASONS, ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD,
  ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL, ROUTE_ADMIN_USERS, ROUTE_ADMIN_USER_ADD,
  ROUTE_ADMIN_USER_DETAIL, ROUTE_ADMIN_VEHICLE_BRANDS, ROUTE_ADMIN_VEHICLE_BRAND_ADD,
  ROUTE_ADMIN_VEHICLE_BRAND_DETAIL, ROUTE_ADMIN_VEHICLE_COLORS, ROUTE_ADMIN_VEHICLE_COLOR_ADD,
  ROUTE_ADMIN_VEHICLE_COLOR_DETAIL, ROUTE_ADMIN_VEHICLE_MODELS, ROUTE_ADMIN_VEHICLE_MODEL_ADD,
  ROUTE_ADMIN_VEHICLE_MODEL_DETAIL, ROUTE_COMPANY_USER_BLACK_LIST_RULES, ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD,
  ROUTE_COMPANY_USER_BLACK_LIST_RULE_DETAIL, ROUTE_COMPANY_USER_BROADCAST,
  ROUTE_COMPANY_USER_BROADCAST_ADD,
  ROUTE_COMPANY_USER_CHAT,
  ROUTE_COMPANY_USER_CONTROLLERS,
  ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION, ROUTE_COMPANY_USER_DELTA_CARS, ROUTE_COMPANY_USER_LAWS, ROUTE_COMPANY_USER_LAW_ADD,
  ROUTE_COMPANY_USER_LAW_DETAIL, ROUTE_COMPANY_USER_PARKING_AREA_TYPES, ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD,
  ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL, ROUTE_COMPANY_USER_PARKING_LOSSES, ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL, ROUTE_COMPANY_USER_PARKING_METERS, ROUTE_COMPANY_USER_PARKING_METER_ADD, ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS, ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL, ROUTE_COMPANY_USER_PARKING_METER_DETAIL, ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES, ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL, ROUTE_COMPANY_USER_RATIFICATION_REPORTS, ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL, ROUTE_COMPANY_USER_REPORTS, ROUTE_COMPANY_USER_REPORT_CANCELLATION_FILE,
  ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS,
  ROUTE_COMPANY_USER_REPORT_DETAIL, ROUTE_COMPANY_USER_ROUTES, ROUTE_COMPANY_USER_ROUTE_ADD,
  ROUTE_COMPANY_USER_ROUTE_DETAIL, ROUTE_COMPANY_USER_SENDTOPROCESS, ROUTE_COMPANY_USER_STREETS, ROUTE_COMPANY_USER_STREET_ADD,
  ROUTE_COMPANY_USER_STREET_DETAIL, ROUTE_COMPANY_USER_VEHICLES, ROUTE_COMPANY_USER_VEHICLE_ADD,
  ROUTE_COMPANY_USER_VEHICLE_DETAIL, ROUTE_COMPLAINT_VIEWER_REPORTS, ROUTE_COMPLAINT_VIEWER_REPORT_DETAIL, ROUTE_COMPLAINT_VIEWER_SENT_REPORTS, ROUTE_COMPLAINT_VIEWER_SENT_REPORT_DETAIL, ROUTE_POLICE_REPORTS, ROUTE_POLICE_REPORT_DETAIL} from "../../../../routing/Routes";

// eslint-disable-next-line import/prefer-default-export
export const sidebarsItems = {
  admin: {
    administration: [
      {
        title: I18n.tr(I18nKeys.USUARIOS),
        route: ROUTE_ADMIN_USERS,
        routes: [ROUTE_ADMIN_USERS, ROUTE_ADMIN_USER_ADD, ROUTE_ADMIN_USER_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.EXPLOTACIONES),
        route: ROUTE_ADMIN_EXPLOITATIONS,
        routes: [ROUTE_ADMIN_EXPLOITATIONS, ROUTE_ADMIN_EXPLOITATION_ADD, ROUTE_ADMIN_EXPLOITATION_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.RAZONES_DE_INVALIDACION),
        route: ROUTE_ADMIN_REPORT_INVALIDATION_REASONS,
        routes: [ROUTE_ADMIN_REPORT_INVALIDATION_REASONS, ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD,
          ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.METODOS_DE_CANCELACION_DE_DENUNCIA),
        route: ROUTE_ADMIN_REPORT_CANCELLATION_METHODS,
        routes: [ROUTE_ADMIN_REPORT_CANCELLATION_METHODS, ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD,
          ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.COLORES_DE_VEHICULOS),
        route: ROUTE_ADMIN_VEHICLE_COLORS,
        routes: [ROUTE_ADMIN_VEHICLE_COLORS, ROUTE_ADMIN_VEHICLE_COLOR_ADD, ROUTE_ADMIN_VEHICLE_COLOR_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.MARCAS_DE_VEHICULOS),
        route: ROUTE_ADMIN_VEHICLE_BRANDS,
        routes: [ROUTE_ADMIN_VEHICLE_BRANDS, ROUTE_ADMIN_VEHICLE_BRAND_ADD, ROUTE_ADMIN_VEHICLE_BRAND_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.MODELOS_DE_VEHICULOS),
        route: ROUTE_ADMIN_VEHICLE_MODELS,
        routes: [ROUTE_ADMIN_VEHICLE_MODELS, ROUTE_ADMIN_VEHICLE_MODEL_ADD, ROUTE_ADMIN_VEHICLE_MODEL_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.LEYES),
        route: ROUTE_ADMIN_LAW_TYPES,
        routes: [ROUTE_ADMIN_LAW_TYPES, ROUTE_ADMIN_LAW_TYPE_ADD, ROUTE_ADMIN_LAW_TYPE_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.MOTIVOS_DE_PLAZAS_PERDIDAS),
        route: ROUTE_ADMIN_PARKING_LOSS_REASONS,
        routes: [ROUTE_ADMIN_PARKING_LOSS_REASONS, ROUTE_ADMIN_PARKING_LOSS_REASON_ADD, ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL],
      },
    ],
  },
  companyUser: {
    chat: [
      {
        title: I18n.tr(I18nKeys.CHAT),
        route: ROUTE_COMPANY_USER_CHAT,
        routes: [ROUTE_COMPANY_USER_CHAT],
      },
      {
        title: I18n.tr(I18nKeys.PUBLICAR_MENSAJE),
        route: ROUTE_COMPANY_USER_BROADCAST,
        routes: [ROUTE_COMPANY_USER_BROADCAST, ROUTE_COMPANY_USER_BROADCAST_ADD],
      },
    ],
    administration: [
      {
        title: I18n.tr(I18nKeys.USUARIOS),
        route: ROUTE_ADMIN_USERS,
        routes: [ROUTE_ADMIN_USERS, ROUTE_ADMIN_USER_ADD, ROUTE_ADMIN_USER_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.RUTAS),
        route: ROUTE_COMPANY_USER_ROUTES,
        routes: [ROUTE_COMPANY_USER_ROUTES, ROUTE_COMPANY_USER_ROUTE_ADD, ROUTE_COMPANY_USER_ROUTE_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.TIPOS_ZONAS_APARCAMIENTOS),
        route: ROUTE_COMPANY_USER_PARKING_AREA_TYPES,
        routes: [ROUTE_COMPANY_USER_PARKING_AREA_TYPES, ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD,
          ROUTE_COMPANY_USER_PARKING_AREA_TYPE_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.INCIDENCIAS_PARQUIMETROS),
        route: ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES,
        routes: [ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES, ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.RECAUDACIONES_PARQUIMETROS),
        route: ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS,
        routes: [ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS, ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.PROCESOS_RECAUDACION),
        route: ROUTE_ADMIN_COLLECTION_PROCESS,
        routes: [ROUTE_ADMIN_COLLECTION_PROCESS, ROUTE_ADMIN_COLLECTION_PROCESS_ADD,
          ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.PLAZAS_PERDIDAS),
        route: ROUTE_COMPANY_USER_PARKING_LOSSES,
        routes: [ROUTE_COMPANY_USER_PARKING_LOSSES, ROUTE_COMPANY_USER_PARKING_LOSS_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.CONFIGURACION_DE_LISTAS_NEGRAS),
        route: ROUTE_COMPANY_USER_BLACK_LIST_RULES,
        routes: [ROUTE_COMPANY_USER_BLACK_LIST_RULES, ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD,
          ROUTE_COMPANY_USER_BLACK_LIST_RULE_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.VEHICULOS),
        route: ROUTE_COMPANY_USER_VEHICLES,
        routes: [ROUTE_COMPANY_USER_VEHICLES, ROUTE_COMPANY_USER_VEHICLE_ADD, ROUTE_COMPANY_USER_VEHICLE_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.VEHICULO_EMBARCADO),
        route: ROUTE_COMPANY_USER_DELTA_CARS,
        routes: [ROUTE_COMPANY_USER_DELTA_CARS],
      },
    ],
    masterTables:[
      {
        title: I18n.tr(I18nKeys.ARTICULOS),
        route: ROUTE_COMPANY_USER_LAWS,
        routes: [ROUTE_COMPANY_USER_LAWS, ROUTE_COMPANY_USER_LAW_ADD, ROUTE_COMPANY_USER_LAW_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.PARQUIMETROS),
        route: ROUTE_COMPANY_USER_PARKING_METERS,
        routes: [ROUTE_COMPANY_USER_PARKING_METERS, ROUTE_COMPANY_USER_PARKING_METER_ADD, ROUTE_COMPANY_USER_PARKING_METER_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.CALLES),
        route: ROUTE_COMPANY_USER_STREETS,
        routes: [ROUTE_COMPANY_USER_STREETS, ROUTE_COMPANY_USER_STREET_ADD, ROUTE_COMPANY_USER_STREET_DETAIL],
      },
    ],
    management: [
      {
        title: I18n.tr(I18nKeys.DENUNCIAS),
        route: ROUTE_COMPANY_USER_REPORTS,
        routes: [ROUTE_COMPANY_USER_REPORTS, ROUTE_COMPANY_USER_REPORT_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.ANULACION_DENUNCIAS_DE_FICHERO),
        route: ROUTE_COMPANY_USER_REPORT_CANCELLATION_FILE,
        routes: [ROUTE_COMPANY_USER_REPORT_CANCELLATION_FILE],
      },
      {
        title: I18n.tr(I18nKeys.INFORME_ANULACION_DENUNCIAS_DE_FICHERO),
        route: ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS,
        routes: [ROUTE_COMPANY_USER_REPORT_CANCELLATION_LOGS],
      },
      {
        title: I18n.tr(I18nKeys.INFORME_CONTROLADORES),
        route: ROUTE_COMPANY_USER_CONTROLLERS,
        routes: [ROUTE_COMPANY_USER_CONTROLLERS],
      },
      {
        title: I18n.tr(I18nKeys.MAPA_DE_SITUACION),
        route: ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION,
        routes: [ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION],
      },
      {
        title: I18n.tr(I18nKeys.ADVERACION),
        route: ROUTE_COMPANY_USER_RATIFICATION_REPORTS,
        routes: [ROUTE_COMPANY_USER_RATIFICATION_REPORTS, ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL],
      },
      {
        title: I18n.tr(I18nKeys.ENVIO_A_TRAMITE),
        route: ROUTE_COMPANY_USER_SENDTOPROCESS,
        routes: [ROUTE_COMPANY_USER_SENDTOPROCESS],
      },
    ],
    userManual: [],
  },
  complaintViewerSent: {
    management: [
      {
        title: I18n.tr(I18nKeys.DENUNCIAS_ENVIADAS),
        route: ROUTE_COMPLAINT_VIEWER_SENT_REPORTS,
        routes: [ROUTE_COMPLAINT_VIEWER_SENT_REPORTS, ROUTE_COMPLAINT_VIEWER_SENT_REPORT_DETAIL],
      },
    ],
  },
  complaintViewer: {
    management: [
      {
        title: I18n.tr(I18nKeys.DENUNCIAS),
        route: ROUTE_COMPLAINT_VIEWER_REPORTS,
        routes: [ROUTE_COMPLAINT_VIEWER_REPORTS, ROUTE_COMPLAINT_VIEWER_REPORT_DETAIL],
      },
    ],
  },
  police: {
    management: [
      {
        title: I18n.tr(I18nKeys.DENUNCIAS),
        route: ROUTE_POLICE_REPORTS,
        routes: [ROUTE_POLICE_REPORTS, ROUTE_POLICE_REPORT_DETAIL],
      },
    ],
  },
};
