
import { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { StyleProps } from '../../components-new/typings';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - PROPS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export interface DetailsTextProps {
    label: string
    value?: string | null
}

type Props = DetailsTextProps & StyleProps;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default class DetailsText extends Component<Props> {

    public render(): ReactNode {
        const {label, value, className, style} = this.props;
        const classComponent = classNames('details-text', className);

        return (
            <div className={classComponent} style={style}>
                <div className='dt-label'>
                    {label}
                </div>
                <div className='dt-value'>
                    {value || '- - -'}
                </div>
            </div>
        );

    }

}
