import { debounce, isEqual } from "lodash";
import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskReportList from "../../../../commons/ws/report/TaskReportList";
import { CardListV3 } from "../../../../components-new/CardList";
import { ReportListTable } from "../../../../components/sections/ReportListTable";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import RatificationReportListCompanyUserFilter from "./filter/RatificationReportListCompanyUserFilter";
import RatificationReportListCompanyUserActions from "./RatificationReportListCompanyUserActions";
import RatificationReportListCompanyUserReducer from "./RatificationReportListCompanyUserReducer";

const handleGoToItemDetails = (id: string): void =>
  goToRoute(generateRoute(ROUTE_COMPANY_USER_RATIFICATION_REPORT_DETAIL, {
    id,
  } ));
const mapActionsToProps = RatificationReportListCompanyUserActions.autoMapToProps();
const mapStateToProps = RatificationReportListCompanyUserReducer.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

class RatificationReportListCompanyUserCard extends Component<Props> {
  public componentDidMount() {
    this.handleFetchData();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const differentCriteria = !isEqual(prevProps.dataCriteria, this.props.dataCriteria);

    if (differentCriteria)
      this.handleFetchDataDebounced();
  }

  public componentWillUnmount() {
    this.props.dispatchResetListReducer();
    this.handleFetchDataDebounced.cancel();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  public render(): ReactNode {
    const {handleFetchData,
      handleSetPage,
      handleSort,
      props: {dataLoading,
        dataPager,
        dataError,
        dataSort,
        data}} = this;

    return (
      <CardListV3
        showCheckboxes={undefined}
        itemIdsSelected={undefined}
        itemIdsDisabled={undefined}
        itemIdsSelectedDisabled={undefined}
        dataLoading={dataLoading}
        dataPager={dataPager}
        dataError={dataError}
        dataSort={dataSort}
        data={data}
        headerOptionIdSelected={undefined}
        headerOptions={undefined}
        emptyOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_DENUNCIAS),
        }}
        ItemsTable={ReportListTable}
        subtitle={undefined}
        footer={undefined}
        search={undefined}
        title={I18n.tr(I18nKeys.ADVERACION)}
        onGoToItemDetails={handleGoToItemDetails}
        onSelectAllItems={undefined}
        onChangeSearch={undefined}
        onSelectItem={undefined}
        onFetchData={handleFetchData}
        onRefresh={undefined}
        onSetPage={handleSetPage}
        onAssign={undefined}
        onSort={handleSort}
        onAdd={undefined}
      >
        <RatificationReportListCompanyUserFilter/>
      </CardListV3>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleSetPage = (page: number) => {
    this.props.dispatchSetPage(page);
  };

  private handleSort = (asc: boolean, column: string) => {
    this.props.dispatchSetSort( {
      asc,
      column,
    } );
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleFetchData = () => {
    const {dataCriteria} = this.props;

    new TaskReportList(dataCriteria).execute();
  };

  private handleFetchDataDebounced = debounce(this.handleFetchData, 500);
}

export default connect(mapStateToProps, mapActionsToProps)(RatificationReportListCompanyUserCard as any);
