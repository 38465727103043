import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import ChatBroadcast, {ChatBroadcastData} from "../../model/ChatBroadcast";

export default class TaskChatBroadcastAdd extends BaseGenericRequest<ChatBroadcastData, TaskResponse<ChatBroadcast>> {

    private readonly data: ChatBroadcastData;

    public constructor(data: ChatBroadcastData) {
        super(METHOD.POST, Urls.URL_CHAT_BROADCAST_ADD);
        this.data = data;
    }

    protected getRequest(): ChatBroadcastData {
        return this.data;
    }
}
