import React from "react";
import { connect } from "react-redux";
import Alert from "../../../../base/alerts/Alert";
import I18n from "../../../../commons/I18n/I18n";
import TaskVehicleBlackListExport from "../../../../commons/ws/vehicle/TaskVehicleBlackListExport";
import TaskVehicleDelete from "../../../../commons/ws/vehicle/TaskVehicleDelete";
import TaskVehicleList from "../../../../commons/ws/vehicle/TaskVehicleList";
import Button from "../../../../components/buttons/Button";
import CardList from "../../../../components/card/CardList";
import Col from "../../../../components/Col";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_VEHICLE_ADD } from "../../../../routing/Routes";
import VehicleListCompanyUserReducer from "./VehicleListCompanyUserReducer";
import VehicleListTable from "./VehicleListTable";

const mapStateToProps = VehicleListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps;

const onExportExcel = (): void => {
  new TaskVehicleBlackListExport().executeAsPromise()
    .then((response) => {
      if (response.success)
        window.open(response.data.url, "_blank");
    } )
    .catch(() => Alert.error(I18n.tr(I18nKeys.HA_OCURRIDO_UN_ERROR_EN_LA_EXPORTACIÓN_DE_LISTA_NEGRA)));
};

class VehicleListCardCompanyUser extends React.Component<Props> {
  public render(): React.ReactNode {
    const {loading, vehicles, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.VEHICULOS)}
        sort={{
          column: "license_plate",
        }}
        TaskList={TaskVehicleList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_VEHICULOS),
        }}
        data={vehicles}
        error={error}
        ItemsTable={VehicleListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_VEHICULOS),
          Task: TaskVehicleDelete,
        }}
        addItem={ROUTE_COMPANY_USER_VEHICLE_ADD}
      >
        {renderHeaderContent()}
      </CardList>
    );
  }
}
const renderHeaderContent = (): React.ReactNode => (
  <Row className={"m-t-15"}>
    <Col sm={8} md={9} lg={9}/>
    <Col sm={4} md={3} lg={3}>
      <Button
        text={I18n.tr(I18nKeys.EXPORTAR_LISTA_NEGRA)}
        onClick={onExportExcel}
        type={"button"}
        block={true}
        className={"btn-lg btn-default btn-export"}
      />
    </Col>
  </Row>
);

export default connect(mapStateToProps)(VehicleListCardCompanyUser as unknown as React.ComponentType<void>);
