import { Component, ReactNode, SyntheticEvent } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import { AppIcon } from '../../config/Config';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PROPERTIES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface ButtonRoundProps {
    icon: string
    disabled?: boolean
    classType: string
    className?: string
    onClick: () => void
}

interface ButtonRoundPredefinedProps {
    disabled?: boolean
    className?: string
    onClick: () => void
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export class ButtonRound extends Component<ButtonRoundProps> {

    public render(): ReactNode {
        const {
            icon,
            disabled,
            className,
            classType,
        } = this.props;
        const classComponent = classNames(classType, className);

        return (
            <button
                className={classComponent}
                onClick={this.handleClick}
                type={'button'}
                disabled={disabled}
            >
                <Icon icon={icon}/>
            </button>
        );
    }

    private handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
        this.props.onClick();
    };

}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PREDEFINED COMPONENTS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const ButtonRoundRemove = (props: ButtonRoundPredefinedProps) => (
    <ButtonRound
        {...props}
        icon={AppIcon.REMOVE}
        classType={'btn-round-remove'}
    />
);

export const ButtonRoundAdd = (props: ButtonRoundPredefinedProps) => (
    <ButtonRound
        {...props}
        icon={AppIcon.ADD}
        classType={'btn-round-add'}
    />
);
