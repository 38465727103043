import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskParkingLossReasonDelete from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonDelete";
import TaskParkingLossReasonList from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_PARKING_LOSS_REASON_ADD } from "../../../../routing/Routes";
import ParkingLossReasonListAdminReducer from "./ParkingLossReasonListAdminReducer";
import ParkingLossReasonListTable from "./ParkingLossReasonListTable";

const mapStateToProps = ParkingLossReasonListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class ParkingLossReasonListCardAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {loading, parkingLossesReasons, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.MOTIVOS_DE_PLAZAS_PERDIDAS)}
        sort={{
          column: "name",
        }}
        TaskList={TaskParkingLossReasonList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_MOTIVOS_DE_PLAZAS_PERDIDAS),
        }}
        data={parkingLossesReasons}
        error={error}
        ItemsTable={ParkingLossReasonListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_MOTIVOS_DE_PLAZAS_PERDIDAS),
          Task: TaskParkingLossReasonDelete,
        }}
        addItem={ROUTE_ADMIN_PARKING_LOSS_REASON_ADD}
      >
      </CardList>
    );
  }
}

export default connect(mapStateToProps)(ParkingLossReasonListCardAdmin as unknown as React.ComponentType<void>);
