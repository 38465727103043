import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import VehicleModel, {VehicleModelData} from "../../model/VehicleModel";

export default class TaskVehicleModelAdd extends BaseGenericRequest<VehicleModelData, TaskResponse<VehicleModel>> {

    private readonly data: VehicleModelData;

    public constructor(data: VehicleModelData) {
        super(METHOD.POST, Urls.URL_VEHICLE_MODEL_ADD);
        this.data = data;
    }

    protected getRequest(): VehicleModelData {
        return this.data;
    }
}
