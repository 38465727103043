import { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';

interface FormHiddenProps extends WrappedFieldProps {}

export default class FormHidden extends Component<FormHiddenProps> {

    public render(): React.ReactNode {
        return null;
    }
}
