import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {PagerBackendProps} from "../../../../../components/Pager";
import WorkdayReport from "../../../../../commons/model/WordayReport";
import TaskWorkdayReport from "../../../../../commons/ws/workdayReport/TaskWorkdayReport";
import User from "../../../../../commons/model/User";
import TaskUserList from "../../../../../commons/ws/user/TaskUserList";

interface InitialState {
    loading: boolean,
    workdayReports: WorkdayReport[],
    error: string,
    pager: PagerBackendProps,
    userList: User[],
}

const INITIAL_STATE: InitialState = {
    loading: false,
    workdayReports: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },

    userList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskWorkdayReport.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskWorkdayReport.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskWorkdayReport.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskWorkdayReport.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            workdayReports: payload.data,
            pager: payload.pager || oldState.pager
        }
    })

    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            userList: payload.data,
        }
    })

    .build()
