import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {PagerBackendProps} from "../../../../components/Pager";
import ParkingMeterIncidence from "../../../../commons/model/ParkingMeterIncidence";
import TaskParkingMeterIncidenceList from "../../../../commons/ws/parkingMeterIncidence/TaskParkingMeterIncidenceList";
import User from "../../../../commons/model/User";
import TaskUserList from "../../../../commons/ws/user/TaskUserList";
import TaskParkingMeterIncidenceTypeList from "../../../../commons/ws/parkingMeterIncidenceType/TaskParkingMeterIncidenceTypeList";
import ParkingMeterIncidenceType from "../../../../commons/model/ParkingMeterIncidenceType";

interface InitialState {
    loading: boolean,
    parkingMeterIncidences: ParkingMeterIncidence[],
    error: string,
    pager: PagerBackendProps,

    parkingMeterIncidenceTypeList: ParkingMeterIncidenceType[],
    userList: User[],
    userListLoading: boolean,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    parkingMeterIncidences: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },

    parkingMeterIncidenceTypeList: [],
    userList: [],
    userListLoading: false,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskParkingMeterIncidenceList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskParkingMeterIncidenceList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskParkingMeterIncidenceList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskParkingMeterIncidenceList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            parkingMeterIncidences: payload.data,
            pager: payload.pager || oldState.pager
        }
    })

    .onEvent(TaskParkingMeterIncidenceTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            parkingMeterIncidenceTypeList: payload.data,
        }
    })

    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, userListLoading: true}
    })
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, userListLoading: false}
    })
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            userList: payload.data,
        }
    })

    .build()
