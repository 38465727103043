import {Component, ReactNode} from "react";
import {connect} from "react-redux";
import CollectionProcessAddReducer from "./CollectionProcessAddReducer";
import CollectionProcessAddActions from "./CollectionProcessAddActions";
import CollectionProcessAddView from "./CollectionProcessAddView";
import TaskParkingMeterCollectionList
	from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionList";


const mapStateToProps = CollectionProcessAddReducer.autoMapToProps();
const mapActionsToProps = CollectionProcessAddActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class CollectionProcessAddDataContainer extends Component<Props> {
	public componentWillUnmount(): void {
		this.props.dispatchClearReducer();
	}

	public componentDidMount() {
		new TaskParkingMeterCollectionList({not_in_collection_process: true}).execute();
	}

	public render(): ReactNode {
		const {parkingMeterCollections, loading, errors, pager} = this.props;
		return (
			<CollectionProcessAddView
				parkingMeterCollections={parkingMeterCollections}
				loading={loading}
				errors={errors}
				pager={pager}
			/>
		);
	}
}
export default connect(mapStateToProps, mapActionsToProps)(CollectionProcessAddDataContainer as unknown as React.ComponentType<{}>);
