import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import {METHOD} from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import {StandardCriteriaRequest} from '../TaskCriteriaRequest';
import Report from '../../model/Report';

export type ReportExportHistoryCriteriaProps = StandardCriteriaRequest;

export default class TaskReportList extends BaseGenericRequest<ReportExportHistoryCriteriaProps, TaskResponse<Report[]>> {

    private readonly data: ReportExportHistoryCriteriaProps;

    public constructor(criteria: ReportExportHistoryCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_REPORT_EXPORT_HISTORY);
        this.data = criteria;
    }

    protected getRequest(): ReportExportHistoryCriteriaProps {
        return this.data;
    }
}
