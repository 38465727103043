import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import ParkingMeterCollection from "../../model/ParkingMeterCollection";
import {ParkingMeterCollectionCriteriaProps} from "./TaskParkingMeterCollectionList";


export default class TaskParkingMeterCollectionExport extends BaseGenericRequest<ParkingMeterCollectionCriteriaProps, TaskResponse<ParkingMeterCollection[]>> {

    private readonly data: ParkingMeterCollectionCriteriaProps;

    public constructor(criteria: ParkingMeterCollectionCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_METER_COLLECTION_EXPORT);
        this.data = criteria;
    }

    protected getRequest(): ParkingMeterCollectionCriteriaProps {
        return this.data;
    }

}
