import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import UserRole from "../../model/UserRole";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

type UserRoleCriteriaProps = TaskCriteriaRequest<{}>;

export default class TaskRoleList extends BaseGenericRequest<UserRoleCriteriaProps, TaskResponse<UserRole[]>> {

    private readonly data: UserRoleCriteriaProps;

    public constructor(criteria: UserRoleCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_USER_ROLE_LIST);
        this.data = criteria;
    }

    protected getRequest(): UserRoleCriteriaProps {
        return this.data;
    }
}
