import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import ParkingMeter, {ParkingMeterData} from "../../model/ParkingMeter";

export default class TaskParkingMeterAdd extends BaseGenericRequest<ParkingMeterData, TaskResponse<ParkingMeter>> {

    private readonly data: ParkingMeterData;

    public constructor(data: ParkingMeterData) {
        super(METHOD.POST, Urls.URL_PARKING_METER_ADD);
        this.data = data;
    }

    protected getRequest(): ParkingMeterData {
        return this.data;
    }
}
