
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_LOSSES } from "../../../../routing/Routes";
import ParkingLossListCardCompanyUser from "./ParkingLossListCardCompanyUser";

const ParkingLossListCompanyUserScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.PLAZAS_PERDIDAS),
      url: ROUTE_COMPANY_USER_PARKING_LOSSES,
      icon: AppIcon.PARKING,
    },
  ]}>
    <ParkingLossListCardCompanyUser/>
  </ScreenCard>
);

export default ParkingLossListCompanyUserScreen;
