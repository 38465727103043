import { useEffect, useState } from "react";
import { Field } from "redux-form";
import Alert from "../../../../../../base/alerts/Alert";
import { ParkId } from "../../../../../../commons/model/ParkId";
import TaskParkIdList from "../../../../../../commons/ws/streetsmart/TaskParkIdList";
import { FormColProps } from "../../../../../../components/form/FormCol";
import FormInputOption from "../../../../../../components/form/FormInputOption";
import FormSelect from "../../../../../../components/form/FormSelect";

interface StreetSmartParkIdFieldProps {
    col: FormColProps;
    name: string;
    label: string;
}

type EffectReturn = ()=> void;

const StreetSmartParkIdField: React.FC<StreetSmartParkIdFieldProps> = (
  {col,
    name,
    label},
) => {
  const [parkIds, setParkIds] = useState<FormInputOption[]>([]);

  useEffect((): EffectReturn => {
    let mounted = true;

    new TaskParkIdList()
      .onFail((error) => Alert.error(error))
      .onSuccess((res) => {
        const options: FormInputOption[] = res.data.map((pi: ParkId) => ( {
          value: String(pi),
          name: String(pi),
        } ));

        if (mounted)
          setParkIds(options);
      } )
      .execute();

    return () => {
      mounted = false;

      return mounted;
    };
  }, []);

  return (
    <Field
      col={col}
      name={name}
      label={label}
      options={parkIds}
      component={FormSelect}
      blankOptionText={" "}
    />
  );
};

export default StreetSmartParkIdField;