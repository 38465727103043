import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import {METHOD} from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import {TaskCriteriaRequest} from '../TaskCriteriaRequest';
import WorkdayResume from "../../model/WorkdayResume";

export interface WorkdayResumeCriteria {
    user_ids: string[],
    date: string,
}

export type WorkdayResumeCriteriaProps = TaskCriteriaRequest<WorkdayResumeCriteria>

export default class TaskWorkdayResume extends BaseGenericRequest<WorkdayResumeCriteriaProps, TaskResponse<WorkdayResume>> {

    private readonly data: WorkdayResumeCriteriaProps;

    public constructor(criteria: WorkdayResumeCriteria) {
        super(METHOD.POST, Urls.URL_WORKDAY_RESUME);
        this.data = criteria;
    }

    protected getRequest(): WorkdayResumeCriteriaProps {
        return this.data;
    }
}
