import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import LawType, {LawTypeData} from "../../model/LawType";

export default class TaskLawTypeAdd extends BaseGenericRequest<LawTypeData, TaskResponse<LawType>> {

    private readonly data: LawTypeData;

    public constructor(data: LawTypeData) {
        super(METHOD.POST, Urls.URL_LAW_TYPE_ADD);
        this.data = data;
    }

    protected getRequest(): LawTypeData {
        return this.data;
    }
}
