import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import CollectionProcessDetailActions from "./CollectionProcessDetailActions";
import {ParkingMeterCollectionProcess} from "../../../../commons/model/ParkingMeterCollectionProcess";
import TaskParkingMeterCollectionProcessDetail
	from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessDetail";
import {PagerBackendProps} from "../../../../components/Pager";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";

interface CorrectionMap {[id: string] : number}

interface CollectionProcessDetailReducerState {
	parkingMeterCollections: Array<ParkingMeterCollection>,
	correctionMap: CorrectionMap,
	parkingMeterCollectionProcess?: ParkingMeterCollectionProcess,
	loading: boolean,
	errors: string,
	pager: PagerBackendProps
}

const INITIAL_STATE: CollectionProcessDetailReducerState = {
	parkingMeterCollections: [],
	correctionMap: {},
	parkingMeterCollectionProcess: undefined,
	loading: false,
	errors: '',
	pager: {
		current: 1,
		total_pages: 1,
		total_elements: 0,
	},
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
	.onEvent(CollectionProcessDetailActions.dispatchClearReducer, () => INITIAL_STATE)

	.onEvent(TaskParkingMeterCollectionProcessDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
	.onEvent(TaskParkingMeterCollectionProcessDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
	.onEvent(TaskParkingMeterCollectionProcessDetail.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, error: payload}))
	.onEvent(TaskParkingMeterCollectionProcessDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
		const newState = {...oldState};

		const parkingMeterCollectionProcess = payload.data;

		const correctionMap: CorrectionMap = {};
		parkingMeterCollectionProcess.parking_meter_collections.forEach(parkingMeterCollection => {
			correctionMap[`${parkingMeterCollection.id}`] = parkingMeterCollection.cash;
		})

		newState.parkingMeterCollectionProcess = parkingMeterCollectionProcess;

		newState.parkingMeterCollections = parkingMeterCollectionProcess.parking_meter_collections.map(parkingMeterCollection => parkingMeterCollection);

		newState.correctionMap = correctionMap;
		newState.pager = payload.pager || oldState.pager;

		return newState;
	})

	.onEvent(CollectionProcessDetailActions.dispatchSetCollectionCash, (oldState, payload) => {
		const newState = {...oldState};
		const correctionMap = {...newState.correctionMap};

		newState.parkingMeterCollections.map(parkingMeterCollection => {
			if (parkingMeterCollection.id === payload.id) {
				parkingMeterCollection.real_cash = payload.cash
			}
			return parkingMeterCollection;
		});

		correctionMap[payload.id] = payload.cash;
		newState.correctionMap = correctionMap;

		return newState;
	})

	.build()
