
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import DeltaCar, { DeltaCarData } from "../../../../commons/model/DeltaCar";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskDeltaCarAdd from "../../../../commons/ws/deltaCar/TaskDeltaCarAdd";
import TaskDeltaCarDetail from "../../../../commons/ws/deltaCar/TaskDeltaCarDetail";
import TaskDeltaCarEdit from "../../../../commons/ws/deltaCar/TaskDeltaCarEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_COMPANY_USER_DELTA_CARS, ROUTE_COMPANY_USER_DELTA_CAR_ADD,
  ROUTE_COMPANY_USER_DELTA_CAR_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import DeltaCarCardFormCompanyUser, { DeltaCarCompanyUserData } from "../formdetail/DeltaCarCardFormCompanyUser";
import DeltaCarFormCompanyUserScreenActions from "./DeltaCarFormCompanyUserScreenActions";
import DeltaCarFormScreenCompanyUserReducer from "./DeltaCarFormScreenCompanyUserReducer";

const mapStateToProps = DeltaCarFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = DeltaCarFormCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class DeltaCarFormCompanyUserScreen extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  public componentDidMount(): void {
    const {id} = this.props.match.params;

    if (id) 
      loadItemDetailsOrGoBack(new TaskDeltaCarDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearDeltaCarFormScreenAdminReducer();
  }

  private onSubmit = (values: DeltaCarCompanyUserData): void => {
    const deltaCarId = this.props.match.params.id;
    const submitData: DeltaCarData = {
      name: values.name,
      license_plate: values.license_plate,
    };

    if (deltaCarId) {
      executeItemTask(
        new TaskDeltaCarEdit(deltaCarId, submitData),
        () => goToRoute(ROUTE_COMPANY_USER_DELTA_CARS),
        I18n.tr(I18nKeys.VEHICULO_EMBARCADO_MODIFICADO_CORRECTAMENTE),
      );
    } else {
      executeItemTask(new TaskDeltaCarAdd(submitData),
        () => goToRoute(ROUTE_COMPANY_USER_DELTA_CARS),
        I18n.tr(I18nKeys.VEHICULO_EMBARCADO_CREADO_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const deltaCarId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.VEHICULOS_EMBARCADOS),
      url: ROUTE_COMPANY_USER_DELTA_CARS,
      icon: AppIcon.CAR,
    }];

    if (deltaCarId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.VEHICULO_EMBARCADO),
        url: generateRoute(ROUTE_COMPANY_USER_DELTA_CAR_DETAIL, {
          id: deltaCarId,
        } ),
        icon: AppIcon.CAR,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVO_VEHICULO_EMBARCADO),
        url: ROUTE_COMPANY_USER_DELTA_CAR_ADD,
        icon: AppIcon.CAR,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderDeltaCarForm()}
      </ScreenCard>
    );
  }

  private renderDeltaCarForm(): React.ReactNode {
    const {deltaCarFormScreenLoading, deltaCar} = this.props;
    let initialValues: DeltaCarCompanyUserData | undefined;

    if (deltaCar) {
      const deltaCarData: DeltaCar = deltaCar;

      initialValues = {
        license_plate: deltaCarData.license_plate,
        name: deltaCarData.name,
      };
    }

    return (
      <DeltaCarCardFormCompanyUser
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        parentLoading={deltaCarFormScreenLoading}
      />);
  }
}

export default connect(mapStateToProps, mapActionsToProps)(DeltaCarFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
