
import classNames from "classnames";
import { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";

export interface SubItems {
    title: string;
    route: string;
    routes: string[];
}

export interface MenuItemProps {
    title: string;
    icon: string;
    active: boolean;
    route?: string;
    expanded?: boolean;
    onExpand?: (expand: boolean)=> void;
    subItems?: SubItems[];
    visible?: boolean;
    selectedSubItemIndex?: number;
}

export default class MenuItem extends Component<MenuItemProps> {
  public render(): ReactNode {
    const {visible = true} = this.props;

    if (!visible)
      return null;

    const classComponent = classNames( {
      active: this.props.active,
    } );

    return (
      <li className={classComponent}>
        {this.renderLink()}
        {this.renderSubItems()}
      </li>
    );
  }

  private renderLink() {
    const {title, icon} = this.props;

    if (this.props.subItems && this.props.onExpand) {
      const classA = classNames("menu-toggle", "waves-effect", "waves-block", {
        toggled: this.props.expanded,
      } );
      const {onExpand} = this.props;

      return (
        <a onClick={() => onExpand(!this.props.expanded)} className={classA}>
          <Icon icon={icon}/>
          <span>{title}</span>
        </a>
      );
    }

    const classA = classNames("waves-effect", "waves-block", {
      toggled: this.props.expanded,
    } );

    return (
      <Link to={this.props.route || ""} className={classA}>
        <Icon icon={icon}/>
        <span>{title}</span>
      </Link>
    );
  }

  private renderSubItems(): ReactNode {
    if (!this.props.subItems)
      return null;

    return (
      <ul className="ml-menu" style={{
        display: this.props.expanded ? "block" : "none",
      }}>
        {this.props.subItems.map((subItem, index: number) => (
          <li key={index}
          >
            <Link to={subItem.route}
              className={this.props.selectedSubItemIndex === index ? "active" : ""}
            >
              {subItem.title}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}
