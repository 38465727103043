import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import ParkingLoss from "../../model/ParkingLoss";


type ManagementProcessingTypeCriteriaProps  = TaskCriteriaRequest<{}>

export default class TaskManagementProcessingTypeList extends BaseGenericRequest<ManagementProcessingTypeCriteriaProps, TaskResponse<ParkingLoss[]>> {

    private readonly data: ManagementProcessingTypeCriteriaProps;

    public constructor(criteria: ManagementProcessingTypeCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_MANAGEMENT_PROCESSING_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): ManagementProcessingTypeCriteriaProps {
        return this.data;
    }

}
