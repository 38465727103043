import classNames from "classnames";
import { StyleProps } from "./typings";

interface Props {
    label: string;
    disabled?: boolean;
    onRef?: any;
    onChange: (date: Date | null)=> void;
}

// eslint-disable-next-line import/prefer-default-export
export const NewFilterDate: React.FunctionComponent<Props & StyleProps> = (
  {label,
    disabled = false,
    onRef,
    style,
    className,
    onChange},
) => {
  const classComponent = classNames("input-filter", className);

  return (
    <div className={classComponent} style={style}>
      <label>{label}</label>
      <input
        type={"date"}
        disabled={disabled}
        ref={onRef}
        onChange={(e) => onChange(e.target.valueAsDate)}
      />
    </div>
  );
};
