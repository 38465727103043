import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { generateRoute } from '../../utils/Router';
import Report from '../../model/Report';

export default class TaskReportDetail extends BaseGenericRequest<{}, TaskResponse<Report>> {

    public constructor(id: string) {
        super(METHOD.GET, generateRoute(Urls.URL_REPORT_DETAIL, {uuid: id}));
    }
}
