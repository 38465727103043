import { ActionBuilder } from '@cuatroochenta/co-redux-builders';
import { SortState } from '../../../../utils/Sort';

export default ActionBuilder.build({
    dispatchResetListReducer: () => ( {} ),
    dispatchAddCriteria: (newCriteria: object) => ( newCriteria ),
    dispatchClearCriteria: () => ( {} ),
    dispatchSetSort: (newSort: SortState) => ( newSort ),
    dispatchSetPage: (newPage: number) => ( newPage ),
});
