
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_COLORS } from "../../../../routing/Routes";
import VehicleColorListCardAdmin from "./VehicleColorListCardAdmin";

const VehicleColorListAdminScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.COLORES_DE_VEHICULOS),
      url: ROUTE_ADMIN_VEHICLE_COLORS,
      icon: AppIcon.CAR,
    },
  ]}>
    <VehicleColorListCardAdmin/>
  </ScreenCard>
);
  
export default VehicleColorListAdminScreen;
