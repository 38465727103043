
import { Component, CSSProperties, ReactNode } from "react";
import { ClipLoader } from "react-spinners";

export interface LoadingProps {
    loading: boolean;
}

export default class LoadingView extends Component<LoadingProps> {
  public render(): ReactNode {
    if (!this.props.loading)
      return null;

    return (
      <div style={style}>
        <div className={"align-middle text-center"}>
          <ClipLoader
            size={"50px"}
            color={"#FBB907"}
            loading={this.props.loading}
          />
        </div>
      </div>
    );
  }
}

const style: CSSProperties = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 100,
  borderRadius: 10,
};
