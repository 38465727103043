import BaseGenericRequest from "../BaseGenericRequest";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import TaskResponse from "../TaskResponse";
import WorkdayReport from "../../model/WordayReport";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";

interface WorkdayReportCustomCriteria {
    user_ids?: string[],
    start_date?: string,
    end_date?: string,
}

export type WorkdayReportCriteria = TaskCriteriaRequest<WorkdayReportCustomCriteria>;

export default class TaskWorkdayReport extends BaseGenericRequest<WorkdayReportCriteria, TaskResponse<WorkdayReport[]>> {

    private readonly data: WorkdayReportCriteria;

    public constructor(criteria: WorkdayReportCriteria = {}) {
        super(METHOD.POST, Urls.URL_WORKDAY_REPORT);
        this.data = criteria;
    }

    protected getRequest(): WorkdayReportCriteria {
        return this.data;
    }

}
