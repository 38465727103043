import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import ReportState from '../../model/ReportState';

export type ReportSentToProcessStateProps = TaskCriteriaRequest<{}>

export default class TaskReportSentToProcessState extends BaseGenericRequest<ReportSentToProcessStateProps, TaskResponse<ReportState[]>> {

    private readonly data: ReportSentToProcessStateProps;

    public constructor(criteria: ReportSentToProcessStateProps = {}) {
        super(METHOD.POST, Urls.URL_REPORT_SENT_TO_PROCESS_STATE_LIST);
        this.data = criteria;
    }

    protected getRequest(): ReportSentToProcessStateProps {
        return this.data;
    }
}
