import { METHOD } from "@cuatroochenta/co-generic-request";
import { sha256 } from "js-sha256";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

interface TaskLoginRequest {
    platform: string;
    email: string;
    password: string;
}

export default class TaskLogin extends BaseGenericRequest<TaskLoginRequest, TaskResponse<string>> {
  private readonly email: string;

  private readonly password: string;

  private readonly platform: string;

  private readonly wrapErrorCodeWithMessage: boolean;

  public constructor(email: string, password: string, wrapErrorCodeWithMessage = false) {
    super(METHOD.POST, Urls.URL_LOGIN);

    this.platform = navigator && navigator.product === "ReactNative" ? "app" : "backend";
    this.email = email;
    this.password = password;
    this.wrapErrorCodeWithMessage = wrapErrorCodeWithMessage;

    this.setErrorMessage(401, I18n.tr(I18nKeys.NO_ESTAS_AUTORIZADO));
    this.setErrorMessage(404, I18n.tr(I18nKeys.EL_USUARIO_NO_EXISTE));
    this.setErrorMessage(490, I18n.tr(I18nKeys.CONTRASENA_INCORRECTA));
  }

  protected parseResponse(responseObj: any) {
    if (!this.wrapErrorCodeWithMessage)
      return super.parseResponse(responseObj);

    if (responseObj.success === false)
    {throw new Error(JSON.stringify( {
      errorMessage: responseObj.message || this.errorMessages[responseObj.code],
      errorCode: responseObj.code,
    } ));}

    return super.parseResponse(responseObj);
  }

  protected getRequest(): TaskLoginRequest {
    return {
      platform: this.platform,
      email: this.email,
      password: sha256(this.password),
    };
  }
}
