import { ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import UnloggedScreen from "../../../base/screens/UnloggedScreen";
import I18n from "../../../commons/I18n/I18n";
import TaskSetPassword from "../../../commons/ws/login/TaskSetPassword";
import Button from "../../../components/buttons/Button";
import Card from "../../../components/card/Card";
import CardBody from "../../../components/card/CardBody";
import Col from "../../../components/Col";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";
import I18nKeys from "../../../I18n/I18nKeys";
import imgLogo from "../../../res/img/logo.png";
import { ROUTE_HOME } from "../../../routing/Routes";
import { executeItemTask } from "../../../utils/FormUtils";
import { goToRoute } from "../../../utils/Router";
import { isValidPassword } from "../../../utils/StringUtils";
import SetNewPasswordActions from "./SetNewPasswordActions";
import SetNewPasswordReducer from "./SetNewPasswordReducer";

const mapStateToProps = SetNewPasswordReducer.autoMapToProps();
const mapActionsToProps = SetNewPasswordActions.autoMapToProps();

type Props = RouteComponentProps<{ token: string }> & typeof mapActionsToProps & typeof mapStateToProps;

interface State {
    showErrorMsg: boolean;
}

class SetNewPasswordScreen extends UnloggedScreen<Props, State> {
  public state: State = {
    showErrorMsg: false,
  };

  // eslint-disable-next-line class-methods-use-this
  public componentDidMount() {
    document.body.classList.add("login-page");
  }

  public componentWillUnmount() {
    this.props.clearReducer();
    document.body.classList.remove("login-page");
  }

  private changePassword = () => {
    const {password, passwordsAreEqual, newPasswordIsValid} = this.props;
    const {token} = this.props.match.params;

    if (passwordsAreEqual && newPasswordIsValid) {
      executeItemTask(
        new TaskSetPassword(password, token),
        () => goToRoute(ROUTE_HOME),
        I18n.tr(I18nKeys.CONTRASENA_MODIFICADA_CORRECTAMENTE),
        I18n.tr(I18nKeys.LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA),
      );
    } else {
      this.setState( {
        showErrorMsg: true,
      } );
    }
  };

  private handleKeyboard = (event) => {
    if (event.key === "Enter") 
      this.changePassword();
  };

  public renderScreen(): ReactNode {
    const {loading, password, setPassword, rePassword, setRePassword, newPasswordIsValid, passwordsAreEqual} = this.props;
    const {showErrorMsg} = this.state;

    return (
      <div className="login-box">
        <div className="logo">
          <a>
            <img src={imgLogo} alt="Pavapark" className="img-responsive center-block m-b-15"/>
          </a>
          <small>{I18n.tr(I18nKeys.MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE)}</small>
        </div>
        <Card loading={loading}>
          <CardBody>
            <div className="msg">{I18n.tr(I18nKeys.RESTABLECE_LA_CONTRASENA)}</div>
            <div className="input-group">
              <span className="input-group-addon"><Icon icon={"lock"}/></span>
              <div className="form-line">
                <input
                  type="password"
                  className="form-control"
                  placeholder={I18n.tr(I18nKeys.NUEVA_CONTRASENA)}
                  required={true}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={this.handleKeyboard}
                />
              </div>
            </div>
            {showErrorMsg && <div>
              <p className={"error-message"}>{isValidPassword(password)
                ? "" :
                I18n.tr(I18nKeys.LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES)}</p>
            </div>}

            <div className="input-group">
              <span className="input-group-addon"><Icon icon={"lock"}/></span>
              <div className="form-line">
                <input
                  type="password"
                  className="form-control"
                  placeholder={I18n.tr(I18nKeys.REPITE_LA_CONTRASENA)}
                  required={true}
                  value={rePassword}
                  onChange={(e) => setRePassword(e.target.value)}
                  onKeyPress={this.handleKeyboard}
                />
              </div>
            </div>
            {showErrorMsg && <div>
              <p className={"error-message"}>{password === rePassword
                ? "" :
                I18n.tr(I18nKeys.LAS_CONTRASENAS_NO_COINCIDEN)}</p>
            </div>}

            <Row>
              <Col xs={12}>
                <Button
                  className={"bg-blue-grey"}
                  text={I18n.tr(I18nKeys.RESTABLECER_CONTRASENA)}
                  onClick={this.changePassword}
                  block={true}
                  disabled={showErrorMsg && (!newPasswordIsValid || !passwordsAreEqual)}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(SetNewPasswordScreen);
