import FormCol from '../form/FormCol';
import DateFormatter from '../../utils/DateFormatter';

interface Props {
    gridSize: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    label: string
    initialValue?: string
    disabled?: boolean
    onRef?: any
    onChange: (date: Date) => void
}

export const FilterDate: React.FunctionComponent<Props> = (
    {
        gridSize,
        label,
        initialValue,
        disabled = false,
        onRef,
        onChange
    }
) => {

    // Si no es con este formato específico el componente no muestra la fecha.
    // https://stackoverflow.com/questions/7372038/is-there-any-way-to-change-input-type-date-format
    const defaultValue: string = initialValue
        ? DateFormatter.formatDate(initialValue, 'YYYY-MM-DD')
        : '';

    return (
        <FormCol md={gridSize} lg={gridSize}>
            <div className={'form-group'}>
                <div className={'form-line'}>
                    <label>{label}</label>
                    <input
                        type={'date'}
                        className="form-control"
                        disabled={disabled}
                        ref={onRef}
                        // @ts-ignore
                        onChange={(e) => onChange(e.target.valueAsDate)}
                        defaultValue={defaultValue}
                    />
                </div>
            </div>
        </FormCol>
    );
};
