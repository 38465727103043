import { FunctionComponent } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import I18n from "../../commons/I18n/I18n";
import I18nKeys from "../../I18n/I18nKeys";
import { AutocompleteOption } from "../form/FormAutocomplete";
import FormCol from "../form/FormCol";

interface Props {
    gridSize: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    label: string;
    options: Array<AutocompleteOption<any>>;
    initialValue?: string;
    isLoading: boolean;
    disabled?: boolean;
    onRef?: any;
    onSearch?: (query: string)=> void;
    onChange: (id: string)=> void;
}

// eslint-disable-next-line import/prefer-default-export
export const FilterTypeahead: FunctionComponent<Props> = (
  {gridSize,
    label,
    options,
    initialValue,
    isLoading,
    disabled = false,
    onRef,
    onSearch = () => null,
    onChange},
) => {
  const defaultOption = options.find((option: AutocompleteOption<any>) => option.value === initialValue);
  const defaultInputValue: string | undefined = defaultOption ? defaultOption.name : undefined;

  return (
    <FormCol md={gridSize} lg={gridSize}>
      <div className="form-group">
        <div className="form-line">
          <label>{label}</label>
          <AsyncTypeahead
            align={"justify"}
            allowNew={false}
            autoFocus={false}
            clearButton={false}
            delay={200}
            disabled={disabled}
            emptyLabel={I18n.tr(I18nKeys.NO_SE_HAN_ENCONTRADO_RESULTADOS)}
            ignoreDiacritics={true}
            isLoading={isLoading}
            labelKey={"name"}
            maxResults={10}
            minLength={2}
            multiple={false}
            onChange={(selected) => selected.length > 0 && onChange(selected[0].data.id)}
            onInputChange={(input) => input === "" && onChange("-1")}
            onSearch={onSearch}
            options={options}
            placeholder={I18n.tr(I18nKeys.BUSCAR)}
            promptText={I18n.tr(I18nKeys.BUSCAR)}
            searchText={I18n.tr(I18nKeys.BUSCANDO)}
            selectHintOnEnter={true}
            useCache={true}
            defaultInputValue={defaultInputValue}
            ref={onRef}
          />
        </div>
      </div>
    </FormCol>
  );
};
