import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {
    ParkingMeterCollectionProcess,
    ParkingMeterCollectionProcessCorrectionData
} from "../../model/ParkingMeterCollectionProcess";
import {generateRoute} from "../../utils/Router";

export default class TaskParkingMeterCollectionProcessCorrection extends BaseGenericRequest<ParkingMeterCollectionProcessCorrectionData, TaskResponse<ParkingMeterCollectionProcess>> {

    private readonly data: ParkingMeterCollectionProcessCorrectionData;

    public constructor(uuid: string, data: ParkingMeterCollectionProcessCorrectionData) {
        super(METHOD.POST, generateRoute(Urls.URL_PARKING_METER_COLLECTION_PROCESS_CORRECTION, {uuid}));
        this.data = data;
    }

    protected getRequest(): ParkingMeterCollectionProcessCorrectionData {
        return this.data;
    }

}
