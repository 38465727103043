import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import UserType from "../../model/UserType";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

type UserTypeCriteriaProps = TaskCriteriaRequest<{}>;

export default class TaskRoleList extends BaseGenericRequest<UserTypeCriteriaProps, TaskResponse<UserType[]>> {

    private readonly data: UserTypeCriteriaProps;

    public constructor(criteria: UserTypeCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_USER_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): UserTypeCriteriaProps {
        return this.data;
    }
}
