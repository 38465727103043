import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import classNames from "classnames";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import I18n from "../../../commons/I18n/I18n";
import ChatBroadcast from "../../../commons/model/ChatBroadcast";
import { ChatMessageData } from "../../../commons/model/ChatMessage";
import SOSData from "../../../commons/model/SOSData";
import TaskChatBroadcastList from "../../../commons/ws/chat/TaskChatBroadcastList";
import TaskChatContactList from "../../../commons/ws/chat/TaskChatContactList";
import TaskChatMessageAdd from "../../../commons/ws/chat/TaskChatMessageAdd";
import TaskChatMessageList from "../../../commons/ws/chat/TaskChatMessageList";
import Button from "../../../components/buttons/Button";
import Card from "../../../components/card/Card";
import Col from "../../../components/Col";
import ChatFormDragFile from "../../../components/form/ChatFormDragFile";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";
import { AppIcon } from "../../../config/Config";
import I18nKeys from "../../../I18n/I18nKeys";
import CurrentUserReducer from "../../../redux/CurrentUserReducer";
import imgDefaultUser from "../../../res/img/default-user.png";
import BroadcastManager from "../../../utils/BroadcastManager";
import DateFormatter, { DateUtils } from "../../../utils/DateFormatter";
import ChatCardCompanyUserReducer from "./ChatCardCompanyUserReducer";
import ChatImageModal from "./ChatImageModal";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  ChatCardCompanyUserReducer.autoMapToProps(),
  CurrentUserReducer.autoMapToProps(),
);

type Props = typeof mapStateToProps;
interface State {
    minInputScrollHeight: number;
    currentInputScrollHeight: number;
    messageText: string;
    files: string[];
    isDragActionStart: boolean;
    openFileDialog: boolean;
    intervalMessages?: NodeJS.Timeout;
    intervalContactList?: NodeJS.Timeout;
    nodeContainer: HTMLElement | null;
    showImageModal: boolean;
    currentImage: string;
}

const onArchiveBroadcast = (broadcastId: string): void => {
  BroadcastManager.addReadedBroadcastId(broadcastId);
  getBroadcastList();
};
const STEP_HEIGHT = 20;
const MAX_HEIGHT_VALUE = 200;
const SHOW_BROADCAST_LIMIT = 3;
const INTERVAL_TIME = 30 * 1000;

enum ChatCardFields {
    FILES = "files",
    MESSAGE_TEXT = "messageText",
}
class ChatCardCompanyUser extends React.Component<Props, State> {
  private nodeTextAreaRef;

  private nodeMessagesContainerRef;

  public constructor(props: Props) {
    super(props);
    this.state = {
      minInputScrollHeight: 0,
      currentInputScrollHeight: 0,
      messageText: "",
      files: [],
      isDragActionStart: false,
      openFileDialog: false,
      nodeContainer: null,
      showImageModal: false,
      currentImage: "",
    };
  }

  public componentDidMount(): void {
    getContactList();
    this.setState( {
      intervalContactList: setInterval(getContactList, INTERVAL_TIME),
    } );
    getBroadcastList();
    this.getMessages();
    this.setState( {
      intervalMessages: setInterval(this.getMessages, INTERVAL_TIME),
    } );

    if (this.nodeTextAreaRef && this.nodeTextAreaRef.scrollHeight)
    {this.setState( {
      minInputScrollHeight: this.nodeTextAreaRef.scrollHeight,
    } );}

    const nodeContainer = document.getElementById("sidebar-content");

    if (nodeContainer) {
      // @ts-ignore: contentClassName existe en Sidebar
      nodeContainer.style.marginBottom = "110px";
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.messageList !== prevProps.messageList)
      this.scrollBodyToBottom();
  }

  public componentWillUnmount(): void {
    if (this.state.intervalMessages)
      clearInterval(this.state.intervalMessages);

    if (this.state.intervalContactList)
      clearInterval(this.state.intervalContactList);
  }

  public render(): React.ReactNode {
    const {currentImage, showImageModal} = this.state;

    return (
      <>
        <div className={"chat"}>
          {this.renderStickyChatHeader()}
          <div className={"messages-container"} ref={(node) => {
            this.nodeMessagesContainerRef = node;

            return node;
          }}>
            {this.renderMessages()}
          </div>
        </div>
        {this.renderFooter()}
        <ChatImageModal key={`image-${currentImage}`}
          url={currentImage}
          show={showImageModal}
          onClose={this.closeImageModal}
        />
      </>
    );
  }

  private openImageModal = (): void => {
    this.setState( {
      showImageModal: true,
    } );
  };

  private closeImageModal = (): void => {
    this.setState( {
      showImageModal: false,
    } );
  };

  private onSetCurrentImage = (url: string): void => {
    this.setState( {
      currentImage: url,
    } );
  };

  private scrollBodyToBottom = (): void => {
    if (this.nodeMessagesContainerRef && this.nodeMessagesContainerRef.scrollHeight) {
      // sidebar-content: id del componente 'ReactSidebar' en la clase 'MenuWrapper'
      const nodeContainer = document.getElementById("sidebar-content");

      if (nodeContainer)
        nodeContainer.scrollTop = nodeContainer.scrollHeight;
    }
  };

  private getMessages = (): void => {
    new TaskChatMessageList( {
      start_date: this.props.maxDate,
      end_date: null,
      limit: 0,
    } )
      .execute();
  };

  private onSetCurrentScrollHeight = (newHeight: number): void => {
    this.setState( {
      currentInputScrollHeight: newHeight,
    } );
  };

  private onSend = (): void => {
    let sendData: ChatMessageData = {
      message: this.state.messageText && this.state.messageText.length !== 0 ? this.state.messageText : null,
    };

    if (this.state.files.length !== 0) {
      sendData = {
        ...sendData,
        image: this.state.files[0],
      };
    }

    new TaskChatMessageAdd(sendData)
      .onSuccess((response) => {
        if (response.success) {
          this.onSetText();
          this.onRestartMessageInputSize();
          this.onSetFiles();
          this.getMessages();
        }
      } )
      .execute();
  };

  private onSetText = (text: string = ""): void => {
    this.setState( {
      messageText: text,
    } );
  };

  private onSetFiles = (files: string[] = []): void => {
    this.setState( {
      files,
    } );
  };

  private onStartDragAction = (): void => {
    this.setState( {
      isDragActionStart: true,
    } );
  };

  private onOpenFileDialog = (): void => {
    this.setState( {
      openFileDialog: true,
    } );
    this.setState( {
      isDragActionStart: true,
    } );
  };

  private onCloseFileDialog = (): void => {
    this.setState( {
      openFileDialog: false,
    } );
    this.setState( {
      isDragActionStart: false,
    } );
  };

  private onFinishDragAction = (): void => {
    this.setState( {
      isDragActionStart: false,
    } );
  };

  private onRestartMessageInputSize = (): void => {
    this.nodeTextAreaRef.style.height = `${this.state.minInputScrollHeight }px`;
  };

  private onChangeMessageInputSize = (): void => {
    if (this.nodeTextAreaRef.scrollHeight > this.state.minInputScrollHeight) {
      const currentHeightValue = parseInt(window.getComputedStyle(this.nodeTextAreaRef).getPropertyValue("height"), 10);

      if (this.nodeTextAreaRef.scrollHeight !== this.state.currentInputScrollHeight) {
        const newHeightValue = currentHeightValue + STEP_HEIGHT;

        if (newHeightValue <= MAX_HEIGHT_VALUE) {
          this.nodeTextAreaRef.style.height = `${newHeightValue.toString() }px`;
          this.onSetCurrentScrollHeight(newHeightValue);
          this.nodeTextAreaRef.style.overflow = "hidden";
        } else {
          this.nodeTextAreaRef.style.overflowY = "scroll";
          this.nodeTextAreaRef.style.overflow = "visible";
        }
      }
      // TODO: Falta redimensionar al borrar líneas.
      // if (this.nodeTextAreaRef.scrollHeight === this.state.currentInputScrollHeight) {
      //     // this.nodeTextAreaRef.style.height = 'fit-content';
      //     this.nodeTextAreaRef.style.height = 'auto';
      //     // const newHeightValue = parseInt(window.getComputedStyle(this.nodeTextAreaRef).getPropertyValue('height'), 10);
      //     // this.onSetCurrentScrollHeight(newHeightValue);
      // }
    }
  };

  private renderStickyChatHeader(): React.ReactNode {
    return (
      <div className="chat-header-sticky-container">
        <Card loading={false} className={"chat-card"}>
          <div className="header chat-header">
            <h2>{I18n.tr(I18nKeys.CHAT)}</h2>
            {this.renderContacts()}
          </div>
        </Card>
        {this.renderBroadcast()}
      </div>
    );
  }

  private renderContacts(): React.ReactNode {
    const {contactList} = this.props;

    return (
      <div className={"contact-container"}>
        {
          contactList.map((contact, index) => {
            const tooltip: React.ReactNode = (<Tooltip id={"userChat"} key={`${index}-tooltip`}>{contact.name} {contact.last_name}</Tooltip>);
            const onlineClassName = contact.online ? "online" : "offline";

            return (
              <div key={index} className={`contact ${onlineClassName}`}>
                <OverlayTrigger key={index} overlay={tooltip} placement={"top"}>
                  <img src={imgDefaultUser} alt="User"/>
                </OverlayTrigger>
              </div>
            );
          } )
        }
      </div>
    );
  }

  private renderBroadcast(): React.ReactNode {
    const {broadcastList} = this.props;
    const broadcastToShow: ChatBroadcast[] = [];

    broadcastList.forEach((broadcast: ChatBroadcast) => {
      const isNotExpiratedDate = DateUtils.isGreaterThan(broadcast.expiration_date, DateUtils.getCurrentDate());
      const isReadedBroadcast = BroadcastManager.getReadedBroadcastIds().includes(broadcast.id);

      if (isNotExpiratedDate && !isReadedBroadcast) {
        if (broadcastToShow.length < SHOW_BROADCAST_LIMIT)
          broadcastToShow.push(broadcast);
      }
    } );

    return (
      broadcastToShow.map((broadcast, index) => {
        const firstBroadcastContainerStyle = {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          marginTop: -7,
        };

        return (
          <div
            key={index}
            className={"broadcast-container"}
            style={index === 0
              ? firstBroadcastContainerStyle
              : {
              }}
          >
            <div className={"broadcast-message"}>
              <span className={"font-bold"}>Título</span><br/>
              {broadcast.message}
            </div>
            <div className={"broadcast-close"} onClick={() => onArchiveBroadcast(broadcast.id)}>
              <Icon icon={AppIcon.CLOSE} className={"icon-close"}/>
            </div>
          </div>
        );
      } ));
  }

  private renderMessages(): React.ReactNode {
    const {messageList, currentUser} = this.props;

    return (
      messageList.map((message, index) => {
        const date = getDate(message.date);
        const isFromCurrentUser = currentUser ? message.user.id === currentUser.id : false;
        const isSameUserMessage = index > 0 && messageList[index - 1].user.id === message.user.id;
        const isSameDate = index > 0 && DateUtils.isSameDay(messageList[index - 1].date, message.date);
        const isSameHour = index < messageList.length - 1 && DateFormatter.formatTime(messageList[index + 1].date) === DateFormatter.formatTime(message.date);
        const isSOSmessage = message.sos;
        const showHourMessage = index === 0 || !isSameHour;
        const newText = message.message && message.message.split("\n").map((item, i) => <><span
          key={i}>{item}</span><br/></>);
        const messageStyle = classNames("chat-message", {
          "message-sos": isSOSmessage,
          "message-user": isFromCurrentUser && !isSOSmessage,
        } );

        return (
          <>
            {!isSameDate &&
                        <div className={"day-text-container"} key={`day${index + 1000000000}`}>
                          <div className={"day-text"}>{date}</div>
                        </div>}
            {isFromCurrentUser ?
              <div className={"message-container-right"} key={index}>
                <div className={messageStyle}>
                  <span className={"font-bold"}>
                    {`${message.user.name} ${message.user.last_name}`}
                  </span><br/>
                  {renderMessageBody(newText, isSOSmessage)}
                </div>
                {message.image && this.renderImage(message.image)}
                {showHourMessage &&
                                <p className={"message-time-right"}>{DateFormatter.formatTime(message.date)}</p>}
              </div>
              :
              <div className={`message-container-left ${!isSameUserMessage ? "m-t-10" : ""}`} key={index}>
                <div className={"message-content-avatar"}>
                  <div className={"img-contact"} key={`image${index}`}>
                    {isSameUserMessage ?
                      <span className={"p-l-30"}/> :
                      <>
                        <img src={imgDefaultUser} alt="User"/>
                        {message.user.online && <div className={"online-indicator"}/>}
                      </>
                    }
                  </div>
                  <div className={"message-content-avatar-inner"}>
                    <div className={messageStyle} key={`message${index}`}>
                      <span className={"font-bold"}>
                        {`${message.user.name} ${message.user.last_name}`}
                      </span><br/>
                      {renderMessageBody(newText, isSOSmessage)}
                    </div>
                    {message.image && this.renderImage(message.image)}
                  </div>
                </div>
                {showHourMessage &&
                                <p className={"message-time-left"}>{DateFormatter.formatTime(message.date)}</p>}
              </div>
            }
          </>
        );
      } )
    );
  }

  private renderImage(image: string): React.ReactNode {
    return (
      <div className={"chat-image-container"}>
        <img src={image} alt={""} className={"chat-image cursor-pointer"}
          onClick={() => {
            this.onSetCurrentImage(image);
            this.openImageModal();
          }}/>
      </div>
    );
  }

  private renderFooter(): React.ReactNode {
    const enabledSend = this.state.messageText.length !== 0 || this.state.files.length !== 0;
    const disabledUploadImage = this.state.files.length === 1;
    const iconCameraClassName = `icon-camera ${disabledUploadImage ? "disabled" : ""}`;
    const fileTypes = [".jpg", ".jpeg", ".png", ".raw"];

    return (
      <div className={"chat-footer"}
        onDragOver={this.onStartDragAction}
        onDragLeave={this.onFinishDragAction}
      >
        <div className={"chat-footer-container"}>
          <div className={"camera-container"}>
            <span onClick={() => !disabledUploadImage && this.onOpenFileDialog()}>
              <Icon icon={AppIcon.CAMERA}
                className={iconCameraClassName}
              />
            </span>
          </div>
          <div className={"message-input"}>
            <ChatFormDragFile name={ChatCardFields.FILES}
              col={{
                md: 12,
                lg: 12,
              }}
              multiple={false}
              input={{
                value: this.state.files,
                onBlur: () => null,
                onChange: (value) => {
                  this.onSetFiles(value);
                  this.onFinishDragAction();
                },
                onDragStart: () => null,
                onDrop: () => null,
                onFocus: () => null,
                name: ChatCardFields.FILES,
              }}
              // @ts-ignore
              meta={{
                autofilled: false,
                asyncValidating: false,
                dirty: false,
                initial: null,
                invalid: false,
                pristine: true,
                submitting: false,
                submitFailed: false,
                touched: false,
                valid: true,
                visited: false,
              }}
              noClick={true}
              className={"drag-file-chat"}
              isDragActionStart={this.state.isDragActionStart}
              openFileDialog={this.state.openFileDialog}
              closeFileDialogHandler={this.onCloseFileDialog}
              fileTypes={fileTypes}
              invalidFileMessage={I18nKeys.SOLO_SE_ADMITEN_FICHEROS_DE_TIPO_IMAGEN}
              finishDragActionStartHandler={this.onFinishDragAction}
            >
              {
                !this.state.isDragActionStart &&
                                <textarea
                                  ref={(node) => {
                                    this.nodeTextAreaRef = node;

                                    return node;
                                  }}
                                  name={ChatCardFields.MESSAGE_TEXT}
                                  value={this.state.messageText}
                                  className="form-control form-control-message"
                                  placeholder={I18n.tr(I18nKeys.ESCRIBIR_MENSAJE)}
                                  maxLength={255}
                                  onChange={(event) => {
                                    this.onChangeMessageInputSize();
                                    this.onSetText(event.target.value);
                                  }}
                                />
              }
            </ChatFormDragFile>
          </div>
          <div className={"button-send"}>
            <Row>
              <Col lg={6} md={6} sm={6} xs={9}>
                <Button
                  text={I18n.tr(I18nKeys.ENVIAR)}
                  onClick={this.onSend}
                  type={"submit"}
                  block={true}
                  className={"btn btn-lg btn-block btn-primary waves-effect form-btn"}
                  disabled={!enabledSend}
                />
              </Col>
            </Row>
            {this.state.isDragActionStart && this.state.files.length === 0 &&
                        <Row className={"m-t-10"}>
                          <Col lg={6} md={6} sm={6} xs={9}>
                            <Button
                              text={I18n.tr(I18nKeys.CANCELAR)}
                              onClick={this.onFinishDragAction}
                              type={"button"}
                              block={true}
                              className={"btn btn-lg btn-block btn-primary waves-effect form-btn"}
                              disabled={false}
                            />
                          </Col>
                        </Row>}
          </div>
        </div>
      </div>
    );
  }
}

const getContactList = (): void => {
  new TaskChatContactList().execute();
};
const getBroadcastList = (): void => {
  new TaskChatBroadcastList().execute();
};
const renderMessageBody = (message, sos: SOSData | undefined) => {
  if (sos) {
    return (
      <div className={"message-sos-body"}>
        <p className={"problem-reported"}>
          {I18n.tr(I18nKeys.ALARMA_SOS)}
        </p>
        <p>{sos.problem_reported}</p>
        <div className={"location"}>
          <a href={`https://maps.google.com/?q=${ sos.latitude },${ sos.longitude}`} target={"_blank"}>
            {I18n.tr(I18nKeys.VER_UBICACION)}
          </a>
          <i className={"material-icons"}>
            {AppIcon.ARROW_FORWARD}
          </i>
        </div>
      </div>
    );
  }

  return <p>{message}</p>;
};
const getDate = (date: Date): string => {
  if (DateUtils.isToday(date))
    return I18n.tr(I18nKeys.HOY);

  if (DateUtils.isYesterday(date))
    return I18n.tr(I18nKeys.AYER);

  return DateFormatter.formatDate(date);
};

export default connect(mapStateToProps)(ChatCardCompanyUser) as unknown as React.ComponentType<{}>;
