import { CSSProperties, FunctionComponent } from 'react';
import classNames from 'classnames';
import { StyleProps } from './typings';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - PROPS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export interface ButtonGroupItem {
    label: string
    disabled: boolean
    onClick: () => void
}

interface ButtonGroupProps {
    buttons: ButtonGroupItem[]
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const NewButtonGroup: FunctionComponent<ButtonGroupProps & StyleProps> = (
    {
        buttons,
        className = '',
        style,
    }
) => {
    const classComponent = classNames('new-btn-group', className);

    // Filtramos para mostrar solo los botones activos
    const buttonsEnabled = buttons.filter((item: ButtonGroupItem) => !item.disabled);

    // Si no hay ningún boton activo no mostramos nada
    if (buttonsEnabled.length === 0) {
        return null;
    }

    return (
        <div
            className={classComponent}
            style={style}
        >
            {
                buttonsEnabled.map((button: ButtonGroupItem, index: number) => {
                    const isFirst: boolean = index === 0;
                    const isLast: boolean = index === buttons.length - 1;

                    const buttonStyle: CSSProperties = {
                        borderBottomLeftRadius: isFirst ? 6 : 0,
                        borderTopLeftRadius: isFirst ? 6 : 0,
                        borderRight: isLast ? 'none' : '#0063A2 solid 1px',
                        borderBottomRightRadius: isLast ? 6 : 0,
                        borderTopRightRadius: isLast ? 6 : 0,
                        color: button.disabled ? '#d3d3d3' : '#0063A2',
                    };

                    return (
                        <button
                            key={index}
                            type={'button'}
                            disabled={button.disabled}
                            onClick={button.onClick}
                            className={'new-btn-agroup'}
                            children={button.label}
                            style={buttonStyle}
                        />
                    );
                })
            }
        </div>
    );
};
