import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";
import {METHOD} from "@cuatroochenta/co-generic-request";
import ParkingMeterIncidence from "../../model/ParkingMeterIncidence";
import {generateRoute} from "../../utils/Router";

export default class TaskParkingMeterIncidenceDetail extends BaseGenericRequest<{}, TaskResponse<ParkingMeterIncidence>> {

    public constructor(parkingMeterIncidenceId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_PARKING_METER_INCIDENCE_DETAIL, {uuid: parkingMeterIncidenceId}));
    }
}
