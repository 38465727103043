import { ChangeEvent, FunctionComponent } from 'react';
import Icon from '../components/Icon';
import { CardHeaderSearcherProps } from './typings';

export const CardHeaderSearcher: FunctionComponent<CardHeaderSearcherProps> = (
    {
        searchText,
        onChangeSearch,
    }
) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChangeSearch(e.target.value);
    };

    return (
        <div className="input-group-search m-b-0">
            <div className="form-line form-line-icon">
                <input
                    type="text"
                    className="form-control date"
                    placeholder="Buscar"
                    value={searchText}
                    onChange={handleChange}
                />
                <a className="input-group-addon" onClick={() => onChangeSearch('')}>
                    {
                        searchText.length > 0 ? <Icon icon={'clear'}/> : null
                    }
                </a>
            </div>
        </div>
    );
};
