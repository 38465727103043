import {ReduxStorageUtils} from '@cuatroochenta/co-redux-builders';
import {reducer as FormReducer} from 'redux-form';
import AuthReducer from '../modules/auth/login/LoginReducer';
import SideBarReducer from '../base/screens/menu/SideBar/SideBarReducer';
import NavBarReducer from '../base/screens/menu/SideBar/NavBarReducer';
import UserListCardAdminReducer from '../modules/admin/users/list/UserListCardAdminReducer';
import UserFormScreenReducer from '../modules/admin/users/formscreen/UserFormScreenAdminReducer';
import LogListCardAdminReducer from '../modules/admin/log/list/LogListCardAdminReducer';
import ExploitationFormAdminScreenReducer from '../modules/admin/exploitations/form/ExploitationFormAdminScreenReducer';
import ExploitationListCardAdminReducer from '../modules/admin/exploitations/list/ExploitationListCardAdminReducer';
import LogUserCardAdminReducer from '../modules/admin/log/user/LogUserCardAdminReducer';
import CurrentUserReducer from './CurrentUserReducer';
import SetNewPasswordReducer from '../modules/auth/setNewPassword/SetNewPasswordReducer';
import RoleFieldArrayReducer from '../modules/admin/users/formdetail/RoleFieldArrayReducer';
import UserTypeFieldArrayReducer from '../modules/admin/users/formdetail/UserTypeFieldArrayReducer';
import UserCardFormAdminReducer from '../modules/admin/users/formdetail/UserCardFormAdminReducer';
import RouteCompanyUserReducer from '../modules/companyUser/routes/list/RouteCompanyUserReducer';
import RouteFormScreenCompanyUserReducer from '../modules/companyUser/routes/formscreen/RouteFormScreenCompanyUserReducer';
import StreetCompanyUserReducer from '../modules/companyUser/street/list/StreetCompanyUserReducer';
import StreetFormScreenCompanyUserReducer from '../modules/companyUser/street/formscreen/StreetFormScreenCompanyUserReducer';
import StreetCardFormCompanyUserReducer from '../modules/companyUser/street/formdetail/StreetCardFormCompanyUserReducer';
import ParkingMeterListCompanyUserReducer from '../modules/companyUser/parkingMeter/list/ParkingMeterListCompanyUserReducer';
import ParkingMeterFormScreenCompanyUserReducer from '../modules/companyUser/parkingMeter/formscreen/ParkingMeterFormScreenCompanyUserReducer';
import ParkingMeterIncidenceListCompanyUserReducer from '../modules/companyUser/parkingMeterIncidence/list/ParkingMeterIncidenceListCompanyUserReducer';
import ParkingMeterIncidenceFormScreenCompanyUserReducer from '../modules/companyUser/parkingMeterIncidence/formscreen/ParkingMeterIncidenceFormScreenCompanyUserReducer';
import ParkingMeterCollectionListCompanyUserReducer from '../modules/companyUser/parkingMeterCollection/list/ParkingMeterCollectionListCompanyUserReducer';
import ParkingMeterCollectionFormScreenCompanyUserReducer from '../modules/companyUser/parkingMeterCollection/formscreen/ParkingMeterCollectionFormScreenCompanyUserReducer';
import ParkingLossListCompanyUserReducer from '../modules/companyUser/parkingLoss/list/ParkingLossListCompanyUserReducer';
import ParkingLossFormScreenCompanyUserReducer from '../modules/companyUser/parkingLoss/formscreen/ParkingLossFormScreenCompanyUserReducer';
import ReportListCompanyUserReducer from '../modules/companyUser/report/list/ReportListCompanyUserReducer';
import ReportDetailsCompanyUserReducer from '../modules/companyUser/report/details/ReportDetailsCompanyUserReducer';
import LawListCompanyUserReducer from '../modules/companyUser/law/list/LawListCompanyUserReducer';
import LawFormScreenCompanyUserReducer from '../modules/companyUser/law/formscreen/LawFormScreenCompanyUserReducer';
import RatificationReportListCompanyUserReducer from '../modules/companyUser/ratificationReport/list/RatificationReportListCompanyUserReducer';
import RatificationReportListCompanyUserFilterReducer from '../modules/companyUser/ratificationReport/list/filter/RatificationReportListCompanyUserFilterReducer';
import RatificationReportDetailsCompanyUserReducer from '../modules/companyUser/ratificationReport/details/RatificationReportDetailsCompanyUserReducer';
import BlackListRuleListCompanyUserReducer from '../modules/companyUser/blackListRule/list/BlackListRuleListCompanyUserReducer';
import BlackListRuleFormScreenCompanyUserReducer from '../modules/companyUser/blackListRule/formscreen/BlackListRuleFormScreenCompanyUserReducer';
import ControllerReportCompanyUserReducer from '../modules/companyUser/controller/controllerReport/list/ControllerReportCompanyUserReducer';
import ControllerSituationMapCompanyUserReducer from '../modules/companyUser/controller/controllerSituationMap/ControllerSituationMapCompanyUserReducer';
import ReportListCompanyUserModalReducer from '../modules/companyUser/report/list/modal/ReportListCompanyUserModalReducer';
import ParkingAreaTypeCompanyUserListReducer from '../modules/companyUser/parkingAreaType/list/ParkingAreaTypeCompanyUserListReducer';
import ParkingAreaTypeCompanyUserFormScreenReducer from '../modules/companyUser/parkingAreaType/formscreen/ParkingAreaTypeCompanyUserFormScreenReducer';
import ReportCancellationMethodListAdminReducer from '../modules/admin/reportCancellationMethod/list/ReportCancellationMethodListAdminReducer';
import ReportCancellationMethodFormScreenAdminReducer from '../modules/admin/reportCancellationMethod/formscreen/ReportCancellationMethodFormScreenAdminReducer';
import VehicleColorListAdminReducer from '../modules/admin/vehicleColor/list/VehicleColorListAdminReducer';
import VehicleColorFormScreenAdminReducer from '../modules/admin/vehicleColor/formscreen/VehicleColorFormScreenAdminReducer';
import VehicleBrandListAdminReducer from '../modules/admin/vehicleBrand/list/VehicleBrandListAdminReducer';
import VehicleBrandFormScreenAdminReducer from '../modules/admin/vehicleBrand/formscreen/VehicleBrandFormScreenAdminReducer';
import VehicleModelListAdminReducer from '../modules/admin/vehicleModel/list/VehicleModelListAdminReducer';
import VehicleModelFormScreenAdminReducer from '../modules/admin/vehicleModel/formscreen/VehicleModelFormScreenAdminReducer';
import ReportInvalidationReasonAdminListReducer from '../modules/admin/reportInvalidationReasons/list/ReportInvalidationReasonAdminListReducer';
import ReportInvalidationReasonAdminFormReducer from '../modules/admin/reportInvalidationReasons/formscreen/ReportInvalidationReasonAdminFormReducer';
import VehicleFormScreenCompanyUserReducer from '../modules/companyUser/vehicle/formscreen/VehicleFormScreenCompanyUserReducer';
import VehicleListCompanyUserReducer from '../modules/companyUser/vehicle/list/VehicleListCompanyUserReducer';
import ChatCardCompanyUserReducer from '../modules/companyUser/chat/ChatCardCompanyUserReducer';
import ChatBroadcastCompanyUserReducer from '../modules/companyUser/chat/broadcast/list/ChatBroadcastCompanyUserReducer';
import SendToProccessReducer from '../modules/companyUser/sendToProcess/SendToProccessReducer';
import ExploitationReportCodeSectionAdminReducer from '../modules/admin/exploitations/formdetail/cards/ExploitationReportCodeSectionAdminReducer';
import ReportDetailsCompanyUserModalReducer from '../modules/companyUser/report/details/modal/ReportDetailsCompanyUserModalReducer';
import LawTypeListAdminReducer from '../modules/admin/lawType/list/LawTypeListAdminReducer';
import LawTypeFormScreenAdminReducer from '../modules/admin/lawType/formscreen/LawTypeFormScreenAdminReducer';
import ParkingLossReasonFormScreenAdminReducer from '../modules/admin/parkingLossReason/formscreen/ParkingLossReasonFormScreenAdminReducer';
import ParkingLossReasonListAdminReducer from '../modules/admin/parkingLossReason/list/ParkingLossReasonListAdminReducer';
import ReportCancellationByFileFormScreenReducer from '../modules/companyUser/report/cancel/file/ReportCancellationByFileFormScreenReducer';
import ReportCancelLogListReducer from '../modules/companyUser/report/cancel/log/ReportCancelLogListReducer';
import UserCertCardCompanyUserReducer from '../modules/companyUser/userCert/UserCertCompanyUserReducerScreen';
import DomainReducer from './DomainReducer';
import HomeReducer from "../modules/home/HomeReducer";
import CollectionProcessAdminReducer from '../modules/admin/collectionprocessadmin/list/CollectionProcessAdminReducer';
import CollectionProcessDetailReducer
    from "../modules/admin/collectionprocessadmin/detail/CollectionProcessDetailReducer";
import CollectionProcessAddReducer from "../modules/admin/collectionprocessadmin/add/CollectionProcessAddReducer";
import DeltaCarListCardAdminReducer from "../modules/companyUser/deltaCar/list/DeltaCarListCompanyUserReducer";
import DeltaCarFormScreenAdminReducer from "../modules/companyUser/deltaCar/formscreen/DeltaCarFormScreenCompanyUserReducer";

( FormReducer as any ).reactotronKey = 'form';

export default ReduxStorageUtils.combineReducers([
    // GENERAL
    FormReducer as any,
    SideBarReducer,
    CurrentUserReducer,
    AuthReducer,
    NavBarReducer,
    SetNewPasswordReducer,
    DomainReducer,

    // HOME
    HomeReducer,

    // ADMIN, COMPANY USER - USER
    UserListCardAdminReducer,
    UserFormScreenReducer,
    RoleFieldArrayReducer,
    UserTypeFieldArrayReducer,
    UserCardFormAdminReducer,

    // ADMIN - LOG
    LogListCardAdminReducer,
    LogUserCardAdminReducer,

    // ADMIN - EXPLOITATION
    ExploitationFormAdminScreenReducer,
    ExploitationListCardAdminReducer,
    ExploitationReportCodeSectionAdminReducer,

    // ADMIN - PARKING AREA TYPE
    ParkingAreaTypeCompanyUserListReducer,
    ParkingAreaTypeCompanyUserFormScreenReducer,

    // ADMIN - REPORT INVALIDATION REASON
    ReportInvalidationReasonAdminListReducer,
    ReportInvalidationReasonAdminFormReducer,

    // ADMIN - REPORT CANCELLATION METHOD
    ReportCancellationMethodListAdminReducer,
    ReportCancellationMethodFormScreenAdminReducer,

    // ADMIN - VEHICLE COLOR
    VehicleColorListAdminReducer,
    VehicleColorFormScreenAdminReducer,

    // ADMIN - VEHICLE BRAND
    VehicleBrandListAdminReducer,
    VehicleBrandFormScreenAdminReducer,

    // ADMIN - VEHICLE MODEL
    VehicleModelListAdminReducer,
    VehicleModelFormScreenAdminReducer,

    // ADMIN - LAW TYPE
    LawTypeListAdminReducer,
    LawTypeFormScreenAdminReducer,

    // ADMIN - PARKING LOSS REASON
    ParkingLossReasonListAdminReducer,
    ParkingLossReasonFormScreenAdminReducer,

    // ADMIN - DELTA CAR
    DeltaCarListCardAdminReducer,
    DeltaCarFormScreenAdminReducer,

    // COMPANY USER - ROUTE
    RouteCompanyUserReducer,
    RouteFormScreenCompanyUserReducer,

    // COMPANY USER - STREET
    StreetCompanyUserReducer,
    StreetFormScreenCompanyUserReducer,
    StreetCardFormCompanyUserReducer,

    // COMPANY USER - PARKING METER
    ParkingMeterListCompanyUserReducer,
    ParkingMeterFormScreenCompanyUserReducer,

    // COMPANY USER - PARKING METER INCIDENCE
    ParkingMeterIncidenceListCompanyUserReducer,
    ParkingMeterIncidenceFormScreenCompanyUserReducer,

    // COMPANY USER - PARKING METER COLLECTION
    ParkingMeterCollectionListCompanyUserReducer,
    ParkingMeterCollectionFormScreenCompanyUserReducer,

    // COMPANY USER - PARKING LOSS
    ParkingLossListCompanyUserReducer,
    ParkingLossFormScreenCompanyUserReducer,

    // COMPANY USER - REPORTS
    ReportListCompanyUserReducer,
    ReportListCompanyUserModalReducer,
    ReportDetailsCompanyUserReducer,
    ReportDetailsCompanyUserModalReducer,
    ReportCancellationByFileFormScreenReducer,
    ReportCancelLogListReducer,

    // COMPANY USER - RATIFICATION REPORTS
    RatificationReportListCompanyUserReducer,
    RatificationReportListCompanyUserFilterReducer,
    RatificationReportDetailsCompanyUserReducer,

    // COMPANY USER - SEND TO PROCESS
    SendToProccessReducer,

    // COMPANY USER - LAW
    LawListCompanyUserReducer,
    LawFormScreenCompanyUserReducer,

    // COMPANY USER - BLACK LIST RULE
    BlackListRuleListCompanyUserReducer,
    BlackListRuleFormScreenCompanyUserReducer,

    // COMPANY USER, MANAGER - WORKDAY REPORT
    ControllerReportCompanyUserReducer,

    // COMPANY USER, MANAGER - WORKDAY SITUATION MAP
    ControllerSituationMapCompanyUserReducer,

    // COMPANY USER - VEHICLE
    VehicleListCompanyUserReducer,
    VehicleFormScreenCompanyUserReducer,

    // COMPANY USER - CHAT
    ChatCardCompanyUserReducer,
    ChatBroadcastCompanyUserReducer,

    // COMPANY USER - USER CERT
    UserCertCardCompanyUserReducer,

    // COLLECTION PROCESS
    CollectionProcessAdminReducer,
    CollectionProcessDetailReducer,
    CollectionProcessAddReducer,
]);
