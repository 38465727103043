import {Modal} from "react-bootstrap";
import React from 'react';

interface Props {
    url: string,
    show: boolean,
    onClose: () => void,
}

export default class ChatImageModal extends React.Component<Props> {

    public render(): React.ReactNode {
        const {onClose, show, url} = this.props;

        return (
            <Modal onHide={onClose} show={show}
                   className={'modal-image'}
                   dialogClassName={'modal-image-size'}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className={'modal-image-container'}>
                        <img src={url} alt={""}/>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

