import React from "react";
import I18n from "../../../../commons/I18n/I18n";
import User from "../../../../commons/model/User";
import { generateRoute } from "../../../../commons/utils/Router";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_USER_DETAIL } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";
import UserCertTooltipInfo from "../UserCertTooltipInfo";

interface UserListTableProps {
    data: User[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

const goToItemDetail = (itemId: string): void => {
  goToRoute(generateRoute(ROUTE_ADMIN_USER_DETAIL, {
    id: itemId,
  } ));
};

export default class UserListTable extends React.Component<UserListTableProps> {
  public render(): React.ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((user: User) => (
            <tr key={user.id}
              onClick={() => goToItemDetail(user.id)}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(user.id) : null}
              <td>
                {user.name}
              </td>
              <td>{user.last_name ? user.last_name : ""}</td>
              <td>{user.email}</td>
              <td>{user.user_cert ?
                <UserCertTooltipInfo key={user.id} userCert={user.user_cert}/>
                : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.NOMBRE)}
          columnName={"name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.APELLIDO)}
          columnName={"last_name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.EMAIL)}
          columnName={"email"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.CERTIFICADO)}
          columnName={"user_cert"}
          {...commonProps}
        />
      </tr>
    );
  }
}
