import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

interface TaskReportExportProcessingRequest {
    date: string;
}

type TaskReportExportProcessingResponse = TaskResponse<{url: string}>;

export default class TaskReportExportProcessing extends BaseGenericRequest<TaskReportExportProcessingRequest, TaskReportExportProcessingResponse> {
  private readonly data: TaskReportExportProcessingRequest;

  public constructor(date: string) {
    super(METHOD.POST, Urls.URL_REPORT_EXPORT_PROCESSING);
    this.data = {
      date,
    };

    this.setErrorMessage(404, I18n.tr(I18nKeys.NO_EXISTEN_DENUNCIAS_PENDIENTES_DE_ENVIAR_PARA_LA_FECHA_INDICADA));
  }

  protected getRequest(): TaskReportExportProcessingRequest {
    return this.data;
  }
}
