import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import Report from '../../model/Report';
import { generateRoute } from '../../utils/Router';

interface TaskReportRatifierRequest {
    signature_image_ratifier?: string
    ratifier_images: string[],
    street_id?: string,
    street_number?: string
    vehicle_type_id?: string
    vehicle_model_id?: string
    vehicle_color_id?: string
}

type TaskReportRatifierResponse = TaskResponse<Report>

export default class TaskReportRatifier extends BaseGenericRequest<TaskReportRatifierRequest, TaskReportRatifierResponse> {

    private readonly data: TaskReportRatifierRequest;

    public constructor(
        id: string,
        ratifierImage: string[],
        signatureImageRatifier?: string,
        streetID?: string,
        streetNumber?: string,
        vehicleTypeId?: string,
        vehicleModelId?: string,
        vehicleColorId?: string,
    ) {
        super(METHOD.POST, generateRoute(Urls.URL_REPORT_RATIFIER, {uuid: id}));
        this.data = {
            signature_image_ratifier: signatureImageRatifier,
            ratifier_images: ratifierImage,
            street_id: streetID,
            street_number: streetNumber,
            vehicle_type_id: vehicleTypeId,
            vehicle_model_id: vehicleModelId,
            vehicle_color_id: vehicleColorId,
        };
    }

    protected getRequest(): TaskReportRatifierRequest {
        return this.data;
    }
}
