
import { Component, ReactNode } from "react";
import I18n from "../../commons/I18n/I18n";
import I18nKeys from "../../I18n/I18nKeys";
import Button from "../buttons/Button";
import Col from "../Col";
import Row from "../Row";

export interface CardDeleteFooterProps {
    okTitle: string;
    okHandler: ()=> void;
    okBtnDisabled?: boolean;
    cancelTitle?: string;
    cancelHandler: ()=> void;
}

export default class CardOkCancelFooter extends Component<CardDeleteFooterProps> {
  public render(): ReactNode {
    const {okTitle,
      okHandler,
      okBtnDisabled,
      cancelTitle = I18n.tr(I18nKeys.CANCELAR),
      cancelHandler} = this.props;

    return (
      <Row className='p-t-20'>
        <Col sm={4} md={6} lg={8}/>
        <Col sm={4} md={3} lg={2}>
          <Button
            text={cancelTitle}
            onClick={cancelHandler}
            type={"button"}
            block={true}
            className={"btn-lg btn-default"}
          />
        </Col>
        <Col sm={4} md={3} lg={2}>
          <Button
            text={okTitle}
            onClick={okHandler}
            type={"button"}
            block={true}
            className={"btn-primary btn-lg"}
            disabled={okBtnDisabled}
          />
        </Col>
      </Row>
    );
  }
}
