import {Component, ReactNode} from 'react';
import BreadcrumbManager, {BreadcrumbItem} from "./BreadcrumbManager";
import {Link} from "react-router-dom";

export interface BreadcrumbProps {
    breadcrumbManager: BreadcrumbManager,
}

export default class Breadcrumb extends Component<BreadcrumbProps> {

    public render(): ReactNode {
        const breadcrumbItems: BreadcrumbItem[] = this.props.breadcrumbManager.getItems();
        if (breadcrumbItems === null || breadcrumbItems.length === 0) {
            return null;
        }
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {breadcrumbItems.map((item: BreadcrumbItem, i: number) => {
                        return (
                            <li key={i}>
                                <Link to={item.url}>
                                    {item.icon ? <i className="material-icons">{item.icon}</i> : null} {item.name}
                                </Link>
                            </li>
                        );
                    })}
                </ol>
            </nav>
        );
    }

}

