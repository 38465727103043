import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_LAW_TYPES } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import LawTypeFormScreenAdminReducer from "../formscreen/LawTypeFormScreenAdminReducer";

const FORM_NAME = "LawTypeCardFormAdmin";

export interface LawTypeCardFormAdminData {
    name: string;
    sumaCode: string;
    discountPercentage: string;
}

interface LawTypeCardFormAdminProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: LawTypeCardFormAdminData)=> void;
    initialValues?: Partial<LawTypeCardFormAdminData>;
    parentLoading?: boolean;
}

const mapStateToProps = LawTypeFormScreenAdminReducer.autoMapToProps();

type Props =
    InjectedFormProps<LawTypeCardFormAdminData> & LawTypeCardFormAdminProps & typeof mapStateToProps;

enum LawTypeFormAdminFields {
    NAME = "name",
    SUMA_CODE = "sumaCode",
    DISCOUNT_PERCENTAGE = "discountPercentage"
}

class LawTypeCardFormAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, pristine, parentLoading} = this.props;

    return (
      <Card loading={parentLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.LEY : I18nKeys.NUEVA_LEY)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={LawTypeFormAdminFields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.CODIGO_SUMA)}
                name={LawTypeFormAdminFields.SUMA_CODE}
                component={FormInput}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />

              <Field
                label={I18n.tr(I18nKeys.DESCUENTO)}
                name={LawTypeFormAdminFields.DISCOUNT_PERCENTAGE}
                type={"number"}
                component={FormInput}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />
            </Row>

            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_ADMIN_LAW_TYPES)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: LawTypeCardFormAdminData) {
  const errors: FormErrors<LawTypeCardFormAdminData> = {
  };

  if (!values.name || values.name.length === 0)
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  if (!values.sumaCode || values.sumaCode.toString().length === 0)
    errors.sumaCode = I18n.tr(I18nKeys.CODIGO_SUMA_OBLIGATORIO);
  else if (values.sumaCode.toString().length > 2)
    errors.sumaCode = `${I18n.tr(I18nKeys.LONGITUD_DEBE_SER)} 2`;

  if (!values.discountPercentage || values.discountPercentage.toString().length === 0)
    errors.discountPercentage = I18n.tr(I18nKeys.DESCUENTO_OBLIGATORIO);

  return errors;
}

export default compose(
  reduxForm<LawTypeCardFormAdminData, LawTypeCardFormAdminProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(LawTypeCardFormAdmin);
