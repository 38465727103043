import React from "react";
import { connect } from "react-redux";
import Alert from "../../../../../base/alerts/Alert";
import I18n from "../../../../../commons/I18n/I18n";
import TaskReportCancellationLogExport from "../../../../../commons/ws/reportCancellationLog/TaskReportCancellationLogExport";
import TaskReportCancellationLogList from "../../../../../commons/ws/reportCancellationLog/TaskReportCancellationLogList";
import Button from "../../../../../components/buttons/Button";
import CardList from "../../../../../components/card/CardList";
import Col from "../../../../../components/Col";
import FormCol from "../../../../../components/form/FormCol";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import DateFormatter from "../../../../../utils/DateFormatter";
import ReportCancelLogListActions from "./ReportCancelLogListActions";
import ReportCancelLogListReducer from "./ReportCancelLogListReducer";
import ReportCancelLogListTable from "./ReportCancelLogListTable";

export interface ReportCancelLogListCardProps {
    date?: string;
}

const mapActionsToProps = ReportCancelLogListActions.autoMapToProps();
const mapStateToProps = ReportCancelLogListReducer.autoMapToProps();

type Props = ReportCancelLogListCardProps & typeof mapActionsToProps & typeof mapStateToProps;

export interface ReportCancelLogListCardState {
    date?: Date;
}

class ReportCancelLogListCard extends React.Component<Props, ReportCancelLogListCardState> {
  private cardList;

  public constructor(props) {
    super(props);
    this.state = {
      date: this.props.date ? new Date(this.props.date) : undefined,
    };
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onDateSelect = (date?: Date | null): void => {
    this.setState( {
      date: date ?? undefined,
    } );
  };

  private onExportExcel(): void {
    new TaskReportCancellationLogExport(this.cardList.getCriteria()).executeAsPromise()
      .then((response) => {
        if (response.success)
          window.open(response.data.url, "_blank");
      } )
      .catch(() => Alert.error(I18n.tr(I18nKeys.SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES)));
  }

  public render(): React.ReactNode {
    const {loading, reportCancelLogs, error, pager} = this.props;

    return (
      <CardList
        ref={(node) => {
          this.cardList = node;

          return node;}}
        loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.LOGS_CANCELACION_DENUNCIAS)}
        sort={{
          column: "date",
          desc: true,
        }}
        // @ts-ignore
        TaskList={TaskReportCancellationLogList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_LOGS),
        }}
        data={reportCancelLogs}
        error={error}
        ItemsTable={ReportCancelLogListTable}
        customCriteria={this.state}
        noSearchBar={true}
      >
        {this.renderHeaderContent()}
      </CardList>
    );
  }

  private renderHeaderContent = (): React.ReactNode => (
    <>
      <Row>
        <FormCol md={3} lg={3}>
          <div className={"form-group"}>
            <div className={"form-line"}>
              <label>{I18n.tr(I18nKeys.FECHA_INICIO)}</label>
              <input
                type={"date"}
                className="form-control"
                value={this.state.date ? DateFormatter.formatDate(this.state.date, "YYYY-MM-DD") : undefined}
                onChange={(e) => this.onDateSelect(e.target.valueAsDate)}/>
            </div>
          </div>
        </FormCol>
        <Col md={6} lg={7}/>
        <Col md={3} lg={2}>
          <Button
            text={I18n.tr(I18nKeys.EXPORTAR_EXCEL)}
            onClick={this.onExportExcel.bind(this)}
            type={"button"}
            block={true}
            className={"btn-lg btn-default btn-export m-t-30"}
            disabled={!this.state.date}
          />
        </Col>
      </Row>
    </>
  );
}

export default connect(mapStateToProps, mapActionsToProps)(ReportCancelLogListCard as unknown as React.ComponentType<ReportCancelLogListCardProps>);
