import { FunctionComponent, useState } from 'react';
import Icon from '../components/Icon';
import { CardSectionCollapsedProps2, StyleProps } from './typings';
import classNames from 'classnames';

export const CardSectionCollapsed2: FunctionComponent<CardSectionCollapsedProps2 & StyleProps> = (
    {
        title,
        initialCollapsed = true,
        children,
        className = '',
        style
    }
) => {
    const [collapsed, setCollapsed] = useState(initialCollapsed);
    const classComponent = classNames('card-section-collapse', className);

    return (
        <div className={classComponent} style={style}>
            <div className={'csc-header'}>
                <div className={'csc-header-title'}>
                    {title}
                </div>
                <div className={'csc-header-line'}/>
                <a onClick={() => setCollapsed(!collapsed)}>
                    <Icon icon={collapsed ? 'expand_more' : 'expand_less'}/>
                </a>
            </div>
            {
                collapsed ? null :
                    <div className={'csc-body'}>
                        {children}
                    </div>
            }
        </div>
    );
};
