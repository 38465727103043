import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from 'classnames';

export interface FormInputFileProps extends WrappedFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    showError?: boolean,
    classNameInput?: boolean,
    classForm?: string,
    accept?: string,
}

export default class FormInputFile extends Component<FormInputFileProps> {

    private onChange(e) {
        const { input: { onChange } } = this.props;
        onChange(e.target.files[0])
    }

    public render(): ReactNode {
        const {input, meta, label, disabled, col, showError, classNameInput, classForm, accept} = this.props;
        const classFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error
        });

        return (
            <FormCol {...col} >
                <div className="form-group">
                    <div className={`${classFormLine} ${classForm}`}>
                        {label ? <label className='main-label'>{label || ''}</label> : null}
                        <input
                            type={'file'}
                            className={`form-control ${classNameInput}`}
                            disabled={disabled || false}
                            {...input}
                            value={undefined}
                            onChange={this.onChange.bind(this)}
                            accept={accept ? accept : '*'}
                        />
                    </div>
                    <label className="error">{(meta.touched || showError) ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }

}

