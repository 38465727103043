import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import Route from "../../model/Route";

type RouteCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskRouteList extends BaseGenericRequest<RouteCriteriaProps, TaskResponse<Route[]>> {

    private readonly data: RouteCriteriaProps;

    public constructor(criteria: RouteCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_ROUTE_LIST);
        this.data = criteria;
    }

    protected getRequest(): RouteCriteriaProps {
        return this.data;
    }

}
