
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_VEHICLES } from "../../../../routing/Routes";
import VehicleListCardCompanyUser from "./VehicleListCardCompanyUser";

const VehicleListCompanyUserScreen = () =>(
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.VEHICULOS),
      url: ROUTE_COMPANY_USER_VEHICLES,
      icon: AppIcon.CAR,
    },
  ]}>
    <VehicleListCardCompanyUser/>
  </ScreenCard>
);

export default VehicleListCompanyUserScreen;
