import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { generateRoute } from '../../utils/Router';
import VehicleModel, { VehicleModelData } from '../../model/VehicleModel';

export default class TaskVehicleModelEdit extends BaseGenericRequest<VehicleModelData, TaskResponse<VehicleModel>> {

    private readonly data: VehicleModelData;

    public constructor(vehicleId: string, vehicleModel: VehicleModelData) {
        super(METHOD.PUT, generateRoute(Urls.URL_VEHICLE_MODEL_EDIT, {uuid: vehicleId}));
        this.data = vehicleModel;
    }

    protected getRequest(): VehicleModelData {
        return this.data;
    }
}
