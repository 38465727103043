import BaseGenericRequest from "../BaseGenericRequest";
import ChatMessage, {ChatMessageData} from "../../model/ChatMessage";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";

export default class TaskChatMessageAdd extends BaseGenericRequest<ChatMessageData, TaskResponse<ChatMessage>> {

    private readonly data: ChatMessageData;

    public constructor(data: ChatMessageData) {
        super(METHOD.POST, Urls.URL_CHAT_MESSAGE_ADD);
        this.data = data;
    }

    protected getRequest(): ChatMessageData {
        return this.data;
    }
}
