import { ChangeEvent, Component } from 'react';
import FormCol from '../form/FormCol';

const WAIT_INTERVAL = 1000;

interface Props {
    gridSize: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    label: string
    initialValue?: string
    disabled?: boolean
    onRef?: any
    onChange: (value: string) => void
}

export class FilterInputSearch extends Component<Props> {

    private timer: NodeJS.Timeout | undefined;

    constructor(props) {
        super(props);
    }

    public componentWillMount() {
        this.timer = undefined;
    }

    public render() {
        const {
            gridSize,
            label,
            initialValue,
            disabled,
            onRef,
        } = this.props;

        return (
            <FormCol md={gridSize} lg={gridSize}>
                <div className="form-group">
                    <div className="form-line">
                        <label>{label}</label>
                        <input
                            type={'text'}
                            className="form-control"
                            disabled={disabled}
                            ref={onRef}
                            onChange={this.handleChange}
                            defaultValue={initialValue}
                        />
                    </div>
                </div>
            </FormCol>
        );
    }

    private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        clearTimeout(this.timer as any);
        this.timer = setTimeout(() => this.props.onChange(value), WAIT_INTERVAL);
    };
}
