import { METHOD } from '@cuatroochenta/co-generic-request';
import { generateRoute } from '../../utils/Router';
import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import Urls from '../Urls';

interface DataResponse {
    url: string
}

export default class TaskReportGetPDF extends BaseGenericRequest<{}, TaskResponse<DataResponse>> {

    public constructor(id: string) {
        super(METHOD.GET, generateRoute(Urls.URL_REPORT_GET_PDF, {uuid: id}));
    }
}
