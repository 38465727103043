import { Component, ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import Alert from "../../../../base/alerts/Alert";
import I18n from "../../../../commons/I18n/I18n";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";
import {CollectionProcessState,
  ParkingMeterCollectionProcess,
  ParkingMeterCollectionProcessCorrectionData} from "../../../../commons/model/ParkingMeterCollectionProcess";
import TaskParkingMeterCollectionProcessCorrection from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessCorrection";
import { CardSectionCollapsed2 } from "../../../../components-new/CardSectionCollapsed2";
import Button from "../../../../components/buttons/Button";
import Col from "../../../../components/Col";
import Row from "../../../../components/Row";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_COLLECTION_PROCESS } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";
import CollectionProcessBaseModal from "../modal/CollectionProcessBaseModal";
import CollectionProcessDetailActions from "./CollectionProcessDetailActions";
import CollectionProcessDetailReducer from "./CollectionProcessDetailReducer";

interface CollectionProcessDetailTableProps {
    data: ParkingMeterCollection[];
    onSort?: OnSortHandler;
    sort?: SortState;
    error: string;
    renderSelectTd?: (itemId: string)=> ReactNode;
}

interface CollectionProcessDetailTableState {
    showChangeCashModal: boolean;
    showCorrectionModal: boolean;
    collectionProcessId?: string;
    realCash?: number;
}

const mapStateToProps = CollectionProcessDetailReducer.autoMapToProps();
const mapActionsToProps = CollectionProcessDetailActions.autoMapToProps();

type Props = CollectionProcessDetailTableProps & typeof mapActionsToProps & typeof mapStateToProps;

class CollectionProcessDetailTable extends Component<Props, CollectionProcessDetailTableState> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      showChangeCashModal: false,
      showCorrectionModal: false,
      collectionProcessId: undefined,
      realCash: undefined,
    };
  }

  private handleCollectionProcessCashUpdate = (cash): void => {
    const id: string = this.state.collectionProcessId as string;

    this.props.dispatchSetCollectionCash(id, Number(cash));
  };

  private handleCollectionProcessCorrection = (messageAlert): void => {
    const {id} = this.props.parkingMeterCollectionProcess as ParkingMeterCollectionProcess;
    const {correctionMap} = this.props;
    const fixedImportValues = Object.values(correctionMap).map((value) => value);
    const fixedImportKeys = Object.keys(correctionMap).map((key) => key);
    // eslint-disable-next-line camelcase
    const corrections: Array<{id: string; real_cash: number}> = [];

    for (let i = 0; i < fixedImportValues.length ; i++) {
      corrections.push( {
        id: fixedImportKeys[i],
        real_cash: Number(fixedImportValues[i]),
      } );
    }

    const submitData: ParkingMeterCollectionProcessCorrectionData = {
      parking_meter_collection_corrections: corrections,
      message_alert: messageAlert,
    };

    new TaskParkingMeterCollectionProcessCorrection(id, submitData)
      .onFail(() => Alert.error(I18n.tr(I18nKeys.GENERIC_WS_ERROR)))
      .onSuccess(() => goToRoute(ROUTE_ADMIN_COLLECTION_PROCESS))

      .execute();
  };

  public render(): ReactNode {
    const {parkingMeterCollectionProcess, data, renderSelectTd} = this.props;

    return (
      <>
        <CardSectionCollapsed2
          title={I18n.tr(I18nKeys.COLECTAS_PARQUIMETRO)}
          initialCollapsed={false}
        >
          <table className="table table-hover">
            <thead>{this.renderTH()}</thead>
            <tfoot>{this.renderTH()}</tfoot>
            <tbody>
              {data && data.map((collection) => (
                <tr key={collection.id}
                  className={"tr-cursor"}
                >
                  {renderSelectTd ? renderSelectTd(collection.id) : null}
                  <td>
                    {collection.id}
                  </td>
                  <td>
                    {DateFormatter.formatDate(collection.date)}
                  </td>
                  <td>
                    {collection.collection_state.name}
                  </td>
                  <td>
                    {collection.cash}
                  </td>
                  <td>
                    {collection.real_cash || "- - -"}
                  </td>

                  { parkingMeterCollectionProcess && parkingMeterCollectionProcess.collection_process_state.id === CollectionProcessState.INCORRECT &&
                                        <td>
                                          <Button
                                            text={I18n.tr(I18nKeys.CAMBIAR)}
                                            className={"btn btn-primary"}
                                            onClick={() => this.setState( {
                                              showChangeCashModal: true,
                                              collectionProcessId: collection.id,
                                              realCash: collection.real_cash || undefined,
                                            } )
                                            }
                                          />
                                        </td>
                  }

                </tr>
              ))}
            </tbody>
          </table>

        </CardSectionCollapsed2>

        { parkingMeterCollectionProcess && parkingMeterCollectionProcess.collection_process_state.id === CollectionProcessState.INCORRECT &&
                    <Row>
                      <Col style={{
                        padding: "15px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}>
                        <Button
                          text={I18n.tr(I18nKeys.TOTALIZAR_DE_NUEVO)}
                          onClick={() => this.setState( {
                            showCorrectionModal: true,
                          } )}
                          className={"btn btn-primary"}
                        />
                      </Col>
                    </Row>
        }

        <CollectionProcessBaseModal
          inputType={"number"}
          title={I18n.tr(I18nKeys.PROCESO_RECAUDACION)}
          message={I18n.tr(I18nKeys.INTRODUZCA_IMPORTE_REAL)}
          realCash={this.state.realCash}
          onConfirmAction={(cash: number) => {
            this.handleCollectionProcessCashUpdate(cash);
            this.setState( {
              showChangeCashModal: false,
            } );
          }}
          show={this.state.showChangeCashModal}
          onClose={() => this.setState( {
            showChangeCashModal: false,
          } )}
        />

        <CollectionProcessBaseModal
          inputType={"textarea"}
          title={I18n.tr(I18nKeys.PROCESO_RECAUDACION)}
          message={I18n.tr(I18nKeys.INTRODUZCA_MOTIVO_DESFASE_DINERO)}
          onConfirmAction={(messageAlert: string) => {
            this.handleCollectionProcessCorrection(messageAlert);
            this.setState( {
              showCorrectionModal: false,
            } );
          }}
          show={this.state.showCorrectionModal}
          onClose={() => this.setState( {
            showCorrectionModal: false,
          } )}
        />
      </>

    );
  }

  private renderTH(): ReactNode {
    const {sort, onSort, children} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={"Id"}
          columnName={"id"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.ESTADO)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.IMPORTE)}
          columnName={"cash"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.IMPORTE_REAL)}
          columnName={"realCash"}
          {...commonProps}
        />
      </tr>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(CollectionProcessDetailTable as unknown as ComponentType<CollectionProcessDetailTableProps>);
