
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { ReportCancellationMethodData } from "../../../../commons/model/ReportCancellationMethod";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskReportCancellationMethodAdd from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodAdd";
import TaskReportCancellationMethodDetail from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodDetail";
import TaskReportCancellationMethodEdit from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_ADMIN_REPORT_CANCELLATION_METHODS, ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD,
  ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import ReportCancellationMethodCardFormAdmin, { ReportCancellationMethodAdminData } from "../formdetail/ReportCancellationMethodCardFormAdmin";
import ReportCancellationMethodFormAdminScreenActions from "./ReportCancellationMethodFormAdminScreenActions";
import ReportCancellationMethodFormScreenAdminReducer from "./ReportCancellationMethodFormScreenAdminReducer";

const mapStateToProps = ReportCancellationMethodFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = ReportCancellationMethodFormAdminScreenActions.autoMapToProps();

type Props = RouteComponentProps<{id: string}> & typeof mapActionsToProps & typeof mapStateToProps;

class ReportCancellationMethodFormAdminScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskReportCancellationMethodDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const reportCancellationMethodId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.METODOS_DE_CANCELACION_DE_DENUNCIA),
      url: ROUTE_ADMIN_REPORT_CANCELLATION_METHODS,
      icon: AppIcon.REPORT_OFF,
    }];

    if (reportCancellationMethodId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.METODO_DE_CANCELACION_DE_DENUNCIA),
        url: generateRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_DETAIL, {
          id: reportCancellationMethodId,
        } ),
        icon: AppIcon.REPORT_OFF,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA),
        url: ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD,
        icon: AppIcon.REPORT_OFF,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderReportCancellationMethodForm()}
      </ScreenCard>
    );
  }

  private onSubmit = (values: ReportCancellationMethodAdminData): void => {
    const existReportCancellationMethod = this.props.match.params.id !== undefined;
    const submitData: ReportCancellationMethodData = {
      name: values.name,
    };

    if (existReportCancellationMethod) {
      executeItemTask(
        new TaskReportCancellationMethodEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHODS),
        I18n.tr(I18nKeys.METODO_DE_CANCELACION_DE_DENUNCIA_MODIFICADO_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskReportCancellationMethodAdd(submitData),
        () => goToRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHODS),
        I18n.tr(I18nKeys.METODO_DE_CANCELACION_DE_DENUNCIA_CREADO_CORRECTAMENTE),
      );
    }
  };

  private renderReportCancellationMethodForm(): React.ReactNode {
    const {reportCancellationMethodLoading, reportCancellationMethod} = this.props;
    let initialValues: Partial<ReportCancellationMethodAdminData> | undefined;

    if (reportCancellationMethod) {
      initialValues = {
        name: reportCancellationMethod.name ? reportCancellationMethod.name : "",
      };
    }

    return (
      <ReportCancellationMethodCardFormAdmin
        parentLoading={reportCancellationMethodLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)
(ReportCancellationMethodFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
