import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { ParkingLossReasonData } from "../../../../commons/model/ParkingLossReason";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskParkingLossReasonAdd from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonAdd";
import TaskParkingLossReasonDetail from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonDetail";
import TaskParkingLossReasonEdit from "../../../../commons/ws/parkingLossReason/TaskParkingLossReasonEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_ADMIN_PARKING_LOSS_REASONS, ROUTE_ADMIN_PARKING_LOSS_REASON_ADD,
  ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import ParkingLossReasonCardFormAdmin, { ParkingLossReasonCardFormAdminData } from "../formdetail/ParkingLossReasonCardFormAdmin";
import ParkingLossReasonFormAdminScreenActions from "./ParkingLossReasonFormAdminScreenActions";
import ParkingLossReasonFormScreenAdminReducer from "./ParkingLossReasonFormScreenAdminReducer";

const mapStateToProps = ParkingLossReasonFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = ParkingLossReasonFormAdminScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class ParkingLossReasonFormAdminScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskParkingLossReasonDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: ParkingLossReasonCardFormAdminData): void => {
    const existParkingLossReason = this.props.match.params.id !== undefined;
    const submitData: ParkingLossReasonData = {
      name: values.name,
    };

    if (existParkingLossReason) {
      executeItemTask(
        new TaskParkingLossReasonEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_ADMIN_PARKING_LOSS_REASONS),
        I18n.tr(I18nKeys.MOTIVO_DE_PLAZA_PERDIDA_MODIFICADO_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskParkingLossReasonAdd(submitData),
        () => goToRoute(ROUTE_ADMIN_PARKING_LOSS_REASONS),
        I18n.tr(I18nKeys.MOTIVO_DE_PLAZA_PERDIDA_CREADO_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const parkingLossReasonId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.MOTIVOS_DE_PLAZAS_PERDIDAS),
      url: ROUTE_ADMIN_PARKING_LOSS_REASONS,
      icon: AppIcon.PARKING,
    }];

    if (parkingLossReasonId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.MOTIVOS_DE_PLAZAS_PERDIDAS),
        url: generateRoute(ROUTE_ADMIN_PARKING_LOSS_REASON_DETAIL, {
          id: parkingLossReasonId,
        } ),
        icon: AppIcon.PARKING,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVO_MOTIVO_DE_PLAZA_PERDIDA),
        url: ROUTE_ADMIN_PARKING_LOSS_REASON_ADD,
        icon: AppIcon.PARKING,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderParkingLossReasonForm()}
      </ScreenCard>
    );
  }

  private renderParkingLossReasonForm(): React.ReactNode {
    const {parkingLossReasonLoading, parkingLossReason} = this.props;
    let initialValues: Partial<ParkingLossReasonCardFormAdminData> | undefined;

    if (parkingLossReason) {
      initialValues = {
        name: parkingLossReason.name ? parkingLossReason.name : "",
      };
    }

    return (
      <ParkingLossReasonCardFormAdmin
        parentLoading={parkingLossReasonLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)
(ParkingLossReasonFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
