import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import Vehicle from '../../model/Vehicle';

export type VehicleCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskVehicleList extends BaseGenericRequest<VehicleCriteriaProps, TaskResponse<Vehicle[]>> {

    private readonly data: VehicleCriteriaProps;

    public constructor(criteria: VehicleCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_VEHICLE_LIST);
        this.data = criteria;
    }

    protected getRequest(): VehicleCriteriaProps {
        return this.data;
    }

}
