import React from "react";
import I18n from "../../../../../commons/I18n/I18n";
import WorkdayReport from "../../../../../commons/model/WordayReport";
import Th from "../../../../../components/table/Th";
import I18nKeys from "../../../../../I18n/I18nKeys";
import DateFormatter from "../../../../../utils/DateFormatter";
import { OnSortHandler, SortState } from "../../../../../utils/Sort";

interface ControllerReportListTableProps {
    data: WorkdayReport[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

export default class ControllerReportListTable extends React.Component<ControllerReportListTableProps> {
  public render(): React.ReactNode {
    const {data} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((controllerReport: WorkdayReport) => (
            <tr key={controllerReport.id}>
              <td>
                {controllerReport.user ? controllerReport.user.name : ""}
              </td>
              <td>
                {controllerReport.start_date ? DateFormatter.formatDatetime(controllerReport.start_date) : ""}
              </td>
              <td>
                {controllerReport.end_date ? DateFormatter.formatDatetime(controllerReport.end_date) : ""}
              </td>
              <td>
                {controllerReport.effective_time ? (controllerReport.effective_time / 60).toFixed(2) : ""}
              </td>
              <td>
                {controllerReport.report_count ? controllerReport.report_count : ""}
              </td>
              <td>
                {controllerReport.report_invalidated_count ? controllerReport.report_invalidated_count : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.NOMBRE)}
          columnName={"name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.INICIO_DE_JORNADA)}
          columnName={"start_date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FIN_DE_JORNADA)}
          columnName={"end_date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.HORAS)}
          columnName={"effective_time"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.DENUNCIAS)}
          columnName={"report_count"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.INVALIDADAS)}
          columnName={"report_invalidated_count"}
          {...commonProps}
        />
      </tr>
    );
  }
}
