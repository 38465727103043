import {READED_BROADCAST_IDS} from "../config/Config";

export default class BroadcastManager {

    public static addReadedBroadcastId(broadcastId: string): void {
        const readedBroadcastIds = this.getReadedBroadcastIds();
        const updatedBroadcastList = [...readedBroadcastIds, broadcastId];
        localStorage.setItem(READED_BROADCAST_IDS, JSON.stringify(updatedBroadcastList));
    };

    public static removeReadedBroadcastId(broadcastId: string): void {
        const readedBroadcastIds = this.getReadedBroadcastIds();
        const findIndex = readedBroadcastIds.findIndex((readedBroadcastId) => readedBroadcastId === broadcastId);
        if (findIndex !== -1) {
            const updatedBroadcastList = readedBroadcastIds.splice(findIndex, 1, broadcastId);
            localStorage.setItem(READED_BROADCAST_IDS, JSON.stringify(updatedBroadcastList));
        }
    };

    public static getReadedBroadcastIds(): string[] {
        const broadcastList = localStorage.getItem(READED_BROADCAST_IDS);
        if (broadcastList) {
            return JSON.parse(broadcastList)
        } else {
            return [];
        }
    }
}