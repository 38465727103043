import TaskResponse from "../TaskResponse";
import BaseGenericRequest from "../BaseGenericRequest";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

export type VehicleBlackListCriteriaProps = TaskCriteriaRequest<{
    special?: boolean
}>

type TaskVehicleBlackListExportResponse = TaskResponse<{url: string}>

export default class TaskVehicleBlackListExport extends BaseGenericRequest<VehicleBlackListCriteriaProps, TaskVehicleBlackListExportResponse> {

    private readonly data: VehicleBlackListCriteriaProps;

    public constructor(criteria: VehicleBlackListCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_VEHICLE_BLACKLIST_EXPORT);
        this.data = criteria;
    }

    protected getRequest(): VehicleBlackListCriteriaProps {
        return this.data;
    }
}
