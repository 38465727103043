
import { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import I18n from "../../commons/I18n/I18n";
import I18nKeys from "../../I18n/I18nKeys";
import BaseModal, { BaseModalProps } from "../modal/BaseModal";

export interface HomeModalProps extends BaseModalProps {
    onLogout: ()=> void;
}

export default class LogoutModal extends BaseModal<HomeModalProps> {
  public render(): ReactNode {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.tr(I18nKeys.CERRAR_SESSION)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{I18n.tr(I18nKeys.ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION)}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onClose}>{I18n.tr(I18nKeys.CANCELAR).toUpperCase()}</Button>
          <Button bsStyle="danger" onClick={this.props.onLogout}>{I18n.tr(I18nKeys.CERRAR_SESSION).toUpperCase()}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
