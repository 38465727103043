import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import ReportCancellationMethod from '../../model/ReportCancellationMethod';

export type ReportCancellationCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskReportCancellationMethodList extends BaseGenericRequest<ReportCancellationCriteriaProps, TaskResponse<ReportCancellationMethod[]>> {

    private readonly data: ReportCancellationCriteriaProps;

    public constructor(criteria: ReportCancellationCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_REPORT_CANCELLATION_METHOD_LIST);
        this.data = criteria;
    }

    protected getRequest(): ReportCancellationCriteriaProps {
        return this.data;
    }
}
