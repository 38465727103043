import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import Lodash from "lodash";
import { Component, ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FieldArray, FormErrors, getFormSyncErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import { WorkingScheduleReduxForm } from "../../../../commons/model/WorkingSchedule";
import TaskLawList from "../../../../commons/ws/law/TaskLawList";
import TaskParkingMeterTypeList from "../../../../commons/ws/parkingMeterType/TaskParkingMeterTypeList";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardCollapsed from "../../../../components/card/CardCollapsed";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormDragFile from "../../../../components/form/FormDragFile";
import FormHidden from "../../../../components/form/FormHidden";
import FormInput from "../../../../components/form/FormInput";
import MapLocationPicker, { MarkerPosition } from "../../../../components/MapLocationPicker";
import Row from "../../../../components/Row";
import Config from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_EXPLOITATIONS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import { isEmailValid, isValidURL } from "../../../../utils/StringUtils";
import ExploitationFormAdminScreenReducer from "../form/ExploitationFormAdminScreenReducer";
import ExploitationAppConfigurationSectionAdmin, { ExploitationAppConfigurationSectionAdminHelpers } from "./cards/ExploitationAppConfigurationSectionAdmin";
import ExploitationConnectionAppSectionAdmin, { ExploitationConnectionAppSectionAdminHelpers } from "./cards/ExploitationConnectionAppSectionAdmin";
import ExploitationPrintReportSectionAdmin, { ExploitationPrintReportSectionAdminHelpers } from "./cards/ExploitationPrintReportSectionAdmin";
import ExploitationReportCodeSectionAdmin, { ExploitationReportCodeSectionAdminHelpers } from "./cards/ExploitationReportCodeSectionAdmin";
// eslint-disable-next-line import/no-cycle
import ExploitationWorkingScheduleSectionAdmin, { ExploitationWorkingScheduleSectionAdminHelper } from "./cards/ExploitationWorkingScheduleSectionAdmin";

const FORM_NAME = "ExploitationCardFormAdmin";

interface SynchronousError {
    coord: string;
}

export interface ExploitationCardFormAdminData {
    name: string;
    serviceOfficeAddress: string;
    serviceOfficePhone: string;
    serviceOfficeEmail: string;
    serviceOfficeUrl: string;
    legalAdvice: string;
    town: string;
    province: string;
    coord: MarkerPosition;
    elkKey: string;
    kmlFile: string[];
    workingSchedules: WorkingScheduleReduxForm[];

    inactivityReportAppTime: number;
    inactivityPositionAppTime: number;
    dataDriverScreen: boolean;
    reportRatificationRadius: number;
    workdayFinishTime: number;
    reportCreationTimeLimit: number;

    codeSuma: string;
    provinceCodeSuma: string;
    townCodeSuma: string;
    parkingMeterTypeId: string;
    cancellationParkingMeterTypeId: string;
    managementProcessingTypeId: string;
    exportNumSuma?: number;
    cancellationProcessManagement: boolean;
    ratification: boolean;
    remoteRatification: boolean;
    reportIdentification: boolean;
    digitalSign: boolean;
    generateC60: boolean;
    c60ApplicationIdentifier: string;
    c60FormatType: string;
    c60EmitterProvince: string;
    c60EmitterMunicipality: string;
    c60EmitterControlDigit: string;
    c60Tax: string;
    c60PaymentDaysTerm: number;

    reportPrintImage: string[];
    reportPrintExploitationName: string;
    reportPrintAmount: boolean;
    reportPrintCancellationAmount: boolean;
    reportPrintTimeExceeded: boolean;
    reportPrintTextTitle1: string;
    reportPrintTextTitle2: string;
    reportPrintTextNumber: string;
    reportPrintTextCancellationNumber: string;
    reportPrintTextDate: string;
    reportPrintTextHour: string;
    reportPrintTextVehicle: string;
    reportPrintTextBrand: string;
    reportPrintTextModel: string;
    reportPrintTextColor: string;
    reportPrintTextLicensePlate: string;
    reportPrintTextLimitHour: string;
    reportPrintTextReportedFact: string;
    reportPrintTextPlace: string;
    reportPrintTextPrecept: string;
    reportPrintTextAmount: string;
    reportPrintTextCancellableAmount: string;
    reportPrintTextSign1: string;
    reportPrintTextSign2: string;
    reportPrintTextController: string;
    reportPrintTextRatifier: string;
    reportPrintTextInternalUse: string;
    reportPrintTextDriverTitle: string;
    reportPrintTextDriverNif: string;
    reportPrintTextDriverType: string;
    reportPrintTextDriverName: string;
    reportPrintTextDriverLastName: string;
    reportPrintTextDriverAddress: string;
    reportPrintTextDriverSign: string;
    reportPrintBarCodeReportId: boolean;
    reportPrintBarCodeC60: boolean;
    reportPrintTextBarCodeC60: string;

    connectionEasypark: boolean;
    connectionMoviltik: boolean;
    connectionStreetSmart: boolean;
    moviltikUsername?: string;
    moviltikPassword?: string;
    notifyCraneEmails: string;
    sosEmails: string;
    connectionBeepark: boolean;
    beeparkApiToken: string;
    beeparkExploitationId: string;

    hidden: string;

    connectionDeltaCar: boolean;
    deltaCarToken: string;
    connectionTicketLine: boolean;
    ticketLineUsername: string;
    ticketLinePassword: string;
    deltaCarLawId: string;
    timeExcessLawId: string;
    streetSmartParkId: string;
}

interface ExploitationCardFormAdminExtProps {
    exploitationId?: string;
    readonly?: boolean;
    onSubmit: (data: ExploitationCardFormAdminData)=> void;
    initialValues?: Partial<ExploitationCardFormAdminData>;
    editMode: boolean;
    parentLoading: boolean;
}

interface ExploitationCardFormAdminProps extends InjectedFormProps<ExploitationCardFormAdminData> {
    onSubmit: (data: ExploitationCardFormAdminData)=> void;
    initialValues: Partial<ExploitationCardFormAdminData>;
    formState: { values: ExploitationCardFormAdminData };
    synchronousError: SynchronousError;
}

const mapStateToPropsGeneric = (state: any) => ( {
  formState: state.form[FORM_NAME],
  synchronousError: getFormSyncErrors(FORM_NAME)(state),
} );
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  ExploitationFormAdminScreenReducer.autoMapToProps(),
  mapStateToPropsGeneric,
);

type Props =
    ExploitationCardFormAdminExtProps & ExploitationCardFormAdminProps & InjectedFormProps<ExploitationCardFormAdminData> & typeof mapStateToProps;

enum ExploitationFormAdminFields {
    NAME = "name",
    SERVICE_OFFICE_ADDRESS = "serviceOfficeAddress",
    SERVICE_OFFICE_PHONE = "serviceOfficePhone",
    SERVICE_OFFICE_EMAIL = "serviceOfficeEmail",
    SERVICE_OFFICE_URL = "serviceOfficeUrl",
    LEGAL_ADVICE = "legalAdvice",
    TOWN = "town",
    PROVINCE = "province",
    COORD = "coord",
    ELK_KEY = "elkKey",
    KML_FILE = "kmlFile",
    WORKING_SCHEDULES = "workingSchedules",
    HIDDEN = "hidden" // campo oculto para validación
}

interface State {
    collapsed: boolean;
    invalidReportCodeSection: boolean;
    invalidPrintReportSection: boolean;
}

class ExploitationCardFormAdmin extends Component<Props, State> {
  private mapContainer;

  public constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false,
      invalidReportCodeSection: false,
      invalidPrintReportSection: false,
    };
  }

  public componentWillMount(): void {
    const {exploitationId} = this.props;

    new TaskParkingMeterTypeList().execute();

    if (exploitationId)
    {new TaskLawList( {
      exploitation_id: exploitationId,
    } ).execute();}
  }

  public render(): ReactNode {
    const {handleSubmit,
      onSubmit,
      initialValues,
      invalid,
      loading,
      pristine,
      editMode,
      parentLoading,
      formState,
      loadingLaws,
      laws} = this.props;
    const {collapsed} = this.state;
    const displayC60Fields: boolean =
            (formState &&
            formState.values &&
            formState.values.generateC60) || false;

    return (
      <Card loading={parentLoading || loading || loadingLaws}>
        <CardHeader title={I18n.tr(initialValues ? I18nKeys.EXPLOTACION : I18nKeys.NUEVA_EXPLOTACION)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardCollapsed
              title={I18n.tr(I18nKeys.DESCRIPCION_DE_LA_EXPLOTACION)}
              collapsedHandler={this.onCollapsedCard}
              collapsed={collapsed}
              invalid={this.onValidateDescriptionSection()}
              className={`exploitation-form-section ${!collapsed && "description"}`}
            >
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.NOMBRE)}
                  name={ExploitationFormAdminFields.NAME}
                  component={FormInput}
                  col={{
                    md: 5,
                    lg: 5,
                  }}
                />
                <Field
                  label={I18n.tr(I18nKeys.DIRECCION)}
                  name={ExploitationFormAdminFields.SERVICE_OFFICE_ADDRESS}
                  component={FormInput}
                  col={{
                    md: 7,
                    lg: 7,
                  }}
                />
              </Row>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.TELEFONO)}
                  name={ExploitationFormAdminFields.SERVICE_OFFICE_PHONE}
                  component={FormInput}
                  col={{
                    md: 3,
                    lg: 3,
                  }}
                />
                <Field
                  label={I18n.tr(I18nKeys.POBLACION)}
                  name={ExploitationFormAdminFields.TOWN}
                  component={FormInput}
                  col={{
                    md: 4,
                    lg: 4,
                  }}
                />
                <Field
                  label={I18n.tr(I18nKeys.PROVINCIA)}
                  name={ExploitationFormAdminFields.PROVINCE}
                  component={FormInput}
                  col={{
                    md: 5,
                    lg: 5,
                  }}
                />
              </Row>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.EMAIL)}
                  name={ExploitationFormAdminFields.SERVICE_OFFICE_EMAIL}
                  component={FormInput}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                />
                <Field
                  label={I18n.tr(I18nKeys.URL)}
                  name={ExploitationFormAdminFields.SERVICE_OFFICE_URL}
                  component={FormInput}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                />
              </Row>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.CLAVE_ELK)}
                  name={ExploitationFormAdminFields.ELK_KEY}
                  component={FormInput}
                  disabled={initialValues.elkKey && initialValues.elkKey.length > 0}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                />
              </Row>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.INFORMACION_LEGAL)}
                  name={ExploitationFormAdminFields.LEGAL_ADVICE}
                  component={FormInput}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                />
              </Row>
              <Row>
                <Field
                  label={I18n.tr(I18nKeys.FICHERO_KML)}
                  name={ExploitationFormAdminFields.KML_FILE}
                  component={FormDragFile}
                  fileTypes={[".kml"]}
                  col={{
                    md: 6,
                    lg: 6,
                  }}
                />
              </Row>
              <Row
                ref={(node) => {
                  this.mapContainer = node;

                  return node;}}
                className={"gmap"}
              >
                <Field
                  label={I18n.tr(I18nKeys.POSICION)}
                  name={ExploitationFormAdminFields.COORD}
                  component={MapLocationPicker}
                  google={this.mapContainer}
                  changeCoordHandler={this.onChangeCoord}
                  invalid={this.onCoordError()}
                  defaultPosition={Config.DEFAULT_MAP_POSITION}
                  detailMode={editMode}
                />
              </Row>
            </CardCollapsed>

            <FieldArray
              name={ExploitationFormAdminFields.WORKING_SCHEDULES}
              component={ExploitationWorkingScheduleSectionAdmin as any}
              workingScheduleValues={formState.values.workingSchedules}
            />

            <ExploitationAppConfigurationSectionAdmin
              invalid={this.onValidateReportCodeSection()}
              className={"exploitation-form-section"}
              existErrorsHandler={this.onSetInvalidReportCodeSection}
            />

            <ExploitationReportCodeSectionAdmin
              invalid={this.onValidateReportCodeSection()}
              className={"exploitation-form-section"}
              displayC60Fields={displayC60Fields}
              existErrorsHandler={this.onSetInvalidReportCodeSection}
            />

            <ExploitationPrintReportSectionAdmin
              invalid={this.onValidatePrintReportSection()}
              className={"exploitation-form-section"}
              displayC60Fields={displayC60Fields}
              existErrorsHandler={this.onSetInvalidPrintReportSection}
            />

            <ExploitationConnectionAppSectionAdmin
              invalid={false}
              className={"exploitation-form-section"}
              existErrorsHandler={this.onSetInvalidPrintReportSection}
              laws={laws}
              editMode={editMode}
            />

            {/* Campo auxiliar para validación */}
            <Field
              label={"Hidden"}
              name={ExploitationFormAdminFields.HIDDEN}
              component={FormHidden}
            />

            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_ADMIN_EXPLOITATIONS)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }

  private onChangeCoord = (newCoord: MarkerPosition): void => {
    this.props.change(ExploitationFormAdminFields.COORD, newCoord);
  };

  private onCoordError(): boolean {
    if (this.props.synchronousError.coord && this.props.formState.values &&
            this.props.formState.values.coord)
      return this.props.synchronousError.coord.length !== 0;

    return false;
  };

  private onSetInvalidReportCodeSection = (isValid: boolean): void => {
    this.setState( {
      invalidReportCodeSection: !isValid,
    } );
  };

  private onSetInvalidPrintReportSection = (isValid: boolean): void => {
    this.setState( {
      invalidPrintReportSection: !isValid,
    } );
  };

  private onCollapsedCard = () => {
    this.setState( {
      collapsed: !this.state.collapsed,
    } );
  };

  private onValidateDescriptionSection = (): boolean => {
    if (this.props.formState && this.props.formState.values) {
      const errors = validateDescriptionSection(this.props.formState.values);

      return !Lodash.isEqual(errors, {
      } );
    }

    return true;
  };

  private onValidateReportCodeSection = (): boolean => {
    if (this.props.formState && this.props.formState.values) {
      const errors = ExploitationReportCodeSectionAdminHelpers.validate(this.props.formState.values);

      return !Lodash.isEqual(errors, {
      } );
    }

    return true;
  };

  private onValidatePrintReportSection = (): boolean => {
    if (this.props.formState && this.props.formState.values) {
      const errors = ExploitationPrintReportSectionAdminHelpers.validate(this.props.formState.values);

      return !Lodash.isEqual(errors, {
      } );
    }

    return true;
  };
}

function validateDescriptionSection(values: ExploitationCardFormAdminData) {
  const errors: FormErrors<ExploitationCardFormAdminData> = {
  };

  if (!values.name || values.name.length === 0)
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  if (!values.serviceOfficeAddress || values.serviceOfficeAddress.length === 0)
    errors.serviceOfficeAddress = I18n.tr(I18nKeys.DIRECCION_OBLIGATORIA);

  if (!values.town || values.town.length === 0)
    errors.town = I18n.tr(I18nKeys.POBLACION_OBLIGATORIA);

  if (!values.province || values.province.length === 0)
    errors.province = I18n.tr(I18nKeys.PROVINCIA_OBLIGATORIA);

  if (!values.serviceOfficePhone || values.serviceOfficePhone.length === 0)
    errors.serviceOfficePhone = I18n.tr(I18nKeys.TELEFONO_OBLIGATORIO);

  if (values.serviceOfficeEmail && !isEmailValid(values.serviceOfficeEmail))
    errors.serviceOfficeEmail = I18n.tr(I18nKeys.EL_EMAIL_NO_ES_VALIDO);

  if (values.serviceOfficeUrl && !isValidURL(values.serviceOfficeUrl))
    errors.serviceOfficeUrl = I18n.tr(I18nKeys.LA_URL_NO_ES_VALIDA);

  if (!values.coord || values.coord.lng.toString().length === 0 || values.coord.lat.toString().length === 0)
    errors.coord = I18n.tr(I18nKeys.COORDENADAS_OBLIGATORIAS);

  if (!values.elkKey || values.elkKey.length === 0)
    errors.elkKey = I18n.tr(I18nKeys.CLAVE_ELK_OBLIGATORIA);

  return errors;
}

function validate(values: ExploitationCardFormAdminData) {
  const errors: FormErrors<ExploitationCardFormAdminData, any> = {
    ...validateDescriptionSection(values),
    ...ExploitationWorkingScheduleSectionAdminHelper.validate(values),
    ...ExploitationAppConfigurationSectionAdminHelpers.validate(values),
    ...ExploitationReportCodeSectionAdminHelpers.validate(values),
    ...ExploitationPrintReportSectionAdminHelpers.validate(values),
    ...ExploitationConnectionAppSectionAdminHelpers.validate(values),
  };

  return errors;
}

export default compose(
  reduxForm<ExploitationCardFormAdminData, ExploitationCardFormAdminExtProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(ExploitationCardFormAdmin) as ComponentType<ExploitationCardFormAdminExtProps>;
