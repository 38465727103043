import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_REPORT_CANCELLATION_METHODS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import ReportCancellationMethodFormScreenAdminReducer from "../formscreen/ReportCancellationMethodFormScreenAdminReducer";

const FORM_NAME = "ReportCancellationMethodFormAdmin";

export interface ReportCancellationMethodAdminData {
    name: string;
}

interface ReportCancellationMethodCardFormAdminProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: ReportCancellationMethodAdminData)=> void;
    initialValues?: Partial<ReportCancellationMethodAdminData>;
    parentLoading?: boolean;
}

const mapStateToProps = ReportCancellationMethodFormScreenAdminReducer.autoMapToProps();

type Props =
    InjectedFormProps<ReportCancellationMethodAdminData> & ReportCancellationMethodCardFormAdminProps & typeof mapStateToProps;

enum ReportMethodCancellationFormAdminFields {
    NAME = "name"
}

class ReportCancellationMethodCardFormAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, reportCancellationMethodLoading, pristine, parentLoading} = this.props;

    return (
      <Card loading={parentLoading || reportCancellationMethodLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.METODO_DE_CANCELACION_DE_DENUNCIA : I18nKeys.NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={ReportMethodCancellationFormAdminFields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_ADMIN_REPORT_CANCELLATION_METHODS)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: ReportCancellationMethodAdminData) {
  const errors: FormErrors<ReportCancellationMethodAdminData> = {
  };

  if (!values.name || values.name.length === 0) 
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);
  
  return errors;
}

export default compose(
  reduxForm<ReportCancellationMethodAdminData, ReportCancellationMethodCardFormAdminProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(ReportCancellationMethodCardFormAdmin);
