import { METHOD, ReduxAwareGenericRequest } from "@cuatroochenta/co-generic-request";
import { ReduxStorageUtils } from "@cuatroochenta/co-redux-builders";
import I18nKeys from "../../I18n/I18nKeys";
import I18n from "../I18n/I18n";
import { TaskCriteriaRequest } from "./TaskCriteriaRequest";
import TaskResponse from "./TaskResponse";

// eslint-disable-next-line no-use-before-define
export type TaskGenericList = new()=> BaseGenericRequest<TaskCriteriaRequest<any>, TaskResponse<any[]>>;

export default abstract class BaseGenericRequest<Request = {}, Response = {}>
  extends ReduxAwareGenericRequest<Request, Response> {
  public static AuthManager: { isLogged(); getAuthToken() };

  protected static NetworkManager: { isConnected() } = { // TODO: este tipado a any puede ser fuente de errores
    // eslint-disable-next-line require-await
    async isConnected() {
      return true;
    },
  };

  protected constructor(method: METHOD, url: string, notSpecifyContentType: boolean = false) {
    super(method, url, I18n.tr(I18nKeys.GENERIC_WS_ERROR), ReduxStorageUtils.dispatch);

    if (BaseGenericRequest.AuthManager && BaseGenericRequest.AuthManager.isLogged())
      this.addHeader("Authorization", `Bearer ${BaseGenericRequest.AuthManager.getAuthToken()}`);

    this.addHeader("Accept", "application/json");

    if (!notSpecifyContentType)
      this.addHeader("Content-type", "application/json");

    this.enableLogs(false);
  }

  private static checkIfConnectionAvailable() {
    return BaseGenericRequest.NetworkManager.isConnected();
  }

  protected sendRequest(request: any) {
    return BaseGenericRequest.checkIfConnectionAvailable()
      .then((isAvailable) => isAvailable ? super.sendRequest(request) : new Error(I18n.tr(I18nKeys.NO_SE_HA_PODIDO_ESTABLECER_UNA_CONEXION_CON_EL_SERVIDOR)));
  }

  protected isResponseSuccess(response: { status: number; _bodyText?: string } ): boolean {
    let success = super.isResponseSuccess(response);

    try {
      // eslint-disable-next-line no-underscore-dangle
      const responseBody = JSON.parse(response._bodyText || "{}");

      success = success || responseBody.success;
    // eslint-disable-next-line no-empty
    } catch (e) {
    }

    return success;
  }

  protected parseResponse(responseObj: any): Response {
    if (this.errorMessages[responseObj.code])
      throw new Error(this.errorMessages[responseObj.code]);

    if (!responseObj.success)
      throw new Error(responseObj.errorMessage || responseObj.message);

    return super.parseResponse(responseObj);
  }

  protected parseError(errorObj: string): string {
    try {
      return JSON.parse(errorObj).message || super.parseError(errorObj);
    } catch (ignored) {
      return super.parseError(errorObj).toString();
    }
  }
}
