import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import ParkingLoss from "../../model/ParkingLoss";

interface ParkingLossCustomCriteria {
    start_date?: string,
    end_date?: string,
}

export type ParkingLossCriteriaProps = TaskCriteriaRequest<ParkingLossCustomCriteria>

export default class TaskParkingLossList extends BaseGenericRequest<ParkingLossCriteriaProps, TaskResponse<ParkingLoss[]>> {

    private readonly data: ParkingLossCriteriaProps;

    public constructor(criteria: ParkingLossCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_LOSS_LIST);
        this.data = criteria;
    }

    protected getRequest(): ParkingLossCriteriaProps {
        return this.data;
    }

}
