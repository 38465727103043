
import React from "react";
import { connect } from "react-redux";
import I18n from "../../../commons/I18n/I18n";
import TaskUserCertDetail from "../../../commons/ws/userCert/TaskUserCertDetail";
import ScreenCard from "../../../components/screen/ScreenCard";
import { AppIcon } from "../../../config/Config";
import I18nKeys from "../../../I18n/I18nKeys";
import { ROUTE_USER_CERT } from "../../../routing/Routes";
import UserCertCardCompanyUser, { UserCertCardCompanyUserData } from "./UserCertCardCompanyUser";
import UserCertCardCompanyUserReducer from "./UserCertCompanyUserReducerScreen";
import UserCertCompanyUserScreenActions from "./UserCertCompanyUserScreenActions";

const mapStateToProps = UserCertCardCompanyUserReducer.autoMapToProps();
const mapActionsToProps = UserCertCompanyUserScreenActions.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

const getUserCertDetail = (): void => {
  new TaskUserCertDetail().execute();
};

class UserCertCompanyUserScreen extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  public componentDidMount(): void {
    getUserCertDetail();
  }

  private handleRefresh = (): void => {
    this.props.clearReducer();
    getUserCertDetail();
  };

  public render(): React.ReactNode {
    return (
      <ScreenCard breadcrumbs={[{
        name: I18n.tr(I18nKeys.FIRMA_DIGITAL),
        url: ROUTE_USER_CERT,
        icon: AppIcon.DIGITAL_SIGN,
      }]}>
        {this.renderUserCertCard()}
      </ScreenCard>
    );
  }

  private renderUserCertCard(): React.ReactNode {
    const {loading, userCert} = this.props;
    let initialValues: UserCertCardCompanyUserData | undefined;

    if (userCert) {
      initialValues = {
        startDate: userCert.start_date,
        endDate: userCert.end_date,
      };
    }

    return (
      <UserCertCardCompanyUser parentLoading={loading}
        initialValues={initialValues}
        refreshHandler={this.handleRefresh}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(UserCertCompanyUserScreen as React.ComponentType<{}>);
