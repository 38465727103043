
import React from "react";
import { connect } from "react-redux";
import UserListCardAdminReducer from "./UserListCardAdminReducer";

import I18n from "../../../../commons/I18n/I18n";
import TaskUserDelete from "../../../../commons/ws/user/TaskUserDelete";
import TaskUserList from "../../../../commons/ws/user/TaskUserList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_USER_ADD } from "../../../../routing/Routes";
import UserListCardAdminActions from "./UserListCardAdminActions";
import UserListTable from "./UserListTable";

const mapActionsToProps = UserListCardAdminActions.autoMapToProps();
const mapStateToProps = UserListCardAdminReducer.autoMapToProps();

class UserListCardAdmin extends React.Component<typeof mapActionsToProps & typeof mapStateToProps> {
  public componentWillMount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const {loading, users, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.USUARIOS)}
        sort={{
          column: "name",
        }}
        TaskList={TaskUserList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_USUARIOS),
        }}
        data={users}
        error={error}
        ItemsTable={UserListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_USUARIOS),
          Task: TaskUserDelete,
        }}
        addItem={ROUTE_ADMIN_USER_ADD}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(UserListCardAdmin as unknown as React.ComponentType<void>);
