import I18n from "../../commons/I18n/I18n";
import { generateRoute } from "../../commons/utils/Router";
import I18nKeys from "../../I18n/I18nKeys";
import { ROUTE_HOME } from "../../routing/Routes";

export interface BreadcrumbItem {
    id?: number;
    name: string;
    url: string;
    icon?: string;
}

export default class BreadcrumbManager {
  private readonly breadcrumbs: BreadcrumbItem[];

  constructor() {
    this.breadcrumbs = [];
    this.breadcrumbs.push( {
      id: this.breadcrumbs.length + 1,
      name: I18n.tr(I18nKeys.INICIO),
      url: generateRoute(ROUTE_HOME),
      icon: "home",
    } );
  }

  public addScreen(name: string, url: string, icon?: string){
    const breadcrumbItem: BreadcrumbItem = {
      name,
      url,
      icon,
    };

    if (this.breadcrumbs.length === 0 || !isEquals(this.breadcrumbs[this.breadcrumbs.length - 1], breadcrumbItem))
    {this.breadcrumbs.push( {
      id: this.breadcrumbs.length + 1,
      ...breadcrumbItem,
    } );}
  }

  public getItems(): BreadcrumbItem[] {
    return this.breadcrumbs;
  }
}

function isEquals(b1: BreadcrumbItem, b2: BreadcrumbItem): boolean {
  return b1.name === b2.name && b1.url === b2.url;
}
