import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import ParkingMeterIncidenceType from '../../model/ParkingMeterIncidenceType';

type CriteriaProps = TaskCriteriaRequest<{}>

export default class TaskParkingMeterIncidenceTypeList extends BaseGenericRequest<CriteriaProps, TaskResponse<ParkingMeterIncidenceType[]>> {

    private readonly data: CriteriaProps;

    public constructor(criteria: CriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_METER_INCIDENCE_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): CriteriaProps {
        return this.data;
    }

}
