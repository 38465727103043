import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import Exploitation, { ExploitationData } from "../../model/Exploitation";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskExploitationAdd extends BaseGenericRequest<ExploitationData, TaskResponse<Exploitation>> {
  private readonly data: ExploitationData;

  public constructor(data: ExploitationData) {
    super(METHOD.POST, Urls.URL_EXPLOITATION_ADD);
    this.data = data;

    this.setErrorMessage(488, I18n.tr(I18nKeys.YA_EXISTE_UN_ELEMENTO_SIMILAR));
  }

  protected getRequest(): ExploitationData {
    return this.data;
  }
}
