import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { RouteData } from "../../../../commons/model/Route";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskRouteAdd from "../../../../commons/ws/route/TaskRouteAdd";
import TaskRouteDetail from "../../../../commons/ws/route/TaskRouteDetail";
import TaskRouteEdit from "../../../../commons/ws/route/TaskRouteEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_COMPANY_USER_ROUTES, ROUTE_COMPANY_USER_ROUTE_ADD,
  ROUTE_COMPANY_USER_ROUTE_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import RouteCardFormCompanyUser, { RouteCardFormCompanyUserData } from "../formdetail/RouteCardFormCompanyUser";
import RouteCompanyUserScreenActions from "./RouteCompanyUserScreenActions";
import RouteFormScreenCompanyUserReducer from "./RouteFormScreenCompanyUserReducer";

const mapStateToProps = RouteFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = RouteCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class RouteFormCompanyUserScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskRouteDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: RouteCardFormCompanyUserData): void => {
    const existRoute = this.props.match.params.id !== undefined;
    const submitData: RouteData = {
      name: values.name,
      parking_area_type_id: values.parkingAreaTypeId,
    };

    if (existRoute) {
      executeItemTask(
        new TaskRouteEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_COMPANY_USER_ROUTES),
        I18n.tr(I18nKeys.RUTA_MODIFICADA_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskRouteAdd(submitData),
        () => goToRoute(ROUTE_COMPANY_USER_ROUTES),
        I18n.tr(I18nKeys.RUTA_CREADA_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const routeId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.RUTAS),
      url: ROUTE_COMPANY_USER_ROUTES,
      icon: AppIcon.ROUTE,
    }];

    if (routeId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.RUTA),
        url: generateRoute(ROUTE_COMPANY_USER_ROUTE_DETAIL, {
          id: routeId,
        } ),
        icon: AppIcon.ROUTE,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVA_RUTA),
        url: ROUTE_COMPANY_USER_ROUTE_ADD,
        icon: AppIcon.ROUTE,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderRouteForm()}
      </ScreenCard>
    );
  }

  private renderRouteForm(): React.ReactNode {
    const {routeLoading, route} = this.props;
    let initialValues: RouteCardFormCompanyUserData | undefined;

    if (route) {
      initialValues = {
        name: route.name,
        parkingAreaTypeId: route.parking_area_type ? route.parking_area_type.id : "",
      };
    }

    return (
      <RouteCardFormCompanyUser
        parentLoading={routeLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(RouteFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
