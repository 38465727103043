import React from "react";
import I18n from "../../../../commons/I18n/I18n";
import ReportInvalidationReason from "../../../../commons/model/ReportInvalidationReason";
import { generateRoute } from "../../../../commons/utils/Router";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface UserListTableProps {
    data: ReportInvalidationReason[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

function goToItemDetail(itemId: string): void {
  goToRoute(generateRoute(ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL, {
    id: itemId,
  } ));
};
export default class ReportInvalidationReasonAdminListTable extends React.Component<UserListTableProps> {
  public render(): React.ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {
            data && data.map((reportInvalidationReason: ReportInvalidationReason) => {
              const {id, name} = reportInvalidationReason;

              return (
                <tr
                  key={id}
                  onClick={() => goToItemDetail(id)}
                  className={"tr-cursor"}
                >
                  {
                    renderSelectTd ? renderSelectTd(reportInvalidationReason.id) : null
                  }
                  <td>{name}</td>
                </tr>
              );
            } )
          }
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th
          text={I18n.tr(I18nKeys.NOMBRE)}
          columnName={"name"}
          {...commonProps}
        />
      </tr>
    );
  }
}
