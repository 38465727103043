import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import ParkingMeter, {ParkingMeterData} from "../../model/ParkingMeter";

export default class TaskParkingMeterEdit extends BaseGenericRequest<ParkingMeterData, TaskResponse<ParkingMeter>> {

    private readonly data: ParkingMeterData;

    public constructor(parkingMeterId: string, parkingMeterData: ParkingMeterData) {
        super(METHOD.PUT, generateRoute(Urls.URL_PARKING_METER_EDIT, {uuid: parkingMeterId}));
        this.data = parkingMeterData;
    }

    protected getRequest(): ParkingMeterData {
        return this.data;
    }
}
