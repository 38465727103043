import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import Law from '../commons/model/Law';
import User from '../commons/model/User';
import TaskReportStateList from '../commons/ws/reportState/TaskReportStateList';
import TaskVehicleList from '../commons/ws/vehicle/TaskVehicleList';
import TaskRouteList from '../commons/ws/route/TaskRouteList';
import Vehicle from '../commons/model/Vehicle';
import TaskLawList from '../commons/ws/law/TaskLawList';
import TaskReportSentToProcessState from '../commons/ws/reportSentToProcessState/TaskReportSentToProcessState';
import ReportState from '../commons/model/ReportState';
import ReportSentToProcessState from '../commons/model/ReportSentToProcessState';
import Street from '../commons/model/Street';
import TaskStreetList from '../commons/ws/street/TaskStreetList';
import Route from '../commons/model/Route';
import DomainActions from './DomainActions';
import TaskUserControllerList from '../commons/ws/user/TaskUserControllerList';
import TaskUserRatifierList from '../commons/ws/user/TaskUserRatifierList';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER STATE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface InitialState {
    vehicles: Vehicle[]
    vehiclesLoading: boolean
    vehiclesError: string
    routes: Route[]
    routesLoading: boolean
    routesError: string
    laws: Law[]
    lawsLoading: boolean
    lawsError: string
    controllers: User[]
    controllersLoading: boolean
    controllersError: string
    ratifiers: User[]
    ratifiersLoading: boolean
    ratifiersError: string
    streets: Street[]
    streetsLoading: boolean
    streetsError: string
    reportStates: ReportState[]
    reportStatesLoading: boolean
    reportStatesError: string
    reportSentToProcessStates: ReportSentToProcessState[]
    reportSentToProcessStatesLoading: boolean
    reportSentToProcessStatesError: string
}

const INITIAL_STATE: InitialState = {
    vehicles: [],
    vehiclesLoading: false,
    vehiclesError: '',
    routes: [],
    routesLoading: false,
    routesError: '',
    laws: [],
    lawsLoading: false,
    lawsError: '',
    controllers: [],
    controllersLoading: false,
    controllersError: '',
    ratifiers: [],
    ratifiersLoading: false,
    ratifiersError: '',
    streets: [],
    streetsLoading: false,
    streetsError: '',
    reportStates: [],
    reportStatesLoading: false,
    reportStatesError: '',
    reportSentToProcessStates: [],
    reportSentToProcessStatesLoading: false,
    reportSentToProcessStatesError: '',
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)

    .onEvent(
        DomainActions.dispatchResetDomain,
        () => (
            {
                ...INITIAL_STATE
            }
        )
    )

    // - - - VEHICLES

    .onEvent(
        TaskVehicleList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                vehiclesLoading: true
            }
        )
    )
    .onEvent(
        TaskVehicleList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                vehiclesLoading: false
            }
        )
    )
    .onEvent(
        TaskVehicleList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => (
            {
                ...oldState,
                vehicles: payload.success ? payload.data : [],
                error: payload.success ? '' : ( payload.message || '' )
            }
        )
    )
    .onEvent(
        TaskVehicleList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => ( {
                ...oldState,
                vehiclesError: payload
            }
        )
    )

    // - - - ROUTES

    .onEvent(
        TaskRouteList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                routesLoading: true
            }
        )
    )
    .onEvent(
        TaskRouteList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                routesLoading: false
            }
        )
    )
    .onEvent(
        TaskRouteList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => (
            {
                ...oldState,
                routes: payload.success ? payload.data : [],
                error: payload.success ? '' : ( payload.message || '' )
            }
        )
    )
    .onEvent(
        TaskRouteList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => (
            {
                ...oldState,
                routesError: payload
            }
        )
    )

    // - - - LAWS

    .onEvent(
        TaskLawList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                lawsLoading: true
            }
        )
    )
    .onEvent(
        TaskLawList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                lawsLoading: false
            }
        )
    )
    .onEvent(
        TaskLawList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => (
            {
                ...oldState,
                laws: payload.success ? payload.data : [],
                error: payload.success ? '' : ( payload.message || '' )
            }
        )
    )
    .onEvent(
        TaskLawList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => (
            {
                ...oldState,
                lawsError: payload
            }
        )
    )

    // - - - CONTROLLERS

    .onEvent(
        TaskUserControllerList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                controllersLoading: true
            }
        )
    )
    .onEvent(
        TaskUserControllerList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                controllersLoading: false
            }
        )
    )
    .onEvent(
        TaskUserControllerList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => (
            {
                ...oldState,
                controllers: payload.success ? payload.data : [],
                error: payload.success ? '' : ( payload.message || '' )
            }
        )
    )
    .onEvent(
        TaskUserControllerList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => (
            {
                ...oldState,
                controllersError: payload
            }
        )
    )

    // - - - RATIFIERS

    .onEvent(
        TaskUserRatifierList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                ratifiersLoading: true
            }
        )
    )
    .onEvent(
        TaskUserRatifierList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                ratifiersLoading: false
            }
        )
    )
    .onEvent(
        TaskUserRatifierList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => (
            {
                ...oldState,
                ratifiers: payload.success ? payload.data : [],
                error: payload.success ? '' : ( payload.message || '' )
            }
        )
    )
    .onEvent(
        TaskUserRatifierList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => (
            {
                ...oldState,
                ratifiersError: payload
            }
        )
    )

    // - - - STREETS

    .onEvent(
        TaskStreetList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                streetsLoading: true
            }
        )
    )
    .onEvent(
        TaskStreetList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                streetsLoading: false
            }
        )
    )
    .onEvent(
        TaskStreetList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => (
            {
                ...oldState,
                streets: payload.success ? payload.data : [],
                error: payload.success ? '' : ( payload.message || '' )
            }
        )
    )
    .onEvent(
        TaskStreetList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => (
            {
                ...oldState,
                streetsError: payload
            }
        )
    )

    // - - - REPORT STATES

    .onEvent(
        TaskReportStateList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                reportStatesLoading: true
            }
        )
    )
    .onEvent(
        TaskReportStateList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                reportStatesLoading: false
            }
        )
    )
    .onEvent(
        TaskReportStateList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => (
            {
                ...oldState,
                reportStates: payload.success ? payload.data : [],
                error: payload.success ? '' : ( payload.message || '' )
            }
        )
    )
    .onEvent(
        TaskReportStateList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => (
            {
                ...oldState,
                reportStatesError: payload
            }
        )
    )

    // - - - REPORT SENT TO PROCESS STATES

    .onEvent(
        TaskReportSentToProcessState.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                reportSentToProcessStatesLoading: true
            }
        )
    )
    .onEvent(
        TaskReportSentToProcessState.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => (
            {
                ...oldState,
                reportSentToProcessStatesLoading: false
            }
        )
    )
    .onEvent(
        TaskReportSentToProcessState.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => (
            {
                ...oldState,
                reportSentToProcessStates: payload.success ? payload.data : [],
                error: payload.success ? '' : ( payload.message || '' )
            }
        )
    )
    .onEvent(
        TaskReportSentToProcessState.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => (
            {
                ...oldState,
                reportSentToProcessStatesError: payload
            }
        )
    )

    .build();
