import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import ParkingLossReason from "../../model/ParkingLossReason";

type TaskParkingLossReasonCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskParkingLossReasonList extends BaseGenericRequest<TaskParkingLossReasonCriteriaProps, TaskResponse<ParkingLossReason[]>> {

    private readonly data: TaskParkingLossReasonCriteriaProps;

    public constructor(criteria: TaskParkingLossReasonCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_LOSS_REASON_LIST);
        this.data = criteria;
    }

    protected getRequest(): TaskParkingLossReasonCriteriaProps {
        return this.data;
    }

}
