import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_COLORS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import VehicleColorFormScreenAdminReducer from "../formscreen/VehicleColorFormScreenAdminReducer";

const FORM_NAME = "VehicleColorFormAdmin";

export interface VehicleColorAdminData {
    name: string;
    alziraCode: string;
}

interface VehicleColorCardFormAdminProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: VehicleColorAdminData)=> void;
    initialValues?: Partial<VehicleColorCardFormAdminProps>;
    parentLoading?: boolean;
}

const mapStateToProps = VehicleColorFormScreenAdminReducer.autoMapToProps();

type Props =
    InjectedFormProps<VehicleColorAdminData> & VehicleColorCardFormAdminProps & typeof mapStateToProps;

enum VehicleColorFormAdminFields {
    NAME = "name",
    ALZIRA_CODE = "alziraCode",
}

class VehicleColorCardFormAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, vehicleColorLoading, pristine, parentLoading} = this.props;

    return (
      <Card loading={parentLoading || vehicleColorLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.COLOR_DE_VEHICULO : I18nKeys.NUEVO_COLOR_DE_VEHICULO)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={VehicleColorFormAdminFields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.CODIGO_ALZIRA)}
                name={VehicleColorFormAdminFields.ALZIRA_CODE}
                component={FormInput}
                col={{
                  md: 2,
                  lg: 1,
                }}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_ADMIN_VEHICLE_COLORS)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: VehicleColorAdminData) {
  const errors: FormErrors<VehicleColorAdminData> = {
  };

  if (!values.name || values.name.length === 0)
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  return errors;
}

export default compose(
  reduxForm<VehicleColorAdminData, VehicleColorCardFormAdminProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(VehicleColorCardFormAdmin);
