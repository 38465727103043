import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import ReportInvalidationReason, { ReportInvalidationReasonData } from '../../model/ReportInvalidationReason';

export default class TaskReportInvalidationReasonAdd extends BaseGenericRequest<ReportInvalidationReasonData, TaskResponse<ReportInvalidationReason>> {

    private readonly data: ReportInvalidationReasonData;

    public constructor(data: ReportInvalidationReasonData) {
        super(METHOD.POST, Urls.URL_REPORT_INVALIDATION_REASON_ADD);
        this.data = data;
    }

    protected getRequest(): ReportInvalidationReasonData {
        return this.data;
    }
}
