import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import BlacklistRule from "../../../../commons/model/BlacklistRule";
import BlackListRuleFormCompanyUserScreenActions from "./BlackListRuleFormCompanyUserScreenActions";
import TaskBlacklistRuleDetail from "../../../../commons/ws/blackListRule/TaskBlacklistRuleDetail";

interface InitialState {
    blackListRuleLoading: boolean,
    blackListRule?: BlacklistRule,
}

const INITIAL_STATE: InitialState = {
    blackListRuleLoading: false,
    blackListRule: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskBlacklistRuleDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        blackListRuleLoading: true
    }))
    .onEvent(TaskBlacklistRuleDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        blackListRuleLoading: false
    }))
    .onEvent(TaskBlacklistRuleDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, blackListRule: payload.data};
        })

    .onEvent(BlackListRuleFormCompanyUserScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
