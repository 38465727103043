
import React from "react";
import { connect } from "react-redux";
import { Field, WrappedFieldArrayProps } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import TaskUserTypeList from "../../../../commons/ws/userType/TaskUserTypeList";
import CardFieldArray from "../../../../components/card/CardFieldArray";
import Col from "../../../../components/Col";
import { UserTypeAutocompleteBuilder } from "../../../../components/filter/FilterTypeaheadOptions";
import FormSelect from "../../../../components/form/FormSelect";
import I18nKeys from "../../../../I18n/I18nKeys";
import UserTypeFieldArrayReducer from "./UserTypeFieldArrayReducer";

const mapStateToProps = UserTypeFieldArrayReducer.autoMapToProps();

interface UserTypeFieldArrayProps {
    showFirstOption?: boolean;
}

type Props = UserTypeFieldArrayProps & WrappedFieldArrayProps<UserTypeAutocompleteBuilder> & typeof mapStateToProps;

class UserTypeFieldArray extends React.Component<Props> {
  public componentWillMount(): void {
    new TaskUserTypeList().execute();

    if (this.props.showFirstOption)
      this.props.fields.push("");
  }

  public render(): React.ReactNode {
    const {fields, userTypeList, meta} = this.props;
    const userTypeOptions = userTypeList.map(
      (userType) => ( {
        value: userType.id,
        name: userType.name,
      } ),
    );

    return (
      <CardFieldArray fields={fields}
        meta={meta}
        title={""}
        fieldsHeader={renderHeader()}
        addHandler={() => fields.push("")}
        hideRemoveWithOneOption={true}
      >
        {fields.map((fieldName: string, index: number) => (
          <Field
            key={index}
            name={fieldName}
            component={FormSelect}
            options={userTypeOptions}
            blankOptionText={" "}
            col={{
              className: "m-b--10 m-t--15 p-r-10",
            }}
            disabled={false}
            errorMessageWithoutTouch={true}
          />
        ))}
      </CardFieldArray>
    );
  }
}

const renderHeader = (): React.ReactNode => (
  <Col className={"m-b-0 m-l-15"}>
    <label>{I18n.tr(I18nKeys.TIPOS_DE_USUARIO)}</label>
  </Col>
);

export default connect(mapStateToProps)(UserTypeFieldArray) as unknown as React.ComponentType<any>;
