import { METHOD } from "@cuatroochenta/co-generic-request";
import { sha256 } from "js-sha256";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

interface TaskSetPasswordRequest {
    token: string;
    password: string;
}

export default class TaskSetPassword extends BaseGenericRequest<TaskSetPasswordRequest, TaskResponse<{}>> {
  private readonly data: TaskSetPasswordRequest;

  public constructor(password: string, token: string) {
    super(METHOD.POST, Urls.URL_USER_SET_PASSWORD);
    this.data = {
      password,
      token,
    };

    this.setErrorMessage(401, I18n.tr(I18nKeys.NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA));
    this.setErrorMessage(404, I18n.tr(I18nKeys.EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE));
    this.setErrorMessage(411, I18n.tr(I18nKeys.LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES));
  }

  protected getRequest(): TaskSetPasswordRequest {
    return {
      ...this.data,
      password: sha256(this.data.password),
    };
  }
}
