import { Component, ReactNode } from "react";
import I18n from "../../../../commons/I18n/I18n";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import DateFormatter from "../../../../utils/DateFormatter";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface CollectionProcessAddTableProps {
    data: Array<ParkingMeterCollection>;
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> ReactNode;
}

export default class CollectionProcessAddTable extends Component<CollectionProcessAddTableProps> {
  public render(): ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((collection) => (
            <tr key={collection.id}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(collection.id) : null}
              <td>
                {collection.id}
              </td>
              <td>
                {DateFormatter.formatDate(collection.date)}
              </td>
              <td>
                {collection.cash}
              </td>
              <td>
                {collection.parking_meter.description}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): ReactNode {
    const {sort, onSort, children} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={"Id"}
          columnName={"id"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.IMPORTE)}
          columnName={"state"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.PARQUIMETRO)}
          columnName={"realCash"}
          {...commonProps}
        />

      </tr>
    );
  }
}
