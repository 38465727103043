import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import ParkingLoss from "../../model/ParkingLoss";

export default class TaskParkingLossDetail extends BaseGenericRequest<{}, TaskResponse<ParkingLoss>> {

    public constructor(parkingLossId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_PARKING_LOSS_DETAIL, {uuid: parkingLossId}));
    }
}
