
import classNames from "classnames";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import I18n from "../../../commons/I18n/I18n";
import UserCert from "../../../commons/model/UserCert";
import { AppIcon } from "../../../config/Config";
import I18nKeys from "../../../I18n/I18nKeys";
import DateFormatter, { DateHelpers, DateUtils } from "../../../utils/DateFormatter";

interface UserCertTooltipInfoProps {
    userCert: UserCert;
    key: string;
}

export default class UserCertTooltipInfo extends React.Component<UserCertTooltipInfoProps> {
  public render(): React.ReactNode {
    const {userCert, key} = this.props;
    let message = "";
    let iconStyle = "";

    if (DateUtils.isGreaterThan(DateUtils.getCurrentDate(), new Date(userCert.end_date))) { // expired
      message = `${I18n.tr(I18nKeys.EL_CERTIFICADO_CADUCO_EL)} ${DateFormatter.formatDatetime(userCert.end_date)}`;
      iconStyle = "invalid";
    } else {
      const nextExpirationDate = DateHelpers.getXNextDays(14);

      if (DateUtils.isGreaterThan(nextExpirationDate, new Date(userCert.end_date))) { // near expiration
        message = `${I18n.tr(I18nKeys.EL_CERTIFICADO_CADUCA_EL)} ${DateFormatter.formatDatetime(userCert.end_date)}`;
        iconStyle = "near-expiration";
      } else {
        message = `${I18n.tr(I18nKeys.CERTIFICADO_VALIDO_HASTA_EL)} ${DateFormatter.formatDatetime(userCert.end_date)}`;
        iconStyle = "valid";
      }
    }

    const tooltip: React.ReactNode = (<Tooltip id={"userCert"} key={`${key}-tooltip`}>{message}</Tooltip>);
    const iconClassComponent = classNames("material-icons", `digital-sign-icon ${iconStyle}`);

    return (
      <OverlayTrigger key={key} overlay={tooltip} placement={"top"}>
        <i className={iconClassComponent}>{AppIcon.DIGITAL_SIGN_ENABLED}</i>
      </OverlayTrigger>
    );
  }
}
