import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import UserCert from "../../../commons/model/UserCert";
import TaskUserCertDetail from "../../../commons/ws/userCert/TaskUserCertDetail";
import UserCertCompanyUserScreenActions from "./UserCertCompanyUserScreenActions";

interface InitialState {
    loading: boolean,
    userCert?: UserCert,
    error: string,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    userCert: undefined,
    error: '',
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskUserCertDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskUserCertDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskUserCertDetail.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskUserCertDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            userCert: payload.data,
        }
    })

    .onEvent(UserCertCompanyUserScreenActions.clearReducer, () => INITIAL_STATE)

    .build()
