import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import Street, {StreetData} from "../../model/Street";

export default class TaskStreetEdit extends BaseGenericRequest<StreetData, TaskResponse<Street>> {

    private readonly data: StreetData;

    public constructor(streetId: string, streetData: StreetData) {
        super(METHOD.PUT, generateRoute(Urls.URL_STREET_EDIT, {uuid: streetId}));
        this.data = streetData;
    }

    protected getRequest(): StreetData {
        return this.data;
    }
}
