import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import Vehicle, {VehicleData} from "../../model/Vehicle";

export default class TaskVehicleAdd extends BaseGenericRequest<VehicleData, TaskResponse<Vehicle>> {

    private readonly data: VehicleData;

    public constructor(data: VehicleData) {
        super(METHOD.POST, Urls.URL_VEHICLE_ADD);
        this.data = data;
    }

    protected getRequest(): VehicleData {
        return this.data;
    }
}
