import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import Street, { StreetData } from "../../../../commons/model/Street";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskStreetEdit from "../../../../commons/ws/route/TaskStreetEdit";
import TaskStreetAdd from "../../../../commons/ws/street/TaskStreetAdd";
import TaskStreetDetail from "../../../../commons/ws/street/TaskStreetDetail";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_STREETS, ROUTE_COMPANY_USER_STREET_ADD, ROUTE_COMPANY_USER_STREET_DETAIL } from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import StreetCardFormCompanyUser, { StreetCardFormCompanyUserData } from "../formdetail/StreetCardFormCompanyUser";
import StreetFormCompanyUserScreenActions from "./StreetFormCompanyUserScreenActions";
import StreetFormScreenCompanyUserReducer from "./StreetFormScreenCompanyUserReducer";

const mapStateToProps = StreetFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = StreetFormCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class StreetFormCompanyUserScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskStreetDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: StreetCardFormCompanyUserData): void => {
    const existStreet = this.props.match.params.id !== undefined;
    const submitData: StreetData = {
      name: values.name ? values.name : "",
      parking_spaces: values.parking_spaces ? values.parking_spaces : values.parking_spaces,
      route_id: values.route_id ? values.route_id : values.route_id,
      street_type_id: values.street_type_id ? values.street_type_id : "",
      parking_area_type_id: values.parking_area_type_id ? values.parking_area_type_id : values.parking_area_type_id,
      suma_code: values.suma_code ? values.suma_code : "",
      kml_file: values.kml_file && values.kml_file.length !== 0 ? values.kml_file[0] : "",
    };

    if (existStreet) {
      executeItemTask(
        new TaskStreetEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_COMPANY_USER_STREETS),
        I18n.tr(I18nKeys.CALLE_MODIFICADA_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskStreetAdd(submitData),
        () => goToRoute(ROUTE_COMPANY_USER_STREETS),
        I18n.tr(I18nKeys.CALLE_CREADA_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const streetId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.CALLES),
      url: ROUTE_COMPANY_USER_STREETS,
      icon: AppIcon.STREET,
    }];

    if (streetId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.CALLE),
        url: generateRoute(ROUTE_COMPANY_USER_STREET_DETAIL, {
          id: streetId,
        } ),
        icon: AppIcon.STREET,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVA_CALLE),
        url: ROUTE_COMPANY_USER_STREET_ADD,
        icon: AppIcon.STREET,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderStreetForm()}
      </ScreenCard>
    );
  }

  private renderStreetForm(): React.ReactNode {
    const {streetLoading, street} = this.props;
    let initialValues: StreetCardFormCompanyUserData | undefined;
    let streetId: string | undefined;

    if (street) {
      const streetData: Street = street;

      initialValues = {
        name: streetData.name ? streetData.name : "",
        suma_code: streetData.suma_code ? streetData.suma_code : "",
        parking_area_type_id: streetData.parking_area_type ? streetData.parking_area_type.id : "",
        street_type_id: streetData.street_type ? streetData.street_type.id : "",
        route_id: streetData.route ? streetData.route.id : "",
        parking_spaces: streetData.parking_spaces ? streetData.parking_spaces : 0,
        kml_file: streetData.kml_file ? [streetData.kml_file] : [],
      };
      streetId = this.props.match.params.id ? this.props.match.params.id : "";
    }

    return (
      <StreetCardFormCompanyUser
        streetId={streetId}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        parentLoading={streetLoading}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(StreetFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
