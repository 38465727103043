import { METHOD } from "@cuatroochenta/co-generic-request";
import VehicleModel from "../../model/VehicleModel";
import BaseGenericRequest from "../BaseGenericRequest";
import { TaskCriteriaRequest } from "../TaskCriteriaRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

type VehicleModelCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskVehicleModelList extends BaseGenericRequest<VehicleModelCriteriaProps, TaskResponse<VehicleModel[]>> {

    private readonly data: VehicleModelCriteriaProps;

    public constructor(criteria: VehicleModelCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_VEHICLE_MODEL_LIST);
        this.data = criteria;
    }

    protected getRequest(): VehicleModelCriteriaProps {
        return this.data;
    }

}
