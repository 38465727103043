import { Component, ReactNode } from "react";
import I18n from "../../../../commons/I18n/I18n";
import { ParkingMeterCollectionProcess } from "../../../../commons/model/ParkingMeterCollectionProcess";
import { Card } from "../../../../components-new/Card";
import { CardHeader } from "../../../../components-new/CardHeader";
import { CardSectionCollapsed2 } from "../../../../components-new/CardSectionCollapsed2";
import CardBody from "../../../../components/card/CardBody";
import Col from "../../../../components/Col";
import DetailsText from "../../../../components/detail/DetailsText";
import { PagerBackendProps } from "../../../../components/Pager";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import DateFormatter from "../../../../utils/DateFormatter";
import CollectionProcessDetailTable from "./CollectionProcessDetailTable";

interface CollectionProcessDetailViewProps {
	parkingMeterCollectionProcess?: ParkingMeterCollectionProcess;
	loading: boolean;
	errors: string;
	pager: PagerBackendProps;
}

export default class CollectionProcessDetailView extends Component<CollectionProcessDetailViewProps> {
  public render(): ReactNode {
    const {parkingMeterCollectionProcess, loading, errors} = this.props;

    return (
      <Card loading={loading}>
        <CardHeader title={parkingMeterCollectionProcess ? parkingMeterCollectionProcess.id : ""} />

        <CardBody>
          <CardSectionCollapsed2
            title={I18n.tr(I18nKeys.INFORMACION)}
            initialCollapsed={false}
            style={{
              marginBottom: "30px",
            }}
          >
            <Row>
              <Col md={3} lg={3}>
                <DetailsText
                  label={I18n.tr(I18nKeys.FECHA)}
                  value={parkingMeterCollectionProcess ? DateFormatter.formatDate(parkingMeterCollectionProcess.date) : ""}
                />
              </Col>
              <Col md={3} lg={3}>
                <DetailsText
                  label={I18n.tr(I18nKeys.IMPORTE_REAL)}
                  value={parkingMeterCollectionProcess ? String(parkingMeterCollectionProcess.real_cash) : ""}
                />
              </Col>
              <Col md={3} lg={3}>
                <DetailsText
                  label={I18n.tr(I18nKeys.ESTADO)}
                  value={parkingMeterCollectionProcess ? parkingMeterCollectionProcess.collection_process_state.name : ""}
                />
              </Col>
            </Row>
          </CardSectionCollapsed2>

          {
            parkingMeterCollectionProcess && parkingMeterCollectionProcess.threshold_alert &&

						<CardSectionCollapsed2
						  title={I18n.tr(I18nKeys.DESVIACION)}
						  initialCollapsed={false}
						>
						  <Row>
						    <Col md={3} lg={3}>
						      <DetailsText
						        label={I18n.tr(I18nKeys.DESVIACION)}
						        value={parkingMeterCollectionProcess ? String(parkingMeterCollectionProcess.cash_deviation_alert) : ""}
						      />
						    </Col>

						    <Col md={3} lg={3}>
						      <DetailsText
						        label={I18n.tr(I18nKeys.MENSAJE_ALERTA)}
						        value={parkingMeterCollectionProcess ? String(parkingMeterCollectionProcess.message_alert) : ""}
						      />
						    </Col>

						  </Row>
						</CardSectionCollapsed2>
          }

          <Row style={{
            marginBottom: "15px",
          }}/>

          <CollectionProcessDetailTable
            data={parkingMeterCollectionProcess ? parkingMeterCollectionProcess.parking_meter_collections : []}
            error={errors}
          />

        </CardBody>
      </Card>
    );
  }
}
