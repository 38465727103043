import { Component, ReactNode } from "react";
import I18n from "../../../../commons/I18n/I18n";
import { ParkingMeterCollectionProcess } from "../../../../commons/model/ParkingMeterCollectionProcess";
import TaskParkingMeterCollectionProcessList from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessList";
import CardList from "../../../../components/card/CardList";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import { PagerBackendProps } from "../../../../components/Pager";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_COLLECTION_PROCESS_ADD } from "../../../../routing/Routes";
import CollectionProcessTable from "./CollectionProcessTable";

interface CollectionProcessAdminViewProps {
    parkingMeterCollectionProcessList: ParkingMeterCollectionProcess[];
    onParkingMeterCollectionProcessSend: ()=> void;
    disabledSend: boolean;
    loading: boolean;
    errors: string;
    pager: PagerBackendProps;
}

interface CollectionProcessAdminViewState {
    showSendModal: boolean;
}

export default class CollectionProcessAdminView extends Component<CollectionProcessAdminViewProps, CollectionProcessAdminViewState> {
  constructor(props: Readonly<CollectionProcessAdminViewProps>) {
    super(props);
    this.state = {
      showSendModal: false,
    };
  }

  public render(): ReactNode {
    const {parkingMeterCollectionProcessList, onParkingMeterCollectionProcessSend
      , disabledSend, loading, errors, pager} = this.props;

    return (
      <>
        <CardList loading={loading}
          pager={pager}
          title={I18n.tr(I18nKeys.PROCESOS_RECAUDACION)}
          sort={{
            column: "id",
          }}
          TaskList={TaskParkingMeterCollectionProcessList}
          emptyListOptions={{
            message: I18n.tr(I18nKeys.NO_EXISTEN_PROCESOS_DE_RECAUDACION),
          }}
          data={parkingMeterCollectionProcessList}
          error={errors}
          ItemsTable={CollectionProcessTable}
          addItem={ROUTE_ADMIN_COLLECTION_PROCESS_ADD}
          sendItems={() => this.setState( {
            showSendModal: true,
          } )}
          enableSend={disabledSend}
          noSearchBar={true}
        />

        <ConfirmModal
          title={I18n.tr(I18nKeys.ENVIAR_PROCESOS_RECAUDACION)}
          message={I18n.tr(I18nKeys.ESTA_SEGURO_DE_QUE_QUIERE_ENVIAR_ESTOS_PROCESOS_RECAUDACION)}
          textConfirmButton={I18n.tr(I18nKeys.ENVIAR)}
          onConfirmAction={() => {
            this.setState( {
              showSendModal: false,
            } );
            onParkingMeterCollectionProcessSend();
          }}
          show={this.state.showSendModal}
          onClose={() => this.setState( {
            showSendModal: false,
          } )}
        />
      </>
    );
  }
}
