import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import Route, { RouteData } from "../../model/Route";
import { generateRoute } from "../../utils/Router";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskRouteEdit extends BaseGenericRequest<RouteData, TaskResponse<Route>> {
  private readonly data: RouteData;

  public constructor(routeId: string, routerData: RouteData) {
    super(METHOD.PUT, generateRoute(Urls.URL_ROUTE_EDIT, {
      uuid: routeId,
    } ));
    this.data = routerData;

    this.setErrorMessage(488, I18n.tr(I18nKeys.YA_EXISTE_UN_ELEMENTO_SIMILAR));
  }

  protected getRequest(): RouteData {
    return this.data;
  }
}
