import { Component, ReactNode } from "react";
import { Field } from "redux-form";
import I18n from "../../commons/I18n/I18n";
import I18nKeys from "../../I18n/I18nKeys";
import Button from "../buttons/Button";
import Col from "../Col";
import Row from "../Row";
import FormSubmit from "./FormSubmit";

export interface CardHeaderProps {
    invalid: boolean;
    pristine: boolean;
    isUpdate: boolean;
    cancelHandler: ()=> void;
    classNameContainer?: string;
}

export default class FormCardFooter extends Component<CardHeaderProps> {
  public render(): ReactNode {
    const {pristine, isUpdate, cancelHandler, classNameContainer} = this.props;

    return (
      <Row className={`p-t-20 ${classNameContainer}`}>
        <Col sm={4} md={6} lg={8}/>
        <Col sm={4} md={3} lg={2}>
          <Button
            text={I18n.tr(I18nKeys.CANCELAR)}
            onClick={cancelHandler}
            type={"button"}
            block={true}
            className={"btn-lg btn-default"}
          />
        </Col>
        <Field
          label={I18n.tr(isUpdate ? I18nKeys.GUARDAR : I18nKeys.ANADIR)}
          name="submit"
          component={FormSubmit}
          col={{
            sm: 4,
            md: 3,
            lg: 2,
          }}
          disabled={pristine}
        />
      </Row>
    );
  }
}
