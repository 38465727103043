import React from "react";
import I18n from "../../../../commons/I18n/I18n";
import LawType from "../../../../commons/model/LawType";
import { generateRoute } from "../../../../commons/utils/Router";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_LAW_TYPE_DETAIL } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface LawTypeListTableProps {
    data: LawType[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

function goToItemDetail(itemId: string): void {
  goToRoute(generateRoute(ROUTE_ADMIN_LAW_TYPE_DETAIL, {
    id: itemId,
  } ));
}

export default class LawTypeListTable extends React.Component<LawTypeListTableProps> {
  public render(): React.ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((lawType: LawType) => (
            <tr key={lawType.id}
              onClick={() => goToItemDetail(lawType.id)}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(lawType.id) : null}
              <td>
                {lawType.name}
              </td>
              <td>
                {lawType.suma_code}
              </td>
              <td>
                {lawType.discount_percentage}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.NOMBRE)}
          columnName={"name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.CODIGO_SUMA)}
          columnName={"suma_code"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.DESCUENTO)}
          columnName={"discount_percentage"}
          {...commonProps}
        />
      </tr>
    );
  }
}
