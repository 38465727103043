import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import VehicleBrand from "../../model/VehicleBrand";

type VehicleBrandCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskVehicleBrandList extends BaseGenericRequest<VehicleBrandCriteriaProps, TaskResponse<VehicleBrand[]>> {

    private readonly data: VehicleBrandCriteriaProps;

    public constructor(criteria: VehicleBrandCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_VEHICLE_BRAND_LIST);
        this.data = criteria;
    }

    protected getRequest(): VehicleBrandCriteriaProps {
        return this.data;
    }

}
