export function isEmailValid(email: string): boolean {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export function Int(value: any): number {
    return parseInt(String(value || -1));
}

export function isIPValid(ip: string): boolean {
    return /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/.test(ip);
}

export function truncate(text: string, length: number = 15, delimiter: string = '...'): string {
    if (text.split(" ").length > length) {
        return text.split(" ").splice(0, length).join(" ") + delimiter;
    }
    return text;
}

export function truncateMiddle(text: string, maxLength: number = 30, separator: string = '...') {
    if (text.length <= maxLength) {
        return text;
    }
    const sepLen = separator.length;
    const charsToShow = maxLength - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return text.substr(0, frontChars) +
        separator +
        text.substr(text.length - backChars);
}

export function contains(text: string, substring: string): boolean {
    return cleanText(text).indexOf(cleanText(substring)) !== -1;
}

function cleanText(text: string): string {
    const normalized = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return normalized.toLowerCase();
}

export function isValidURL(url: string): boolean {
    return true;
}

export function getMBfromBytes(bytes: number, decimals: number = 1): string {
    const mb = bytes / 1024 / 1024;
    return mb.toFixed(decimals);
}

export function isValidPassword(password: string): boolean {
    // al menos un numero, una minúscula, una mayúscula y de 8 caracteres
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return regExp.test(password);
}

export function isNifNiePersonValid(nif: string) {

    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = nif.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
        return false;
    }

    const nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

    return validChars.charAt(charIndex) === letter;
}

export function isHEXColorValid(hexCode: string): boolean {
    const str = hexCode.toString().toUpperCase();

    return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(str);
}