import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import Law from "../../../../commons/model/Law";
import TaskLawDetail from "../../../../commons/ws/law/TaskLawDetail";
import LawCompanyUserScreenActions from "./LawFormCompanyUserScreenActions";
import LawType from "../../../../commons/model/LawType";
import TaskLawTypeList from "../../../../commons/ws/lawType/TaskLawTypeList";
import TaskCancellationReferenceTypeList
    from "../../../../commons/ws/cancellationReferenceType/TaskCancellationReferenceTypeList";
import CancellationReferenceType from "../../../../commons/model/CancellationReferenceType";

interface InitialState {
    lawLoading: boolean,
    law?: Law,
    lawTypeList: LawType[],
    cancellationReferenceTypeList: CancellationReferenceType[],
}

const INITIAL_STATE: InitialState = {
    lawLoading: false,
    law: undefined,
    lawTypeList: [],
    cancellationReferenceTypeList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskLawDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        lawLoading: true
    }))
    .onEvent(TaskLawDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        lawLoading: false
    }))
    .onEvent(TaskLawDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, law: payload.data};
        })

    .onEvent(TaskLawTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, lawTypeList: payload.data};
    })

    .onEvent(TaskCancellationReferenceTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, cancellationReferenceTypeList: payload.data};
    })

    .onEvent(LawCompanyUserScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
