
import {Component, ReactNode} from 'react';
import SideBarReducer from "./SideBar/SideBarReducer";
import SideBarActions from "./SideBar/SideBarActions";
import {connect} from "react-redux";
import ReactSidebar from "react-sidebar";
import SideBar from "./SideBar/SideBar";
import NavBar from "./NavBar";
import CurrentUserReducer from "../../../redux/CurrentUserReducer";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import LoadingView from "../../loading/LoadingView";
import TaskUserCurrentDetail from "../../../commons/ws/user/TaskUserCurrentDetail";

const mapStateToPropsSideBar = SideBarReducer.autoMapToProps();
const mapStateToPropsCurrentUser = CurrentUserReducer.autoMapToProps();
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(mapStateToPropsSideBar, mapStateToPropsCurrentUser);
const mapActionsToProps = SideBarActions.autoMapToProps();

class MenuWrapper extends Component<typeof mapStateToProps & typeof mapActionsToProps & { children: ReactNode }> {

    private readonly mql: any;

    public constructor(props: any) {
        super(props);
        this.mql = window.matchMedia(`(min-width: 1170px)`);
        props.onChangeMediaQuery(this.mql);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSideBar = this.onSetSideBar.bind(this);
        this.toggleSideBar = this.toggleSideBar.bind(this);
    }

    public componentDidMount() {
        document.body.classList.add('theme-grey');
        this.mql.addListener(this.mediaQueryChanged);

        if (!this.props.currentUser) {
            new TaskUserCurrentDetail().execute()
        }
    }

    public componentWillUnmount() {
        document.body.classList.remove('theme-grey');
        this.mql.removeListener(this.mediaQueryChanged);
    }

    /* SIDEBAR */

    private onSetSideBar(open: boolean) {
        this.props.onSetSideBar(open);
    }

    private toggleSideBar() {
        this.props.toggleSideBar();
    }

    private mediaQueryChanged() {
        this.props.onChangeMediaQuery(this.mql);
    }

    /* RENDER */

    public render(): React.ReactNode {
        if (this.props.loadingCurrentUser) {
            return <LoadingView loading={true}/>;
        }
        if (!this.props.currentUser) {
            return null;
        }
        return (
            <ReactSidebar
                key={"sidebar"}
                contentId={"sidebar-content"} // necesario para obtener el nodo por id desde "ChatCardCompanyUser"
                sidebar={<SideBar user={this.props.currentUser} isAdmin={this.props.isAdmin}/>}
                open={this.props.sidebarOpen}
                docked={this.props.sidebarDocked}
                onSetOpen={this.onSetSideBar}
                transitions={false}
            >
                <NavBar onClickBars={this.toggleSideBar} className={''}/>
                {this.props.children}
            </ReactSidebar>
        );

    }

}

export default connect(mapStateToProps, mapActionsToProps as any)(MenuWrapper);
