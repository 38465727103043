
import { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { LinkProps } from 'react-router-dom';
import { StyleProps } from '../../components-new/typings';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - PROPS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export interface DetailsTextProps {
    label: string
    href?: LinkProps['href'] | null
}

type Props = DetailsTextProps & StyleProps;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default class DetailsLink extends Component<Props> {

    public render(): ReactNode {
        const {label, href, className, style} = this.props;
        const classComponent = classNames('details-text', className);

        return (
            <div className={classComponent} style={style}>
                <div className='dt-label'>
                    {label}
                </div>
                <div className='dt-value'>
                    {
                        href && href.length > 0
                            ? <a href={href} >{href}</a>
                            : '- - -'
                    }
                </div>
            </div>
        );
    }

}
