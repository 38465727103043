import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import VehicleBrand from "../../model/VehicleBrand";

export default class TaskVehicleBrandDetail extends BaseGenericRequest<{}, TaskResponse<VehicleBrand>> {

    public constructor(vehicleBrandId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_VEHICLE_BRAND_DETAIL, {uuid: vehicleBrandId}));
    }
}
