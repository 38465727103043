import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskParkingLossDetail from "../../../../commons/ws/parkingLoss/TaskParkingLossDetail";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_COMPANY_USER_PARKING_LOSSES,
  ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL} from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import ParkingLossCardFormCompanyUser, { ParkingLossCardFormCompanyUserData } from "../formdetail/ParkingLossCardFormCompanyUser";
import ParkingLossFormCompanyUserScreenActions from "./ParkingLossFormCompanyUserScreenActions";
import ParkingLossFormScreenCompanyUserReducer from "./ParkingLossFormScreenCompanyUserReducer";

const mapStateToProps = ParkingLossFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = ParkingLossFormCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class ParkingLossFormCompanyUserScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id) 
      loadItemDetailsOrGoBack(new TaskParkingLossDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const parkingLossId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.PLAZAS_PERDIDAS),
      url: ROUTE_COMPANY_USER_PARKING_LOSSES,
      icon: AppIcon.PARKING,
    }];

    if (parkingLossId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.PLAZA_PERDIDA),
        url: generateRoute(ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL, {
          id: parkingLossId,
        } ),
        icon: AppIcon.PARKING,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderParkingMeterForm()}
      </ScreenCard>
    );
  }

  private renderParkingMeterForm(): React.ReactNode {
    const {parkingLossLoading, parkingLoss} = this.props;
    let initialValues: ParkingLossCardFormCompanyUserData | undefined;

    if (parkingLoss) {
      initialValues = {
        date: parkingLoss.date ? DateFormatter.formatDatetime(parkingLoss.date) : "",
        places: parkingLoss.places,
        occupied_hours: parkingLoss.occupied_hours,
        street: parkingLoss.street ? parkingLoss.street.name : "",
        parking_loss_reason: parkingLoss.parking_loss_reason ? parkingLoss.parking_loss_reason.name : "",
        controller: parkingLoss.controller ?
          `${parkingLoss.controller.name} ${parkingLoss.controller.last_name}`
          : "",
        observations: parkingLoss.observations ? parkingLoss.observations : "",
      };
    }

    return (
      <ParkingLossCardFormCompanyUser
        initialValues={initialValues}
        parentLoading={parkingLossLoading}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingLossFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
