import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import VehicleBrand from "../../../../commons/model/VehicleBrand";
import TaskVehicleBrandDetail from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandDetail";
import VehicleBrandFormAdminScreenActions from "./VehicleBrandFormAdminScreenActions";

interface InitialState {
    vehicleBrandLoading: boolean,
    vehicleBrand?: VehicleBrand,
}

const INITIAL_STATE: InitialState = {
    vehicleBrandLoading: false,
    vehicleBrand: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskVehicleBrandDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        vehicleBrandLoading: true
    }))
    .onEvent(TaskVehicleBrandDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        vehicleBrandLoading: false
    }))
    .onEvent(TaskVehicleBrandDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, vehicleBrand: payload.data};
        })

    .onEvent(VehicleBrandFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
