import React from "react";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_DELTA_CARS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";

const FORM_NAME = "DeltaCarFormCompanyUser";

export interface DeltaCarCompanyUserData {
    name: string;
    license_plate: string;
}

interface DeltaCarCardFormCompanyUserProps {
    readonly?: boolean;
    onSubmit: (data: DeltaCarCompanyUserData)=> void;
    initialValues?: Partial<DeltaCarCompanyUserData>;
    parentLoading?: boolean;
}

type Props = DeltaCarCardFormCompanyUserProps & InjectedFormProps<DeltaCarCompanyUserData>;

enum DeltaCarFormCompanyUserFields {
    NAME = "name",
    LICENSE_PLATE = "license_plate",
}

class DeltaCarCardFormCompanyUser extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, parentLoading, pristine} = this.props;

    return (
      <Card loading={parentLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.VEHICULO_EMBARCADO : I18nKeys.NUEVO_VEHICULO_EMBARCADO)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={DeltaCarFormCompanyUserFields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.MATRICULA)}
                name={DeltaCarFormCompanyUserFields.LICENSE_PLATE}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>

            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_DELTA_CARS)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: DeltaCarCompanyUserData) {
  const errors: FormErrors<DeltaCarCompanyUserData> = {
  };

  if (!values.name || values.name.length === 0) 
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  if (!values.license_plate || values.license_plate.length === 0) 
    errors.license_plate = I18n.tr(I18nKeys.MATRICULA_OBLIGATORIA);

  return errors;
}

export default reduxForm<DeltaCarCompanyUserData, DeltaCarCardFormCompanyUserProps>( {
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
} )(DeltaCarCardFormCompanyUser as any) as unknown as React.ComponentType<DeltaCarCardFormCompanyUserProps>;
