import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import {METHOD} from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import {TaskCriteriaRequest} from '../TaskCriteriaRequest';
import DeltaCar from "../../model/DeltaCar";

type DeltaCarCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskDeltaCarList extends BaseGenericRequest<DeltaCarCriteriaProps, TaskResponse<DeltaCar[]>> {

    private readonly data: DeltaCarCriteriaProps;

    public constructor(criteria: DeltaCarCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_DELTA_CAR_LIST);
        this.data = criteria;
    }

    protected getRequest(): DeltaCarCriteriaProps {
        return this.data;
    }
}

