import {FormErrors} from "redux-form";
import TaskResponse from "../../commons/ws/TaskResponse";
import {FormChangeHandler} from "../../utils/FormUtils";

interface GenericModelData {
    [property: string]: any
}

interface GenericFormData {
    [fieldPath: string]: any
}

export default abstract class BaseFormFieldsHelpers {

    public static validate(values: GenericFormData, props = {}): FormErrors<any, any> {
        return {}
    };

    public static getModelData(values: GenericFormData): GenericModelData | null {
        return null;
    };

    public static getFormData(model: GenericModelData): GenericFormData {
        return {}
    };

    public static addToBackend(values: GenericFormData, onSuccess: (response: TaskResponse<any>) => void): void {
        return undefined;
    }

    public static updateBackend(values: GenericFormData, onSuccess: (response: TaskResponse<any>) => void,
                                itemId: number | string): void {
        return undefined;
    }

    public static adaptToFormChanges(
        changeHandler: FormChangeHandler,
        newFormValues: GenericFormData,
        prevFormValues: GenericFormData,
    ): void {
        return undefined;
    }

}

