import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {
    ParkingMeterCollectionProcess,
    ParkingMeterCollectionProcessAddData
} from "../../model/ParkingMeterCollectionProcess";

export default class TaskParkingMeterCollectionProcessAdd extends BaseGenericRequest<ParkingMeterCollectionProcessAddData, TaskResponse<ParkingMeterCollectionProcess>> {

    private readonly data: ParkingMeterCollectionProcessAddData;

    public constructor(data: ParkingMeterCollectionProcessAddData) {
        super(METHOD.POST, Urls.URL_PARKING_METER_COLLECTION_PROCESS_ADD);
        this.data = data;
    }

    protected getRequest(): ParkingMeterCollectionProcessAddData {
        return this.data;
    }
}
