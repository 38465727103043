import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskStreetDelete from "../../../../commons/ws/route/TaskStreetDelete";
import TaskStreetList from "../../../../commons/ws/street/TaskStreetList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_STREET_ADD } from "../../../../routing/Routes";
import StreetCompanyUserActions from "./StreetCompanyUserActions";
import StreetCompanyUserReducer from "./StreetCompanyUserReducer";
import StreetListTable from "./StreetListTable";

const mapActionsToProps = StreetCompanyUserActions.autoMapToProps();
const mapStateToProps = StreetCompanyUserReducer.autoMapToProps();

class StreetCardCompanyUser extends React.Component<typeof mapActionsToProps & typeof mapStateToProps> {
  public componentWillMount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const {loading, streets, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.CALLES)}
        sort={{
          column: "name",
        }}
        TaskList={TaskStreetList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_CALLES),
        }}
        data={streets}
        error={error}
        ItemsTable={StreetListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_CALLES),
          Task: TaskStreetDelete,
        }}
        addItem={ROUTE_COMPANY_USER_STREET_ADD}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(StreetCardCompanyUser as unknown as React.ComponentType<void>);
