import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_PARKING_LOSS_REASONS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import ParkingLossReasonFormScreenAdminReducer from "../formscreen/ParkingLossReasonFormScreenAdminReducer";

const FORM_NAME = "ParkingLossReasonCardFormAdmin";

export interface ParkingLossReasonCardFormAdminData {
    name: string;
}

interface ParkingLossReasonCardFormAdminProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: ParkingLossReasonCardFormAdminData)=> void;
    initialValues?: Partial<ParkingLossReasonCardFormAdminData>;
    parentLoading?: boolean;
}

const mapStateToProps = ParkingLossReasonFormScreenAdminReducer.autoMapToProps();

type Props =
    InjectedFormProps<ParkingLossReasonCardFormAdminData> & ParkingLossReasonCardFormAdminProps & typeof mapStateToProps;

enum ParkingLossReasonFormAdminFields {
    NAME = "name",
}

class ParkingLossReasonCardFormAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, pristine, parentLoading} = this.props;

    return (
      <Card loading={parentLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.MOTIVO_DE_PLAZA_PERDIDA : I18nKeys.NUEVO_MOTIVO_DE_PLAZA_PERDIDA)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={ParkingLossReasonFormAdminFields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>

            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_ADMIN_PARKING_LOSS_REASONS)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: ParkingLossReasonCardFormAdminData) {
  const errors: FormErrors<ParkingLossReasonCardFormAdminData> = {
  };

  if (!values.name || values.name.length === 0) 
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  return errors;
}

export default compose(
  reduxForm<ParkingLossReasonCardFormAdminData, ParkingLossReasonCardFormAdminProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(ParkingLossReasonCardFormAdmin);
