
import React from "react";
import { connect } from "react-redux";
import Alert from "../../../../../base/alerts/Alert";
import I18n from "../../../../../commons/I18n/I18n";
import TaskUserList from "../../../../../commons/ws/user/TaskUserList";
import TaskWorkdayReport, { WorkdayReportCriteria } from "../../../../../commons/ws/workdayReport/TaskWorkdayReport";
import TaskWorkdayReportExport from "../../../../../commons/ws/workdayReport/TaskWorkdayReportExport";
import Button from "../../../../../components/buttons/Button";
import CardList from "../../../../../components/card/CardList";
import Col from "../../../../../components/Col";
import FormCol from "../../../../../components/form/FormCol";
import FormMultiSelect from "../../../../../components/form/FormMultiSelect";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import ControllerReportCompanyUserActions from "./ControllerReportCompanyUserActions";
import ControllerReportCompanyUserReducer from "./ControllerReportCompanyUserReducer";
import ControllerReportListTable from "./ControllerReportListTable";

const mapStateToProps = ControllerReportCompanyUserReducer.autoMapToProps();
const mapActionsToProps = ControllerReportCompanyUserActions.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

interface State {
    currentCriteria: WorkdayReportCriteria;
    exportLoading: boolean;
}

class ControllerReportCardCompanyUser extends React.Component<Props, State> {
  private cardList;

  public constructor(props: Props) {
    super(props);
    this.state = {
      currentCriteria: {
      },
      exportLoading: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskUserList( {
      limit: 0,
    } ).execute();
  }

  private onControllerSelect = (selectControllerId: string): void => {
    let currentControllerIds: string[] = this.state.currentCriteria.user_ids ? this.state.currentCriteria.user_ids : [];
    const existInCurrentControllersIds: boolean = currentControllerIds.includes(selectControllerId);

    if (existInCurrentControllersIds)
      currentControllerIds = currentControllerIds.filter((id) => id !== selectControllerId);
    else
      currentControllerIds = [...currentControllerIds, selectControllerId];

    this.setState( {
      currentCriteria: {
        ...this.state.currentCriteria,
        user_ids: currentControllerIds.length === 0 || (currentControllerIds.length === 1 && currentControllerIds[0] === "-1") ?
          undefined
          : currentControllerIds,
      },
    } );
  };

  private onStartDateSelect = (date?: Date | null): void => {
    const newCriteria: WorkdayReportCriteria = {
      ...this.state.currentCriteria,
      start_date: date ? date.toISOString() : undefined,
    };

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  private onEndDateSelect = (date?: Date | null): void => {
    const newCriteria: WorkdayReportCriteria = {
      ...this.state.currentCriteria,
    };

    if (date) {
      date.setDate(date.getDate() + 1);
      newCriteria.end_date = date.toISOString();
    } else
      delete newCriteria.end_date;

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  private onExportExcel = (): void => {
    const criteria = this.cardList.getCriteria();

    criteria.limit = 0; // Es necesario reconfigurar el límite para que exporte todos los elementos
    this.setState( {
      exportLoading: true,
    } );
    new TaskWorkdayReportExport(criteria)
      .executeAsPromise()
      .then((response) => {
        if (response.success) {
          this.setState( {
            exportLoading: false,
          } );
          window.open(response.data.url, "_blank");
        }
      } )
      .catch(() => {
        this.setState( {
          exportLoading: false,
        } );
        Alert.error(I18n.tr(I18nKeys.SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_EL_INFORME_DE_CONTROLADORES));
      } );
  };

  public render(): React.ReactNode {
    const {loading, workdayReports, error, pager} = this.props;
    const {exportLoading} = this.state;

    return (
      <CardList ref={(node) => {
        this.cardList = node;

        return node;
      }}
      loading={loading || exportLoading}
      pager={pager}
      title={I18n.tr(I18nKeys.CONTROLADORES)}
      sort={{
        column: "user.name",
      }}
      TaskList={TaskWorkdayReport}
      emptyListOptions={{
        message: I18n.tr(I18nKeys.NO_EXISTEN_INFORMES_DE_CONTROLADORES),
      }}
      data={workdayReports}
      error={error}
      ItemsTable={ControllerReportListTable}
      customCriteria={this.state.currentCriteria}
      >
        {TaskWorkdayReport && this.renderHeaderContent()}
      </CardList>
    );
  }

  private renderHeaderContent = (): React.ReactNode => {
    const {userList, workdayReports} = this.props;
    const userOptions = userList.map((user) => ( {
      value: user.id,
      name: `${user.name} ${user.last_name}`,
    } ));
    const existWordayReports = workdayReports.length !== 0;

    return (
      <>
        <Row>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <div className={"form-line"}>
                <label>{I18n.tr(I18nKeys.CONTROLADOR)}</label>
                <FormMultiSelect
                  options={userOptions}
                  selectHandler={this.onControllerSelect}
                  selectedOptions={this.state.currentCriteria.user_ids || []}
                />
              </div>
            </div>
          </FormCol>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <div className={"form-line"}>
                <label>{I18n.tr(I18nKeys.FECHA_INICIO)}</label>
                <input type={"date"} className="form-control"
                  onChange={(e) => this.onStartDateSelect(e.target.valueAsDate)}/>
              </div>
            </div>
          </FormCol>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <div className={"form-line"}>
                <label>{I18n.tr(I18nKeys.FECHA_FIN)}</label>
                <input type={"date"} className="form-control"
                  onChange={(e) => this.onEndDateSelect(e.target.valueAsDate)}/>
              </div>
            </div>
          </FormCol>
          <Col sm={4} md={3} lg={2} className={"m-t-35"}>
            <Button
              text={I18n.tr(I18nKeys.EXPORTAR_EXCEL)}
              onClick={this.onExportExcel}
              type={"button"}
              block={true}
              className={"btn-lg btn-default btn-export"}
              disabled={!existWordayReports}
            />
          </Col>
        </Row>
      </>
    );
  };
}

export default connect(mapStateToProps, mapActionsToProps)(ControllerReportCardCompanyUser as unknown as React.ComponentType<void>);
