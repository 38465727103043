import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import ChatMessage from "../../model/ChatMessage";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";

interface ChatMessageCustomCriteria {
    start_date: Date | null,
    end_date: Date | null,
}

type ChatMessageCriteriaProps = TaskCriteriaRequest<ChatMessageCustomCriteria>

export default class TaskChatMessageList extends BaseGenericRequest<ChatMessageCriteriaProps, TaskResponse<ChatMessage[]>> {

    private readonly data: ChatMessageCriteriaProps;

    public constructor(criteria: ChatMessageCriteriaProps) {
        super(METHOD.POST, Urls.URL_CHAT_MESSAGE_LIST);
        this.data = criteria;
    }

    protected getRequest(): ChatMessageCriteriaProps {
        return this.data;
    }
}
