import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import Report from '../../../../commons/model/Report';
import TaskReportDetail from '../../../../commons/ws/report/TaskReportDetail';
import RatificationReportDetailsCompanyUserActions from './RatificationReportDetailsCompanyUserActions';
import TaskReportRatifier from '../../../../commons/ws/report/TaskReportRatifier';
import TaskReportRatifierCancel from '../../../../commons/ws/report/TaskReportRatifierCancel';

interface InitialState {
    report: Report | null,
    error: string,
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    report: null,
    error: '',
    loading: false,
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(
        RatificationReportDetailsCompanyUserActions.dispatchResetDetailsReducer,
        () => ( {...INITIAL_STATE} )
    )

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    .onEvent(
        TaskReportDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, loading: true} )
    )
    .onEvent(
        TaskReportDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, loading: false} )
    )
    .onEvent(
        TaskReportDetail.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => ( {...oldState, error: payload} )
    )
    .onEvent(
        TaskReportDetail.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, report: payload.data} )
    )

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    .onEvent(
        TaskReportRatifier.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, loading: true} )
    )
    .onEvent(
        TaskReportRatifier.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, loading: false} )
    )
    .onEvent(
        TaskReportRatifier.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => ( {...oldState, error: payload} )
    )
    .onEvent(
        TaskReportRatifier.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, report: payload.data} )
    )

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    .onEvent(
        TaskReportRatifierCancel.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, loading: true} )
    )
    .onEvent(
        TaskReportRatifierCancel.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, loading: false} )
    )
    .onEvent(
        TaskReportRatifierCancel.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => ( {...oldState, error: payload} )
    )
    .onEvent(
        TaskReportRatifierCancel.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, report: payload.data} )
    )

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    .build();
