import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskDeltaCarDelete from "../../../../commons/ws/deltaCar/TaskDeltaCarDelete";
import TaskDeltaCarList from "../../../../commons/ws/deltaCar/TaskDeltaCarList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_DELTA_CAR_ADD } from "../../../../routing/Routes";
import DeltaCarListCardAdminActions from "./DeltaCarListCompanyUserActions";
import DeltaCarListCardAdminReducer from "./DeltaCarListCompanyUserReducer";
import DeltaCarListTable from "./DeltaCarListTable";

const mapActionsToProps = DeltaCarListCardAdminActions.autoMapToProps();
const mapStateToProps = DeltaCarListCardAdminReducer.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

class DeltaCarListCardCompanyUser extends React.Component<Props> {
  public componentWillUnmount(): void {
    this.props.clearDeltaCarListCardAdminReducer();
  }

  public render(): React.ReactNode {
    const {loading, deltaCars, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.VEHICULOS_EMBARCADOS)}
        sort={{
          column: "name",
        }}
        TaskList={TaskDeltaCarList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTE_NINGUN_VEHICULO_EMBARCADO),
        }}
        data={deltaCars}
        error={error}
        ItemsTable={DeltaCarListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_VEHICULO_EMBARCADO),
          Task: TaskDeltaCarDelete,
        }}
        addItem={ROUTE_COMPANY_USER_DELTA_CAR_ADD}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(DeltaCarListCardCompanyUser as unknown as React.ComponentType<void>);
