import React, { ReactNode } from "react";
import I18n from "../../commons/I18n/I18n";
import Icon from "../Icon";

interface CardCollapsedProps {
    title: string;
    collapsed: boolean;
    collapsedHandler: ()=> void;
    children: ReactNode;
    invalid?: boolean;
    className?: string;
}

export default class CardCollapsed extends React.Component<CardCollapsedProps> {
  public render(): React.ReactNode {
    const {title, collapsedHandler, collapsed, children, invalid, className = ""} = this.props;
    const classError = collapsed && invalid ? "card-collapsed-error" : "";

    return (
      <div className={`fields-card m-b-25 p-t-5 p-b-5 ${classError} ${className}`}>
        <h5 className={"p-t-5 p-b-5"}>{I18n.tr(title)}
          <a onClick={collapsedHandler}>
            <Icon className={"right"} icon={collapsed ? "expand_more" : "expand_less"}/>
          </a>
        </h5>
        <div className={collapsed ? "display-none" : ""}>
          {children}
        </div>
      </div>
    );
  }
}