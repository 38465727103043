
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS } from "../../../../routing/Routes";
import ParkingMeterCollectionListCardCompanyUser from "./ParkingMeterCollectionListCardCompanyUser";

const ParkingMeterCollectionListCompanyUserScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.RECAUDACIONES_PARQUIMETROS),
      url: ROUTE_COMPANY_USER_PARKING_METER_COLLECTIONS,
      icon: AppIcon.PARKING_METER_COLLECTION,
    },
  ]}>
    <ParkingMeterCollectionListCardCompanyUser/>
  </ScreenCard>
);

export default ParkingMeterCollectionListCompanyUserScreen;
