
import { Component, ReactNode } from 'react';
import Icon from '../Icon';
import classNames from 'classnames';

export interface ThProps {
    text: string,
    columnName?: string,
    sort?: {column: string, asc: boolean}
    onSort?: (asc: boolean, column: string) => void,
}

export default class Th extends Component<ThProps> {

    public render(): ReactNode {
        const {columnName, sort} = this.props;
        const classTh = classNames({active: sort && columnName === sort.column});

        return (
            <th className={classTh}>
                {this.renderTh()}
            </th>
        );
    }

    private renderTh(): ReactNode {
        const {
            columnName,
            onSort,
            text,
            sort,
        } = this.props;

        if ( onSort && sort && columnName ) {
            const asc: boolean = columnName === sort.column ? !sort.asc : true;
            const onClick = () => onSort(asc, columnName);

            return (
                <a onClick={onClick}>
                    {text}
                    <Icon icon={'unfold_more'}/>
                </a>
            );
        }

        return text;
    }
}
