
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_USERS } from "../../../../routing/Routes";
import UserListCardAdmin from "./UserListCardAdmin";

const UserListAdminScreen = () =>(
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.USUARIOS),
      url: ROUTE_ADMIN_USERS,
      icon: AppIcon.USER_GROUP,
    },
  ]}>
    <UserListCardAdmin/>
  </ScreenCard>
);
  
export default UserListAdminScreen;
