import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";
import {PagerBackendProps} from "../../../../components/Pager";
import CollectionProcessAddActions from "./CollectionProcessAddActions";
import TaskParkingMeterCollectionList
	from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionList";
import TaskParkingMeterCollectionProcessAdd
	from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessAdd";

interface AddCollectionProcessReducerState {
	parkingMeterCollections: Array<ParkingMeterCollection>,
	loading: boolean,
	errors: string,
	pager: PagerBackendProps
}

const INITIAL_STATE: AddCollectionProcessReducerState = {
	parkingMeterCollections: [],
	loading: false,
	errors: '',
	pager: {
		current: 1,
		total_pages: 1,
		total_elements: 0,
	},
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
	.onEvent(CollectionProcessAddActions.dispatchClearReducer, () => INITIAL_STATE)

	.onEvent(TaskParkingMeterCollectionList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
	.onEvent(TaskParkingMeterCollectionList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
	.onEvent(TaskParkingMeterCollectionList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, errors: payload}))
	.onEvent(TaskParkingMeterCollectionList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) =>
		({...oldState, parkingMeterCollections: payload.data, errors: '', pager: payload.pager || oldState.pager}))

	.onEvent(TaskParkingMeterCollectionProcessAdd.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
		const newState = {...oldState};

		const idsCollectionToRemove = payload.data.parking_meter_collections.map(value => value.id);

		idsCollectionToRemove.forEach(id => {
			newState.parkingMeterCollections = newState.parkingMeterCollections.filter(value => value.id !== id)
		})

		return newState;
	})

	.build()
