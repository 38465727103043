/* eslint-disable max-classes-per-file */
import React from "react";
import { connect } from "react-redux";
import { Field, FormErrors, InjectedFormProps } from "redux-form";
import BaseFormFieldsHelpers from "../../../../../base/form/BaseFormFieldsHelpers";
import I18n from "../../../../../commons/I18n/I18n";
import Exploitation from "../../../../../commons/model/Exploitation";
import CardCollapsed from "../../../../../components/card/CardCollapsed";
import FormDragFile from "../../../../../components/form/FormDragFile";
import FormInput from "../../../../../components/form/FormInput";
import FormSwitch from "../../../../../components/form/FormSwitch";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { formStateAutoMapToPropsFactory } from "../../../../../utils/FormUtils";

const FORM_NAME = "ExploitationPrintReportSectionAdmin";

export interface ExploitationPrintReportSectionAdminData {
    reportPrintImage: string[];
    reportPrintExploitationName: string;
    reportPrintAmount: boolean;
    reportPrintCancellationAmount: boolean;
    reportPrintTimeExceeded: boolean;
    reportPrintTextTitle1: string;
    reportPrintTextTitle2: string;
    reportPrintTextNumber: string;
    reportPrintTextCancellationNumber: string;
    reportPrintTextDate: string;
    reportPrintTextHour: string;
    reportPrintTextVehicle: string;
    reportPrintTextBrand: string;
    reportPrintTextModel: string;
    reportPrintTextColor: string;
    reportPrintTextLicensePlate: string;
    reportPrintTextLimitHour: string;
    reportPrintTextReportedFact: string;
    reportPrintTextPlace: string;
    reportPrintTextPrecept: string;
    reportPrintTextAmount: string;
    reportPrintTextCancellableAmount: string;
    reportPrintTextSign1: string;
    reportPrintTextSign2: string;
    reportPrintTextController: string;
    reportPrintTextRatifier: string;
    reportPrintTextInternalUse: string;
    reportPrintTextDriverTitle: string;
    reportPrintTextDriverNif: string;
    reportPrintTextDriverType: string;
    reportPrintTextDriverName: string;
    reportPrintTextDriverLastName: string;
    reportPrintTextDriverAddress: string;
    reportPrintTextDriverSign: string;
    reportPrintBarCodeReportId: boolean;
    reportPrintBarCodeC60: boolean;
    reportPrintTextBarCodeC60: string;
}

interface ExploitationPrintReportSectionProps {
    className?: string;
    invalid: boolean;
    displayC60Fields: boolean;
    existErrorsHandler: (isValid: boolean)=> void;
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationPrintReportSectionAdminData>(FORM_NAME);
const mapStateToProps = mapFormStateToProps;

type Props =
    ExploitationPrintReportSectionProps & InjectedFormProps<ExploitationPrintReportSectionAdminData> & typeof mapStateToProps;

interface State {
    collapsed: boolean;
}

export enum ExploitationPrintReportSectionFields {
    REPORT_PRINT_IMAGE = "reportPrintImage",
    REPORT_PRINT_EXPLOITATION_NAME = "reportPrintExploitationName",
    REPORT_PRINT_AMOUNT = "reportPrintAmount",
    REPORT_PRINT_CANCELLATION_AMOUNT = "reportPrintCancellationAmount",
    REPORT_PRINT_TIME_EXCEEDED = "reportPrintTimeExceeded",
    REPORT_PRINT_TEXT_TITLE_1 = "reportPrintTextTitle1",
    REPORT_PRINT_TEXT_TITLE_2 = "reportPrintTextTitle2",
    REPORT_PRINT_TEXT_NUMBER = "reportPrintTextNumber",
    REPORT_PRINT_TEXT_CANCELLATION_NUMBER = "reportPrintTextCancellationNumber",
    REPORT_PRINT_TEXT_DATE = "reportPrintTextDate",
    REPORT_PRINT_TEXT_HOUR = "reportPrintTextHour",
    REPORT_PRINT_TEXT_VEHICLE = "reportPrintTextVehicle",
    REPORT_PRINT_TEXT_BRAND = "reportPrintTextBrand",
    REPORT_PRINT_TEXT_MODEL = "reportPrintTextModel",
    REPORT_PRINT_TEXT_COLOR = "reportPrintTextColor",
    REPORT_PRINT_TEXT_LICENSE_PLATE = "reportPrintTextLicensePlate",
    REPORT_PRINT_TEXT_LIMIT_HOUR = "reportPrintTextLimitHour",
    REPORT_PRINT_TEXT_REPORTED_FACT = "reportPrintTextReportedFact",
    REPORT_PRINT_TEXT_PLACE = "reportPrintTextPlace",
    REPORT_PRINT_TEXT_PRECEPT = "reportPrintTextPrecept",
    REPORT_PRINT_TEXT_AMOUNT = "reportPrintTextAmount",
    REPORT_PRINT_TEXT_CANCELLABLE_AMOUNT = "reportPrintTextCancellableAmount",
    REPORT_PRINT_TEXT_SIGN_1 = "reportPrintTextSign1",
    REPORT_PRINT_TEXT_SIGN_2 = "reportPrintTextSign2",
    REPORT_PRINT_TEXT_CONTROLLER = "reportPrintTextController",
    REPORT_PRINT_TEXT_RATIFIER = "reportPrintTextRatifier",
    REPORT_PRINT_TEXT_INTERNAL_USE = "reportPrintTextInternalUse",
    REPORT_PRINT_TEXT_DRIVER_TITLE = "reportPrintTextDriverTitle",
    REPORT_PRINT_TEXT_DRIVER_NIF = "reportPrintTextDriverNif",
    REPORT_PRINT_TEXT_DRIVER_TYPE = "reportPrintTextDriverType",
    REPORT_PRINT_TEXT_DRIVER_NAME = "reportPrintTextDriverName",
    REPORT_PRINT_TEXT_DRIVER_LAST_NAME = "reportPrintTextDriverLastName",
    REPORT_PRINT_TEXT_DRIVER_ADDRESS = "reportPrintTextDriverAddress",
    REPORT_PRINT_TEXT_DRIVER_SIGN = "reportPrintTextDriverSign",
    REPORT_PRINT_BAR_CODE_REPORT_ID = "reportPrintBarCodeReportId",
    REPORT_PRINT_BAR_CODE_C60 = "reportPrintBarCodeC60",
    REPORT_PRINT_TEXT_BAR_CODE_C60 = "reportPrintTextBarCodeC60",
}

class ExploitationPrintReportSectionAdmin extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  public render(): React.ReactNode {
    const {invalid, className, valid, existErrorsHandler, displayC60Fields} = this.props;
    const {collapsed} = this.state;
    const fileTypes = [".jpg", ".jpeg", ".png", ".raw", ".ico"];

    existErrorsHandler(valid);

    return (

      <CardCollapsed title={I18n.tr(I18nKeys.IMPRESION_DENUNCIA)}
        collapsedHandler={this.onCollapsedCard}
        collapsed={collapsed}
        invalid={invalid}
        className={className}
      >
        <Row>
          <Field
            label={I18n.tr(I18nKeys.ICONO_PARA_IMPRESION)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_IMAGE}
            component={FormDragFile}
            fileTypes={fileTypes}
            col={"lg"}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.NOMBRE_DE_LA_EXPLOTACION_PARA_TICKET)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_EXPLOITATION_NAME}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.IMPRIMIR_IMPORTE)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_AMOUNT}
            component={FormSwitch}
            col={{
              md: 3,
              lg: 3,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.IMPRIMIR_IMPORTE_REDUCIDO)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            component={FormSwitch}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_CANCELLATION_AMOUNT}
            col={{
              md: 3,
              lg: 3,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.IMPRIMIR_TIEMPO_EXCEDIDO)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TIME_EXCEEDED}
            component={FormSwitch}
            col={{
              md: 3,
              lg: 3,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.TITULO_1)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_TITLE_1}
            component={FormInput}
            col={{
              md: 6,
              lg: 6,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.TITULO_2)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_TITLE_2}
            component={FormInput}
            col={{
              md: 6,
              lg: 6,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.NUMERO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_NUMBER}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.NUMERO_CANCELACION)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_CANCELLATION_NUMBER}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.FECHA)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_DATE}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.HORA)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_HOUR}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.VEHICULO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_VEHICLE}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.MARCA)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_BRAND}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.MODELO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_MODEL}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.COLOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_COLOR}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.MATRICULA)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_LICENSE_PLATE}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.HORA_LIMITE)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_LIMIT_HOUR}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.HECHO_DENUNCIADO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_REPORTED_FACT}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.LUGAR_DE_LA_DENUNCIA)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_PLACE}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.PRECEPTO_INFRINGIDO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_PRECEPT}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.IMPORTE)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_AMOUNT}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.IMPORTE_REDUCIDO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_CANCELLABLE_AMOUNT}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.FIRMA_1)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_SIGN_1}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.FIRMA_2)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_SIGN_2}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.CONTROLADOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_CONTROLLER}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.ADVERADOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_RATIFIER}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.USO_INTERNO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_INTERNAL_USE}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.TITULO_DATOS_CONDUCTOR_DENUNCIADO)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_DRIVER_TITLE}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.NIF_CONDUCTOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_DRIVER_NIF}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.TIPO_CONDUCTOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_DRIVER_TYPE}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.NOMBRE_CONDUCTOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_DRIVER_NAME}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.APELLIDOS_CONDUCTOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_DRIVER_LAST_NAME}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.DIRECCION_CONDUCTOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_DRIVER_ADDRESS}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.FIRMA_CONDUCTOR)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_DRIVER_SIGN}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.ETIQUETA_CODIGO_DE_BARRAS_C60)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_TEXT_BAR_CODE_C60}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.IMPRIMIR_CODIGO_DE_BARRAS_NUM_DENUNCIA)}
            name={ExploitationPrintReportSectionFields.REPORT_PRINT_BAR_CODE_REPORT_ID}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            component={FormSwitch}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          {
            displayC60Fields
              ? (
                <Field
                  label={I18n.tr(I18nKeys.IMPRIMIR_CODIGO_DE_BARRAS_C60)}
                  name={ExploitationPrintReportSectionFields.REPORT_PRINT_BAR_CODE_C60}
                  labelEnabled={I18n.tr(I18nKeys.SI)}
                  labelDisabled={I18n.tr(I18nKeys.NO)}
                  component={FormSwitch}
                  col={{
                    md: 4,
                    lg: 4,
                  }}
                />
              )
              : null
          }
        </Row>
      </CardCollapsed>
    );
  }

  private onCollapsedCard = () => {
    this.setState( {
      collapsed: !this.state.collapsed,
    } );
  };
}

// @ts-ignore
export default connect(mapStateToProps)(ExploitationPrintReportSectionAdmin) as React.ComponentType<ExploitationPrintReportSectionProps>;

export class ExploitationPrintReportSectionAdminHelpers extends BaseFormFieldsHelpers {
  public static validate(values: ExploitationPrintReportSectionAdminData) {
    const errors: FormErrors<ExploitationPrintReportSectionAdminData> = {
    };

    if (!values.reportPrintImage || values.reportPrintImage.length === 0)
      errors.reportPrintImage = I18n.tr(I18nKeys.ICONO_OBLIGATORIO);

    if (!values.reportPrintExploitationName || values.reportPrintExploitationName.length === 0)
      errors.reportPrintExploitationName = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

    if (!values.reportPrintTextTitle1 || values.reportPrintTextTitle1.length === 0)
      errors.reportPrintTextTitle1 = I18n.tr(I18nKeys.TITULO_OBLIGATORIO);

    if (!values.reportPrintTextTitle2 || values.reportPrintTextTitle2.length === 0)
      errors.reportPrintTextTitle2 = I18n.tr(I18nKeys.TITULO_OBLIGATORIO);

    if (!values.reportPrintTextNumber || values.reportPrintTextNumber.length === 0)
      errors.reportPrintTextNumber = I18n.tr(I18nKeys.NUMERO_OBLIGATORIO);

    if (!values.reportPrintTextCancellationNumber || values.reportPrintTextCancellationNumber.length === 0)
      errors.reportPrintTextCancellationNumber = I18n.tr(I18nKeys.NUMERO_CANCELACION_OBLIGATORIO);

    if (!values.reportPrintTextDate || values.reportPrintTextDate.length === 0)
      errors.reportPrintTextDate = I18n.tr(I18nKeys.FECHA_OBLIGATORIA);

    if (!values.reportPrintTextHour || values.reportPrintTextHour.length === 0)
      errors.reportPrintTextHour = I18n.tr(I18nKeys.HORA_OBLIGATORIA);

    if (!values.reportPrintTextVehicle || values.reportPrintTextVehicle.length === 0)
      errors.reportPrintTextVehicle = I18n.tr(I18nKeys.VEHICULO_OBLIGATORIO);

    if (!values.reportPrintTextBrand || values.reportPrintTextBrand.length === 0)
      errors.reportPrintTextBrand = I18n.tr(I18nKeys.MARCA_OBLIGATORIA);

    if (!values.reportPrintTextModel || values.reportPrintTextModel.length === 0)
      errors.reportPrintTextModel = I18n.tr(I18nKeys.MODELO_OBLIGATORIO);

    if (!values.reportPrintTextColor || values.reportPrintTextColor.length === 0)
      errors.reportPrintTextColor = I18n.tr(I18nKeys.COLOR_OBLIGATORIO);

    if (!values.reportPrintTextLicensePlate || values.reportPrintTextLicensePlate.length === 0)
      errors.reportPrintTextLicensePlate = I18n.tr(I18nKeys.MATRICULA_OBLIGATORIA);

    if (!values.reportPrintTextLimitHour || values.reportPrintTextLimitHour.length === 0)
      errors.reportPrintTextLimitHour = I18n.tr(I18nKeys.HORA_LIMITE_OBLIGATORIA);

    if (!values.reportPrintTextReportedFact || values.reportPrintTextReportedFact.length === 0)
      errors.reportPrintTextReportedFact = I18n.tr(I18nKeys.HECHO_DENUNCIADO_OBLIGATORIO);

    if (!values.reportPrintTextPlace || values.reportPrintTextPlace.length === 0)
      errors.reportPrintTextPlace = I18n.tr(I18nKeys.HECHO_DENUNCIADO_OBLIGATORIO);

    if (!values.reportPrintTextPrecept || values.reportPrintTextPrecept.length === 0)
      errors.reportPrintTextPrecept = I18n.tr(I18nKeys.PRECEPTO_INFRINGIDO_OBLIGATORIO);

    if (!values.reportPrintTextAmount || values.reportPrintTextAmount.length === 0)
      errors.reportPrintTextAmount = I18n.tr(I18nKeys.IMPORTE_OBLIGATORIO);

    if (!values.reportPrintTextCancellableAmount || values.reportPrintTextCancellableAmount.length === 0)
      errors.reportPrintTextCancellableAmount = I18n.tr(I18nKeys.IMPORTE_REDUCIDO_OBLIGATORIO);

    if (!values.reportPrintTextSign1 || values.reportPrintTextSign1.length === 0)
      errors.reportPrintTextSign1 = I18n.tr(I18nKeys.FIRMA_1_OBLIGATORIA);

    if (!values.reportPrintTextSign2 || values.reportPrintTextSign2.length === 0)
      errors.reportPrintTextSign2 = I18n.tr(I18nKeys.FIRMA_2_OBLIGATORIA);

    if (!values.reportPrintTextController || values.reportPrintTextController.length === 0)
      errors.reportPrintTextController = I18n.tr(I18nKeys.CONTROLADOR_OBLIGATORIO);

    if (!values.reportPrintTextRatifier || values.reportPrintTextRatifier.length === 0)
      errors.reportPrintTextRatifier = I18n.tr(I18nKeys.ADVERADOR_OBLIGATORIO);

    if (!values.reportPrintTextInternalUse || values.reportPrintTextInternalUse.length === 0)
      errors.reportPrintTextInternalUse = I18n.tr(I18nKeys.USO_INTERNO_OBLIGATORIO);

    if (!values.reportPrintTextDriverTitle || values.reportPrintTextDriverTitle.length === 0)
      errors.reportPrintTextDriverTitle = I18n.tr(I18nKeys.TITULO_DATOS_CONDUCTOR_DENUNCIADO_OBLIGATORIO);

    if (!values.reportPrintTextDriverNif || values.reportPrintTextDriverNif.length === 0)
      errors.reportPrintTextDriverNif = I18n.tr(I18nKeys.NIF_CONDUCTOR_OBLIGATORIO);

    if (!values.reportPrintTextDriverType || values.reportPrintTextDriverType.length === 0)
      errors.reportPrintTextDriverType = I18n.tr(I18nKeys.TIPO_CONDUCTOR_OBLIGATORIO);

    if (!values.reportPrintTextDriverName || values.reportPrintTextDriverName.length === 0)
      errors.reportPrintTextDriverName = I18n.tr(I18nKeys.NOMBRE_CONDUCTOR_OBLIGATORIO);

    if (!values.reportPrintTextDriverLastName || values.reportPrintTextDriverLastName.length === 0)
      errors.reportPrintTextDriverLastName = I18n.tr(I18nKeys.APELLIDOS_CONDUCTOR_OBLIGATORIO);

    if (!values.reportPrintTextDriverAddress || values.reportPrintTextDriverAddress.length === 0)
      errors.reportPrintTextDriverAddress = I18n.tr(I18nKeys.DIRECCION_CONDUCTOR_OBLIGATORIO);

    if (!values.reportPrintTextDriverSign || values.reportPrintTextDriverSign.length === 0)
      errors.reportPrintTextDriverSign = I18n.tr(I18nKeys.FIRMA_CONDUCTOR_OBLIGATORIO);

    if (!values.reportPrintTextBarCodeC60 || values.reportPrintTextBarCodeC60.length === 0)
      errors.reportPrintTextBarCodeC60 = I18n.tr(I18nKeys.CAMPO_OBLIGATORIO);

    return errors;
  }

  public static getFormData(formData: Partial<Exploitation>): ExploitationPrintReportSectionAdminData {
    return {
      reportPrintImage: formData.report_print_image ? [formData.report_print_image] : [],
      reportPrintExploitationName: formData.report_print_exploitation_name ? formData.report_print_exploitation_name : "",
      reportPrintAmount: formData.report_print_amount ? formData.report_print_amount : false,
      reportPrintCancellationAmount: formData.report_print_cancellation_amount ? formData.report_print_cancellation_amount : false,
      reportPrintTimeExceeded: formData.report_print_time_exceeded ? formData.report_print_time_exceeded : false,
      reportPrintTextTitle1: formData.report_print_text_title_1 ? formData.report_print_text_title_1 : "",
      reportPrintTextTitle2: formData.report_print_text_title_2 ? formData.report_print_text_title_2 : "",
      reportPrintTextNumber: formData.report_print_text_number ? formData.report_print_text_number : "",
      reportPrintTextCancellationNumber: formData.report_print_text_cancellation_number ? formData.report_print_text_cancellation_number : "",
      reportPrintTextDate: formData.report_print_text_date ? formData.report_print_text_date : "",
      reportPrintTextHour: formData.report_print_text_hour ? formData.report_print_text_hour : "",
      reportPrintTextVehicle: formData.report_print_text_vehicle ? formData.report_print_text_vehicle : "",
      reportPrintTextBrand: formData.report_print_text_brand ? formData.report_print_text_brand : "",
      reportPrintTextModel: formData.report_print_text_model ? formData.report_print_text_model : "",
      reportPrintTextColor: formData.report_print_text_color ? formData.report_print_text_color : "",
      reportPrintTextLicensePlate: formData.report_print_text_license_plate ? formData.report_print_text_license_plate : "",
      reportPrintTextLimitHour: formData.report_print_text_limit_hour ? formData.report_print_text_limit_hour : "",
      reportPrintTextReportedFact: formData.report_print_text_reported_fact ? formData.report_print_text_reported_fact : "",
      reportPrintTextPlace: formData.report_print_text_place ? formData.report_print_text_place : "",
      reportPrintTextPrecept: formData.report_print_text_precept ? formData.report_print_text_precept : "",
      reportPrintTextAmount: formData.report_print_text_amount ? formData.report_print_text_amount : "",
      reportPrintTextCancellableAmount: formData.report_print_text_cancellable_amount ? formData.report_print_text_cancellable_amount : "",
      reportPrintTextSign1: formData.report_print_text_sign_1 ? formData.report_print_text_sign_1 : "",
      reportPrintTextSign2: formData.report_print_text_sign_2 ? formData.report_print_text_sign_2 : "",
      reportPrintTextController: formData.report_print_text_controller ? formData.report_print_text_controller : "",
      reportPrintTextRatifier: formData.report_print_text_ratifier ? formData.report_print_text_ratifier : "",
      reportPrintTextInternalUse: formData.report_print_text_internal_use ? formData.report_print_text_internal_use : "",
      reportPrintTextDriverTitle: formData.report_print_text_driver_title ? formData.report_print_text_driver_title : "",
      reportPrintTextDriverNif: formData.report_print_text_driver_nif ? formData.report_print_text_driver_nif : "",
      reportPrintTextDriverType: formData.report_print_text_driver_type ? formData.report_print_text_driver_type : "",
      reportPrintTextDriverName: formData.report_print_text_driver_name ? formData.report_print_text_driver_name : "",
      reportPrintTextDriverLastName: formData.report_print_text_driver_last_name ? formData.report_print_text_driver_last_name : "",
      reportPrintTextDriverAddress: formData.report_print_text_driver_address ? formData.report_print_text_driver_address : "",
      reportPrintTextDriverSign: formData.report_print_text_driver_sign ? formData.report_print_text_driver_sign : "",
      reportPrintBarCodeReportId: formData.report_print_bar_code_report_id ? formData.report_print_bar_code_report_id : false,
      reportPrintBarCodeC60: formData.report_print_bar_code_c60 ? formData.report_print_bar_code_c60 : false,
      reportPrintTextBarCodeC60: formData.report_print_text_bar_code_c60 ? formData.report_print_text_bar_code_c60 : "",
    };
  }
}
