import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import ParkingMeterCollection from "../../model/ParkingMeterCollection";

interface ParkingMeterCollectionCustomCriteria {
    start_date?: string,
    end_date?: string,
    controller_ids?: string[],
    parking_meter_ids?: string[],
    not_in_collection_process?: boolean
}

export type ParkingMeterCollectionCriteriaProps = TaskCriteriaRequest<ParkingMeterCollectionCustomCriteria>

export default class TaskParkingMeterCollectionList extends BaseGenericRequest<ParkingMeterCollectionCriteriaProps, TaskResponse<ParkingMeterCollection[]>> {

    private readonly data: ParkingMeterCollectionCriteriaProps;

    public constructor(criteria: ParkingMeterCollectionCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_METER_COLLECTION_LIST);
        this.data = criteria;
    }

    protected getRequest(): ParkingMeterCollectionCriteriaProps {
        return this.data;
    }

}
