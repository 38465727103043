
import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import TaskReportDetail from "../../../../commons/ws/report/TaskReportDetail";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import { ROUTE_COMPANY_USER_REPORTS, ROUTE_COMPANY_USER_REPORT_DETAIL } from "../../../../routing/Routes";
import { loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import ReportDetailsCompanyUserActions from "./ReportDetailsCompanyUserActions";
import ReportDetailsCompanyUserCard from "./ReportDetailsCompanyUserCard";
import ReportDetailsCompanyUserReducer from "./ReportDetailsCompanyUserReducer";

const mapActionsToProps = ReportDetailsCompanyUserActions.autoMapToProps();
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  ReportDetailsCompanyUserReducer.autoMapToProps(),
  CurrentUserReducer.autoMapToProps(),
);

type Props = RouteComponentProps<{id: string}> & typeof mapActionsToProps & typeof mapStateToProps;

class ReportDetailsCompanyUserScreen extends React.Component<Props> {
  public componentDidMount(): void {
    const {id} = this.props.match.params;

    if (id) {
      // @ts-ignore
      loadItemDetailsOrGoBack(new TaskReportDetail(id), this.props);
    }
  }

  public componentWillUnmount(): void {
    this.props.dispatchResetDetailsReducer();
  }

  public render(): React.ReactNode {
    const {isComplaintViewerSent, report, loading, isComplaintViewer, isPolice} = this.props;
    const reportId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(isComplaintViewerSent ? I18nKeys.DENUNCIAS_ENVIADAS : I18nKeys.DENUNCIAS),
      url: ROUTE_COMPANY_USER_REPORTS,
      icon: AppIcon.REPORT,
    }];

    if (reportId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.DETALLES_DENUNCIA),
        url: ROUTE_COMPANY_USER_REPORT_DETAIL,
        icon: AppIcon.REPORT,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        <ReportDetailsCompanyUserCard
          report={report}
          loading={loading}
          backRoute={ROUTE_COMPANY_USER_REPORTS}
          isComplaintViewerSent={isComplaintViewerSent}
          isComplaintViewer={isComplaintViewer}
          isPolice={isPolice}
        />
      </ScreenCard>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportDetailsCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
