import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { generateRoute } from '../../utils/Router';
import ReportInvalidationReason, { ReportInvalidationReasonData } from '../../model/ReportInvalidationReason';

export default class TaskReportInvalidationReasonEdit extends BaseGenericRequest<ReportInvalidationReasonData, TaskResponse<ReportInvalidationReason>> {

    private readonly data: ReportInvalidationReasonData;

    public constructor(id: string, data: ReportInvalidationReasonData) {
        super(METHOD.PUT, generateRoute(Urls.URL_REPORT_INVALIDATION_REASON_EDIT, {uuid: id}));
        this.data = data;
    }

    protected getRequest(): ReportInvalidationReasonData {
        return this.data;
    }
}
