import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_BRANDS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import VehicleBrandFormScreenAdminReducer from "../formscreen/VehicleBrandFormScreenAdminReducer";

const FORM_NAME = "VehicleBrandFormAdmin";

export interface VehicleBrandAdminData {
    name: string;
    order: number;
    alziraCode: string;
}

interface VehicleBrandCardFormAdminProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: VehicleBrandAdminData)=> void;
    initialValues?: Partial<VehicleBrandCardFormAdminProps>;
    parentLoading?: boolean;
}

const mapStateToProps = VehicleBrandFormScreenAdminReducer.autoMapToProps();

type Props =
    InjectedFormProps<VehicleBrandCardFormAdminProps> & VehicleBrandCardFormAdminProps & typeof mapStateToProps;

enum VehicleBrandFormAdminFields {
    NAME = "name",
    ORDER = "order",
    ALZIRA_CODE = "alziraCode",
}

class VehicleBrandCardFormAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, vehicleBrandLoading, pristine, parentLoading} = this.props;

    return (
      <Card loading={parentLoading || vehicleBrandLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.MARCA_DE_VEHICULO : I18nKeys.NUEVA_MARCA_DE_VEHICULO)}/>
        <CardBody>
          {/*
                    // @ts-ignore */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={VehicleBrandFormAdminFields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.ORDEN)}
                name={VehicleBrandFormAdminFields.ORDER}
                component={FormInput}
                col={{
                  md: 2,
                  lg: 1,
                }}
                type={"number"}
              />
              <Field
                label={I18n.tr(I18nKeys.CODIGO_ALZIRA)}
                name={VehicleBrandFormAdminFields.ALZIRA_CODE}
                component={FormInput}
                col={{
                  md: 2,
                  lg: 1,
                }}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_ADMIN_VEHICLE_BRANDS)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: VehicleBrandAdminData) {
  const errors: FormErrors<VehicleBrandAdminData> = {
  };

  if (!values.name || values.name.length === 0)
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  if (!values.order)
    errors.order = I18n.tr(I18nKeys.ORDEN_OBLIGATORIO);
  else if (values.order < 0 || values.order > 5)
    errors.order = I18n.tr(I18nKeys.EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5);

  return errors;
}

export default compose(
  reduxForm<VehicleBrandAdminData, VehicleBrandCardFormAdminProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(VehicleBrandCardFormAdmin);
