import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import LawType from "../../model/LawType";

type LawTypeCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskLawTypeList extends BaseGenericRequest<LawTypeCriteriaProps, TaskResponse<LawType[]>> {

    private readonly data: LawTypeCriteriaProps;

    public constructor(criteria: LawTypeCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_LAW_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): LawTypeCriteriaProps {
        return this.data;
    }

}
