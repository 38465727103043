
import {Component, CSSProperties, ReactNode} from 'react';
import Col from '../Col';
import DropDown, { DropDownOption } from '../dropdown/DropDown';
import Icon from '../Icon';
import { goToRoute } from '../../utils/Router';

export interface CardHeaderProps {
    title: string,
    subtitle?: string,
    searchText?: string,
    options?: DropDownOption[],
    onSearchChange?: (text: string) => void,
    onRefresh?: () => void,
    onAdd?: () => void,
    onAssign?: () => void,
    onSend?: () => void,
    disabledSendButton?: boolean,
    children?: ReactNode,
    otherActions?: ReactNode,
    titleRoute?: string,
    optionIdSelected?: number,
}

export default class CardHeader extends Component<CardHeaderProps> {

    public render(): ReactNode {
        const {title, subtitle, children, otherActions, titleRoute} = this.props;
        const titleOptionalProps = titleRoute ?
            {
                onClick: () => goToRoute(titleRoute),
                className: 'title-cursor',
            } : {};

        return (
            <div className="header">
                <div className="row">
                    <Col sm={6}>
                        <h2 {...titleOptionalProps}>{title} {subtitle && <small>{subtitle}</small>}</h2>
                    </Col>
                    <Col sm={6} className={'card-search-filter'}>
                        {this.renderSearcher()}
                        {this.renderRefresh()}
                        {this.renderAssign()}
                        {this.renderAdd()}
                        {this.renderOptions()}
                        {otherActions}
                    </Col>
                </div>
                {children}
            </div>
        );
    }

    private renderSearcher(): ReactNode {
        const {onSearchChange, searchText} = this.props;
        if ( !onSearchChange || searchText === null || searchText === undefined ) {
            return null;
        }
        return (
            <div className="input-group-search m-b-0">
                <div className="form-line form-line-icon">
                    <input type="text" className="form-control date" placeholder="Buscar" value={searchText}
                           onChange={(e) => onSearchChange(e.target.value)}/>

                    <a className="input-group-addon" onClick={() => onSearchChange('')}>
                        {searchText.length > 0 ? <Icon icon={'clear'}/> : null}
                    </a>
                </div>
            </div>
        );
    }

    private renderRefresh(): ReactNode {
        const {onRefresh} = this.props;
        if ( !onRefresh ) {
            return null;
        }
        return (
            <a className={'header-item'} onClick={onRefresh}>
                <Icon icon={'loop'}/>
            </a>
        );
    }

    private renderAssign(): ReactNode {
        const {onAssign} = this.props;
        if ( !onAssign ) {
            return null;
        }
        return (
            <a className={'header-item add'} onClick={onAssign}>
                <Icon className={''} icon={'zoom_in'}/>
            </a>
        );
    }

    private renderAdd(): ReactNode {
        const {onAdd, onSend, disabledSendButton} = this.props;

        const lockedStyle: CSSProperties = {
            cursor: "no-drop"
        };

        if ( !onAdd ) {
            return null;
        }
        return (
            <>
                {
                    onSend ?
                        <a className={'header-item add'}
                           style={disabledSendButton ? lockedStyle : {}}
                           onClick={!disabledSendButton ? onSend : undefined}>
                            <Icon className={'add'} icon={'send'}/>
                        </a>

                        : null
                }

                <a className={'header-item add'} onClick={onAdd}>
                    <Icon className={'add'} icon={'add_circle'}/>
                </a>
            </>
        );
    }

    private renderOptions(): ReactNode {
        const {options, optionIdSelected} = this.props;
        if ( !options ) {
            return null;
        }
        return (
            <DropDown
                className={'header-item'}
                pullRight={true}
                options={options}
                idSelected={optionIdSelected}
            >
                <Icon icon={'more_vert'}/>
            </DropDown>
        );
    }

}

