import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import DeltaCar from "../../model/DeltaCar";

export default class TaskDeltaCarDetail extends BaseGenericRequest<{}, TaskResponse<DeltaCar>> {

    public constructor(deltaCarId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_DELTA_CAR_DETAIL, {uuid: deltaCarId}));
    }
}
