
import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskUserCurrentDetail from "../../../../commons/ws/user/TaskUserCurrentDetail";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import { ROUTE_COMPANY_USER_SENDTOPROCESS, ROUTE_COMPANY_USER_SENDTOPROCESS_FILE } from "../../../../routing/Routes";
import SendToProccessActions from "../SendToProccessActions";
import SendToProccessReducer from "../SendToProccessReducer";
import SendToProcessFileCard from "./SendToProcessFileCard";

const mapActionsToProps = SendToProccessActions.autoMapToProps();
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  SendToProccessReducer.autoMapToProps(),
  CurrentUserReducer.autoMapToProps(),
);

type Props = typeof mapActionsToProps & typeof mapStateToProps;

class ReportDetailsCompanyUserScreen extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  public componentDidMount(): void {
    new TaskUserCurrentDetail().execute();
  }

  public componentWillUnmount(): void {
    this.props.dispatchResetSendToProccessReducer();
  }

  public render(): React.ReactNode {
    const {date, fileUrl, currentUser, loadingCurrentUser} = this.props;
    const breadcrumbs: BreadcrumbItem[] = [
      {
        name: I18n.tr(I18nKeys.ENVIO_A_TRAMITE),
        url: ROUTE_COMPANY_USER_SENDTOPROCESS,
        icon: AppIcon.SEND,
      },
      {
        name: I18n.tr(I18nKeys.FICHERO_DE_ENVIO_A_TRAMITE),
        url: ROUTE_COMPANY_USER_SENDTOPROCESS_FILE,
        icon: AppIcon.ATTACH_FILE,
      },
    ];
    const explotationId = currentUser ? currentUser.exploitation.id : "";

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        <SendToProcessFileCard
          date={date}
          fileUrl={fileUrl}
          loading={loadingCurrentUser}
          explotationId={explotationId}
        />
      </ScreenCard>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportDetailsCompanyUserScreen) as any;
