import React from "react";
import { Field, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Button from "../../../../components/buttons/Button";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import Col from "../../../../components/Col";
import FormInput from "../../../../components/form/FormInput";
import FormTextArea from "../../../../components/form/FormTextArea";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_LOSSES } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";

const FORM_NAME = "ParkingLossCardFormCompanyUser";

export interface ParkingLossCardFormCompanyUserData {
    date: string;
    places: number;
    occupied_hours: number;
    street: string;
    parking_loss_reason: string;
    controller: string;
    observations: string;
}

interface ParkingLossCardFormExtProps {
    parkingMeterCollectionId?: string;
    initialValues?: Partial<ParkingLossCardFormCompanyUserData>;
    parentLoading: boolean;
}

interface ParkingLossCardFormProps {
    initialValues: Partial<ParkingLossCardFormCompanyUserData>;
}

type Props = ParkingLossCardFormExtProps & ParkingLossCardFormProps;

enum ParkingAreaTypeFormAdminFields {
    DATE = "date",
    STREET = "street",
    PLACES = "places",
    OCCUPIED_HOURS = "occupied_hours",
    PARKING_LOSS_REASON = "parking_loss_reason",
    CONTROLLER = "controller",
    OBSERVATIONS = "observations",
}

class ParkingLossCardFormCompanyUser extends React.Component<Props> {
  public render(): React.ReactNode {
    const {parentLoading} = this.props;

    return (
      <Card loading={parentLoading}>
        <CardHeader
          title={I18n.tr(I18nKeys.PLAZA_PERDIDA)}/>
        <CardBody>
          <form>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.FECHA)}
                name={ParkingAreaTypeFormAdminFields.DATE}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
              <Field
                label={I18n.tr(I18nKeys.CALLE)}
                name={ParkingAreaTypeFormAdminFields.STREET}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
              <Field
                label={I18n.tr(I18nKeys.MOTIVO)}
                name={ParkingAreaTypeFormAdminFields.PARKING_LOSS_REASON}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.PLAZAS_PERDIDAS)}
                name={ParkingAreaTypeFormAdminFields.PLACES}
                component={FormInput}
                type={"number"}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
              <Field
                label={I18n.tr(I18nKeys.HORAS)}
                name={ParkingAreaTypeFormAdminFields.OCCUPIED_HOURS}
                component={FormInput}
                type={"number"}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
              <Field
                label={I18n.tr(I18nKeys.CONTROLADOR)}
                name={ParkingAreaTypeFormAdminFields.CONTROLLER}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.OBSERVACIONES)}
                name={ParkingAreaTypeFormAdminFields.OBSERVATIONS}
                component={FormTextArea}
                col={{
                  md: 12,
                  lg: 12,
                }}
                disabled
              />
            </Row>
            <Row className='p-t-20'>
              <Col sm={4} md={6} lg={8}/>
              <Col sm={4} md={3} lg={2}/>
              <Col sm={4} md={3} lg={2}>
                <Button
                  text={I18n.tr(I18nKeys.CANCELAR)}
                  onClick={() => goToRoute(ROUTE_COMPANY_USER_PARKING_LOSSES)}
                  type={"button"}
                  block={true}
                  className={"btn-lg btn-default"}
                />
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default reduxForm<ParkingLossCardFormCompanyUserData, ParkingLossCardFormExtProps>( {
  form: FORM_NAME,
  enableReinitialize: true,
} )(ParkingLossCardFormCompanyUser as any) as React.ComponentType<ParkingLossCardFormExtProps>;
