import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { generateRoute } from '../../utils/Router';
import { DashboardInformation } from '../../model/Dashboard';

export default class TaskDashboardInformation extends BaseGenericRequest<{}, TaskResponse<DashboardInformation>> {

    public constructor() {
        super(METHOD.GET, generateRoute(Urls.URL_DASHBOARD_INFORMATION));
    }

}
