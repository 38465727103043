import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import ReportState from '../../model/ReportState';

export type ReportStateCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskReportStateList extends BaseGenericRequest<ReportStateCriteriaProps, TaskResponse<ReportState[]>> {

    private readonly data: ReportStateCriteriaProps;

    public constructor(criteria: ReportStateCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_REPORT_STATE_LIST);
        this.data = criteria;
    }

    protected getRequest(): ReportStateCriteriaProps {
        return this.data;
    }
}
