import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import ReportInvalidationReasonAdminFormActions from './ReportInvalidationReasonAdminFormActions';
import ReportInvalidationReason from '../../../../commons/model/ReportInvalidationReason';
import TaskReportInvalidationReasonDetail
    from '../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonDetail';

interface InitialState {
    loading: boolean,
    reportInvalidationReason?: ReportInvalidationReason,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    reportInvalidationReason: undefined,
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(
        ReportInvalidationReasonAdminFormActions.clearReducer,
        () => INITIAL_STATE
    )
    .onEvent(
        TaskReportInvalidationReasonDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, loading: true} )
    )
    .onEvent(
        TaskReportInvalidationReasonDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, loading: false} )
    )
    .onEvent(
        TaskReportInvalidationReasonDetail.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, reportInvalidationReason: payload.data} )
    )
    .build();
