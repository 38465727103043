import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import { PagerBackendProps } from "../../../../components/Pager";
import NavBarActions from "./NavBarActions";

interface State {
    loading: boolean;
    alerts: any[];
    error: string;
    pager: PagerBackendProps;
}

const INITIAL_STATE: State = {
  loading: false,
  alerts: [],
  error: "",
  pager: {
    current: 1,
    total_pages: 1,
    total_elements: 0,
  },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
  .onEvent(NavBarActions.clearReducer, (() => INITIAL_STATE))
  .build();
