import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import FormSwitch from "../../../../components/form/FormSwitch";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_AREA_TYPES } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import ParkingAreaTypeCompanyUserFormScreenReducer from "../formscreen/ParkingAreaTypeCompanyUserFormScreenReducer";

const FORM_NAME = "ParkingAreaTypeCardFormAdmin";

export interface ParkingAreaTypeCardFormAdminData {
    name: string;
    enabled: boolean;
}

interface ParkingAreaTypeCardFormAdminProps {
    parkingAreaTypeId?: string;
    readonly?: boolean;
    onSubmit: (data: ParkingAreaTypeCardFormAdminData)=> void;
    initialValues?: Partial<ParkingAreaTypeCardFormAdminData>;
    parentLoading?: boolean;
}

const mapStateToProps = ParkingAreaTypeCompanyUserFormScreenReducer.autoMapToProps();

type Props =
    InjectedFormProps<ParkingAreaTypeCardFormAdminData> & ParkingAreaTypeCardFormAdminProps & typeof mapStateToProps;

enum ParkingAreaTypeFormAdminFields {
    NAME = "name",
    ENABLED = "enabled",
}

class ParkingAreaTypeCompanyUserFormCard extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, parkingAreaTypeFormScreenLoading, pristine} = this.props;

    return (
      <Card loading={parkingAreaTypeFormScreenLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.TIPO_ZONA_APARCAMIENTO : I18nKeys.NUEVO_TIPO_ZONA_APARCAMIENTO)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={ParkingAreaTypeFormAdminFields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.ESTADO)}
                labelEnabled={I18n.tr(I18nKeys.HABILITADA)}
                labelDisabled={I18n.tr(I18nKeys.DESAHABILITADA)}
                name={ParkingAreaTypeFormAdminFields.ENABLED}
                component={FormSwitch}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_PARKING_AREA_TYPES)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: ParkingAreaTypeCardFormAdminData) {
  const errors: FormErrors<ParkingAreaTypeCardFormAdminData> = {
  };

  if (!values.name || values.name.length === 0) 
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);
  
  return errors;
}

export default compose(
  reduxForm<ParkingAreaTypeCardFormAdminData, ParkingAreaTypeCardFormAdminProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(ParkingAreaTypeCompanyUserFormCard);
