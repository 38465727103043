import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { BlacklistRuleData } from "../../../../commons/model/BlacklistRule";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskBlacklistRuleAdd from "../../../../commons/ws/blackListRule/TaskBlacklistRuleAdd";
import TaskBlacklistRuleDetail from "../../../../commons/ws/blackListRule/TaskBlacklistRuleDetail";
import TaskBlacklistRuleEdit from "../../../../commons/ws/blackListRule/TaskBlacklistRuleEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_BLACK_LIST_RULES, ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD, ROUTE_COMPANY_USER_LAW_DETAIL } from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import { Int } from "../../../../utils/StringUtils";
import BlackListRuleCardFormCompanyUser, { BlackListRuleCardFormCompanyUserData } from "../formdetail/BlackListRuleCardFormCompanyUser";
import BlackListRuleFormCompanyUserScreenActions from "./BlackListRuleFormCompanyUserScreenActions";
import BlackListRuleFormScreenCompanyUserReducer from "./BlackListRuleFormScreenCompanyUserReducer";

const mapStateToProps = BlackListRuleFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = BlackListRuleFormCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class BlackListRuleFormCompanyUserScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskBlacklistRuleDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: BlackListRuleCardFormCompanyUserData): void => {
    const existBlackListRule = this.props.match.params.id !== undefined;
    const submitData: BlacklistRuleData = {
      reports: Int(values.reports),
      days: Int(values.days),
      description: values.description,
    };

    if (existBlackListRule) {
      executeItemTask(
        new TaskBlacklistRuleEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_COMPANY_USER_BLACK_LIST_RULES),
        I18n.tr(I18nKeys.CONFIGURACION_DE_LISTA_NEGRA_MODIFICADA_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskBlacklistRuleAdd(submitData),
        () => goToRoute(ROUTE_COMPANY_USER_BLACK_LIST_RULES),
        I18n.tr(I18nKeys.CONFIGURACION_DE_LISTA_NEGRA_CREADA_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const blackListRuleId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.CONFIGURACION_DE_LISTAS_NEGRAS),
      url: ROUTE_COMPANY_USER_BLACK_LIST_RULES,
      icon: AppIcon.BLACK_LIST_RULE,
    }];

    if (blackListRuleId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.CONFIGURACION_LISTA_NEGRA),
        url: generateRoute(ROUTE_COMPANY_USER_LAW_DETAIL, {
          id: blackListRuleId,
        } ),
        icon: AppIcon.BLACK_LIST_RULE,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVA_CONFIGURACION_LISTA_NEGRA),
        url: ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD,
        icon: AppIcon.BLACK_LIST_RULE,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderBlackListRuleForm()}
      </ScreenCard>
    );
  }

  private renderBlackListRuleForm(): React.ReactNode {
    const {blackListRuleLoading, blackListRule} = this.props;
    let initialValues: Partial<BlackListRuleCardFormCompanyUserData> | undefined;

    if (blackListRule) {
      initialValues = {
        reports: blackListRule.reports ? blackListRule.reports.toString() : "0",
        days: blackListRule.days ? blackListRule.days.toString() : "0",
        description: blackListRule.description ? blackListRule.description : "",
      };
    }

    return (
      <BlackListRuleCardFormCompanyUser
        parentLoading={blackListRuleLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)
(BlackListRuleFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
