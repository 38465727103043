import { METHOD } from '@cuatroochenta/co-generic-request';
import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import Urls from '../Urls';
import BlacklistRule, { BlacklistRuleData } from '../../model/BlacklistRule';

export default class TaskBlacklistRuleAdd extends BaseGenericRequest<BlacklistRuleData, TaskResponse<BlacklistRule>> {

    private readonly data: BlacklistRuleData;

    public constructor(data: BlacklistRuleData) {
        super(METHOD.POST, Urls.URL_BLACK_LIST_RULE_ADD);
        this.data = data;
    }

    protected getRequest(): BlacklistRuleData {
        return this.data;
    }
}
