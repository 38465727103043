import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import LogType from "../../model/LogType";

export default class TaskLogTypeList extends BaseGenericRequest<{}, TaskResponse<LogType[]>> {

    public constructor() {
        super(METHOD.GET, Urls.URL_LOG_TYPE_LIST);
    }
}
