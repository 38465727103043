import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_COLLECTION_PROCESS } from "../../../../routing/Routes";
import CollectionProcessAdminDataContainer from "./CollectionProcessAdminDataContainer";

const CollectionProcessAdminScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.PROCESOS_RECAUDACION),
      url: ROUTE_ADMIN_COLLECTION_PROCESS,
      icon: AppIcon.EURO,
    },
  ]}>
    <CollectionProcessAdminDataContainer/>
  </ScreenCard>
);

export default CollectionProcessAdminScreen;