import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import FormTextArea from "../../../../components/form/FormTextArea";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_BLACK_LIST_RULES } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import BlackListRuleListCompanyUserReducer from "../list/BlackListRuleListCompanyUserReducer";

const FORM_NAME = "BlackListRuleCardFormAdmin";

export interface BlackListRuleCardFormCompanyUserData {
    reports: string;
    days: string;
    description: string;
}

interface BlackListRuleCardFormCompanyUserProps {
    readonly?: boolean;
    onSubmit: (data: BlackListRuleCardFormCompanyUserData)=> void;
    initialValues?: Partial<BlackListRuleCardFormCompanyUserData>;
    parentLoading?: boolean;
}

const mapStateToProps = BlackListRuleListCompanyUserReducer.autoMapToProps();

type Props =
    BlackListRuleCardFormCompanyUserProps & InjectedFormProps<BlackListRuleCardFormCompanyUserData> & typeof mapStateToProps;

enum BlackListRuleFormAdminFields {
    REPORTS = "reports",
    DAYS = "days",
    DESCRIPTION = "description",
}

class BlackListRuleCardFormCompanyUser extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, loading, pristine, parentLoading} = this.props;

    return (
      <Card loading={parentLoading || loading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.CONFIGURACION_LISTA_NEGRA : I18nKeys.NUEVA_CONFIGURACION_LISTA_NEGRA)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NUMERO_DE_MULTAS_ACUMULADAS_SIN_ANULAR_NI_INVALIDAR)}
                name={BlackListRuleFormAdminFields.REPORTS}
                component={FormInput}
                type={"number"}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.DIAS_ATRAS_PARA_REALIZAR_LA_MEDICION)}
                name={BlackListRuleFormAdminFields.DAYS}
                component={FormInput}
                type={"number"}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.DESCRIPCION)}
                name={BlackListRuleFormAdminFields.DESCRIPTION}
                component={FormTextArea}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_BLACK_LIST_RULES)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: BlackListRuleCardFormCompanyUserData) {
  const errors: FormErrors<BlackListRuleCardFormCompanyUserData> = {
  };

  if (!values.reports || values.reports.length === 0)
    errors.reports = I18n.tr(I18nKeys.NUMERO_DE_MULTAS_OBLIGATORIO);

  if (!values.days || values.days.length === 0)
    errors.days = I18n.tr(I18nKeys.NUMERO_DE_DIAS_OBLIGATORIO);

  return errors;
}

export default compose(
  reduxForm<BlackListRuleCardFormCompanyUserData, BlackListRuleCardFormCompanyUserProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(BlackListRuleCardFormCompanyUser);
