import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import User, { UserData } from "../../../../commons/model/User";
import { UserRoleValue } from "../../../../commons/model/UserRole";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskUserAdd from "../../../../commons/ws/user/TaskUserAdd";
import TaskUserDetail from "../../../../commons/ws/user/TaskUserDetail";
import TaskUserEdit from "../../../../commons/ws/user/TaskUserEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import {ROUTE_ADMIN_CURRENT_USER_DETAIL, ROUTE_ADMIN_USERS, ROUTE_ADMIN_USER_ADD,
  ROUTE_ADMIN_USER_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import UserCardFormAdmin, { UserCardFormAdminData } from "../formdetail/UserCardFormAdmin";
import UserFormAdminScreenActions from "./UserFormAdminScreenActions";
import UserFormScreenAdminReducer from "./UserFormScreenAdminReducer";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  UserFormScreenAdminReducer.autoMapToProps(),
  CurrentUserReducer.autoMapToProps(),
);
const mapActionsToProps = UserFormAdminScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class UserFormAdminScreen extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id) {
      // @ts-ignore
      loadItemDetailsOrGoBack(new TaskUserDetail(id), this.props);
    }
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: UserCardFormAdminData): void => {
    const userId = this.props.match.params.id;
    // TODO: incluir recognition_sw_license
    // @ts-ignore
    let submitData: UserData = {
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      username: values.username,
      nif: values.nif ? values.nif : undefined,
      suma_code: values.suma_code,
      recognition_sw_license: values.recognition_sw_license ? values.recognition_sw_license : null,
      color: values.color,
      role_ids: values.role_ids,
      user_type_ids: values.role_ids.includes(UserRoleValue.USER_APP) ?
        values.user_type_ids
        : [],
    };

    if (values.exploitation_id) {
      submitData = {
        ...submitData,
        exploitation_id: values.exploitation_id,
      };
    }

    if (userId) {
      executeItemTask(
        new TaskUserEdit(userId, submitData),
        () => goToRoute(ROUTE_ADMIN_USERS),
        I18n.tr(I18nKeys.USUARIO_MODIFICADO_CORRECTAMENTE),
      );
    } else {
      submitData = {
        ...submitData,
        password: values.password,
      };
      executeItemTask(new TaskUserAdd(submitData),
        () => goToRoute(ROUTE_ADMIN_USERS),
        I18n.tr(I18nKeys.USUARIO_CREADO_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const userId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.USUARIOS),
      url: ROUTE_ADMIN_USERS,
      icon: AppIcon.USER,
    }];

    if (userId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.USUARIO),
        url: generateRoute(ROUTE_ADMIN_USER_DETAIL, {
          id: userId,
        } ),
        icon: AppIcon.USER,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVO_USUARIO),
        url: ROUTE_ADMIN_USER_ADD,
        icon: AppIcon.USER,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderUserForm()}
      </ScreenCard>
    );
  }

  private renderUserForm(): React.ReactNode {
    const {userFormScreenLoading, user, match, isAdmin} = this.props;
    let initialValues: UserCardFormAdminData | undefined;
    let userId: string | undefined;

    if (user) {
      const userData: User = user;

      initialValues = {
        name: userData.name ? userData.name : "",
        last_name: userData.last_name ? userData.last_name : "",
        email: userData.email ? userData.email : "",
        password: userData.password ? userData.password : "",
        username: userData.username ? userData.username : "",
        nif: userData.nif ? userData.nif : "",
        suma_code: userData.suma_code ? userData.suma_code : "",
        recognition_sw_license: userData.recognition_sw_license ? userData.recognition_sw_license : "",
        color: userData.color ? userData.color : "",
        exploitation_id: userData.exploitation ? userData.exploitation.id : undefined,
        role_ids: userData.roles ? userData.roles.map((role) => role.id) : [],
        user_type_ids: userData.user_types ? userData.user_types.map((userType) => userType.id) : [],
        user_cert: userData.user_cert ? userData.user_cert : undefined,
      };
      userId = this.props.match.params.id ? this.props.match.params.id : "";
    }

    return (
      <UserCardFormAdmin
        userId={userId}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        isCurrentUser={match.path === ROUTE_ADMIN_CURRENT_USER_DETAIL}
        parentLoading={userFormScreenLoading}
        needsPassword={!user}
        currentUserIsAdmin={isAdmin}
      />);
  }
}

export default connect(mapStateToProps, mapActionsToProps)(UserFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
