
import React, { ReactNode } from "react";
import I18n from "../../../../commons/I18n/I18n";
import { NewButton } from "../../../../components-new/Button";
import { Card } from "../../../../components-new/Card";
import { CardHeader } from "../../../../components-new/CardHeader";
import CardBody from "../../../../components/card/CardBody";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_SENDTOPROCESS } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { goToRoute } from "../../../../utils/Router";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface Props {
    date: Date | null;
    fileUrl: string;
    explotationId: string;
    loading: boolean;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default class SendToProcessFileCard extends React.Component<Props> {
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  public render(): ReactNode {
    const {date, fileUrl, explotationId, loading} = this.props;
    const hasData: boolean = !!date && !!fileUrl && !!explotationId;

    return (
      <div className={"card-send-to-process-file"}>
        <Card loading={loading}>
          <CardHeader title={I18n.tr(I18nKeys.FICHERO_DE_ENVIO_A_TRAMITE)}>
          </CardHeader>
          <CardBody>
            {
              hasData ? this.renderDataText() : renderNoDataText()
            }
            <NewButton
              style={{
                gridArea: "button",
              }}
              label={I18n.tr(I18nKeys.VOLVER)}
              disabled={false}
              type={"btn-filled"}
              onClick={handleGoBack}
            />
          </CardBody>
        </Card>
      </div>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private renderDataText = () => {
    const {date, explotationId} = this.props;
    const textMarginStyle = {
      margin: "0 5px",
    };

    return (
      <div style={{
        gridArea: "text",
        display: "flex",
        flexFlow: "row wrap",
      }}>
        <div>
          {I18n.tr("Se ha generado el")}
        </div>
        <a style={textMarginStyle} onClick={this.handleGetFile}>
          {I18n.tr("fichero")}
        </a>
        <div>
          {I18n.tr("de suma para la empresa")}
        </div>
        <div style={{
          ...textMarginStyle,
          fontWeight: "bold",
        }}>
          {explotationId}
        </div>
        <div>
          {I18n.tr("con fecha")}
        </div>
        <div style={{
          ...textMarginStyle,
          fontWeight: "bold",
        }}>
          {DateFormatter.formatDate(date!)}
        </div>
      </div>
    );
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleGetFile = () => {
    const {fileUrl} = this.props;

    window.open(fileUrl, "_blank");
  };
}
const handleGoBack = () => {
  goToRoute(ROUTE_COMPANY_USER_SENDTOPROCESS);
};
const renderNoDataText = () => (
  <div style={{
    gridArea: "text",
  }}>
    {I18n.tr("No se ha generado ningún fichero.")}
  </div>
);
