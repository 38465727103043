/* eslint-disable max-classes-per-file */
import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import { Component, ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import { Field, FormErrors, InjectedFormProps } from "redux-form";
import BaseFormFieldsHelpers from "../../../../../base/form/BaseFormFieldsHelpers";
import I18n from "../../../../../commons/I18n/I18n";
import Exploitation from "../../../../../commons/model/Exploitation";
import TaskManagementProcessingTypeList from "../../../../../commons/ws/managementProcessingType/TaskManagementProcessingTypeList";
import TaskParkingMeterTypeList from "../../../../../commons/ws/parkingMeterType/TaskParkingMeterTypeList";
import CardCollapsed from "../../../../../components/card/CardCollapsed";
import FormInput from "../../../../../components/form/FormInput";
import FormInputOption from "../../../../../components/form/FormInputOption";
import FormSelect from "../../../../../components/form/FormSelect";
import FormSwitch from "../../../../../components/form/FormSwitch";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { formStateAutoMapToPropsFactory } from "../../../../../utils/FormUtils";
import ExploitationReportCodeSectionAdminActions from "./ExploitationReportCodeSectionAdminActions";
import ExploitationReportCodeSectionAdminReducer from "./ExploitationReportCodeSectionAdminReducer";

const FORM_NAME = "ExploitationReportCodeSectionAdmin";

export interface ExploitationReportCodeSectionAdminData {
    codeSuma: string;
    provinceCodeSuma: string;
    townCodeSuma: string;
    parkingMeterTypeId: string;
    cancellationParkingMeterTypeId: string;
    managementProcessingTypeId: string;
    exportNumSuma?: number;
    ratification: boolean;
    remoteRatification: boolean;
    reportIdentification: boolean;
    digitalSign: boolean;
    generateC60: boolean;
    cancellationProcessManagement: boolean;
    c60ApplicationIdentifier: string;
    c60FormatType: string;
    c60EmitterProvince: string;
    c60EmitterMunicipality: string;
    c60EmitterControlDigit: string;
    c60Tax: string;
    c60PaymentDaysTerm?: number;
}

interface ExploitationReportCodeSectionProps {
    className?: string;
    invalid: boolean;
    displayC60Fields: boolean;
    existErrorsHandler: (isValid: boolean)=> void;
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationReportCodeSectionAdminData>(FORM_NAME);
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  ExploitationReportCodeSectionAdminReducer.autoMapToProps(),
  mapFormStateToProps,
);
const mapActionsToProps = ExploitationReportCodeSectionAdminActions.autoMapToProps();

type Props =
    ExploitationReportCodeSectionProps & InjectedFormProps<ExploitationReportCodeSectionAdminData> & typeof mapStateToProps;

interface State {
    collapsed: boolean;
}

export enum ExploitationReportCodeSectionFields {
    CODE_SUMA = "codeSuma",
    PROVINCE_CODE_SUMA = "provinceCodeSuma",
    TOWN_CODE_SUMA = "townCodeSuma",
    PARKING_METER_TYPE_ID = "parkingMeterTypeId",
    CANCELLATION_PARKING_METER_TYPE_ID = "cancellationParkingMeterTypeId",
    MANAGEMENT_PROCESSING_TYPE_ID = "managementProcessingTypeId",
    EXPORT_NUM_SUMA = "exportNumSuma",
    RATIFICATION = "ratification",
    REMOTE_RATIFICATION = "remoteRatification",
    REPORT_IDENTIFICATION = "reportIdentification",
    DIGITAL_SIGN = "digitalSign",
    GENERATE_C60 = "generateC60",
    CANCELLATION_PROCESS_MANAGEMENT = "cancellationProcessManagement",
    C60_APPLICATION_IDENTIFIER = "c60ApplicationIdentifier",
    C60_FORMAT_TYPE = "c60FormatType",
    C60_EMITTER_PROVINCE = "c60EmitterProvince",
    C60_EMITTER_MUNICIPALITY = "c60EmitterMunicipality",
    C60_EMITTER_CONTROL_DIGIT = "c60EmitterControlDigit",
    C60_TAX = "c60Tax",
    C60_PAYMENT_DAYS_TERM = "c60PaymentDaysTerm",
}

class ExploitationReportCodeSectionAdmin extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskParkingMeterTypeList().execute();
    new TaskManagementProcessingTypeList().execute();
  }

  public render(): ReactNode {
    const { collapsed } = this.state;
    const {invalid,
      className,
      parkingMeterTypeList,
      valid,
      existErrorsHandler,
      managementProcessingTypeList,
      displayC60Fields} = this.props;
    const parkingMeterTypeOptions: FormInputOption[] =
            parkingMeterTypeList.map((parkingMeterType) => ( {
              value: parkingMeterType.id,
              name: parkingMeterType.name,
            } ));
    const managementTypeOptions: FormInputOption[] =
            managementProcessingTypeList.map((managementProcessingType) => ( {
              value: managementProcessingType.id,
              name: managementProcessingType.name,
            } ));

    existErrorsHandler(valid);

    return (
      <CardCollapsed
        title={I18n.tr(I18nKeys.CODIFICACION_DE_DENUNCIA_Y_TRAMITACION)}
        collapsedHandler={this.onCollapsedCard}
        collapsed={collapsed}
        invalid={invalid}
        className={className}
      >
        <Row>
          <Field
            label={I18n.tr(I18nKeys.CODIGO_SUMA)}
            name={ExploitationReportCodeSectionFields.CODE_SUMA}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.CODIGO_DE_PROVINCIA_SUMA)}
            name={ExploitationReportCodeSectionFields.PROVINCE_CODE_SUMA}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.CODIGO_DE_MUNICIPIO_SUMA)}
            name={ExploitationReportCodeSectionFields.TOWN_CODE_SUMA}
            component={FormInput}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.NUMERO_DE_FICHERO_DE_EXPORTACION_SUMA)}
            name={ExploitationReportCodeSectionFields.EXPORT_NUM_SUMA}
            component={FormInput}
            type={"number"}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.TIPO_DE_CODIFICACION_DE_DENUNCIA)}
            name={ExploitationReportCodeSectionFields.PARKING_METER_TYPE_ID}
            component={FormSelect}
            options={parkingMeterTypeOptions}
            blankOptionText={" "}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.TIPO_DE_CODIFICACION_DE_CANCELACION)}
            name={ExploitationReportCodeSectionFields.CANCELLATION_PARKING_METER_TYPE_ID}
            component={FormSelect}
            options={parkingMeterTypeOptions}
            blankOptionText={" "}
            col={{
              md: 3,
              lg: 3,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.TIPO_DE_GESTION_DE_TRAMITACION)}
            name={ExploitationReportCodeSectionFields.MANAGEMENT_PROCESSING_TYPE_ID}
            component={FormSelect}
            options={managementTypeOptions}
            blankOptionText={" "}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.ADVERACION)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationReportCodeSectionFields.RATIFICATION}
            component={FormSwitch}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.ADVERACION_RATIFICACION_REMOTA)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationReportCodeSectionFields.REMOTE_RATIFICATION}
            component={FormSwitch}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.IDENTIFICACION_DE_DENUNCIADO)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationReportCodeSectionFields.REPORT_IDENTIFICATION}
            component={FormSwitch}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.FIRMA_DIGITAL)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationReportCodeSectionFields.DIGITAL_SIGN}
            component={FormSwitch}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.GESTION_DE_ANULACION)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationReportCodeSectionFields.CANCELLATION_PROCESS_MANAGEMENT}
            component={FormSwitch}
            col={{
              md: 4,
              lg: 4,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.GENERACION_C60)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationReportCodeSectionFields.GENERATE_C60}
            component={FormSwitch}
            col={{
              md: 4,
              lg: 4,
            }}
          />
        </Row>
        {
          displayC60Fields
            ? (
              <>
                <Row>
                  <Field
                    label={I18n.tr(I18nKeys.CODIGO_DE_APLICACION_C60)}
                    name={ExploitationReportCodeSectionFields.C60_APPLICATION_IDENTIFIER}
                    component={FormInput}
                    col={{
                      md: 4,
                      lg: 4,
                    }}
                  />
                  <Field
                    label={I18n.tr(I18nKeys.CODIGO_DE_TIPO_DE_FORMATO_C60)}
                    name={ExploitationReportCodeSectionFields.C60_FORMAT_TYPE}
                    component={FormInput}
                    col={{
                      md: 4,
                      lg: 4,
                    }}
                  />
                  <Field
                    label={I18n.tr(I18nKeys.TRIBUTO_CONCEPTO_O_GRAVAMEN)}
                    name={ExploitationReportCodeSectionFields.C60_TAX}
                    component={FormInput}
                    col={{
                      md: 4,
                      lg: 4,
                    }}
                  />
                </Row>
                <Row>
                  <Field
                    label={I18n.tr(I18nKeys.CODIGO_DE_PROVINCIA_C60)}
                    name={ExploitationReportCodeSectionFields.C60_EMITTER_PROVINCE}
                    component={FormInput}
                    col={{
                      md: 4,
                      lg: 4,
                    }}
                  />
                  <Field
                    label={I18n.tr(I18nKeys.CODIGO_DE_MUNICIPIO_C60)}
                    name={ExploitationReportCodeSectionFields.C60_EMITTER_MUNICIPALITY}
                    component={FormInput}
                    col={{
                      md: 4,
                      lg: 4,
                    }}
                  />
                  <Field
                    label={I18n.tr(I18nKeys.DIGITO_DE_CONTROL_C60)}
                    name={ExploitationReportCodeSectionFields.C60_EMITTER_CONTROL_DIGIT}
                    component={FormInput}
                    col={{
                      md: 4,
                      lg: 4,
                    }}
                  />
                </Row>
                <Row>
                  <Field
                    label={I18n.tr(I18nKeys.PLAZO_DIAS_PAGO)}
                    name={ExploitationReportCodeSectionFields.C60_PAYMENT_DAYS_TERM}
                    component={FormInput}
                    type={"number"}
                    min={0}
                    col={{
                      md: 4,
                      lg: 4,
                    }}
                  />
                </Row>
              </>
            )
            : null
        }
      </CardCollapsed>
    );
  }

  private onCollapsedCard = () => {
    this.setState( {
      collapsed: !this.state.collapsed,
    } );
  };
}

// @ts-ignore
export default connect(mapStateToProps, mapActionsToProps)(ExploitationReportCodeSectionAdmin) as ComponentType<ExploitationReportCodeSectionProps>;

export class ExploitationReportCodeSectionAdminHelpers extends BaseFormFieldsHelpers {
  public static validate(values: ExploitationReportCodeSectionAdminData) {
    const errors: FormErrors<ExploitationReportCodeSectionAdminData> = {
    };

    if (values.codeSuma && values.codeSuma.length !== 1)
      errors.codeSuma = `${I18n.tr(I18nKeys.LONGITUD_DEBE_SER)} 1`;

    if (values.provinceCodeSuma && values.provinceCodeSuma.length !== 2)
      errors.provinceCodeSuma = `${I18n.tr(I18nKeys.LONGITUD_DEBE_SER)} 2`;

    if (values.townCodeSuma && values.townCodeSuma.length !== 3)
      errors.townCodeSuma = `${I18n.tr(I18nKeys.LONGITUD_DEBE_SER)} 3`;

    if (!values.parkingMeterTypeId || values.parkingMeterTypeId === "-1")
      errors.parkingMeterTypeId = I18n.tr(I18nKeys.TIPO_DE_CODIFICACION_DE_DENUNCIA_OBLIGATORIO);

    if (!values.managementProcessingTypeId || values.managementProcessingTypeId === "-1")
      errors.managementProcessingTypeId = I18n.tr(I18nKeys.TIPO_DE_GESTION_DE_TRAMITACION_OBLIGATORIA);

    if (values.exportNumSuma && values.exportNumSuma < 1)
      errors.exportNumSuma = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_SUPERIOR_A_CERO);

    if (values.generateC60) {
      errors.c60ApplicationIdentifier = ExploitationReportCodeSectionAdminHelpers.checkEmptyAndLength(values.c60ApplicationIdentifier, 2);
      errors.c60FormatType = ExploitationReportCodeSectionAdminHelpers.checkEmptyAndLength(values.c60FormatType, 3);
      errors.c60EmitterProvince = ExploitationReportCodeSectionAdminHelpers.checkEmptyAndLength(values.c60EmitterProvince, 2);
      errors.c60EmitterMunicipality = ExploitationReportCodeSectionAdminHelpers.checkEmptyAndLength(values.c60EmitterMunicipality, 3);
      errors.c60EmitterControlDigit = ExploitationReportCodeSectionAdminHelpers.checkEmptyAndLength(values.c60EmitterControlDigit, 1);
      errors.c60Tax = ExploitationReportCodeSectionAdminHelpers.checkEmptyAndLength(values.c60Tax, 3);
    }

    return errors;
  }

  public static getFormData(formData: Partial<Exploitation>): ExploitationReportCodeSectionAdminData {
    return {
      codeSuma: formData.code_suma ? formData.code_suma : "",
      provinceCodeSuma: formData.province_code_suma ? formData.province_code_suma : "",
      townCodeSuma: formData.town_code_suma ? formData.town_code_suma : "",
      parkingMeterTypeId: formData.parking_meter_type ? formData.parking_meter_type.id : "-1",
      cancellationParkingMeterTypeId: formData.cancellation_parking_meter_type ? formData.cancellation_parking_meter_type.id : "-1",
      managementProcessingTypeId: formData.management_processing_type ? formData.management_processing_type.id : "-1", // management_processing_type: object,
      exportNumSuma: formData.export_num_suma,
      ratification: formData.ratification ? formData.ratification : false,
      remoteRatification: formData.remote_ratification ? formData.remote_ratification : false,
      reportIdentification: formData.report_identification ? formData.report_identification : false,
      digitalSign: formData.digital_sign ? formData.digital_sign : false,
      generateC60: formData.generate_c60 ? formData.generate_c60 : false,
      cancellationProcessManagement: formData.cancellation_process_management !== undefined ? formData.cancellation_process_management : true,
      c60ApplicationIdentifier: formData.c60_application_identifier ? formData.c60_application_identifier : "",
      c60FormatType: formData.c60_format_type ? formData.c60_format_type : "",
      c60EmitterProvince: formData.c60_emitter_province ? formData.c60_emitter_province : "",
      c60EmitterMunicipality: formData.c60_emitter_municipality ? formData.c60_emitter_municipality : "",
      c60EmitterControlDigit: formData.c60_emitter_control_digit ? formData.c60_emitter_control_digit : "",
      c60Tax: formData.c60_tax ? formData.c60_tax : "",
      c60PaymentDaysTerm: formData.c60_payment_days_term,
    };
  }

  private static checkEmptyAndLength(value: any, length: number): string | undefined {
    // eslint-disable-next-line no-nested-ternary
    return !value
      ? I18n.tr(I18nKeys.CAMPO_OBLIGATORIO)
      : typeof value === "string" && value.length !== length
        ? `${I18n.tr(I18nKeys.LONGITUD_DEBE_SER)} ${length}`
        : undefined;
  }
}
