import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import ParkingMeter from "../../model/ParkingMeter";

type ParkingMeterCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskParkingMeterList extends BaseGenericRequest<ParkingMeterCriteriaProps, TaskResponse<ParkingMeter[]>> {

    private readonly data: ParkingMeterCriteriaProps;

    public constructor(criteria: ParkingMeterCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_METER_LIST);
        this.data = criteria;
    }

    protected getRequest(): ParkingMeterCriteriaProps {
        return this.data;
    }

}
