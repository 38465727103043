
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION } from "../../../../routing/Routes";
import ControllerSituationMapCardCompanyUser from "./ControllerSituationMapCardCompanyUser";

const ControllerSituationMapCompanyUserScreen = () =>(
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.MAPA_DE_SITUACION),
      url: ROUTE_COMPANY_USER_CONTROLLERS_MAP_SITUATION,
      icon: AppIcon.MAP,
    },
  ]}>
    <ControllerSituationMapCardCompanyUser/>
  </ScreenCard>
);

export default ControllerSituationMapCompanyUserScreen;
