import React from "react";

export interface ProgressBarProps {
    now: number,
}

export default class ProgressBar extends React.Component<ProgressBarProps> {
    public render() {
        const {now} = this.props;

        return (
            <div className="progress">
                <div className="progress-bar"
                     role="progressbar"
                     aria-valuenow={now}
                     aria-valuemin={0}
                     aria-valuemax={100}
                     style={{width: `${now}%`}}>
                    <span className="sr-only">{`${now}% Complete`}</span>
                </div>
            </div>
        )
    }

}
