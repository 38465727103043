import { FunctionComponent } from 'react';
import Icon from '../components/Icon';
import DropDown from '../components/dropdown/DropDown';
import { CardHeaderDropdownProps } from './typings';

export const CardHeaderDropdown: FunctionComponent<CardHeaderDropdownProps> = (
    {
        headerOptionIdSelected,
        headerOptions,
    }
) => {
    return (
        <DropDown
            className={'header-item ch-dropdown'}
            pullRight={true}
            options={headerOptions}
            idSelected={headerOptionIdSelected}
        >
            <Icon icon={'more_vert'}/>
        </DropDown>
    );
};
