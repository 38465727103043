import React from 'react';

export interface BaseModalProps {
    show: boolean,
    onClose: () => void,
}

export default abstract class BaseModal<P extends BaseModalProps> extends React.Component<P > {

}

