
import { Component, ComponentType, ReactNode } from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import { connect } from "react-redux";
import I18n from "../../../commons/I18n/I18n";
import NavBarDropDownToggle from "../../../components/dropdown/DropDownCustomToggle";
import Icon from "../../../components/Icon";
import I18nKeys from "../../../I18n/I18nKeys";
import imgLogo from "../../../res/img/logo.png";
import { ROUTE_HOME, ROUTE_START_ALERT } from "../../../routing/Routes";
import LoadingView from "../../loading/LoadingView";
import NavBarActions from "./SideBar/NavBarActions";
import NavBarReducer from "./SideBar/NavBarReducer";

export interface NavBarProps {
    onClickBars: ()=> void;
    className: string;
}

const mapStateToProps = NavBarReducer.autoMapToProps();

class NavBar extends Component<NavBarProps & typeof mapStateToProps> {
  private containerCollapse: HTMLDivElement;

  // eslint-disable-next-line class-methods-use-this
  public componentWillUnmount(): void {
    NavBarActions.clearReducer();
  }

  private toggleContainer = (): void => {
    if (this.containerCollapse) {
      if (this.containerCollapse.classList.contains("collapse"))
        this.containerCollapse.classList.remove("collapse");
      else
        this.containerCollapse.classList.add("collapse");
    }
  };

  public render(): ReactNode {
    const {alerts} = this.props;

    return (
      <nav
        className="navbar sidebar-closed">
        <div className="container-fluid">
          <div className="navbar-header">
            <a onClick={this.toggleContainer} className="navbar-toggle collapsed"/>
            <a className="bars" onClick={this.props.onClickBars}/>
            <a className="navbar-brand" href={ROUTE_HOME}><img src={imgLogo} alt="Inicio"/></a>
          </div>
          <div className="collapse navbar-collapse"
            ref={(ref: HTMLDivElement) => {
              this.containerCollapse = ref;

              return ref;
            }}>
            <ul className="nav navbar-nav navbar-right">
              <Dropdown id="dropdown-custom-2" componentClass={"li"}>
                <NavBarDropDownToggle bsRole="toggle" bsClass={""}>
                  <Icon icon={"notifications"}/>
                  {alerts.length > 0 ?
                    <span className="label-count">{alerts.length}</span> :
                    null}
                </NavBarDropDownToggle>

                <Dropdown.Menu className="super-colors">
                  {alerts.length > 0 ?
                    this.renderAlertsMenu() :
                    <MenuItem>
                      <b>{I18n.tr(I18nKeys.NO_HAY_ALERTAS)}</b>
                    </MenuItem>
                  }
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  private renderAlertsMenu = (): ReactNode => {
    const {loading} = this.props;

    return (
      <>
        <MenuItem header={true}
          bsClass="header"
          className="align-center">
          <b>{I18n.tr(I18nKeys.ALERTAS).toUpperCase()}</b>
        </MenuItem>

        <MenuItem divider={true}/>

        <MenuItem header={true} bsClass="body" className={"body menuitem-body"}>
          <LoadingView loading={loading}/>
        </MenuItem>

        <MenuItem divider={true}/>

        <MenuItem
          href={ROUTE_START_ALERT}
          bsClass="footer"
          className="align-center"
        >
          <b>{I18n.tr(I18nKeys.VER_TODAS_LAS_ALERTAS).toUpperCase()}</b>
        </MenuItem>
      </>
    );
  };
}

export default connect(mapStateToProps)(NavBar) as unknown as ComponentType<NavBarProps>;
