import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import AuthActions from "./SetNewPasswordActions";
import {isValidPassword} from "../../../utils/StringUtils";
import TaskLogin from "../../../commons/ws/login/TaskLogin";

interface State {
    loading: boolean,
    password: string,
    rePassword: string,

    newPasswordIsValid: boolean,
    passwordsAreEqual: boolean,

}

const INITIAL_STATE: State = {
    loading: false,
    password: '',
    rePassword: '',

    newPasswordIsValid: false,
    passwordsAreEqual: false,
};

const buildState = (state: State): State => {
    const newPasswordIsValid = isValidPassword(state.password);
    const passwordsAreEqual = state.password === state.rePassword;

    return ({...state, newPasswordIsValid, passwordsAreEqual });
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => ({ ...oldState, loading: true }))
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => ({ ...oldState, loading: false }))

    .onEvent(AuthActions.setPassword, (oldState, payload) => buildState({ ...oldState, password: payload}))

    .onEvent(AuthActions.setRePassword, (oldState, payload) => buildState({ ...oldState, rePassword: payload}))

    .onEvent(AuthActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build();
