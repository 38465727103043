import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import Report from '../../../../commons/model/Report';
import ReportDetailsCompanyUserActions from './ReportDetailsCompanyUserActions';
import TaskReportDetail from '../../../../commons/ws/report/TaskReportDetail';
import TaskReportGetPDF from "../../../../commons/ws/report/TaskReportGetPDF";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER STATE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface InitialState {
    report: Report | null
    error: string
    loading: boolean
}

const INITIAL_STATE: InitialState = {
    report: null,
    error: '',
    loading: false,
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(
        ReportDetailsCompanyUserActions.dispatchResetDetailsReducer,
        () => ( {...INITIAL_STATE} )
    )
    .onEvent(
        TaskReportDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, loading: true} )
    )
    .onEvent(
        TaskReportDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, loading: false} )
    )
    .onEvent(
        TaskReportDetail.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => ( {...oldState, error: payload} )
    )
    .onEvent(
        TaskReportDetail.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, report: payload.data} )
    )
    .onEvent(
        TaskReportGetPDF.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, loading: true} )
    )
    .onEvent(
        TaskReportGetPDF.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, loading: false} )
    )
    .build();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER LOGIC
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//
