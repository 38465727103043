import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Law from "../../model/Law";
import {generateRoute} from "../../utils/Router";

export default class TaskLawDetail extends BaseGenericRequest<{}, TaskResponse<Law>> {

    public constructor(lawId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_LAW_DETAIL, {uuid: lawId}));
    }
}
