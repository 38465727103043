import { FunctionComponent } from "react";
import I18n from "../commons/I18n/I18n";
import Button from "../components/buttons/Button";
import Col from "../components/Col";
import Row from "../components/Row";
import I18nKeys from "../I18n/I18nKeys";
import { CardBodyFooterProps } from "./typings";

const CardBodyFooter: FunctionComponent<CardBodyFooterProps> = (
  {acceptTitle,
    acceptOnClick,
    acceptDisabled,
    cancelTitle = I18n.tr(I18nKeys.CANCELAR),
    cancelOnClick},
) => (
  <Row className='p-t-20'>
    <Col sm={4} md={6} lg={8}/>
    <Col sm={4} md={3} lg={2}>
      <Button
        text={cancelTitle}
        onClick={cancelOnClick}
        type={"button"}
        block={true}
        className={"btn-lg btn-default"}
      />
    </Col>
    <Col sm={4} md={3} lg={2}>
      <Button
        text={acceptTitle}
        onClick={acceptOnClick}
        type={"button"}
        block={true}
        className={"btn-primary btn-lg"}
        disabled={acceptDisabled}
      />
    </Col>
  </Row>
);

export default CardBodyFooter;