import {ActionBuilder} from '@cuatroochenta/co-redux-builders';
import {SortState} from '../../../../utils/Sort';
import { PagerBackendProps } from '../../../../components/Pager';
import { ReportCriteriaProps } from '../../../../commons/ws/report/TaskReportList';

export default ActionBuilder.build({
    dispatchResetListReducer: () => ({}),
    dispatchChangeSearchText: (newSeach: string) => (newSeach),
    dispatchAddCriteria: (newCriteria: object) => (newCriteria),
    dispatchChangeHeaderOption: (newOptionId: string) => (newOptionId),
    dispatchClearHeaderOption: () => ({}),
    dispatchSetSort: (newSort: SortState) => (newSort),
    dispatchSetPage: (newPage: number) => (newPage),
    dispatchSelectItem: (itemId: string) => (itemId),
    dispatchSelectAllItems: () => ({}),
    dispatchSetStorageData: (
        dataSort: SortState | null,
        dataPager: PagerBackendProps | null,
        dataCriteria: ReportCriteriaProps | null,
    ) => ({dataSort, dataPager, dataCriteria}),
});
