import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {ParkingMeterCollectionProcess} from "../../model/ParkingMeterCollectionProcess";
import {generateRoute} from "../../utils/Router";

export default class TaskParkingMeterCollectionProcesDetail extends BaseGenericRequest<{id: string}, TaskResponse<ParkingMeterCollectionProcess>> {

    public constructor(uuid: string) {
        super(METHOD.GET, generateRoute(Urls.URL_PARKING_METER_COLLECTION_PROCESS_DETAIL, {uuid}));
    }

}
