import { GoogleApiWrapper, InfoWindow, Map, Marker, Polyline } from "google-maps-react";
import { Component, ComponentType, ReactNode } from "react";
import { WrappedFieldProps } from "redux-form";
import LoadingView from "../base/loading/LoadingView";
import I18n from "../commons/I18n/I18n";
import Config from "../config/Config";
import I18nKeys from "../I18n/I18nKeys";

export interface MarkerPosition {
    lat: number;
    lng: number;
}
interface ReportInfoWindowData {
    controller: string;
    licensePlate: string;
    reportDate: string;
    reportHour: string;
}

export interface MarkerReportProps {
    position: MarkerPosition;
    draggable?: boolean;
    reportData: ReportInfoWindowData;
}

export interface MarkerControllerProps {
    position: MarkerPosition;
    date: Date;
}

interface MapStyle {
    width: string;
    height: string;
}

export interface MapLocationPickerProps {
    google: any;
    mapStyle?: MapStyle;
    zoom?: string;
    defaultPosition: MarkerPosition;
    draggableMap?: boolean;
    draggableMark?: boolean;
    changeCoordHandler?: (newCoord: MarkerPosition)=> void;
    label?: string;
    invalid?: boolean;
    detailMode?: boolean;
    showMainMarker: boolean;
    reportMarkers?: MarkerReportProps[];
    controllerMarkers?: MarkerControllerProps[];
    currentPositionController?: MarkerControllerProps;
    iconMarker?: any;
}

type Props = MapLocationPickerProps & WrappedFieldProps;

interface State {
    markerCoord?: MarkerPosition;
    currentInfoWindow: number;
    openInfoWindow: boolean;
}

const MARKER_NAME = "ParkingMeterMarker";

class MapLocationPicker extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      markerCoord: undefined,
      currentInfoWindow: -1,
      openInfoWindow: false,
    };
  };

  public componentDidMount(): void {
    if (this.props.detailMode && this.props.input.value)
    {this.setMarkerCoord( {
      lat: this.props.input.value.lat,
      lng: this.props.input.value.lng,
    } );}

    if (!this.props.detailMode)
      this.setMarkerCoord(this.props.defaultPosition);
  }

  public componentWillUpdate(nextProps: Readonly<MapLocationPickerProps & WrappedFieldProps>): void {
    if (this.props.input.value !== nextProps.input.value)
      this.setMarkerCoord(nextProps.input.value);

    if (!this.props.input.value && this.props.reportMarkers) {
      if (this.props.reportMarkers !== nextProps.reportMarkers) {
        if (nextProps.reportMarkers && nextProps.reportMarkers.length !== 0)
          this.setMarkerCoord(nextProps.reportMarkers[0].position);
      }
    }

    if (!this.props.input.value && this.props.controllerMarkers) {
      if (this.props.controllerMarkers !== nextProps.controllerMarkers) {
        if (nextProps.controllerMarkers && nextProps.controllerMarkers.length !== 0)
          this.setMarkerCoord(nextProps.controllerMarkers[0].position);
      }
    }

    if (!this.props.input.value && this.props.currentPositionController) {
      if (this.props.currentPositionController !== nextProps.currentPositionController) {
        if (nextProps.currentPositionController && nextProps.currentPositionController.position)
          this.setMarkerCoord(nextProps.currentPositionController.position);
      }
    }
  }

  private setMarkerCoord(newMarkerCoord: MarkerPosition): void {
    this.setState( {
      markerCoord: newMarkerCoord,
    } );
  }

  private onMarkerDragEnd = (coord) => {
    const {latLng} = coord;
    const newMarkerCoord: MarkerPosition = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };

    this.setMarkerCoord(newMarkerCoord);

    if (this.props.changeCoordHandler)
      this.props.changeCoordHandler(newMarkerCoord);
  };

  private onShowInfoWindow = (infoWindowId: number) => {
    this.setState( {
      currentInfoWindow: infoWindowId,
      openInfoWindow: true,
    } );
  };

  private onHideInfoWindow = () => {
    this.setState( {
      currentInfoWindow: -1,
      openInfoWindow: false,
    } );
  };

  public render(): ReactNode {
    const {google,
      zoom = 14,
      draggableMap = true,
      draggableMark = true,
      label,
      invalid,
      showMainMarker = true,
      mapStyle = {
        width: "100%",
        height: "100%",
        borderRadius: "10px",
      }} = this.props;
    const style = invalid
      ? {
        ...mapStyle,
        border: "1px solid red",
      }
      : {
        ...mapStyle,
      };

    return (
      this.state.markerCoord ?
      // eslint-disable-next-line multiline-ternary
        <>
          {label &&
                    <label className={"m-b-15"}>{label}</label>}

          {invalid &&
                    <label className={"error"}>{I18n.tr(I18nKeys.LA_POSICION_ES_OBLIGATORIA)}</label>}

          <Map
            google={google}
            zoom={zoom}
            style={style}
            initialCenter={this.state.markerCoord}
            draggable={draggableMap}
            center={this.state.markerCoord}
          >

            {showMainMarker && <Marker
              draggable={draggableMark}
              name={MARKER_NAME}
              position={this.state.markerCoord}
              onDragend={(markerProps, mapProps, coord) => this.onMarkerDragEnd(coord)}
              // TODO: iconMarkerProps
            />}

            {this.renderControllerPositionMarkers()}
            {this.renderMarkers()}
            {this.renderInfoWindows()}

          </Map>
        </> : <LoadingView loading={true}/>
    );
  }

  private renderControllerPositionMarkers(): ReactNode {
    const {currentPositionController, controllerMarkers, google} = this.props;

    if (currentPositionController) {
      return (
        <Marker draggable={false}
          name={"controller-marker"}
          position={currentPositionController.position}
          icon={{
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            scaledSize: new google.maps.Size(22, 22),
          }}
        />
      );
    }

    if (controllerMarkers) {
      const triangleCoords = controllerMarkers.map((marker) => (marker.position));

      return (
        <Polyline
          path={triangleCoords}
          strokeColor="#0063a2"
          strokeOpacity={0.8}
          strokeWeight={2}/>
      );
    }

    return null;
  }

  private renderMarkers(): ReactNode {
    const {reportMarkers, google} = this.props;

    return reportMarkers && reportMarkers.map((marker: MarkerReportProps, index) => (
      <Marker
        key={index}
        draggable={marker.draggable}
        name={`report-marker-${index}`}
        position={marker.position}
        onClick={() => {
          this.onShowInfoWindow(index);
        }}
        icon={{
          // url: "http://maps.google.com/mapfiles/ms/icons/caution.png",
          url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
          scaledSize: new google.maps.Size(22, 22),
        }}
      />
    ));
  }

  private renderInfoWindows(): ReactNode {
    const {reportMarkers, google} = this.props;
    const {openInfoWindow, currentInfoWindow} = this.state;

    return reportMarkers && reportMarkers.map((marker: MarkerReportProps, index) => {
      const showInfoWindow = openInfoWindow && currentInfoWindow === index;

      return (
        <InfoWindow
          key={index}
          position={marker.position}
          visible={showInfoWindow}
          onClose={this.onHideInfoWindow}
          pixelOffset={new google.maps.Size(0, -25)}
        >
          <div>
            <h5>{I18n.tr(I18nKeys.DATOS_DENUNCIA)}</h5>
            <span
              className={"font-bold"}>{I18n.tr(I18nKeys.CONTROLADOR)}:</span> {marker.reportData.controller}
            <br/>
            <span
              className={"font-bold"}>{I18n.tr(I18nKeys.MATRICULA)}:</span> {marker.reportData.licensePlate}<br/>
            <span
              className={"font-bold"}>{I18n.tr(I18nKeys.FECHA_DENUNCIA)}:</span> {marker.reportData.reportDate}<br/>
            <span
              className={"font-bold"}>{I18n.tr(I18nKeys.HORA_DENUNCIA)}:</span> {marker.reportData.reportHour}<br/>
          </div>
        </InfoWindow>
      );
    } );
  }
}

export default GoogleApiWrapper( {
  apiKey: Config.GOOGLE_API_KEY,
} )(MapLocationPicker) as ComponentType<MapLocationPickerProps>;
