import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import Law from "../../model/Law";

interface LawCriteria {
    exploitation_id?: string,
}

type LawCriteriaProps = TaskCriteriaRequest<LawCriteria>

export default class TaskLawList extends BaseGenericRequest<LawCriteriaProps, TaskResponse<Law[]>> {

    private readonly data: LawCriteriaProps;

    public constructor(criteria: LawCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_LAW_LIST);
        this.data = criteria;
    }

    protected getRequest(): LawCriteriaProps {
        return this.data;
    }

}
