import BaseGenericRequest from "../BaseGenericRequest";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";

interface WorkdayReportExportCustomCriteria {
    user_ids?: string[],
    start_date?: string,
    end_date?: string,
}

export type WorkdayReportExportCriteria = TaskCriteriaRequest<WorkdayReportExportCustomCriteria>;

export default class TaskWorkdayReportExport extends BaseGenericRequest<WorkdayReportExportCriteria, TaskResponse<{url: string}>> {

    private readonly data: WorkdayReportExportCustomCriteria;

    public constructor(criteria: WorkdayReportExportCustomCriteria = {}) {
        super(METHOD.POST, Urls.URL_WORKDAY_REPORT_EXPORT);
        this.data = criteria;
    }

    protected getRequest(): WorkdayReportExportCustomCriteria {
        return this.data;
    }

}
