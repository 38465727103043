import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";
import {METHOD} from "@cuatroochenta/co-generic-request";
import {generateRoute} from "../../utils/Router";
import LawType from "../../model/LawType";

export default class TaskLawTypeDetail extends BaseGenericRequest<{}, TaskResponse<LawType>> {

    public constructor(lawId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_LAW_TYPE_DETAIL, {uuid: lawId}));
    }
}
