import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import Report from '../../model/Report';
import { generateRoute } from '../../utils/Router';

export default class TaskReportRatifierCancel extends BaseGenericRequest<{}, TaskResponse<Report>> {

    public constructor(id: string) {
        super(METHOD.POST, generateRoute(Urls.URL_REPORT_RATIFIER_CANCEL, {uuid: id}));
    }
}
