
import { Component, ComponentType, ReactNode } from "react";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import { AppInfoDataReduxForm } from "../../../../commons/model/AppInfo";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import Col from "../../../../components/Col";
import FormInput from "../../../../components/form/FormInput";
import FormSubmit from "../../../../components/form/FormSubmit";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";

const FORM_NAME = "AppSettingFormAdmin";

enum AppSettingFormAdminFields {
    MINIMUM_VERSION_APP = "minimumVersionApp",
    APP_URL_ANDROID = "appUrlAndroid",
    APP_URL_IOS = "appUrlIos",
}

interface AppSettingsFormAdminCardExtProps {
    onSubmit: (data: AppInfoDataReduxForm)=> void;
    initialValues: Partial<AppInfoDataReduxForm>;
    parentLoading: boolean;
}

type Props =
    AppSettingsFormAdminCardExtProps & InjectedFormProps<AppInfoDataReduxForm, AppSettingsFormAdminCardExtProps>;

class AppSettingsFormAdminCard extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render(): ReactNode {
    const {pristine,
      onSubmit,
      handleSubmit,
      parentLoading} = this.props;

    return (
      <Card loading={parentLoading}>
        <CardHeader title={I18n.tr(I18nKeys.CONFIGURACION_APP)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.VERSION_MINIMA)}
                name={AppSettingFormAdminFields.MINIMUM_VERSION_APP}
                component={FormInput}
                placeholder={"xx.xx.xx"}
                col={{
                  md: 4,
                  lg: 4,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.URL_ANDROID)}
                name={AppSettingFormAdminFields.APP_URL_ANDROID}
                component={FormInput}
                col={{
                  md: 12,
                  lg: 12,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.URL_IOS)}
                name={AppSettingFormAdminFields.APP_URL_IOS}
                component={FormInput}
                col={{
                  md: 12,
                  lg: 12,
                }}
              />
            </Row>
            <Row className={"p-t-20"}>
              <Col sm={4} md={9} lg={10}/>
              <Field
                label={I18n.tr(I18nKeys.GUARDAR)}
                name="submit"
                component={FormSubmit}
                col={{
                  sm: 4,
                  md: 3,
                  lg: 2,
                }}
                disabled={pristine}
              />
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: AppInfoDataReduxForm) {
  const errors: FormErrors<AppInfoDataReduxForm> = {
  };
  const {minimumVersionApp,
    appUrlAndroid,
    appUrlIos} = values;

  if (!minimumVersionApp) 
    errors.minimumVersionApp = I18n.tr(I18nKeys.VERSION_OBLIGATORIA);

  if (!appUrlAndroid) 
    errors.appUrlAndroid = I18n.tr(I18nKeys.URL_ANDROID_OBLIGATORIA);

  if (!appUrlIos) 
    errors.appUrlIos = I18n.tr(I18nKeys.URL_IOS_OBLIGATORIA);

  if (minimumVersionApp) {
    const CODE_PATTERN = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
    const hasCorrectFormat: boolean = minimumVersionApp.match(CODE_PATTERN) !== null;

    if (!hasCorrectFormat) 
      errors.minimumVersionApp = I18n.tr(I18nKeys.FORMATO_DE_LA_VERSION_INCORRECTO);
  }

  return errors;
}

export default reduxForm<AppInfoDataReduxForm, AppSettingsFormAdminCardExtProps>( {
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
} )(AppSettingsFormAdminCard) as ComponentType<AppSettingsFormAdminCardExtProps>;
