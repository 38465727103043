import React, { ReactNode } from "react";
import { connect } from "react-redux";
import Alert from "../../../base/alerts/Alert";
import I18n from "../../../commons/I18n/I18n";
import TaskReportExportProcessing from "../../../commons/ws/report/TaskReportExportProcessing";
import TaskReportExportHistoryList from "../../../commons/ws/reportexporthistory/TaskReportExportHistoryList";
import CardList from "../../../components/card/CardList";
import I18nKeys from "../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_SENDTOPROCESS_FILE } from "../../../routing/Routes";
import { goToRoute } from "../../../utils/Router";
import ReportExportHistoryTableItem from "./ReportExportHistoryTableItem";
import SendToProccessActions from "./SendToProccessActions";
import SendToProccessReducer from "./SendToProccessReducer";
import SendToProcessView from "./SendToProcessView";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const mapStateToProps = SendToProccessReducer.autoMapToProps();
const mapActionsToProps = SendToProccessActions.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

interface State {showModal: boolean}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class SendToProcessDataContainer extends React.Component<Props, State> {
  public state: State = {
    showModal: false,
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  public componentDidMount(): void {
    this.props.dispatchResetDate();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleConfirm = () => {
    const {date, dispatchSetFile} = this.props;

    this.setState( {
      showModal: false,
    } );
    new TaskReportExportProcessing(date ? date.toISOString() : "")
      .onSuccess((res) => {
        if (res.success) {
          dispatchSetFile(res.data.url);
          goToRoute(ROUTE_COMPANY_USER_SENDTOPROCESS_FILE);
        } else
          Alert.error(res.message || I18n.tr(I18nKeys.SE_HA_PRODUCIDO_UN_ERROR_EN_LA_EXPORTACION));
      } )
      .onFail((message) => Alert.error(message))
      .execute();
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  public render(): ReactNode {
    return (
      <>
        <SendToProcessView
          date={this.props.date}
          dispatchChangeDate={this.props.dispatchChangeDate}
          loading={this.props.loading}
          handleConfirm={this.handleConfirm}
        />
        <CardList
          loading={this.props.reportExportHistoryLoading}
          pager={this.props.reportExportHistoryPager}
          title={I18n.tr(I18nKeys.HISTORICO_DE_EXPORTACIONES)}
          sort={{
            column: "",
          }}
          noSearchBar={true}
          TaskList={TaskReportExportHistoryList}
          emptyListOptions={{
            message: I18n.tr(I18nKeys.NO_EXISTEN_EXPORTACIONES),
          }}
          data={this.props.reportExportHistoryList}
          error={this.props.reportExportHistoryError}
          ItemsTable={ReportExportHistoryTableItem}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(SendToProcessDataContainer) as unknown as React.ComponentType<{}>;
