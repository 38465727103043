import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import ReportCancellationMethod, { ReportCancellationMethodData } from '../../model/ReportCancellationMethod';

export default class TaskReportCancellationMethodAdd extends BaseGenericRequest<ReportCancellationMethodData, TaskResponse<ReportCancellationMethod>> {

    private readonly data: ReportCancellationMethodData;

    public constructor(data: ReportCancellationMethodData) {
        super(METHOD.POST, Urls.URL_REPORT_CANCELLATION_METHOD_ADD);
        this.data = data;
    }

    protected getRequest(): ReportCancellationMethodData {
        return this.data;
    }
}
