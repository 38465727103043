import { METHOD } from "@cuatroochenta/co-generic-request";
import classNames from "classnames";
import { Component, ReactNode } from "react";
import Dropzone from "react-dropzone";
import { WrappedFieldProps } from "redux-form";
import Alert from "../../base/alerts/Alert";
import I18n from "../../commons/I18n/I18n";
import Urls from "../../commons/ws/Urls";
import Config, { AppIcon } from "../../config/Config";
import I18nKeys from "../../I18n/I18nKeys";
import AuthManager from "../../utils/AuthManager";
import FileInfo from "../FileInfo";
import FileProgress from "../FileProgress";
import Icon from "../Icon";
import FormCol, { FormColProps } from "./FormCol";

export interface FormDragFileProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps;
    showError?: boolean;
    maxMBSize?: number;
    multiple?: boolean;
    fileTypes?: string[];
    fileIcon?: string;
    invalidFileMessage?: string;
    showErrorInLabel?: boolean;
}

interface DropZoneFile extends File {
    path: string;
}

interface State {
    uploadingFiles: DropZoneFile[];
    touched: boolean;

    [fileIndex: number]: number;
}

export default class FormDragFile extends Component<FormDragFileProps, State> {
  public state: State = {
    uploadingFiles: [],
    touched: false,
  };

  private requests: XMLHttpRequest[] = [];

  public componentWillUnmount(): void {
    this.requests.forEach((request) => request.abort());
  }

  public render(): ReactNode {
    const { meta, label, col, showError, input: { value }, multiple = false } = this.props;
    const hasFiles = this.isUploadingFile() || (Array.isArray(value) && value.length !== 0);

    return (
      <FormCol {...col} >
        <div className={"form-group"}>
          {label ? <label className={"main-label"}>{label || ""}</label> : null}
          {hasFiles && !multiple ?
            this.renderFiles() :
            this.renderDropZone(hasFiles)
          }
          <label className="error">{(meta.touched || showError) ? meta.error : ""}</label>
        </div>
      </FormCol>
    );
  }

  private sendFile = (file: DropZoneFile, index: number): void => {
    const { input: { onChange }, multiple } = this.props;
    const data = new FormData();

    data.append("file", file, file.name);

    const request = this.requests[index];

    request.upload.onprogress = (event) => {
      const percent = +((event.loaded / event.total) * 100).toFixed(2);

      this.setState( {
        [index]: percent,
      } );
    };
    request.onreadystatechange = () => {
      if (request.readyState !== 4)
        return;

      if (request.status !== 0) {
        const response = JSON.parse(request.response);

        if (response.success) {
          // importante que lea el value desde esta línea!!!
          onChange(multiple ? [response.data.url, ...this.props.input.value] : [response.data.url]);
          Alert.success(I18n.tr(I18nKeys.FICHERO_CARGADO_CORRECTAMENTE));
        } else if (response.message && response.message.code === 500)
          Alert.error(I18nKeys.ERROR_EN_EL_SERVIDOR);
        else
          Alert.error(response.message);
      }
    };

    request.open(METHOD.POST, Urls.URL_FILE_UPLOAD);

    if (AuthManager.isLogged())
      request.setRequestHeader("Authorization", `Bearer ${AuthManager.getAuthToken()}`);

    request.setRequestHeader("Accept", "application/json");
    request.send(data);
  };

  private onDrop = (acceptedFiles: DropZoneFile[]): void => {
    const newState: State = {
      uploadingFiles: [],
      touched: false,
    };

    // resetea el array de peticiones ajax
    this.requests.length = 0;

    // iniciamos el estado individual antes de la carga
    acceptedFiles.forEach((file, index) => {
      this.requests.push(new XMLHttpRequest());
      newState.uploadingFiles.push(file);
      newState[index] = 0;
    } );

    this.setState(newState);

    acceptedFiles.forEach(this.sendFile);
  };

  private onRemoveFile = (toRemoveUrl: string): void => {
    const { input: { onChange, value } } = this.props;

    if (Array.isArray(value))
      onChange(value.filter((url) => url !== toRemoveUrl));

    this.setState( {
      touched: true,
    } );
  };

  private isUploadingFile = (): boolean => !!this.requests.find((request) => request.readyState !== 4);

  private renderDropZone = (hasFiles: boolean): ReactNode => {
    const {disabled, maxMBSize = Config.MAX_FILE_SIZE_MB, fileTypes = [], multiple = false, invalidFileMessage,
      showErrorInLabel = false} = this.props;
    const maxSize = maxMBSize * 1024 * 1024;
    const fileTypesText = fileTypes.join(", ");

    return (
      // @ts-ignore
      <Dropzone onDrop={this.onDrop}
        disabled={disabled || this.isUploadingFile()}
        maxSize={maxSize}
        multiple={multiple}
        accept={fileTypes.join(",")}
        onFileDialogCancel={() => this.setState( {
          touched: true,
        } )}
        onDropRejected={(files) => {
          let existInvalidExtension: boolean = false;

          files.forEach((file) => {
            const fileExtension = file.type.split("/")[1];

            existInvalidExtension = !fileTypes.includes(fileExtension);
          } );

          if (existInvalidExtension && !showErrorInLabel)
            Alert.error(I18n.tr(invalidFileMessage || I18nKeys.TIPO_DE_FICHERO_NO_VALIDO));
        }}
      >
        {( { getRootProps, getInputProps, rejectedFiles } ) => {
          const rejected = rejectedFiles.length > 0;

          return (
            <>
              <div className={
                classNames(`dropzone ${rejected ? "dz-error" : ""}`, {
                  disabled,
                } )}>
                <div {...getRootProps()} className={"dz-root"}>
                  <input {...getInputProps()} />
                  {hasFiles ?
                    this.renderFiles() :
                    this.renderDZMessage()}
                </div>
              </div>
              {rejected && showErrorInLabel &&
                            <label className={"error m-t-5 m-b-0"}>
                              {`${I18n.tr(I18nKeys.SOLO_SE_ADMITEN_FICHEROS_DE_TIPO)} ${fileTypesText}.`}
                            </label>}
            </>
          );
        }}
      </Dropzone>
    );
  };

  private renderDZMessage = (): ReactNode => {
    const { fileIcon = AppIcon.DOCUMENTATION, multiple = false } = this.props;

    return (
      <div className={"dz-message"}>
        <Icon icon={fileIcon} />
        <p>{I18n.tr(multiple ?
          I18nKeys.ARRASTRA_LOS_ARCHIVOS_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS :
          I18nKeys.ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO)}</p>
      </div>
    );
  };

  private renderFiles = (): ReactNode => {
    const { input: { value }, multiple = false, fileIcon = AppIcon.DOCUMENTATION } = this.props;
    const { uploadingFiles } = this.state;

    return (
      <div className={"dz-files"}>
        {Array.isArray(value) && value.map((url: string) => (
          <FileInfo key={url}
            url={url}
            icon={fileIcon}
            fileName={url.replace(`${Urls.URL_FILE_UPLOAD }s/`, "")}
            removeHandler={() => this.onRemoveFile(url)}
            className={multiple ? "" : "p-l-0 m-t-15"}
          />
        ))}
        {this.requests.map((request, index) => request.readyState !== 4 && <FileProgress
          key={index}
          icon={fileIcon}
          cancelHandler={() => this.requests[index].abort()}
          percentLoaded={this.state[index] || 0}
          size={uploadingFiles[index].size} />)}
      </div>
    );
  };
}
