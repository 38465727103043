import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import VehicleType from "../../model/VehicleType";

type VehicleTypeCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskVehicleTypeList extends BaseGenericRequest<VehicleTypeCriteriaProps, TaskResponse<VehicleType[]>> {

    private readonly data: VehicleTypeCriteriaProps;

    public constructor(criteria: VehicleTypeCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_VEHICLE_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): VehicleTypeCriteriaProps {
        return this.data;
    }

}
