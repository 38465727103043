import { METHOD } from '@cuatroochenta/co-generic-request';
import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import CancellationReferenceType from '../../model/CancellationReferenceType';

type CancellationReferenceTypeCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskCancellationReferenceTypeList extends BaseGenericRequest<CancellationReferenceTypeCriteriaProps, TaskResponse<CancellationReferenceType[]>> {

    private readonly data: CancellationReferenceTypeCriteriaProps;

    public constructor(criteria: CancellationReferenceTypeCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_CANCELLATION_REFERENCE_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): CancellationReferenceTypeCriteriaProps {
        return this.data;
    }
}
