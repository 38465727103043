import { Checkbox } from "@material/react-checkbox";
import { Component, ComponentType, ReactNode, SyntheticEvent } from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import {CollectionProcessState,
  ParkingMeterCollectionProcess} from "../../../../commons/model/ParkingMeterCollectionProcess";
import { generateRoute } from "../../../../commons/utils/Router";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";
import CollectionProcessAdminActions from "./CollectionProcessAdminActions";
import CollectionProcessAdminReducer from "./CollectionProcessAdminReducer";

interface CollectionProcessTableProps {
    data: Array<ParkingMeterCollectionProcess>;
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> ReactNode;
}

const mapStateToProps = CollectionProcessAdminReducer.autoMapToProps();
const mapActionsToProps = CollectionProcessAdminActions.autoMapToProps();

type Props = CollectionProcessTableProps & typeof mapActionsToProps & typeof mapStateToProps;

function gotoItemDetail(itemId: string): void {
  goToRoute(generateRoute(ROUTE_ADMIN_COLLECTION_PROCESS_DETAIL, {
    id: itemId,
  } ));
};
class CollectionProcessTable extends Component<Props> {
  private areAnyPendingToSend (): boolean {
    const {data} = this.props;
    let areAnyPendingToSend: boolean = false;

    data.forEach(value => {
      if (value.collection_process_state.id === CollectionProcessState.PENDING_TO_SEND) 
        areAnyPendingToSend = true;
    } );

    return areAnyPendingToSend;
  }

  public render(): ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((collectionProcess: ParkingMeterCollectionProcess) => (
            <tr key={collectionProcess.id}
              onClick={() => gotoItemDetail(collectionProcess.id)}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(collectionProcess.id) : null}

              {this.renderCheckboxTD(collectionProcess.collection_process_state.id, collectionProcess.id)}

              <td>{collectionProcess.id}</td>

              <td>{DateFormatter.formatDate(collectionProcess.date)}</td>

              <td className={collectionProcess.collection_process_state.id === CollectionProcessState.INCORRECT ? "incorrect-state" : ""}>
                {collectionProcess.collection_process_state.name}
              </td>

              <td>{collectionProcess.real_cash}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): ReactNode {
    const {sort, onSort, children} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}

        {this.renderCheckboxTH()}

        <Th text={"Id"}
          columnName={"id"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.ESTADO)}
          columnName={"state"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.IMPORTE)}
          columnName={"realCash"}
          {...commonProps}
        />
      </tr>
    );
  }

  private renderCheckboxTD (state: CollectionProcessState, id: string): ReactNode {
    if (state === CollectionProcessState.PENDING_TO_SEND) {
      const selected = this.props.parkingMeterCollectionProcessToSend.filter(value => value === id);
      const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        event.stopPropagation();

        if (selected[0])
          this.props.dispatchRemoveCollectionProcessToSendList(id);
        else
          this.props.dispatchAddCollectionProcessToSendList(id);
      };

      return (
        <td className='td-checkbox'>
          <Checkbox
            initRipple={() => null}
            unbounded={false}
            checked={false}
            onChange={onChangeHandler}
            // @ts-ignore
            onClick={(event: SyntheticEvent<HTMLInputElement>) => event.stopPropagation()}
          />
        </td>
      );
    }

    if (this.areAnyPendingToSend())
      return <td/>;

    return null;
  }

  private renderCheckboxTH(): ReactNode {
    if (this.areAnyPendingToSend())
      return <Th text={""}/>;

    return null;
  }
}

export default connect(mapStateToProps, mapActionsToProps)(CollectionProcessTable as unknown as ComponentType<CollectionProcessTableProps>);
