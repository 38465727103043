
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_REPORTS } from "../../../../routing/Routes";
import ReportListCompanyUserCard from "./ReportListCompanyUserCard";

const ReportListCompanyUserScreen = () =>(
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.DENUNCIAS),
      url: ROUTE_COMPANY_USER_REPORTS,
      icon: AppIcon.REPORT,
    },
  ]}>
    <ReportListCompanyUserCard/>
  </ScreenCard>
);
  
export default ReportListCompanyUserScreen;
