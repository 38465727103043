import React, { ReactNode } from "react";
import I18n from "../../../commons/I18n/I18n";
import { NewButton } from "../../../components-new/Button";
import { Card } from "../../../components-new/Card";
import { CardHeader } from "../../../components-new/CardHeader";
import { NewFilterDate } from "../../../components-new/FilterDate";
import CardBody from "../../../components/card/CardBody";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import I18nKeys from "../../../I18n/I18nKeys";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface State {showModal: boolean}

export interface SendToProcessViewProps {
    date: Date | null;
    dispatchChangeDate: (date: Date)=> void;
    loading: boolean;
    handleConfirm: ()=> void;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default class SendToProcessView extends React.Component<SendToProcessViewProps, State> {
  public state: State = {
    showModal: false,
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleOpenModal = () => {
    this.setState( {
      showModal: true,
    } );
  };

  private handleCloseModal = () => {
    this.setState( {
      showModal: false,
    } );
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  public render(): ReactNode {
    const {date, dispatchChangeDate, loading, handleConfirm} = this.props;
    const {showModal} = this.state;

    return (
      <div className={"card-send-to-process"}>
        <Card loading={loading}>
          <CardHeader
            title={I18n.tr(I18nKeys.ENVIO_A_TRAMITE)}
            headerButtons={
              <NewButton
                label={I18n.tr(I18nKeys.ENVIAR_A_TRAMITE)}
                disabled={!date || loading}
                type={"btn-bordered"}
                onClick={this.handleOpenModal}
              />
            }
          >
          </CardHeader>
          <CardBody>
            <NewFilterDate
              style={{
                gridArea: "date",
              }}
              label={I18n.tr(I18nKeys.FECHA)}
              onChange={dispatchChangeDate}
            />
            <div style={{
              gridArea: "text",
            }}>
              {I18n.tr(I18nKeys.ESCOJA_UNA_FECHA_ANTES_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_PENDIENTES_DE_ENVIAR)}
            </div>
          </CardBody>
        </Card>
        <ConfirmModal
          show={showModal}
          title={I18n.tr(I18nKeys.CONFIRMACION_DE_ENVIO_A_TRAMITE)}
          message={I18n.tr(I18nKeys.ENVIAR_A_TRAMITE_TODAS_AQUELLAS_DENUNCIAS_PENDIENTES_DE_ENVIAR)}
          confirmDisabled={loading}
          textConfirmButton={I18n.tr(I18nKeys.ENVIAR)}
          onConfirmAction={handleConfirm}
          onClose={this.handleCloseModal}
        />
      </div>
    );
  }
}
