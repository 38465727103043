import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import WorkdayResume from "../../../../commons/model/WorkdayResume";
import TaskWorkdayResume from "../../../../commons/ws/workdayResume/TaskWorkdayResume";
import User from "../../../../commons/model/User";
import TaskUserList from "../../../../commons/ws/user/TaskUserList";

interface InitialState {
    loading: boolean,
    workdayResume: WorkdayResume[],
    error: string,
    userList: User[],
}

const INITIAL_STATE: InitialState = {
    loading: false,
    workdayResume: [],
    error: '',
    userList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskWorkdayResume.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskWorkdayResume.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskWorkdayResume.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskWorkdayResume.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            workdayResume: payload.data,
        }
    })

    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            userList: payload.data
        }
    })

    .build()
