import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import VehicleBrand, {VehicleBrandData} from "../../model/VehicleBrand";

export default class TaskVehicleBrandAdd extends BaseGenericRequest<VehicleBrandData, TaskResponse<VehicleBrand>> {

    private readonly data: VehicleBrandData;

    public constructor(data: VehicleBrandData) {
        super(METHOD.POST, Urls.URL_VEHICLE_BRAND_ADD);
        this.data = data;
    }

    protected getRequest(): VehicleBrandData {
        return this.data;
    }
}
