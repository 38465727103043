import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskParkingAreaTypeDelete from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeDelete";
import TaskParkingAreaTypeList from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD } from "../../../../routing/Routes";
import ParkingAreaTypeCompanyUserListActions from "./ParkingAreaTypeCompanyUserListActions";
import ParkingAreaTypeCompanyUserListReducer from "./ParkingAreaTypeCompanyUserListReducer";
import ParkingAreaTypeCompanyUserListTable from "./ParkingAreaTypeCompanyUserListTable";

const mapActionsToProps = ParkingAreaTypeCompanyUserListActions.autoMapToProps();
const mapStateToProps = ParkingAreaTypeCompanyUserListReducer.autoMapToProps();

class ParkingAreaTypeCompanyUserListCard extends React.Component<typeof mapActionsToProps & typeof mapStateToProps> {
  public componentWillMount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const {loading, parkingAreaTypes, error, pager} = this.props;

    return (
      <CardList
        loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.TIPOS_ZONAS_APARCAMIENTOS)}
        sort={{
          column: "name",
        }}
        TaskList={TaskParkingAreaTypeList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_TIPOS_DE_ZONA_DE_APARCAMIENTO),
        }}
        data={parkingAreaTypes}
        error={error}
        ItemsTable={ParkingAreaTypeCompanyUserListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_TIPOS_ZONAS_APARCAMIENTOS),
          Task: TaskParkingAreaTypeDelete,
        }}
        addItem={ROUTE_COMPANY_USER_PARKING_AREA_TYPE_ADD}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingAreaTypeCompanyUserListCard as unknown as React.ComponentType<void>);
