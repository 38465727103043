/* eslint-disable camelcase */

import { connect } from "react-redux";
import LoggedScreen from "../../base/screens/LoggedScreen";
import I18n from "../../commons/I18n/I18n";
import { DashboardInformation } from "../../commons/model/Dashboard";
import TaskDashboardInformation from "../../commons/ws/dashboard/TaskDashboardInformation";
import InfoBox, { InfoBoxData } from "../../components/InfoBox";
import ScreenContent from "../../components/screen/ScreenContent";
import I18nKeys from "../../I18n/I18nKeys";
import DateFormatter from "../../utils/DateFormatter";
import HomeReducer from "./HomeReducer";

const mapStateToProps = HomeReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class HomeScreen extends LoggedScreen<Props> {
  // eslint-disable-next-line class-methods-use-this
  public componentDidMount(): void {
    new TaskDashboardInformation().execute();
  }

  public renderScreen(): React.ReactNode {
    const {information, informationLoading} = this.props;

    return (
      <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>

        <div className="dashboard">
          <InfoBox
            icon={"send"}
            title={I18n.tr(I18nKeys.ULTIMO_ENVIO).toUpperCase()}
            data={handleDashboardLastSendingToInfoBoxData(information)}
            style={{
              backgroundColor: "#8BC34A",
              gridArea: "sending",
            }}
            loading={informationLoading}
          />
          <InfoBox
            icon={"assessment"}
            title={I18n.tr(I18nKeys.TOTALIZACION_DENUNCIAS).toUpperCase()}
            data={handleDashboardTotalReportsToInfoBoxData(information)}
            style={{
              backgroundColor: "#0063A2",
              gridArea: "reports",
            }}
            loading={informationLoading}
            columnWidth={200}
          />
          <InfoBox
            icon={"account_balance"}
            title={I18n.tr(I18nKeys.RECAUDACIONES).toUpperCase()}
            data={handleDashboardCollectionsToInfoBoxData(information)}
            style={{
              backgroundColor: "#FBB907",
              gridArea: "collections",
            }}
            loading={informationLoading}
          />
          <InfoBox
            icon={"warning"}
            title={I18n.tr(I18nKeys.INCIDENCIAS_PKM_Y_PLAZAS_PERDIDAS).toUpperCase()}
            data={handleDashboardParkingToInfoBoxData(information)}
            style={{
              backgroundColor: "#FF6347",
              gridArea: "parking",
            }}
            loading={informationLoading}
          />
        </div>

      </ScreenContent>
    );
  }
}

const handleDashboardLastSendingToInfoBoxData = (information: DashboardInformation | null): InfoBoxData[][] => {
  if (information && information.last_report) {
    const {lot,
      date,
      start_date,
      end_date,
      export_proccess_report_count} = information.last_report;
    const sendingDate: string = DateFormatter.formatDate(date);
    const periodStartDate: string = DateFormatter.formatDate(start_date);
    const periodEndDate: string = DateFormatter.formatDate(end_date);

    return [[
      {
        label: I18n.tr(I18nKeys.LOTE),
        value: lot,
      },
      {
        label: I18n.tr(I18nKeys.FECHA_ENVIO),
        value: sendingDate,
      },
      {
        label: I18n.tr(I18nKeys.PERIODO_DENUNCIAS),
        value: `${periodStartDate} - ${periodEndDate}`,
      },
      {
        label: I18n.tr(I18nKeys.TOTAL_DENUNCIAS_TRAMITADAS),
        value: export_proccess_report_count,
      },
    ]];
  }

  return [];
};
const handleDashboardTotalReportsToInfoBoxData = (information: DashboardInformation | null): InfoBoxData[][] => {
  if (information) {
    const {previous_day,
      previous_week,
      previous_month,
      previous_year,
      actual_day,
      actual_week,
      actual_month,
      actual_year} = information.total_reports;

    return [
      [
        {
          label: I18n.tr(I18nKeys.DIA_ACTUAL),
          value: actual_day,
        },
        {
          label: I18n.tr(I18nKeys.SEMANA_ACTUAL),
          value: actual_week,
        },
        {
          label: I18n.tr(I18nKeys.MES_ACTUAL),
          value: actual_month,
        },
        {
          label: I18n.tr(I18nKeys.ANO_ACTUAL),
          value: actual_year,
        },
      ],
      [
        {
          label: I18n.tr(I18nKeys.DIA_ANTERIOR),
          value: previous_day,
        },
        {
          label: I18n.tr(I18nKeys.SEMANA_ANTERIOR),
          value: previous_week,
        },
        {
          label: I18n.tr(I18nKeys.MES_ANTERIOR),
          value: previous_month,
        },
        {
          label: I18n.tr(I18nKeys.ANO_ANTERIOR),
          value: previous_year,
        },
      ],
    ];
  }

  return [];
};
const handleDashboardCollectionsToInfoBoxData = (information: DashboardInformation | null): InfoBoxData[][] => {
  if (information) {
    const {last_collection_made_date} = information.collections;
    const lastCollectionMadeDate: string = DateFormatter.formatDate(last_collection_made_date);

    return [[
      {
        label: I18n.tr(I18nKeys.FECHA_ULTIMA_RECAUDACION),
        value: lastCollectionMadeDate,
      },
    ]];
  }

  return [];
};
const handleDashboardParkingToInfoBoxData = (information: DashboardInformation | null): InfoBoxData[][] => {
  if (information) {
    const {num_parking_incidences_actual_week,
      rate_places_hours_parking_loss_month} = information.parking;

    return [[
      {
        label: I18n.tr(I18nKeys.TOTAL_INCIDENCIAS_REPORTADAS_SEMANA_ACTUAL),
        value: num_parking_incidences_actual_week,
      },
      {
        label: I18n.tr(I18nKeys.TOTAL_PLAZAS_PERDIDAS_MES_ACTUAL),
        value: Number(rate_places_hours_parking_loss_month).toFixed(0),
      },
    ]];
  }

  return [];
};

export default connect(mapStateToProps)(HomeScreen as unknown as React.ComponentType<{}>);
