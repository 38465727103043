
import { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import BaseModal, { BaseModalProps } from "../../base/modal/BaseModal";
import I18n from "../../commons/I18n/I18n";
import I18nKeys from "../../I18n/I18nKeys";
import Button from "../buttons/Button";
import Col from "../Col";
import Row from "../Row";

interface ConfirmCardProps extends BaseModalProps {
    title: string;
    message: string;
    onConfirmAction: ()=> void;
    textConfirmButton: string;
    confirmDisabled?: boolean;
}

export default class ConfirmModal extends BaseModal<ConfirmCardProps> {
  public render(): ReactNode {
    const {show,
      title,
      message,
      children,
      textConfirmButton,
      confirmDisabled,
      onClose,
      onConfirmAction} = this.props;
    const onCancelClick = () => onClose();
    const onConfirmClick = () => {
      onConfirmAction();
      onClose();
    };

    return (
      <Modal onHide={onClose} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={0} sm={0} md={6} lg={6}/>
            <Col sm={6} md={3} lg={3}>
              <Button
                className={"btn-lg"}
                block={true}
                text={I18n.tr(I18nKeys.CANCELAR).toUpperCase()}
                onClick={onCancelClick}
              />
            </Col>
            <Col sm={6} md={3} lg={3}>
              <Button
                className={"btn-lg btn-primary"}
                block={true}
                text={textConfirmButton.toUpperCase()}
                disabled={confirmDisabled}
                onClick={onConfirmClick}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
