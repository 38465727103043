import React from "react";
import { Modal } from "react-bootstrap";
import { BaseModalProps } from "../../../base/modal/BaseModal";
import I18n from "../../../commons/I18n/I18n";
import Button from "../../../components/buttons/Button";
import Col from "../../../components/Col";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";
import I18nKeys from "../../../I18n/I18nKeys";
import { isEmailValid } from "../../../utils/StringUtils";

interface RecoverPasswordModalProps {
    show: boolean;
    onSubmit: (email: string)=> void;
    onClose: ()=> void;
}

interface State {
    email: string;
    isValidEmail: boolean;
}

type Props = BaseModalProps & RecoverPasswordModalProps;

export default class RecoverPasswordModal extends React.Component<Props, State> {
  public state: State = {
    email: "",
    isValidEmail: false,
  };

  private onChangeEMail = (email: string): void => {
    this.setState( {
      isValidEmail: isEmailValid(email),
      email,
    } );
  };

  private handleKeyboard = (event) => {
    if (event.key === "Enter" && this.state.isValidEmail) 
      this.props.onSubmit(this.state.email);
  };

  public render(): React.ReactNode {
    const {show, onSubmit, onClose} = this.props;
    const {isValidEmail, email} = this.state;

    return (
      <Modal onHide={onClose} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.tr(I18nKeys.OLVIDASTE_TU_CONTRASENA)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{I18n.tr(I18nKeys.TE_MANDAREMOS_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA)}</p>
          <div className="input-group">
            <span className="input-group-addon"><Icon icon={"person"}/></span>
            <div className="form-line">
              <input
                type="text"
                className="form-control"
                placeholder={I18n.tr(I18nKeys.EMAIL_O_NOMBRE_DE_USUARIO)}
                required={true}
                autoFocus={true}
                value={email}
                onChange={(e) => this.onChangeEMail(e.target.value)}
                onKeyPress={this.handleKeyboard}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={0} sm={0} md={6} lg={6}/>
            <Col sm={6} md={3} lg={3}>
              <Button
                text={I18n.tr(I18nKeys.CANCELAR)}
                onClick={onClose}
                block={true}
                className={"btn-lg"}
              />
            </Col>
            <Col sm={6} md={3} lg={3}>
              <Button
                className={"btn-lg bg-blue-grey"}
                text={I18n.tr(I18nKeys.ENVIAR)}
                onClick={() => onSubmit(this.state.email)}
                block={true}
                disabled={!isValidEmail}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
