import { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { StyleProps } from '../components-new/typings';

export interface RowProps {
    clearfix?: boolean,
}

export default class Row extends Component<RowProps & StyleProps> {

    public render(): ReactNode {
        const classComponent = classNames(
            'row',
            this.props.className,
            {clearfix: this.props.clearfix}
        );

        return (
            <div className={classComponent} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }

}

