import BaseGenericRequest from "../BaseGenericRequest";
import ParkingAreaType from "../../model/ParkingAreaType";
import TaskResponse from "../TaskResponse";
import {generateRoute} from "../../utils/Router";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";

export default class TaskParkingAreaTypeDetail extends BaseGenericRequest<{}, TaskResponse<ParkingAreaType>> {

    public constructor(parkingAreaTypeId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_PARKING_AREA_TYPE_DETAIL, {uuid: parkingAreaTypeId}));
    }
}
