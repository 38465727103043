import React, {Component, ReactNode, SyntheticEvent} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import Checkbox from '@material/react-checkbox';
import FormInputOption from "./FormInputOption";

export interface FormGroupCheckboxProps extends WrappedFieldProps {
    label?: string
    disabled?: boolean
    className?: string
    options: FormInputOption[]
    col: FormColProps
}

export default class FormCheckboxGroup extends Component<FormGroupCheckboxProps> {

    constructor(props: FormGroupCheckboxProps & WrappedFieldProps) {
        super(props);
    }

    public render(): ReactNode {
        const {input, meta, label, disabled, col, options} = this.props;
        const {value: values = [], ...otherInput} = input;
        return (
            <FormCol {...col} >
                    <div className={'form-group'}>
                        <label className='main-label'>{label}</label>
                        <div>
                            {
                                options.map((option) => {
                                    const checked = values ? values.includes(option.value) : false;

                                    const updateValues = (event: SyntheticEvent) => {
                                        event.preventDefault();

                                        if (checked) {
                                            const newValues = values.filter((value) => value !== option.value);
                                            otherInput.onChange(newValues);
                                        } else {
                                            const newValues = [...values, option.value];
                                            otherInput.onChange(newValues);
                                        }
                                    };

                                    return (
                                        <React.Fragment key={option.value}>
                                            <Checkbox
                                                nativeControlId={option.name}
                                                checked={checked && !option.disabled}
                                                onChange={updateValues}
                                                onBlur={() => otherInput.onBlur(values)}
                                                disabled={disabled || option.disabled}
                                            />
                                            <label className={'label-checkbox'} htmlFor={option.name}>{option.name}</label>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        <label className="error">{meta.touched ? meta.error : ""}</label>
                    </div>
                </FormCol>
        );
    }

}
