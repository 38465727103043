
import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskReportInvalidationReasonDelete from "../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonDelete";
import TaskReportInvalidationReasonList from "../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD } from "../../../../routing/Routes";
import ReportInvalidationReasonAdminListActions from "./ReportInvalidationReasonAdminListActions";
import ReportInvalidationReasonAdminListReducer from "./ReportInvalidationReasonAdminListReducer";
import ReportInvalidationReasonAdminListTable from "./ReportInvalidationReasonAdminListTable";

const mapActionsToProps = ReportInvalidationReasonAdminListActions.autoMapToProps();
const mapStateToProps = ReportInvalidationReasonAdminListReducer.autoMapToProps();

class ReportInvalidationReasonAdminListCard extends React.Component<typeof mapActionsToProps & typeof mapStateToProps> {
  public componentWillMount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const {loading, reportInvalidationReasons, error, pager} = this.props;

    return (
      <CardList
        loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.RAZONES_DE_INVALIDACION)}
        sort={{
          column: "name",
        }}
        TaskList={TaskReportInvalidationReasonList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_RAZONES),
        }}
        data={reportInvalidationReasons}
        error={error}
        ItemsTable={ReportInvalidationReasonAdminListTable}
        addItem={ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_RAZONES),
          Task: TaskReportInvalidationReasonDelete,
        }}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportInvalidationReasonAdminListCard as unknown as React.ComponentType<void>);
