import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import ParkingAreaType, { ParkingAreaTypeData } from "../../model/ParkingAreaType";
import { generateRoute } from "../../utils/Router";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskParkingAreaTypeEdit extends BaseGenericRequest<ParkingAreaTypeData, TaskResponse<ParkingAreaType>> {
  private readonly data: ParkingAreaTypeData;

  public constructor(parkingAreaTypeId: string, parkingAreaTypeData: ParkingAreaTypeData) {
    super(METHOD.PUT, generateRoute(Urls.URL_PARKING_AREA_TYPE_EDIT, {
      uuid: parkingAreaTypeId,
    } ));
    this.data = parkingAreaTypeData;

    this.setErrorMessage(488, I18n.tr(I18nKeys.YA_EXISTE_UN_ELEMENTO_SIMILAR));
  }

  protected getRequest(): ParkingAreaTypeData {
    return this.data;
  }
}
