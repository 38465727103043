import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import ParkingLossReason, {ParkingLossReasonData} from "../../model/ParkingLossReason";

export default class TaskParkingLossReasonAdd extends BaseGenericRequest<ParkingLossReasonData, TaskResponse<ParkingLossReason>> {

    private readonly data: ParkingLossReasonData;

    public constructor(data: ParkingLossReasonData) {
        super(METHOD.POST, Urls.URL_PARKING_LOSS_REASON_ADD);
        this.data = data;
    }

    protected getRequest(): ParkingLossReasonData {
        return this.data;
    }
}
