import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { generateRoute } from '../../utils/Router';
import ReportCancellationMethod, { ReportCancellationMethodData } from '../../model/ReportCancellationMethod';

export default class TaskReportCancellationMethodEdit extends BaseGenericRequest<ReportCancellationMethodData, TaskResponse<ReportCancellationMethod>> {

    private readonly data: ReportCancellationMethodData;

    public constructor(id: string, data: ReportCancellationMethodData) {
        super(METHOD.PUT, generateRoute(Urls.URL_REPORT_CANCELLATION_METHOD_EDIT, {uuid: id}));
        this.data = data;
    }

    protected getRequest(): ReportCancellationMethodData {
        return this.data;
    }
}
