import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import DeltaCar, {DeltaCarData} from "../../model/DeltaCar";

export default class TaskDeltaCarEdit extends BaseGenericRequest<DeltaCarData, TaskResponse<DeltaCar>> {

    private readonly data: DeltaCarData;

    public constructor(deltaCarId: string, deltaCarData: DeltaCarData) {
        super(METHOD.PUT, generateRoute(Urls.URL_DELTA_CAR_EDIT, {uuid: deltaCarId}));
        this.data = deltaCarData;
    }

    protected getRequest(): DeltaCarData {
        return this.data;
    }
}
