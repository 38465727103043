import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";
import {METHOD} from "@cuatroochenta/co-generic-request";
import ParkingMeter from "../../model/ParkingMeter";
import {generateRoute} from "../../utils/Router";

export default class TaskParkingMeterDetail extends BaseGenericRequest<{}, TaskResponse<ParkingMeter>> {

    public constructor(parkingMeterId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_PARKING_METER_DETAIL, {uuid: parkingMeterId}));
    }
}
