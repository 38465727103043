import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import Street from "../../model/Street";

type StreetCriteraProps = TaskCriteriaRequest<{}>

export default class TaskStreetList extends BaseGenericRequest<StreetCriteraProps, TaskResponse<Street[]>> {

    private readonly data: StreetCriteraProps;

    public constructor(criteria: StreetCriteraProps = {}) {
        super(METHOD.POST, Urls.URL_STREET_LIST);
        this.data = criteria;
    }

    protected getRequest(): StreetCriteraProps {
        return this.data;
    }

}
