import React from "react";
import I18n from "../../../commons/I18n/I18n";
import ReportExportHistory from "../../../commons/model/ReportExportHistory";
import Button from "../../../components/buttons/Button";
import Th from "../../../components/table/Th";
import I18nKeys from "../../../I18n/I18nKeys";
import DateFormatter from "../../../utils/DateFormatter";
import { OnSortHandler, SortState } from "../../../utils/Sort";

interface ReportExportHistoryTableItemProps {
    data: ReportExportHistory[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

const openUrl = (url: string): void => {
  window.open(url, "_blank");
};

export default class ReportExportHistoryTableItem extends React.Component<ReportExportHistoryTableItemProps> {
  public render(): React.ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((reportExportHistory: ReportExportHistory) => (
            <tr key={reportExportHistory.lot}>
              {renderSelectTd ? renderSelectTd(reportExportHistory.lot) : null}
              <td>
                {reportExportHistory.lot}
              </td>
              <td>
                {DateFormatter.formatDate(reportExportHistory.start_date)}
              </td>
              <td>
                {DateFormatter.formatDate(reportExportHistory.end_date)}
              </td>
              <td>
                {reportExportHistory.export_proccess_report_count}
              </td>
              <td>
                {DateFormatter.formatDate(reportExportHistory.date)}
              </td>
              <td>
                <Button
                  text={I18n.tr(I18nKeys.DESCARGAR_FICHERO)}
                  onClick={() => openUrl(reportExportHistory.file_export_url)}
                  type={"button"}
                  block={false}
                  className={"btn-sm btn-primary"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.LOTE)}
          columnName={"lot"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA_INICIO)}
          columnName={"start_date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA_FIN)}
          columnName={"end_date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.TOTAL_DENUNCIAS_TRAMITADAS)}
          columnName={"export_proccess_report_count"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FICHERO_DE_ENVIO_A_TRAMITE)}
          columnName={"file_export_url"}
          {...commonProps}
        />
      </tr>
    );
  }
}
