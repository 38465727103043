import { Component, SyntheticEvent } from "react";
import I18n from "../commons/I18n/I18n";
import { AppIcon } from "../config/Config";
import I18nKeys from "../I18n/I18nKeys";
import { getMBfromBytes } from "../utils/StringUtils";
import Button from "./buttons/Button";
import Col from "./Col";
import Icon from "./Icon";
import ProgressBar from "./ProgressBar";

export interface FileProgressProps {
    cancelHandler: (event: SyntheticEvent<HTMLButtonElement>)=> void;
    percentLoaded: number;
    size: number;
    icon?: string;
}

export default class FileProgress extends Component<FileProgressProps> {
  public render() {
    const {size, percentLoaded, cancelHandler, icon = AppIcon.DOCUMENTATION} = this.props;
    const currentMB = getMBfromBytes((size * percentLoaded) / 100);

    return (
      <Col xs={2} sm={2} md={2} lg={2} className={"text-center"}>
        <div className={"progress-icon"}>
          <Icon icon={icon}/>
          <p>{`${currentMB} ${I18n.tr(I18nKeys.DE)} ${getMBfromBytes(size)}MB`}</p>
          <ProgressBar now={percentLoaded} />
          <Button
            text={I18n.tr(I18nKeys.CANCELAR)}
            onClick={cancelHandler}
            type={"button"}
            block={true}
            className={"btn-primary btn"}
          />
        </div>
      </Col>
    );
  }
}
