import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskVehicleBrandDelete from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandDelete";
import TaskVehicleBrandList from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_BRAND_ADD } from "../../../../routing/Routes";
import VehicleBrandListAdminReducer from "./VehicleBrandListAdminReducer";
import VehicleBrandListTable from "./VehicleBrandListTable";

const mapStateToProps = VehicleBrandListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class VehicleBrandListCardAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {loading, vehicleBrands, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.MARCAS_DE_VEHICULOS)}
        sort={{
          column: "id",
        }}
        TaskList={TaskVehicleBrandList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_MARCAS_DE_VEHICULOS),
        }}
        data={vehicleBrands}
        error={error}
        ItemsTable={VehicleBrandListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_MARCAS),
          Task: TaskVehicleBrandDelete,
        }}
        addItem={ROUTE_ADMIN_VEHICLE_BRAND_ADD}
      />
    );
  }
}

export default connect(mapStateToProps)(VehicleBrandListCardAdmin as unknown as React.ComponentType<void>);
