import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import DeltaCar, {DeltaCarData} from "../../model/DeltaCar";

export default class TaskDeltaCarAdd extends BaseGenericRequest<DeltaCarData, TaskResponse<DeltaCar>> {

    private readonly data: DeltaCarData;

    public constructor(data: DeltaCarData) {
        super(METHOD.POST, Urls.URL_DELTA_CAR_ADD);
        this.data = data;
    }

    protected getRequest(): DeltaCarData {
        return this.data;
    }
}
