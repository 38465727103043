import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import LawType, {LawTypeData} from "../../model/LawType";

export default class TaskLawTypeEdit extends BaseGenericRequest<LawTypeData, TaskResponse<LawType>> {

    private readonly data: LawTypeData;

    public constructor(lawTypeId: string, lawTypeData: LawTypeData) {
        super(METHOD.PUT, generateRoute(Urls.URL_LAW_TYPE_EDIT, {uuid: lawTypeId}));
        this.data = lawTypeData;
    }

    protected getRequest(): LawTypeData {
        return this.data;
    }
}
