import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import ReportCancellationLog from "../../model/ReportCancellationLog";

export type ReportCancellationLogCriteriaProps = TaskCriteriaRequest<{date: Date}>

export default class TaskReportCancellationMethodList extends BaseGenericRequest<ReportCancellationLogCriteriaProps, TaskResponse<ReportCancellationLog[]>> {

    private readonly data: ReportCancellationLogCriteriaProps;

    public constructor(criteria: ReportCancellationLogCriteriaProps) {
        super(METHOD.POST, Urls.URL_REPORT_CANCELLATION_LOG_LIST);
        this.data = criteria;
    }

    protected getRequest(): ReportCancellationLogCriteriaProps {
        return this.data;
    }
}
