import { Component, ReactNode } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import DropDownCustomToggle from './DropDownCustomToggle';
import Icon from '../Icon';

export interface DropDownOption {
    id?: number | string
    text?: string
    icon?: string
    onClick?: () => void
    divider?: boolean
}

export interface DropDownProps {
    idSelected?: number | string,
    options: DropDownOption[],
    pullRight?: boolean,
    className?: string,
}

export default class DropDown extends Component<DropDownProps> {

    public render(): ReactNode {
        const {
            idSelected,
            options,
            pullRight,
            className,
            children,
        } = this.props;

        return (
            <Dropdown id={'dropdown'} pullRight={pullRight} className={className}>
                <DropDownCustomToggle bsRole="toggle">
                    {children}
                </DropDownCustomToggle>
                <Dropdown.Menu>
                    {
                        options.map((option: DropDownOption, index: number) => {
                            const {
                                id,
                                text,
                                icon,
                                onClick,
                                divider,
                            } = option;

                            const active = ( idSelected !== undefined && id !== undefined && idSelected === id );

                            if ( divider ) {
                                return <MenuItem key={index} divider/>;
                            } else {
                                return (
                                    <MenuItem key={index} onClick={onClick} active={active}>
                                        {icon && <Icon icon={icon}/>}{text}
                                    </MenuItem>
                                );
                            }
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
