import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { StyleProps } from './typings';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - PROPS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface ButtonProps {
    label: string
    disabled?: boolean
    type?: 'btn-filled' | 'btn-bordered'
    onClick: () => void
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const NewButton: FunctionComponent<ButtonProps & StyleProps> = (
    {
        label,
        disabled = false,
        type = 'btn-filled',
        className = '',
        style,
        onClick,
    }
) => {
    const classDisabled = disabled ? 'btn-disabled' : '';
    const classComponent = classNames('btn-new', type, classDisabled, className);

    return (
        <button
            type={'button'}
            disabled={disabled}
            onClick={onClick}
            className={classComponent}
            children={label}
            style={style}
        />
    );
};
