import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {PagerBackendProps} from "../../../../components/Pager";
import LogUserAdminScreenActions from "./LogUserCardAdminActions";
import Log from "../../../../commons/model/Log";
import TaskLogList from "../../../../commons/ws/log/TaskLogList";
import User from "../../../../commons/model/User";
import TaskUserDetail from "../../../../commons/ws/user/TaskUserDetail";

interface InitialState {
    loading: boolean,

    logs: Log[],
    user?: User,
    error: string,
    pager: PagerBackendProps,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    logs: [],
    user: undefined,
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0
    },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskLogList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskLogList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskLogList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskLogList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            logs: payload.data,
            pager: payload.pager || oldState.pager,
        }
    })

    .onEvent(TaskUserDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskUserDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskUserDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            user: payload.data,
        }
    })

    .onEvent(LogUserAdminScreenActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build()
