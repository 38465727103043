/* eslint-disable max-classes-per-file */
import { Component, ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import { Field, FormErrors, InjectedFormProps } from "redux-form";
import BaseFormFieldsHelpers from "../../../../../base/form/BaseFormFieldsHelpers";
import I18n from "../../../../../commons/I18n/I18n";
import Exploitation from "../../../../../commons/model/Exploitation";
import Law from "../../../../../commons/model/Law";
import CardCollapsed from "../../../../../components/card/CardCollapsed";
import FormInput from "../../../../../components/form/FormInput";
import FormInputOption from "../../../../../components/form/FormInputOption";
import FormSelect from "../../../../../components/form/FormSelect";
import FormSwitch from "../../../../../components/form/FormSwitch";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { formStateAutoMapToPropsFactory } from "../../../../../utils/FormUtils";
import { isEmailValid } from "../../../../../utils/StringUtils";
import StreetSmartParkIdField from "./fields/StreetSmartParkField";

const FORM_NAME = "ExploitationCardFormAdmin";

export interface ExploitationConnectionAppSectionAdminData {
    connectionEasypark: boolean;
    connectionMoviltik: boolean;
    connectionStreetSmart: boolean;
    moviltikUsername?: string;
    moviltikPassword?: string;
    notifyCraneEmails: string;
    sosEmails: string;
    connectionBeepark: boolean;
    beeparkApiToken: string;
    beeparkExploitationId: string;

    connectionDeltaCar: boolean;
    deltaCarToken: string;
    connectionTicketLine: boolean;
    ticketLineUsername: string;
    ticketLinePassword: string;
    deltaCarLawId: string;
    timeExcessLawId: string;
    streetSmartParkId: string;
}

interface ExploitationConnectionnAppSectionProps {
    className?: string;
    invalid: boolean;
    existErrorsHandler: (isValid: boolean)=> void;
    laws: Law[];
    editMode: boolean;
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationConnectionAppSectionAdminData>(FORM_NAME);
const mapStateToProps = mapFormStateToProps;

type Props =
    ExploitationConnectionnAppSectionProps & InjectedFormProps<ExploitationConnectionAppSectionAdminData> & typeof mapStateToProps;

interface State {
    collapsed: boolean;
}

export enum ExploitationConnectionAppSectionFields {
    CONNECTION_EASYPARK = "connectionEasypark",
    CONNECTION_MOVILTIK = "connectionMoviltik",
    CONNECTION_STREET_SMART = "connectionStreetSmart",
    MOVILTIK_USERNAME = "moviltikUsername",
    MOVILTIK_PASSWORD = "moviltikPassword",
    NOTIFY_CRANE_EMAILS = "notifyCraneEmails",
    SOS_EMAILS = "sosEmails",
    CONNECTION_BEEPARK = "connectionBeepark",
    BEEPARK_API_TOKEN = "beeparkApiToken",
    BEEPARK_EXPLOITATION_ID = "beeparkExploitationId",
    CONNECTION_DELTA_CAR = "connectionDeltaCar",
    DELTA_CAR_TOKEN = "deltaCarToken",
    CONNECTION_TICKET_LINE = "connectionTicketLine",
    TICKET_LINE_USERNAME = "ticketLineUsername",
    TICKET_LINE_PASSWORD = "ticketLinePassword",
    DELTA_CAR_LAW_ID = "deltaCarLawId",
    TIME_EXCESS_LAW_ID = "timeExcessLawId",
    STREET_SMART_PARK_ID = "streetSmartParkId",
}

class ExploitationConnectionAppSectionAdmin extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  private onCollapsedCard = () => {
    this.setState( {
      collapsed: !this.state.collapsed,
    } );
  };

  public render(): ReactNode {
    const {collapsed} = this.state;
    const {invalid,
      className,
      valid,
      existErrorsHandler,
      formState,
      laws,
      editMode} = this.props;
    const moviltikEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_MOVILTIK];
    const connectionBeeparkEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_BEEPARK];
    const deltaCarEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_DELTA_CAR];
    const ticketLineEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_TICKET_LINE];
    const streetSmartEnabled = formState && formState.values && formState.values[ExploitationConnectionAppSectionFields.CONNECTION_STREET_SMART];
    const lawOptions: FormInputOption[] = laws.map((law) => ( {
      value: law.id,
      name: `${law.law_type.name} ${law.municipal_code}`,
    } ));

    existErrorsHandler(valid);

    return (
      <CardCollapsed title={I18n.tr(I18nKeys.INTEGRACION_CON_OTROS_SISTEMAS)}
        collapsedHandler={this.onCollapsedCard}
        collapsed={collapsed}
        invalid={invalid}
        className={className}
      >
        <Row>
          <Field
            label={I18n.tr(I18nKeys.MOVILTIK)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationConnectionAppSectionFields.CONNECTION_MOVILTIK}
            component={FormSwitch}
            col={{
              md: 3,
              lg: 3,
            }}
          />
          {moviltikEnabled &&
                    <>
                      <Field
                        label={I18n.tr(I18nKeys.USUARIO_MOVILTIK)}
                        name={ExploitationConnectionAppSectionFields.MOVILTIK_USERNAME}
                        component={FormInput}
                        col={{
                          md: 3,
                          lg: 3,
                        }}
                      />
                      <Field
                        label={I18n.tr(I18nKeys.CONTRASENA_MOVILTIK)}
                        name={ExploitationConnectionAppSectionFields.MOVILTIK_PASSWORD}
                        component={FormInput}
                        col={{
                          md: 3,
                          lg: 3,
                        }}
                      />
                    </>
          }
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.BEEPARK)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationConnectionAppSectionFields.CONNECTION_BEEPARK}
            component={FormSwitch}
            col={{
              md: 3,
              lg: 3,
            }}
          />
          {connectionBeeparkEnabled &&
                    <>
                      <Field
                        label={I18n.tr(I18nKeys.TOKEN)}
                        name={ExploitationConnectionAppSectionFields.BEEPARK_API_TOKEN}
                        component={FormInput}
                        col={{
                          md: 3,
                          lg: 3,
                        }}
                      />
                      <Field
                        label={I18n.tr(I18nKeys.EXPLOTACION_ID)}
                        name={ExploitationConnectionAppSectionFields.BEEPARK_EXPLOITATION_ID}
                        component={FormInput}
                        col={{
                          md: 3,
                          lg: 3,
                        }}
                      />
                    </>
          }
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.EASYPARK)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationConnectionAppSectionFields.CONNECTION_EASYPARK}
            component={FormSwitch}
            col={{
              md: 3,
              lg: 3,
            }}
          />
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.EMAILS_NOTIFICACION_GRUA)}
            name={ExploitationConnectionAppSectionFields.NOTIFY_CRANE_EMAILS}
            component={FormInput}
            col={{
              md: 9,
              lg: 9,
            }}
          />
          <Field
            label={I18n.tr(I18nKeys.EMAILS_SOS)}
            name={ExploitationConnectionAppSectionFields.SOS_EMAILS}
            component={FormInput}
            col={{
              md: 9,
              lg: 9,
            }}
          />
        </Row>

        {editMode && <Row>
          <Field
            label={I18n.tr(I18nKeys.VEHICULO_EMBARCADO)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationConnectionAppSectionFields.CONNECTION_DELTA_CAR}
            component={FormSwitch}
            col={{
              md: 3,
              lg: 3,
            }}
          />
          {deltaCarEnabled &&
                    <>
                      <Field
                        label={I18n.tr(I18nKeys.TOKEN)}
                        name={ExploitationConnectionAppSectionFields.DELTA_CAR_TOKEN}
                        component={FormInput}
                        col={{
                          md: 8,
                          lg: 8,
                        }}
                      />
                      <Field
                        label={I18n.tr(I18nKeys.LEY_SIN_TICKET)}
                        name={ExploitationConnectionAppSectionFields.DELTA_CAR_LAW_ID}
                        component={FormSelect}
                        options={lawOptions}
                        col={{
                          md: 4,
                          lg: 4,
                        }}
                      />
                      <Field
                        label={I18n.tr(I18nKeys.LEY_EXCESO_TICKET)}
                        name={ExploitationConnectionAppSectionFields.TIME_EXCESS_LAW_ID}
                        component={FormSelect}
                        options={lawOptions}
                        col={{
                          md: 4,
                          lg: 4,
                        }}
                      />
                    </>
          }
        </Row>}
        <Row>
          <Field
            label={I18n.tr(I18nKeys.TICKET_LINE)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationConnectionAppSectionFields.CONNECTION_TICKET_LINE}
            component={FormSwitch}
            col={{
              md: 3,
              lg: 3,
            }}
          />
          {ticketLineEnabled &&
                    <>
                      <Field
                        label={I18n.tr(I18nKeys.NOMBRE_DE_USUARIO)}
                        name={ExploitationConnectionAppSectionFields.TICKET_LINE_USERNAME}
                        component={FormInput}
                        col={{
                          md: 4,
                          lg: 4,
                        }}
                      />
                      <Field
                        label={I18n.tr(I18nKeys.CONTRASENA)}
                        name={ExploitationConnectionAppSectionFields.TICKET_LINE_PASSWORD}
                        component={FormInput}
                        type={"password"}
                        col={{
                          md: 3,
                          lg: 3,
                        }}
                      />
                    </>
          }
        </Row>
        <Row>
          <Field
            label={I18n.tr(I18nKeys.FLOWBIRD)}
            labelEnabled={I18n.tr(I18nKeys.SI)}
            labelDisabled={I18n.tr(I18nKeys.NO)}
            name={ExploitationConnectionAppSectionFields.CONNECTION_STREET_SMART}
            component={FormSwitch}
            col={{
              md: 3,
              lg: 3,
            }}
          />
          {
            streetSmartEnabled
              ? (
                <StreetSmartParkIdField
                  label={I18n.tr(I18nKeys.PARK_ID)}
                  name={ExploitationConnectionAppSectionFields.STREET_SMART_PARK_ID}
                  col={{
                    md: 4,
                    lg: 4,
                  }}
                />
              )
              : null
          }
        </Row>
      </CardCollapsed>
    );
  }
}

// @ts-ignore
export default connect(mapStateToProps)(ExploitationConnectionAppSectionAdmin) as ComponentType<ExploitationConnectionnAppSectionProps>;

export class ExploitationConnectionAppSectionAdminHelpers extends BaseFormFieldsHelpers {
  public static validate(values: ExploitationConnectionAppSectionAdminData) {
    const errors: FormErrors<ExploitationConnectionAppSectionAdminData> = {
    };

    if (values.connectionMoviltik) {
      if (!values.moviltikUsername || values.moviltikUsername.length === 0)
        errors.moviltikUsername = I18n.tr(I18nKeys.USUARIO_OBLIGATORIO);

      if (!values.moviltikPassword || values.moviltikPassword.length === 0)
        errors.moviltikPassword = I18n.tr(I18nKeys.CONTRASENA_OBLIGATORIA);
    }

    if (values.notifyCraneEmails && values.notifyCraneEmails.length > 0) {
      const emails = values.notifyCraneEmails.replace(/\s/g, "").split(",");

      for (const email of emails) {
        if (!isEmailValid(email)) {
          errors.notifyCraneEmails = I18n.tr(I18nKeys.FORMATO_INCORRECTO_EMAILS);
          break;
        }
      }
    }

    if (values.sosEmails && values.sosEmails.length > 0) {
      const emails = values.sosEmails.replace(/\s/g, "").split(",");

      for (const email of emails) {
        if (!isEmailValid(email)) {
          errors.sosEmails = I18n.tr(I18nKeys.FORMATO_INCORRECTO_EMAILS);
          break;
        }
      }
    }

    if (values.connectionBeepark) {
      if (!values.beeparkApiToken || values.beeparkApiToken.length === 0)
        errors.beeparkApiToken = I18n.tr(I18nKeys.TOKEN_OBLIGATORIO);

      if (!values.beeparkExploitationId || values.beeparkExploitationId.length === 0)
        errors.beeparkExploitationId = I18n.tr(I18nKeys.EXPLOTACION_ID_OBLIGATORIO);
    }

    if (values.connectionDeltaCar) {
      if (!values.deltaCarToken || values.deltaCarToken.length === 0)
        errors.deltaCarToken = I18n.tr(I18nKeys.TOKEN_OBLIGATORIO);

      if (!values.deltaCarLawId || values.deltaCarLawId === "-1")
        errors.deltaCarLawId = I18n.tr(I18nKeys.LEY_OBLIGATORIA);

      if (!values.timeExcessLawId || values.timeExcessLawId === "-1")
        errors.timeExcessLawId = I18n.tr(I18nKeys.LEY_OBLIGATORIA);
    }

    if (values.connectionTicketLine) {
      if (!values.ticketLineUsername || values.ticketLineUsername.length === 0)
        errors.ticketLineUsername = I18n.tr(I18nKeys.NOMBRE_DE_USUARIO_OBLIGATORIO);

      if (!values.ticketLinePassword || values.ticketLinePassword.length === 0)
        errors.ticketLinePassword = I18n.tr(I18nKeys.CONTRASENA_OBLIGATORIA);
    }

    if (values.connectionStreetSmart) {
      if (!values.streetSmartParkId || values.streetSmartParkId === "-1")
        errors.streetSmartParkId = I18n.tr(I18nKeys.PARK_ID_OBLIGATORIO);
    }

    return errors;
  }

  public static getFormData(formData: Partial<Exploitation>): ExploitationConnectionAppSectionAdminData {
    return {
      connectionEasypark: formData.connection_easypark ? formData.connection_easypark : false,
      connectionMoviltik: formData.connection_moviltik ? formData.connection_moviltik : false,
      connectionStreetSmart: formData.connection_street_smart ? formData.connection_street_smart : false,
      moviltikUsername: formData.moviltik_username || "",
      moviltikPassword: formData.moviltik_password || "",
      notifyCraneEmails: formData.notify_crane_emails || "",
      sosEmails: formData.sos_emails || "",
      connectionBeepark: formData.connection_beepark ? formData.connection_beepark : false,
      beeparkApiToken: formData.beepark_api_token || "",
      beeparkExploitationId: formData.beepark_exploitation_id || "",
      connectionDeltaCar: formData.connection_delta_car ? formData.connection_delta_car : false,
      deltaCarToken: formData.delta_car_token || "",
      connectionTicketLine: formData.connection_ticket_line ? formData.connection_ticket_line : false,
      ticketLineUsername: formData.ticket_line_username || "",
      ticketLinePassword: formData.ticket_line_password || "",
      deltaCarLawId: formData.delta_car_law_id || "",
      timeExcessLawId: formData.time_excess_law_id || "",
      streetSmartParkId: formData.street_smart_park_id ? String(formData.street_smart_park_id) : "",
    };
  }
}
