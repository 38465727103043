import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import User from "../commons/model/User";
import TaskUserCurrentDetail from "../commons/ws/user/TaskUserCurrentDetail";
import {UserRoleValue} from "../commons/model/UserRole";

interface State {
    currentUser: User | null,
    loadingCurrentUser: boolean,
    isAdmin: boolean,
    isComplaintViewerSent: boolean,
    isComplaintViewer: boolean,
    isPolice: boolean,
}

const INITIAL_STATE: State = {
    currentUser: null,
    loadingCurrentUser: false,
    isAdmin: false,
    isComplaintViewerSent: false,
    isComplaintViewer: false,
    isPolice: false,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUserCurrentDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            currentUser: payload.data,
            isAdmin: Object.values(payload.data.roles.map((role) => role.id)).includes(UserRoleValue.ADMIN),
            isComplaintViewerSent: Object.values(payload.data.roles.map((role) => role.id)).includes(UserRoleValue.COMPLAINT_VIEWER_SENT),
            isComplaintViewer: Object.values(payload.data.roles.map((role) => role.id)).includes(UserRoleValue.COMPLAINT_VIEWER),
            isPolice: Object.values(payload.data.roles.map((role) => role.id)).includes(UserRoleValue.POLICE),
        }
    })
    .onEvent(TaskUserCurrentDetail.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({
        ...oldState,
        currentUser: null,
        isAdmin: false,
        isComplaintViewerSent: false,
        isComplaintViewer: false,
        isPolice: false
    }))
    .onEvent(TaskUserCurrentDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loadingCurrentUser: true
    }))
    .onEvent(TaskUserCurrentDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loadingCurrentUser: false
    }))
    .build();
