import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import VehicleBrand, {VehicleBrandData} from "../../model/VehicleBrand";

export default class TaskVehicleBrandEdit extends BaseGenericRequest<VehicleBrandData, TaskResponse<VehicleBrand>> {

    private readonly data: VehicleBrandData;

    public constructor(vehicleBrabdId: string, vehicleBrandData: VehicleBrandData) {
        super(METHOD.PUT, generateRoute(Urls.URL_VEHICLE_BRAND_EDIT, {uuid: vehicleBrabdId}));
        this.data = vehicleBrandData;
    }

    protected getRequest(): VehicleBrandData {
        return this.data;
    }
}
