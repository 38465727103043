export default class LocalStorageManager {

    public static setItem<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public static getItem<T>(key: string): T | null {
        const item: string | null = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    public static removeItem(key: string) {
        localStorage.removeItem(key);
    }

}
