import React from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import User from "../../../../commons/model/User";
import TaskParkingMeterIncidenceList, { ParkingMeterIncidenceCriteriaProps } from "../../../../commons/ws/parkingMeterIncidence/TaskParkingMeterIncidenceList";
import TaskParkingMeterIncidenceTypeList from "../../../../commons/ws/parkingMeterIncidenceType/TaskParkingMeterIncidenceTypeList";
import TaskUserList from "../../../../commons/ws/user/TaskUserList";
import CardCollapsed from "../../../../components/card/CardCollapsed";
import CardList from "../../../../components/card/CardList";
import { typeaheadOptionsBuilder } from "../../../../components/filter";
import { IncidenceStateOptions } from "../../../../components/filter/FilterSelectOptions";
import { AutocompleteOption } from "../../../../components/form/FormAutocomplete";
import FormCol from "../../../../components/form/FormCol";
import FormInputOption from "../../../../components/form/FormInputOption";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import ParkingMeterIncidenceListCompanyUserActions from "./ParkingMeterIncidenceListCompanyUserActions";
import ParkingMeterIncidenceListCompanyUserReducer from "./ParkingMeterIncidenceListCompanyUserReducer";
import ParkingMeterIncidenceListTable from "./ParkingMeterIncidenceListTable";

const mapStateToProps = ParkingMeterIncidenceListCompanyUserReducer.autoMapToProps();
const mapActionsToProps = ParkingMeterIncidenceListCompanyUserActions.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

interface State {
    currentCriteria: ParkingMeterIncidenceCriteriaProps;
    collapseHeaderContent: boolean;
}

class ParkingMeterIncidenceListCardCompanyUser extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      currentCriteria: {
      },
      collapseHeaderContent: true,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskParkingMeterIncidenceTypeList( {
      limit : 0,
      sort: "+name",
    } ).execute();
  }

  public componentWillUnMount(): void {
    this.props.clearReducer();
  }

  private onParkingMeterIncidenceTypeSelect = (selectParkingMeterIncidenceTypeId: string): void => {
    const newCriteria: ParkingMeterIncidenceCriteriaProps = {
      ...this.state.currentCriteria,
      type_ids: (selectParkingMeterIncidenceTypeId !== "-1") ? [selectParkingMeterIncidenceTypeId] : undefined,
    };

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  private onUserSelect = (selectedUser?: AutocompleteOption<User>): void => {
    const newCriteria: ParkingMeterIncidenceCriteriaProps = {
      ...this.state.currentCriteria,
      controller_ids: selectedUser ? [selectedUser.value] : undefined,
    };

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  private onUserSearch = (newSearch: string): void => {
    new TaskUserList( {
      ...this.state.currentCriteria,
      search: newSearch,
    } ).execute();
  };

  private onStartDateSelect = (date?: Date | null): void => {
    const newCriteria: ParkingMeterIncidenceCriteriaProps = {
      ...this.state.currentCriteria,
      start_date: date ? date.toISOString() : undefined,
    };

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  private onEndDateSelect = (date?: Date | null): void => {
    const newCriteria: ParkingMeterIncidenceCriteriaProps = {
      ...this.state.currentCriteria,
    };

    if (date) {
      date.setDate(date.getDate() + 1);
      newCriteria.end_date = date.toISOString();
    } else
      delete newCriteria.end_date;

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  private onSolvedSelect = (selectSolvedId: string): void => {
    const newCriteria: ParkingMeterIncidenceCriteriaProps = {
      ...this.state.currentCriteria,
      solved: (selectSolvedId !== "-1") ? selectSolvedId === IncidenceStateOptions.SOLVED.value : undefined,
    };

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  private onCollapsedCard = () => {
    this.setState( {
      collapseHeaderContent: !this.state.collapseHeaderContent,
    } );
  };

  public render(): React.ReactNode {
    const {loading, parkingMeterIncidences, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.INCIDENCIAS_PARQUIMETROS)}
        sort={{
          column: "",
        }}
        TaskList={TaskParkingMeterIncidenceList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_INCIDENCIAS),
        }}
        data={parkingMeterIncidences}
        error={error}
        ItemsTable={ParkingMeterIncidenceListTable}
        customCriteria={this.state.currentCriteria}
      >
        {parkingMeterIncidences && this.renderHeaderContent()}
      </CardList>
    );
  }

  private renderHeaderContent = (): React.ReactNode => {
    const {parkingMeterIncidenceTypeList, userList, userListLoading} = this.props;
    const searchPlaceholder = I18n.tr(I18nKeys.BUSCAR);
    const parkingMeterIncidenceTypeOptions = parkingMeterIncidenceTypeList.map((parkingMeterIncidenceType) => ( {
      value: parkingMeterIncidenceType.id,
      name: parkingMeterIncidenceType.name,
    } ));
    const userOptions = typeaheadOptionsBuilder.users(userList);

    return (
      <CardCollapsed
        title={I18n.tr(I18nKeys.FILTROS)}
        collapsed={this.state.collapseHeaderContent}
        collapsedHandler={this.onCollapsedCard}
        className={"card-filters"}
      >
        <Row>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <label>{I18n.tr(I18nKeys.TIPO_DE_INCIDENCIA)}</label>
              <select className={"form-control show-tick bootstrap-select"}
                onChange={(e) => this.onParkingMeterIncidenceTypeSelect(e.target.value)}>
                <option key={"-1"} value={"-1"}/>
                {parkingMeterIncidenceTypeOptions.map((option: FormInputOption, index) => (<option key={index} value={option.value}>{option.name}</option>))}
              </select>
            </div>
          </FormCol>

          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <div className={"form-line"}>
                <label>{I18n.tr(I18nKeys.CONTROLADOR)}</label>
                <AsyncTypeahead
                  placeholder={searchPlaceholder}
                  promptText={searchPlaceholder}
                  options={userOptions}
                  onChange={(selected) => {
                    this.onUserSelect(selected[0]);
                  }}
                  onInputChange={
                    (newText: string) => newText === "" && this.onUserSelect(undefined)
                  }
                  labelKey={"name"}
                  filterBy={() => true}
                  isLoading={false}
                  onSearch={this.onUserSearch}
                  searchText={userListLoading ?
                    I18n.tr(I18nKeys.BUSCANDO) :
                    I18n.tr(I18nKeys.NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                />
              </div>
            </div>
          </FormCol>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <div className={"form-line"}>
                <label>{I18n.tr(I18nKeys.FECHA_INICIO)}</label>
                <input type={"date"} className="form-control"
                  onChange={(e) => this.onStartDateSelect(e.target.valueAsDate)}/>
              </div>
            </div>
          </FormCol>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <div className={"form-line"}>
                <label>{I18n.tr(I18nKeys.FECHA_FIN)}</label>
                <input type={"date"} className="form-control"
                  onChange={(e) => this.onEndDateSelect(e.target.valueAsDate)}/>
              </div>
            </div>
          </FormCol>
        </Row>
        <Row>
          <FormCol md={3} lg={3}>
            <div className={"form-group"}>
              <label>{I18n.tr(I18nKeys.SOLUCIONADA)}</label>
              <select className={"form-control show-tick bootstrap-select"}
                onChange={(e) => this.onSolvedSelect(e.target.value)}>
                <option key={"-1"} value={"-1"}/>
                <option key={IncidenceStateOptions.SOLVED.value}
                  value={IncidenceStateOptions.SOLVED.value}>
                  {IncidenceStateOptions.SOLVED.name}
                </option>
                <option key={IncidenceStateOptions.NO_SOLVED.value}
                  value={IncidenceStateOptions.NO_SOLVED.value}>
                  {IncidenceStateOptions.NO_SOLVED.name}
                </option>
              </select>
            </div>
          </FormCol>
        </Row>
      </CardCollapsed>
    );
  };
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterIncidenceListCardCompanyUser as unknown as React.ComponentType<void>);
