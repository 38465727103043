import Screen from "./Screen";
import {Redirect} from "react-router";
import {ROUTE_HOME} from "../../routing/Routes";
import AuthManager from '../../utils/AuthManager';

export default abstract class UnloggedScreen<P = {}, S = {}> extends Screen<P, S>{

    public render(): React.ReactNode {
        if (AuthManager.isLogged()) {
            return <Redirect to={ROUTE_HOME} />;
        }
        return this.renderScreen();
    }
}
