import Route from "../../../../commons/model/Route";
import StreetType from "../../../../commons/model/StreetType";
import ParkingAreaType from "../../../../commons/model/ParkingAreaType";
import StreetCardFormCompanyUserActions from "./StreetCardFormCompanyUserActions";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import TaskRouteList from "../../../../commons/ws/route/TaskRouteList";
import TaskStreetTypeList from "../../../../commons/ws/streetType/TaskStreetTypeList";
import TaskParkingAreaTypeList from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeList";

interface InitialState {
    routeList: Route[],
    routeListLoading: boolean,
    streetTypeList: StreetType[],
    streetTypeListLoading: boolean,
    parkingAreaTypeList: ParkingAreaType[],
    parkingAreaTypeListLoading: boolean,
}

const INITIAL_STATE: InitialState = {
    routeList: [],
    routeListLoading: false,
    streetTypeList: [],
    streetTypeListLoading: false,
    parkingAreaTypeList: [],
    parkingAreaTypeListLoading: false,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskRouteList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        routeListLoading: true
    }))
    .onEvent(TaskRouteList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        routeListLoading: false
    }))
    .onEvent(TaskRouteList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, routeList: payload.data};
    })

    .onEvent(TaskStreetTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        streetTypeListLoading: true
    }))
    .onEvent(TaskStreetTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        streetTypeListLoading: false
    }))
    .onEvent(TaskStreetTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, streetTypeList: payload.data};
    })

    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        parkingAreaTypeListLoading: true
    }))
    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        parkingAreaTypeListLoading: false
    }))
    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, parkingAreaTypeList: payload.data};
    })

    .onEvent(StreetCardFormCompanyUserActions.clearReducer, () => INITIAL_STATE)

    .build();
