import Exploitation from "../../../../commons/model/Exploitation";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import TaskExploitationList from "../../../../commons/ws/exploitation/TaskExploitationList";

interface InitialState {
    exploitationList: Exploitation[],
}

const INITIAL_STATE: InitialState = {
    exploitationList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, exploitationList: payload.data};
    })

    .build();
