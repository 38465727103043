/* eslint-disable camelcase */

import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Alert from "../../../../base/alerts/Alert";
import I18n from "../../../../commons/I18n/I18n";
import Report from "../../../../commons/model/Report";
import { ReportSentToProcessStateIds } from "../../../../commons/model/ReportSentToProcessState";
import { ReportStateIds } from "../../../../commons/model/ReportState";
import TaskReportDetail from "../../../../commons/ws/report/TaskReportDetail";
import TaskReportGetPDF from "../../../../commons/ws/report/TaskReportGetPDF";
import TaskReportSentToProcessStateCancelToSend from "../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateCancelToSend";
import TaskReportSentToProcessStateClaim from "../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateClaim";
import TaskReportSentToProcessStateMarkToSend from "../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateMarkToSend";
import TaskReportSentToProcessStateReturnCancellation from "../../../../commons/ws/reportSentToProcessState/TaskReportSentToProcessStateReturnCancellation";
import TaskReportStateCancel from "../../../../commons/ws/reportState/TaskReportStateCancel";
import TaskReportStateInvalidate from "../../../../commons/ws/reportState/TaskReportStateInvalidate";
import TaskReportStateRectify from "../../../../commons/ws/reportState/TaskReportStateRectify";
import { NewButtonGroup } from "../../../../components-new/ButtonGroup";
import { Card } from "../../../../components-new/Card";
import { CardHeader } from "../../../../components-new/CardHeader";
import CardBody from "../../../../components/card/CardBody";
import { ReportDetailsCardBody } from "../../../../components/sections/ReportDetailsCard";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_REPORTS } from "../../../../routing/Routes";
import { HeaderOptions } from "../list/ReportListCompanyUserReducer";
import ReportListCompanyUserStorageManager from "../list/ReportListCompanyUserStorageManager";
import ReportDetailsCompanyUserModal from "./modal/ReportDetailsCompanyUserModal";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - AUXILIARY
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface InnerProps {
    report: Report | null;
    loading: boolean;
    backRoute: string;
    isComplaintViewerSent: boolean;
    isComplaintViewer: boolean;
    isPolice: boolean;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PROPS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface State {
    showModal: boolean;
    headerOptionIdSelected: HeaderOptions | null;
    headerOptionOnConfirm: (reportOperationReasonId: string)=> void;
}

type Props = InnerProps & RouteComponentProps;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class ReportDetailsCompanyUserCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      headerOptionIdSelected: null,
      // eslint-disable-next-line no-empty-function
      headerOptionOnConfirm: () => {
      },
    };
  }

  public componentWillUnmount(): void {
    this.handleLocalStorageDeletion();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  public render() {
    const {report, loading, backRoute, isComplaintViewerSent, isComplaintViewer, isPolice} = this.props;
    const {showModal, headerOptionIdSelected, headerOptionOnConfirm} = this.state;

    return (
      <Card loading={loading}>
        <CardHeader
          title={`${I18n.tr(I18nKeys.DETALLES_DENUNCIA)} ${report && report.number}`}
          headerButtons={
            report &&
                        !isComplaintViewerSent &&
                        !isComplaintViewer &&
                        !isPolice
              ? this.renderHeaderButtons(report)
              : null
          }
        >
        </CardHeader>
        <CardBody>
          {
            report ?
              <ReportDetailsCardBody
                report={report}
                backRoute={backRoute}
              />
              : null
          }
          <ReportDetailsCompanyUserModal
            show={showModal}
            headerOptionIdSelected={headerOptionIdSelected}
            onClose={() => this.setState( {
              showModal: false,
            } )}
            onConfirm={headerOptionOnConfirm}
          />
        </CardBody>
      </Card>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private renderHeaderButtons = (report: Report) => {
    const {id, report_state, report_sent_to_process_state, claimed, cancellation_returned} = report;
    const notOkToSentOrUndo: boolean = report_state.id !== ReportStateIds.PUESTA;
    const stateCannotBeModified: boolean =
            report_sent_to_process_state.id === ReportSentToProcessStateIds.ENVIADA ||
            report_sent_to_process_state.id === ReportSentToProcessStateIds.DUPLICADA;

    return (
      <>
        <NewButtonGroup
          buttons={[
            {
              label: I18n.tr(I18nKeys.ANULAR),
              disabled: stateCannotBeModified || report_state.id === ReportStateIds.ANULADA,
              onClick: () => this.handleNullify([id]),
            },
            {
              label: I18n.tr(I18nKeys.INVALIDAR),
              disabled: stateCannotBeModified || report_state.id === ReportStateIds.INVALIDADA,
              onClick: () => this.handleInvalidate([id]),
            },
            {
              label: I18n.tr(I18nKeys.RECTIFICAR_ANULACION_INVALIDACION),
              disabled: stateCannotBeModified || report_state.id === ReportStateIds.PUESTA,
              onClick: () => this.handleRectify([id]),
            },
          ]}
        />
        <NewButtonGroup
          style={{
            marginLeft: 25,
          }}
          buttons={[
            {
              label: I18n.tr(I18nKeys.ENVIAR_A_TRAMITE),
              disabled: stateCannotBeModified || notOkToSentOrUndo || report_sent_to_process_state.id === ReportSentToProcessStateIds.PENDIENTE_DE_ENVIAR,
              onClick: () => this.handleSend([id]),
            },
            {
              label: I18n.tr(I18nKeys.DESHACER_ENVIO_A_TRAMITE),
              disabled: stateCannotBeModified || notOkToSentOrUndo || report_sent_to_process_state.id === ReportSentToProcessStateIds.NO_ENVIADA,
              onClick: () => this.handleUndoSend([id]),
            },
            {
              label: I18n.tr(I18nKeys.RECLAMAR),
              disabled: !stateCannotBeModified || claimed,
              onClick: () => this.handleClaim([id]),
            },
            {
              label: I18n.tr(I18nKeys.DEVOLVER_ANULACION),
              disabled: !stateCannotBeModified || cancellation_returned,
              onClick: () => this.handleReturnCancellation([id]),
            },
          ]}
        />
        <NewButtonGroup
          style={{
            marginLeft: 25,
          }}
          buttons={[
            {
              label: I18n.tr(I18nKeys.EXPORTAR_A_PDF),
              disabled: false,
              onClick: () => handleExportPDF(report),
            },
          ]}
        />
      </>
    );
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleNullify = (reportsIdsSelected: string[]) => {
    this.setState( {
      showModal: true,
      headerOptionIdSelected: HeaderOptions.NULLIFY,
      headerOptionOnConfirm: (reportOperationReasonId: string) =>
        new TaskReportStateCancel(reportsIdsSelected, reportOperationReasonId)
          .onSuccess(() => this.handleSuccess(I18nKeys.DENUNCIA_ANULADA))
          .onFail((error) => Alert.error(error))
          .execute(),
    } );
  };

  private handleInvalidate = (reportsIdsSelected: string[]) => {
    this.setState( {
      showModal: true,
      headerOptionIdSelected: HeaderOptions.INVALIDATE,
      headerOptionOnConfirm: (reportOperationReasonId: string) =>
        new TaskReportStateInvalidate(reportsIdsSelected, reportOperationReasonId)
          .onSuccess(() => this.handleSuccess(I18nKeys.DENUNCIA_INVALIDADA))
          .onFail((error) => Alert.error(error))
          .execute(),
    } );
  };

  private handleRectify = (reportsIdsSelected: string[]) => {
    new TaskReportStateRectify(reportsIdsSelected)
      .onSuccess(() => this.handleSuccess(I18nKeys.DENUNCIA_RECTIFICADA))
      .onFail((error) => Alert.error(error))
      .execute();
  };

  private handleSend = (reportsIdsSelected: string[]) => {
    new TaskReportSentToProcessStateMarkToSend(reportsIdsSelected)
      .onSuccess(() => this.handleSuccess(I18nKeys.DENUNCIA_PUESTA_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE))
      .onFail((error) => Alert.error(error))
      .execute();
  };

  private handleUndoSend = (reportsIdsSelected: string[]) => {
    new TaskReportSentToProcessStateCancelToSend(reportsIdsSelected)
      .onSuccess(() => this.handleSuccess(I18nKeys.DENUNCIA_PUESTA_COMO_NO_ENVIADA_A_TRAMITE))
      .onFail((error) => Alert.error(error))
      .execute();
  };

  private handleClaim = (reportsIdsSelected: string[]) => {
    new TaskReportSentToProcessStateClaim(reportsIdsSelected)
      .onSuccess(() => this.handleSuccess(I18nKeys.DENUNCIA_RECLAMADA))
      .onFail((error) => Alert.error(error))
      .execute();
  };

  private handleReturnCancellation = (reportsIdsSelected: string[]) => {
    new TaskReportSentToProcessStateReturnCancellation(reportsIdsSelected)
      .onSuccess(() => this.handleSuccess(I18nKeys.IMPORTE_ANULACION_DEVUELTO))
      .onFail((error) => Alert.error(error))
      .execute();
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleSuccess = (tr: string) => {
    new TaskReportDetail(this.props.report!.id).execute();
    Alert.success(I18n.tr(tr));
  };

  private handleLocalStorageDeletion = () => {
    const {pathname} = this.props.history.location;

    if (pathname !== ROUTE_COMPANY_USER_REPORTS)
      ReportListCompanyUserStorageManager.removeAll();
  };
}

const handleExportPDF = ( {id} ) => {
  new TaskReportGetPDF(id)
    .executeAsPromise()
    .then((response) => {
      if (response.success) {
        Alert.success(I18n.tr(I18nKeys.DENUNCIA_EXPORTADA));
        window.open(
          response.data.url,
          "_blank",
          // eslint-disable-next-line no-restricted-globals
          `screenX=0,screenY=0,left=0,top=0,fullscreen=yes,width=${ screen.availWidth - 5 },height=${ screen.availHeight - (55)}`,
        );
      }
    } )
    .catch(() => Alert.error(I18n.tr(I18nKeys.SE_HA_PRODUCIDO_UN_ERROR_EN_LA_EXPORTACION)));
};

export default withRouter(ReportDetailsCompanyUserCard);
