import { Component, CSSProperties, ReactNode } from "react";
import { ClipLoader } from "react-spinners";
import Icon from "./Icon";

export interface InfoBoxData {
    label: string;
    value: number | string;
}

interface InfoBoxProps {
    icon: string;
    title: string;
    data: InfoBoxData[][];
    style: CSSProperties;
    loading: boolean;
    columnWidth?: number;
}

export default class InfoBox extends Component<InfoBoxProps> {
  public render(): ReactNode {
    const {icon,
      title,
      data,
      loading,
      style,
      columnWidth} = this.props;

    return (
      <div
        className="info-box"
        style={style}
      >
        <div className="icon">
          {
            loading ?
              <ClipLoader
                size={"35px"}
                color={"#FFFFFF"}
                loading={loading}
              />
              :
              <Icon icon={icon}/>
          }
        </div>
        <div className="content">
          <div className="title">
            {title}
          </div>
          <div className="data">
            {
              loading
                ? null :
                data.map((infoBoxDataArray: InfoBoxData[], index: number) => {
                  const style2 = columnWidth !== undefined
                    ? {
                      maxWidth: columnWidth,
                    }
                    : {
                    };

                  return (
                    <div
                      key={index}
                      className="data-column"
                      style={style2}
                    >
                      {
                        renderDataColumn(infoBoxDataArray)
                      }
                    </div>
                  );
                } )
            }
          </div>
        </div>
        {
          loading ? <div className="loading"/> : null
        }
      </div>
    );
  }
}
const renderDataColumn = (data: InfoBoxData[]) => data.map((infoBoxData: InfoBoxData, index: number) => {
  const {label, value} = infoBoxData;

  return (
    <div key={index} className="data-row">
      <div className="text">
        {label}
      </div>
      <div className="text" style={{
        textAlign: "right",
      }}>
        {value}
      </div>
    </div>
  );
} );
