import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import ParkingAreaType from '../../../../commons/model/ParkingAreaType';
import TaskParkingAreaTypeDetail from '../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeDetail';
import ParkingAreaTypeCompanyUserFormScreenActions from './ParkingAreaTypeCompanyUserFormScreenActions';

interface InitialState {
    parkingAreaTypeFormScreenLoading: boolean,
    parkingAreaType?: ParkingAreaType,
}

const INITIAL_STATE: InitialState = {
    parkingAreaTypeFormScreenLoading: false,
    parkingAreaType: undefined,
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(TaskParkingAreaTypeDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ( {
        ...oldState,
        parkingAreaTypeFormScreenLoading: true
    } ))
    .onEvent(TaskParkingAreaTypeDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ( {
        ...oldState,
        parkingAreaTypeFormScreenLoading: false
    } ))
    .onEvent(TaskParkingAreaTypeDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, parkingAreaType: payload.data};
    })

    .onEvent(ParkingAreaTypeCompanyUserFormScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
