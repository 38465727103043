import { FunctionComponent } from 'react';
import { CardHeaderButtonAdd, CardHeaderButtonAssign, CardHeaderButtonRefresh } from './CardHeaderButtons';
import { CardHeaderSearcher } from './CardHeaderSearcher';
import { CardHeaderDropdown } from './CardHeaderDropdown';
import { CardHeaderProps } from './typings';

export const CardHeader: FunctionComponent<CardHeaderProps> = (
    {
        headerOptionIdSelected,
        headerOptions,
        headerButtons,
        searchText = '',
        children,
        title,
        onChangeSearch,
        onRefresh,
        onAssign,
        onAdd,
    }
) => {
    return (
        <div className={'card-header'}>
            <div className={'ch-header'}>
                <div className={'ch-title'}>
                    {title}
                </div>
                <div className={'ch-buttons'}>
                    {headerButtons}
                    {
                        headerOptions && headerOptionIdSelected !== undefined ?
                            <CardHeaderDropdown
                                headerOptionIdSelected={headerOptionIdSelected}
                                headerOptions={headerOptions}
                            /> : null
                    }
                </div>
            </div>
            <div className={'c-search-filter'}>
                {
                    onChangeSearch ?
                        <CardHeaderSearcher
                            searchText={searchText}
                            onChangeSearch={onChangeSearch}
                        /> : null
                }
                {
                    onRefresh ? <CardHeaderButtonRefresh onClick={onRefresh}/> : null
                }
                {
                    onAssign ? <CardHeaderButtonAssign onClick={onAssign}/> : null
                }
                {
                    onAdd ? <CardHeaderButtonAdd onClick={onAdd}/> : null
                }
            </div>
            {
                children ?
                    <div className={'ch-body'}>
                        {children}
                    </div> : null
            }
        </div>
    );
};
