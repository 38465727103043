import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import Vehicle from '../../../../../commons/model/Vehicle';
import User from '../../../../../commons/model/User';
import Street from '../../../../../commons/model/Street';
import TaskVehicleList from '../../../../../commons/ws/vehicle/TaskVehicleList';
import TaskStreetList from '../../../../../commons/ws/street/TaskStreetList';
import Route from '../../../../../commons/model/Route';
import TaskRouteList from '../../../../../commons/ws/route/TaskRouteList';
import RatificationReportListCompanyUserFilterActions from './RatificationReportListCompanyUserFilterActions';
import TaskUserControllerList from '../../../../../commons/ws/user/TaskUserControllerList';
import TaskUserRatifierList from '../../../../../commons/ws/user/TaskUserRatifierList';

interface InitialState {
    vehicles: Vehicle[]
    vehiclesLoading: boolean
    routes: Route[]
    routesLoading: boolean
    controllers: User[]
    controllersLoading: boolean
    ratifiers: User[]
    ratifiersLoading: boolean
    streets: Street[]
    streetsLoading: boolean
}

const INITIAL_STATE: InitialState = {
    vehicles: [],
    vehiclesLoading: false,
    routes: [],
    routesLoading: false,
    controllers: [],
    controllersLoading: false,
    ratifiers: [],
    ratifiersLoading: false,
    streets: [],
    streetsLoading: false,
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)

    .onEvent(
        RatificationReportListCompanyUserFilterActions.dispatchResetFilterReducer,
        () => ( {...INITIAL_STATE} )
    )

    // - - - VEHICLES

    .onEvent(
        TaskVehicleList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, vehiclesLoading: true} )
    )
    .onEvent(
        TaskVehicleList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, vehiclesLoading: false} )
    )
    .onEvent(
        TaskVehicleList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, vehicles: payload.data} )
    )

    // - - - ROUTES

    .onEvent(
        TaskRouteList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, routesLoading: true} )
    )
    .onEvent(
        TaskRouteList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, routesLoading: false} )
    )
    .onEvent(
        TaskRouteList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, routes: payload.data} )
    )

    // - - - CONTROLLERS

    .onEvent(
        TaskUserControllerList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, controllersLoading: true} )
    )
    .onEvent(
        TaskUserControllerList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, controllersLoading: false} )
    )
    .onEvent(
        TaskUserControllerList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, controllers: payload.data} )
    )

    // - - - RATIFIERS

    .onEvent(
        TaskUserRatifierList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, ratifiersLoading: true} )
    )
    .onEvent(
        TaskUserRatifierList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, ratifiersLoading: false} )
    )
    .onEvent(
        TaskUserRatifierList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, ratifiers: payload.data} )
    )

    // - - - STREETS

    .onEvent(
        TaskStreetList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, streetsLoading: true} )
    )
    .onEvent(
        TaskStreetList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, streetsLoading: false} )
    )
    .onEvent(
        TaskStreetList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, streets: payload.data} )
    )

    .build();
