import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {PagerBackendProps} from "../../../../components/Pager";
import Exploitation from "../../../../commons/model/Exploitation";
import TaskExploitationList from "../../../../commons/ws/exploitation/TaskExploitationList";
import ExploitationListAdminScreenActions from "./ExploitationListcardAdminActions";
import TaskExploitationDelete from "../../../../commons/ws/exploitation/TaskExploitationDelete";

interface InitialState {
    loading: boolean,
    exploitations: Exploitation[],
    error: string,
    pager: PagerBackendProps,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    exploitations: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskExploitationDelete.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskExploitationDelete.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })

    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            exploitations: payload.data,
            pager: payload.pager || oldState.pager,
        }
    })

    .onEvent(ExploitationListAdminScreenActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build()
