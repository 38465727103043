/* eslint-disable camelcase */
import I18n from "../../commons/I18n/I18n";
import Law from "../../commons/model/Law";
import ReportCancellationMethod from "../../commons/model/ReportCancellationMethod";
import ReportInvalidationReason from "../../commons/model/ReportInvalidationReason";
import ReportSentToProcessState from "../../commons/model/ReportSentToProcessState";
import ReportState from "../../commons/model/ReportState";
import Route from "../../commons/model/Route";
import User from "../../commons/model/User";
import I18nKeys from "../../I18n/I18nKeys";
import FormInputOption from "../form/FormInputOption";

const formInputOptionSorter = (optionA: FormInputOption, optionB: FormInputOption) => {
  if (optionA.name > optionB.name)
    return 1;

  if (optionA.name < optionB.name)
    return -1;

  return 0;
};
// - - - - -
const reportStates = (data: ReportState[]): FormInputOption[] => data.map(( {id, name} ) => ( {
  value: id,
  name,
} ));
const reportCancellationMethods = (data: ReportCancellationMethod[]): FormInputOption[] => data.map(( {id, name} ) => ( {
  value: id,
  name,
} ));
const reportInvalidationReasons = (data: ReportInvalidationReason[]): FormInputOption[] => data.map(( {id, name} ) => ( {
  value: id,
  name,
} ));
const reportSentToProcessStates = (data: ReportSentToProcessState[]): FormInputOption[] => data.map(( {id, name} ) => ( {
  value: id,
  name,
} ));
const routes = (data: Route[]): FormInputOption[] => data.map(
  ( {id, name} ) => ( {
    value: id,
    name,
  } ),
).sort(formInputOptionSorter);
const laws = (data: Law[]): FormInputOption[] => data.map(
  ( {id, municipal_code, screen_text} ) => ( {
    value: id,
    name: `${municipal_code} - ${screen_text}`,
  } ),
).sort(formInputOptionSorter);
const users = (data: User[]): FormInputOption[] => data.map(( {id, name, last_name} ) => ( {
  value: id,
  name: `${name} ${last_name}`,
} ),
).sort(formInputOptionSorter);
const ratified: FormInputOption[] = [
  {
    name: I18n.tr(I18nKeys.ADVERADA),
    value: "true",
  },
  {
    name: I18n.tr(I18nKeys.PENDIENTE),
    value: "false",
  },
];
const controllerSignature: FormInputOption[] = [
  {
    name: I18n.tr(I18nKeys.SI),
    value: "true",
  },
  {
    name: I18n.tr(I18nKeys.NO),
    value: "false",
  },
];
const ratifierSignature: FormInputOption[] = [
  {
    name: I18n.tr(I18nKeys.SI),
    value: "true",
  },
  {
    name: I18n.tr(I18nKeys.NO),
    value: "false",
  },
];

// - - - - - TODO: PIC: Refactorización - Sustituir

export class IncidenceStateOptions {
  public static SOLVED = {
    name: I18n.tr(I18nKeys.SOLUCIONADA),
    value: "1",
  };

  public static NO_SOLVED = {
    name: I18n.tr(I18nKeys.NO_SOLUCIONADA),
    value: "2",
  };
}

const incidenceState: FormInputOption[] = [
  {
    name: I18n.tr(I18nKeys.SOLUCIONADA),
    value: "1",
  },
  {
    name: I18n.tr(I18nKeys.NO_SOLUCIONADA),
    value: "2",
  },
];

// - - - - -

export const selectOptionsBuilder = {
  reportStates,
  reportCancellationMethods,
  reportInvalidationReasons,
  reportSentToProcessStates,
  routes,
  laws,
  users,
  ratified: () => ratified,
  controllerSignature: () => controllerSignature,
  ratifierSignature: () => ratifierSignature,
  incidenceState: () => incidenceState,
};
