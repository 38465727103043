import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {ReportCancellationLogCriteriaProps} from "./TaskReportCancellationLogList";
import Upload from "../../model/Upload";


export default class TaskReportCancellationLogExport extends BaseGenericRequest<ReportCancellationLogCriteriaProps, TaskResponse<Upload>> {

    private readonly data: ReportCancellationLogCriteriaProps;

    public constructor(criteria: ReportCancellationLogCriteriaProps) {
        super(METHOD.POST, Urls.URL_REPORT_CANCELLATION_LOG_EXPORT);
        this.data = criteria;
    }

    protected getRequest(): ReportCancellationLogCriteriaProps {
        return this.data;
    }

}
