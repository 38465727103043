import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import ParkingLossReason, {ParkingLossReasonData} from "../../model/ParkingLossReason";

export default class TaskParkingLossReasonEdit extends BaseGenericRequest<ParkingLossReasonData, TaskResponse<ParkingLossReason>> {

    private readonly data: ParkingLossReasonData;

    public constructor(parkingLossReasonId: string, parkingLossReasonData: ParkingLossReasonData) {
        super(METHOD.PUT, generateRoute(Urls.URL_PARKING_LOSS_REASON_EDIT, {uuid: parkingLossReasonId}));
        this.data = parkingLossReasonData;
    }

    protected getRequest(): ParkingLossReasonData {
        return this.data;
    }
}
