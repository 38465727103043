import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskVehicleColorDelete from "../../../../commons/ws/vehicleColor/TaskVehicleColorDelete";
import TaskVehicleColorList from "../../../../commons/ws/vehicleColor/TaskVehicleColorList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_COLOR_ADD } from "../../../../routing/Routes";
import VehicleColorListAdminReducer from "./VehicleColorListAdminReducer";
import VehicleColorListTable from "./VehicleColorListTable";

const mapStateToProps = VehicleColorListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class VehicleColorListCardAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {loading, vehicleColors, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.COLORES_DE_VEHICULOS)}
        sort={{
          column: "id",
        }}
        TaskList={TaskVehicleColorList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_COLORES_DE_VEHICULOS),
        }}
        data={vehicleColors}
        error={error}
        ItemsTable={VehicleColorListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_COLORES),
          Task: TaskVehicleColorDelete,
        }}
        addItem={ROUTE_ADMIN_VEHICLE_COLOR_ADD}
      />
    );
  }
}

export default connect(mapStateToProps)(VehicleColorListCardAdmin as unknown as React.ComponentType<void>);
