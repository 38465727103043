import moment from "moment";

class DateUtils {
   private static INSTANCE = new DateUtils();

   public static getInstance() {
       return DateUtils.INSTANCE;
   }

   public getHours(date: string) {
       return moment(date).format('HH:mm')
   }

   public getSimpleDate(date: string) {
       return moment(date).format('D MMM YYYY')
   }

   public getLastMonth():string{
    return moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
   }
}

export default DateUtils.getInstance();
