import I18n from "../../../../../commons/I18n/I18n";
import ScreenCard from "../../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../../config/Config";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_BROADCAST } from "../../../../../routing/Routes";
import ChatBroadcastCardCompanyUser from "./ChatBroadcastCardCompanyUser";

const ChatBroadcastCompanyUserScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.MENSAJES_A_TODOS),
      url: ROUTE_COMPANY_USER_BROADCAST,
      icon: AppIcon.POST_ADD,
    },
  ]}>
    <ChatBroadcastCardCompanyUser/>
  </ScreenCard>
);

export default ChatBroadcastCompanyUserScreen;
