/* eslint-disable camelcase */
import { FunctionComponent } from "react";
import Alert from "../../base/alerts/Alert";
import I18n from "../../commons/I18n/I18n";
import Report from "../../commons/model/Report";
import TaskReportRatifier from "../../commons/ws/report/TaskReportRatifier";
import TaskReportRatifierCancel from "../../commons/ws/report/TaskReportRatifierCancel";
import { NewButton } from "../../components-new/Button";
import { Card } from "../../components-new/Card";
import { CardHeader } from "../../components-new/CardHeader";
import { CardSectionCollapsed2 } from "../../components-new/CardSectionCollapsed2";
import I18nKeys from "../../I18n/I18nKeys";
import DateFormatter from "../../utils/DateFormatter";
import NumberFormatter from "../../utils/NumberFormatter";
import { goToRoute } from "../../utils/Router";
import CardBody from "../card/CardBody";
import Col from "../Col";
import DetailsImage from "../detail/DetailsImage";
import DetailsLink from "../detail/DetailsLink";
import DetailsText from "../detail/DetailsText";
import Row from "../Row";

interface Props {
    report: Report | null;
    loading: boolean;
    backRoute: string;
    withRatifier?: boolean;
    withStateOperations?: boolean;
    withSentToProcessStateOperations?: boolean;
    withExportPDF?: boolean;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const handleGoBack = (route) => goToRoute(route);
const handleRatifier = ( { id, ratifier_images } ) =>
  new TaskReportRatifier(id, ratifier_images, undefined)
    .onFail((error) => Alert.error(error))
    .onSuccess(() => Alert.success(I18n.tr(I18nKeys.DENUNCIA_ADVERADA)))
    .execute();
const handleRatifierCancel = ( { id } ) =>
  new TaskReportRatifierCancel(id)
    .onFail((error) => Alert.error(error))
    .onSuccess(() => Alert.success(I18n.tr(I18nKeys.ADVERACION_ANULADA)))
    .execute();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const ReportDetailsCard: FunctionComponent<Props> = (
  {report,
    loading,
    backRoute,
    withRatifier = false,
    withExportPDF = false,
    withStateOperations = false,
    withSentToProcessStateOperations = false},
) => {
  const hasHeaderButtons = withExportPDF || withRatifier;

  return (
    <Card loading={loading}>
      <CardHeader
        title={`${I18n.tr(I18nKeys.DETALLES_DENUNCIA)} ${report && report.number}`}
        headerButtons={
          hasHeaderButtons && report ?
            <HeaderButtons
              report={report}
              withExportPDF={withExportPDF}
              withRatifier={withRatifier}
              withStateOperations={withStateOperations}
              withSentToProcessStateOperations={withSentToProcessStateOperations}
            />
            : null
        }
      >
      </CardHeader>
      <CardBody>
        {
          report ?
            <ReportDetailsCardBody
              report={report}
              backRoute={backRoute}
            />
            : null
        }
      </CardBody>
    </Card>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const ReportDetailsCardBody: FunctionComponent<any> = (
  {report,
    backRoute},
) => {
  const {date,
    expiration_date,
    number: reportNumber,
    cancellation_number,
    report_state,
    report_invalidation_reason,
    report_invalidation_image,
    report_invalidation_observations,
    report_cancellation_method,
    vehicle,
    law,
    workday,
    street,
    street_number,
    // street_situation,
    // street_situation_description,
    ratified,
    ratifier,
    ratification_date,
    report_sent_to_process_state,
    claimed,
    cancellation_returned,
    notify_crane,
    observations,
    signature_image_controller,
    signature_image_ratifier,
    report_pdf,
    report_ratified_pdf,
    images,
    ratifier_images,
    // latitude,
    // longitude,
    exploitation,
    driver_name,
    driver_last_name,
    driver_nif,
    driver_address,
    driver_type,
    driver_signature_image} = report as Report;
  const sectionCollapsed = false;
  const sectionCollapsedStyle = {
    marginBottom: "30px",
  };
  const inputDirection = `${street.name } ${ street_number}`;

  return (
    <>
      <CardSectionCollapsed2
        title={I18n.tr(I18nKeys.VEHICULO)}
        initialCollapsed={sectionCollapsed}
        style={sectionCollapsedStyle}
      >
        <Row>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.MATRICULA)}
              value={vehicle.license_plate}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.TIPO_DE_VEHICULO)}
              value={vehicle.vehicle_type ? vehicle.vehicle_type.name : "-"}
            />
          </Col>
        </Row>
        <Row style={{
          marginTop: 15, 
        }}>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.MARCA)}
              value={vehicle.vehicle_model && vehicle.vehicle_model.vehicle_brand ? vehicle.vehicle_model.vehicle_brand.name : "-"}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.MODELO)}
              value={vehicle.vehicle_model ? vehicle.vehicle_model.name : "-"}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.COLOR)}
              value={vehicle.vehicle_color ? vehicle.vehicle_color.name : "-"}
            />
          </Col>
        </Row>
      </CardSectionCollapsed2>
      <CardSectionCollapsed2
        title={I18n.tr(I18nKeys.DATOS_DENUNCIA)}
        initialCollapsed={sectionCollapsed}
        style={sectionCollapsedStyle}
      >
        <Row>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.NUMERO_DE_DENUNCIA)}
              value={reportNumber}
            />
          </Col>
          {
            cancellation_number
              ? (
                <Col md={3} lg={3}>
                  <DetailsText
                    label={I18n.tr(I18nKeys.NUMERO_DE_ANULACION)}
                    value={cancellation_number}
                  />
                </Col>
              )
              : null
          }
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.FECHA)}
              value={DateFormatter.formatDatetime(date)}
            />
          </Col>
          {
            expiration_date
              ? (
                <Col md={3} lg={3}>
                  <DetailsText
                    label={I18n.tr(I18nKeys.FECHA_DE_EXPIRACION)}
                    value={DateFormatter.formatDatetime(expiration_date)}
                  />
                </Col>
              )
              : null
          }
        </Row>
        <Row style={{
          marginTop: 15, 
        }}>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.IMPORTE_A_PAGAR)}
              value={NumberFormatter.formatMoneyNumber(law.amount)}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.IMPORTE_REDUCIDO)}
              value={NumberFormatter.formatMoneyNumber(law.reduced_amount)}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.IMPORTE_ANULACION)}
              value={NumberFormatter.formatMoneyNumber(law.cancellation_amount)}
            />
          </Col>
        </Row>
        <Row style={{
          marginTop: 15,
        }}>
          <Col md={3} lg={3}>
            <DetailsText
              label={`${I18n.tr(I18nKeys.LEY)} / ${I18n.tr(I18nKeys.ARTICULO)}`}
              value={law.screen_text}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.ESTADO_DE_LA_DENUNCIA)}
              value={report_state.name}
            />
          </Col>
          <Col md={2} lg={2}>
            <DetailsText
              label={I18n.tr(I18nKeys.TRAMITE_DE_LA_DENUNCIA)}
              value={report_sent_to_process_state.name}
            />
          </Col>
          <Col md={2} lg={2}>
            <DetailsText
              label={I18n.tr(I18nKeys.RECLAMADA)}
              value={I18n.tr(claimed ? I18nKeys.SI : I18nKeys.NO)}
            />
          </Col>
          <Col md={2} lg={2}>
            <DetailsText
              label={I18n.tr(I18nKeys.IMPORTE_ANULACION_DEVUELTO)}
              value={I18n.tr(cancellation_returned ? I18nKeys.SI : I18nKeys.NO)}
            />
          </Col>
        </Row>
        <Row style={{
          marginTop: 15,
        }}>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.CIUDAD)}
              value={exploitation.name}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.DIRECCION)}
              value={inputDirection}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.RUTA)}
              value={workday.route ? workday.route.name : "-"}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.TIPO_DE_ZONA)}
              value={street.parking_area_type.name}
            />
          </Col>
        </Row>
        <Row style={{
          marginTop: 15,
        }}>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.ESTADO_ADVERACION)}
              value={ratified ? I18n.tr(I18nKeys.ADVERADA) : I18n.tr(I18nKeys.PENDIENTE)}
            />
          </Col>
          <Col md={3} lg={3}>
            <DetailsText
              label={I18n.tr(I18nKeys.NOTIFICACION_DE_LA_GRUA)}
              value={notify_crane ? I18n.tr(I18nKeys.AVISADA) : I18n.tr(I18nKeys.NO_AVISADA)}
            />
          </Col>
        </Row>
        <Row style={{
          marginTop: 15,
        }}>
          <Col md={9} lg={9}>
            <DetailsText
              label={I18n.tr(I18nKeys.OBSERVACIONES)}
              value={observations}
            />
          </Col>
        </Row>
        <Row style={{
          marginTop: 15,
        }}>
          {
            report_invalidation_reason
              ? (
                <Col md={3} lg={3}>
                  <DetailsText
                    label={I18n.tr(I18nKeys.RAZON_DE_INVALIDACION)}
                    value={report_invalidation_reason && report_invalidation_reason.name}
                  />
                </Col>
              )
              : null
          }
          {
            report_invalidation_observations
              ? (
                <Col md={3} lg={3}>
                  <DetailsText
                    label={I18n.tr(I18nKeys.OBSERVACIONES_DE_INVALIDACION)}
                    value={report_invalidation_observations}
                  />
                </Col>
              )
              : null
          }
          {
            report_cancellation_method
              ? (
                <Col md={3} lg={3}>
                  <DetailsText
                    label={I18n.tr(I18nKeys.RAZON_DE_ANULACION)}
                    value={report_cancellation_method && report_cancellation_method.name}
                  />
                </Col>
              )
              : null
          }
        </Row>
        {
          report_invalidation_image
            ? (
              <Row style={{
                marginTop: 15,
              }}>
                <Col md={3} lg={3}>
                  <DetailsImage
                    label={I18n.tr(I18nKeys.FOTOGRAFIA_DE_INVALIDACION)}
                    images={report_invalidation_image ? [report_invalidation_image] : []}
                    imageWidth={"254px"}
                    imageHeight={"160px"}
                  />
                </Col>
              </Row>
            )
            : null
        }
      </CardSectionCollapsed2>
      <CardSectionCollapsed2
        title={I18n.tr(I18nKeys.CONTROLADOR)}
        initialCollapsed={sectionCollapsed}
        style={sectionCollapsedStyle}
      >
        <Row>
          <Col md={4} lg={4}>
            <DetailsText
              label={I18n.tr(I18nKeys.NOMBRE)}
              value={workday.user.name}
            />
            <DetailsImage
              label={I18n.tr(I18nKeys.FIRMA)}
              images={signature_image_controller ? [signature_image_controller] : []}
              style={{
                marginTop: 15, 
              }}
            />
          </Col>
          <Col md={8} lg={8}>
            <DetailsImage
              label={I18n.tr(I18nKeys.FOTOGRAFIAS)}
              images={images || []}
              imageWidth={"254px"}
              imageHeight={"160px"}
            />
          </Col>
        </Row>
      </CardSectionCollapsed2>
      {
        ratifier ?
          <CardSectionCollapsed2
            title={I18n.tr(I18nKeys.ADVERADOR)}
            style={sectionCollapsedStyle}
            initialCollapsed={sectionCollapsed}
          >
            <Row>
              <Col md={4} lg={4}>
                <DetailsText
                  label={I18n.tr(I18nKeys.NOMBRE)}
                  value={ratifier.name}
                />
                <DetailsText
                  label={I18n.tr(I18nKeys.FECHA_Y_HORA_DE_RATIFICACION)}
                  value={DateFormatter.formatDatetime(ratification_date)}
                  style={{
                    marginTop: 15, 
                  }}
                />
                <DetailsImage
                  label={I18n.tr(I18nKeys.FIRMA)}
                  images={signature_image_ratifier ? [signature_image_ratifier] : []}
                  style={{
                    marginTop: 15, 
                  }}
                />
              </Col>
              <Col md={8} lg={8}>
                <DetailsImage
                  label={I18n.tr(I18nKeys.FOTOGRAFIAS)}
                  images={ratifier_images || []}
                  imageWidth={"254px"}
                  imageHeight={"160px"}
                />
              </Col>
            </Row>
          </CardSectionCollapsed2>
          : null
      }
      <CardSectionCollapsed2
        title={I18n.tr(I18nKeys.DOCUMENTACION)}
        initialCollapsed={sectionCollapsed}
        style={sectionCollapsedStyle}
      >
        <Row>
          <Col md={12} lg={12}>
            <DetailsLink
              label={I18n.tr(I18nKeys.PDF_DENUNCIA)}
              href={report_pdf}
            />
          </Col>
          <Col md={12} lg={12}>
            <DetailsLink
              label={I18n.tr(I18nKeys.PDF_DENUNCIA_RATIFICADA)}
              href={report_ratified_pdf}
            />
          </Col>
        </Row>
      </CardSectionCollapsed2>
      {
        exploitation.data_driver_screen
          ? (
            <CardSectionCollapsed2
              title={I18n.tr(I18nKeys.DATOS_CONDUCTOR)}
              initialCollapsed={sectionCollapsed}
              style={sectionCollapsedStyle}
            >

              <Row>
                <Col md={8} lg={8}>
                  <Row>
                    <Col md={6} lg={6}>
                      <DetailsText
                        label={I18n.tr(I18nKeys.NIF)}
                        value={driver_nif}
                      />
                    </Col>
                    <Col md={6} lg={6}>
                      <DetailsText
                        label={I18n.tr(I18nKeys.TIPO_CONDUCTOR)}
                        value={driver_type && driver_type.name}
                      />
                    </Col>
                  </Row>
                  <Row style={{
                    marginTop: 15, 
                  }}>
                    <Col md={6} lg={6}>
                      <DetailsText
                        label={I18n.tr(I18nKeys.NOMBRE)}
                        value={driver_name}
                      />
                    </Col>
                    <Col md={6} lg={6}>
                      <DetailsText
                        label={I18n.tr(I18nKeys.APELLIDO)}
                        value={driver_last_name}
                      />
                    </Col>
                  </Row>
                  <Row style={{
                    marginTop: 15, 
                  }}>
                    <Col md={12} lg={12}>
                      <DetailsText
                        label={I18n.tr(I18nKeys.DIRECCION)}
                        value={driver_address}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={4} lg={4}>
                  {
                    driver_signature_image
                      ? (
                        <DetailsImage
                          label={I18n.tr(I18nKeys.FIRMA)}
                          images={[driver_signature_image]}
                          style={{
                            marginTop: 15, 
                          }}
                        />
                      )
                      : (
                        <DetailsText
                          label={I18n.tr(I18nKeys.FIRMA)}
                          value={I18n.tr(I18nKeys.NO_PRESENTE)}
                        />
                      )
                  }
                </Col>
              </Row>
            </CardSectionCollapsed2>
          )
          : null
      }
      <BodyButtons backRoute={backRoute} />
    </>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const HeaderButtons: FunctionComponent<any> = (
  {report,
    withRatifier},
) => {
  const { ratified } = report!;

  return (
    <>
      {
        withRatifier ?
          <NewButton
            label={ratified ? I18n.tr(I18nKeys.ANULAR_ADVERACION) : I18n.tr(I18nKeys.ADVERAR_DENUNCIA)}
            type={"btn-bordered"}
            style={{
              marginRight: 25,
            }}
            onClick={ratified ? () => handleRatifierCancel(report) : () => handleRatifier(report)}
          />
          : null
      }
    </>
  );
};
const BodyButtons: FunctionComponent<any> = (
  {backRoute},
) => (
  <div className={"card-footer"}>
    <NewButton
      label={I18n.tr(I18nKeys.VOLVER)}
      disabled={false}
      type={"btn-filled"}
      style={{
        width: "133px", 
      }}
      onClick={() => handleGoBack(backRoute)}
    />
  </div>
);
