import Col from "../../../components/Col";
import Row from "../../../components/Row";
import LoggedScreen from "../../../base/screens/LoggedScreen";
import ChatCardCompanyUser from "./ChatCardCompanyUser";

export default class ChatCompanyUserScreen extends LoggedScreen<{}> {

    public renderScreen(): React.ReactNode {
        return (
            <section className="content">
                <div className="container-fluid">
                    <Row clearfix>
                        <Col xs={12} sm={12} md={12} lg={12}>

                            <ChatCardCompanyUser/>

                        </Col>
                    </Row>
                </div>
            </section>
        )
    }
}
