import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import Exploitation from '../../model/Exploitation';
import { generateRoute } from '../../utils/Router';
import { AppInfoData } from '../../model/AppInfo';

export default class TaskAppInfoEdit extends BaseGenericRequest<AppInfoData, TaskResponse<Exploitation>> {

    private readonly data: AppInfoData;

    public constructor(appInfoData: AppInfoData) {
        super(METHOD.PUT, generateRoute(Urls.URL_APP_INFO_EDIT));
        this.data = appInfoData;
    }

    protected getRequest(): AppInfoData {
        return this.data;
    }

}
