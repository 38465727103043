import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import Vehicle from "../../model/Vehicle";

export default class TaskVehicleDetail extends BaseGenericRequest<{}, TaskResponse<Vehicle>> {

    public constructor(vehicleId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_VEHICLE_DETAIL, {uuid: vehicleId}));
    }
}
