import { Component, ReactNode } from 'react';
import FormCol, { FormColProps } from './FormCol';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';

export interface FormTimeProps extends WrappedFieldProps {
    label?: string
    placeholder?: string
    disabled?: boolean
    className?: string
    col: FormColProps
}

export default class FormTime extends Component<FormTimeProps> {

    public render(): ReactNode {
        const {input, meta, label, placeholder, disabled, col} = this.props;
        const classFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error
        });

        return (
            <FormCol {...col} >
                <div className={'form-group'}>
                    <div className={classFormLine}>
                        <label htmlFor="">{label}</label>
                        <input
                            {...input}
                            id={input.name}
                            type={'time'}
                            className={'form-control'}
                            placeholder={placeholder || 'HH:MM'}
                            disabled={disabled}
                            // onChange={(text: any) => console.log(text.currentTarget)}
                        />
                    </div>
                    <label className="error">{meta.touched ? meta.error : ''}</label>
                </div>
            </FormCol>
        );
    }
}
