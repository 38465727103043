import React, { FunctionComponent } from 'react';
import Pager from '../components/Pager';
import SelectTh from '../components/table/SelectTh';
import Icon from '../components/Icon';
import { CardBodyProps } from './typings';

export const CardBody: FunctionComponent<CardBodyProps> = (
    {
        itemIdsSelectedDisabled,
        itemIdsSelected,
        itemIdsDisabled,
        showCheckboxes,
        dataLoading,
        dataPager,
        elementsLabelPager,
        dataError,
        dataSort,
        data,
        TableComponent,
        emptyOptions,
        footer,
        onGoToItemDetails,
        onSelectAllItems,
        onSelectItem,
        onFetchData,
        onSetPage,
        onSort,
    }
) => {
    const isEmpty = ( !data || data.length === 0 ) && !dataLoading;

    if ( isEmpty ) {
        const {message, addHandler} = emptyOptions;

        return (
            <div className={'empty-msg-cnt'}>
                <h4 className={'text-center m-t-30 m-b-30'}>{message}</h4>
                {
                    addHandler ?
                        <a onClick={addHandler}>
                            <Icon className={'empty-msg-icon'} icon={'add_circle'}/>
                        </a> : null
                }
            </div>
        );
    }

    if ( dataError ) {
        return <h4 className={'table-error'}>{dataError}</h4>;
    }

    return (
        <div className={'card-body table-responsive'}>
            <React.Fragment>
                <TableComponent
                    data={data}
                    error={dataError}
                    sort={dataSort}
                    onSort={onSort}
                    refreshListHandler={onFetchData}
                    showCheckboxes={showCheckboxes}
                    itemIdsSelectedDisabled={itemIdsSelectedDisabled}
                    itemIdsSelected={itemIdsSelected}
                    itemIdsDisabled={itemIdsDisabled}
                    onSelectItem={onSelectItem}
                    onSelectAllItems={onSelectAllItems}
                    onGoToItemDetails={onGoToItemDetails}
                >
                    <SelectTh
                        title={''}
                        showSelect={false}
                    />
                </TableComponent>
                <Pager
                    data={dataPager}
                    onPage={onSetPage}
                    elementsLabel={elementsLabelPager}
                />
                {
                    footer ? footer : null
                }
            </React.Fragment>
        </div>
    );
};
