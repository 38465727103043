import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_MODELS } from "../../../../routing/Routes";
import VehicleModelListCardAdmin from "./VehicleModelListCardAdmin";

const VehicleModelListAdminScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.MODELOS_DE_VEHICULOS),
      url: ROUTE_ADMIN_VEHICLE_MODELS,
      icon: AppIcon.CAR,
    },
  ]}>
    <VehicleModelListCardAdmin/>
  </ScreenCard>
);
  
export default VehicleModelListAdminScreen;
