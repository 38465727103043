import LocalStorageManager from '../../../../utils/LocalStorageManager';
import { ReportCriteriaProps } from '../../../../commons/ws/report/TaskReportList';
import { PagerBackendProps } from '../../../../components/Pager';
import { ReportListCompanyUserFilterValues } from './filter/ReportListCompanyUserFilter';
import { SortState } from '../../../../utils/Sort';

enum Keys {
    REPORT_LIST_FILTERS = 'REPORT_LIST_FILTERS',
    REPORT_LIST_DATA_CRITERIA = 'REPORT_LIST_DATA_CRITERIA',
    REPORT_LIST_DATA_PAGER = 'REPORT_LIST_DATA_PAGER',
    REPORT_LIST_DATA_SORT = 'REPORT_LIST_DATA_SORT',
}

export default class ReportListCompanyUserStorageManager {

    public static removeAll() {
        ReportListCompanyUserStorageManager.removeFilters();
        ReportListCompanyUserStorageManager.removeDataCriteria();
        ReportListCompanyUserStorageManager.removeDataPager();
        ReportListCompanyUserStorageManager.removeDataSort();
    }

    public static setFilters(data: ReportListCompanyUserFilterValues) {
        LocalStorageManager.setItem(Keys.REPORT_LIST_FILTERS, data);
    }

    public static getFilters(): ReportListCompanyUserFilterValues | null {
        return LocalStorageManager.getItem(Keys.REPORT_LIST_FILTERS);
    }

    public static removeFilters() {
        LocalStorageManager.removeItem(Keys.REPORT_LIST_FILTERS);
    }

    public static setDataCriteria(data: ReportCriteriaProps) {
        LocalStorageManager.setItem(Keys.REPORT_LIST_DATA_CRITERIA, data);
    }

    public static getDataCriteria(): ReportCriteriaProps | null {
        return LocalStorageManager.getItem(Keys.REPORT_LIST_DATA_CRITERIA);
    }

    public static removeDataCriteria() {
        LocalStorageManager.removeItem(Keys.REPORT_LIST_DATA_CRITERIA);
    }

    public static setDataPager(data: PagerBackendProps) {
        LocalStorageManager.setItem(Keys.REPORT_LIST_DATA_PAGER, data);
    }

    public static getDataPager(): PagerBackendProps | null {
        return LocalStorageManager.getItem(Keys.REPORT_LIST_DATA_PAGER);
    }

    public static removeDataPager() {
        LocalStorageManager.removeItem(Keys.REPORT_LIST_DATA_PAGER);
    }

    public static setDataSort(data: SortState) {
        LocalStorageManager.setItem(Keys.REPORT_LIST_DATA_SORT, data);
    }

    public static getDataSort(): SortState | null {
        return LocalStorageManager.getItem(Keys.REPORT_LIST_DATA_SORT);
    }

    public static removeDataSort() {
        LocalStorageManager.removeItem(Keys.REPORT_LIST_DATA_SORT);
    }

};
