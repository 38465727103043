import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from 'classnames';
import FormInputOption from "./FormInputOption";

export interface FormSelectProps extends WrappedFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    options: FormInputOption[],
    disabledBorderBottom?: boolean;
    blankOptionText: string;
    errorMessageWithoutTouch?: boolean
}

export default class FormSelect extends Component<FormSelectProps> {

    constructor(props: FormSelectProps & WrappedFieldProps) {
        super(props);
    }

    public render(): ReactNode {
        const {input, meta, label, disabled, col, options, blankOptionText, disabledBorderBottom = false,
            errorMessageWithoutTouch = false} = this.props;
        const classSelectComponent =
            classNames(
                'form-control',
                'show-tick',
                'bootstrap-select',
                {'bootstrap-select-error': meta.touched && meta.error},
                disabledBorderBottom ? 'no-border-bottom' : ''
            );

        return (
            <FormCol {...col} >
                <div className="form-group">
                    {label ? <label className={'main-label'}>{label}</label> : null}
                    <select className={classSelectComponent}
                            {...input}
                            disabled={disabled || false}
                    >
                        {blankOptionText ? <option key={-1} value={-1}>{blankOptionText}</option> : null}
                        {options.map((option: FormInputOption, index) => {
                            return (<option key={index} value={option.value}>{option.name}</option>);
                        })}
                    </select>
                    {errorMessageWithoutTouch ?
                        <label className="error">{meta.error ? meta.error : ""}</label> :
                        <label className="error">{meta.touched ? meta.error : ""}</label>
                    }
                    {/*<label className="error">{meta.touched ? meta.error : ""}</label>*/}
                </div>
            </FormCol>
        );
    }

}

