import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_COLLECTION_PROCESS, ROUTE_ADMIN_COLLECTION_PROCESS_ADD } from "../../../../routing/Routes";
import CollectionProcessAddDataContainer from "./CollectionProcessAddDataContainer";

const CollectionProcessAddScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.PROCESOS_RECAUDACION),
      url: ROUTE_ADMIN_COLLECTION_PROCESS,
      icon: AppIcon.EURO,
    },
    {
      name: I18n.tr(I18nKeys.COLECTAS_PARQUIMETRO),
      url: ROUTE_ADMIN_COLLECTION_PROCESS_ADD,
      icon: AppIcon.EURO,
    },
  ]}>
    <CollectionProcessAddDataContainer/>
  </ScreenCard>
);

export default CollectionProcessAddScreen;
