import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import User, { UserData } from "../../model/User";
import { generateRoute } from "../../utils/Router";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskUserEdit extends BaseGenericRequest<UserData, TaskResponse<User>> {
  private readonly data: UserData;

  public constructor(userId: string, userData: UserData) {
    super(METHOD.PUT, generateRoute(Urls.URL_USER_EDIT, {
      uuid: userId,
    } ));
    this.data = userData;

    this.setErrorMessage(488, I18n.tr(I18nKeys.YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF));
  }

  protected getRequest(): UserData {
    return this.data;
  }
}
