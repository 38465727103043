import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import ParkingLoss from "../../../../commons/model/ParkingLoss";
import TaskParkingLossDetail from "../../../../commons/ws/parkingLoss/TaskParkingLossDetail";
import ParkingLossFormCompanyUserScreenActions from "./ParkingLossFormCompanyUserScreenActions";

interface InitialState {
    parkingLossLoading: boolean,
    parkingLoss?: ParkingLoss,
}

const INITIAL_STATE: InitialState = {
    parkingLossLoading: false,
    parkingLoss: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskParkingLossDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        parkingLossLoading: true
    }))
    .onEvent(TaskParkingLossDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        parkingLossLoading: false
    }))
    .onEvent(TaskParkingLossDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, parkingLoss: payload.data};
        })

    .onEvent(ParkingLossFormCompanyUserScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
