import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Law from "../../model/Law";
import {METHOD} from "@cuatroochenta/co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";

export default class TaskUserCertDetail extends BaseGenericRequest<{}, TaskResponse<Law>> {

    public constructor() {
        super(METHOD.GET, generateRoute(Urls.URL_USER_CERT));
    }
}
