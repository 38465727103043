import React from "react";
import I18n from "../../../../../commons/I18n/I18n";
import { ChatBroadcastData } from "../../../../../commons/model/ChatBroadcast";
import TaskChatBroadcastAdd from "../../../../../commons/ws/chat/TaskChatBroadcastAdd";
import { BreadcrumbItem } from "../../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../../config/Config";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_BROADCAST, ROUTE_COMPANY_USER_BROADCAST_ADD } from "../../../../../routing/Routes";
import { executeItemTask } from "../../../../../utils/FormUtils";
import { goToRoute } from "../../../../../utils/Router";
import ChatBroadcastCardFormCompanyUser, { ChatBroadcastCardFormCompanyUserData } from "../formAdd/ChatBroadcastCardFormCompanyUser";

const onSubmit = (values: ChatBroadcastCardFormCompanyUserData): void => {
  const submitData: ChatBroadcastData = {
    message: values.message,
    expiration_date: values.expirationDate,
  };

  executeItemTask(
    new TaskChatBroadcastAdd(submitData),
    () => goToRoute(ROUTE_COMPANY_USER_BROADCAST),
    I18n.tr(I18nKeys.MENSAJE_CREADO_CORRECTAMENTE),
  );
};
const renderChatBroadcastForm = (): React.ReactNode => <ChatBroadcastCardFormCompanyUser onSubmit={onSubmit}/>;
const ChatBroadcastFormCompanyUserScreen = () => {
  const breadcrumbs: BreadcrumbItem[] = [{
    name: I18n.tr(I18nKeys.MENSAJES_A_TODOS),
    url: ROUTE_COMPANY_USER_BROADCAST,
    icon: AppIcon.POST_ADD,
  }];

  breadcrumbs.push( {
    name: I18n.tr(I18nKeys.NUEVO_MENSAJE),
    url: ROUTE_COMPANY_USER_BROADCAST_ADD,
    icon: AppIcon.POST_ADD,
  } );

  return (
    <ScreenCard breadcrumbs={breadcrumbs}>
      {renderChatBroadcastForm()}
    </ScreenCard>
  );
};

export default ChatBroadcastFormCompanyUserScreen;
