import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { generateRoute } from '../../utils/Router';
import AppInfo from '../../model/AppInfo';

export default class TaskAppInfoDetail extends BaseGenericRequest<{}, TaskResponse<AppInfo>> {

    public constructor() {
        super(METHOD.GET, generateRoute(Urls.URL_APP_INFO_DETAIL));
    }

}
