import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import LicensePlateMask from "../../model/LicensePlateMask";

export type LicensenPlateMaskCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskVehicleLicenseplatemaskList extends BaseGenericRequest<LicensenPlateMaskCriteriaProps, TaskResponse<LicensePlateMask[]>> {

    private readonly data: LicensenPlateMaskCriteriaProps;

    public constructor(criteria: LicensenPlateMaskCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_VEHICLE_LICENSE_PLATE_LIST);
        this.data = criteria;
    }

    protected getRequest(): LicensenPlateMaskCriteriaProps {
        return this.data;
    }

}
