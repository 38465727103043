import { Component, ComponentType } from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskExploitationDelete from "../../../../commons/ws/exploitation/TaskExploitationDelete";
import TaskExploitationList from "../../../../commons/ws/exploitation/TaskExploitationList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_EXPLOITATION_ADD } from "../../../../routing/Routes";
import ExploitationListAdminScreenActions from "./ExploitationListcardAdminActions";
import ExploitationListCardAdminReducer from "./ExploitationListCardAdminReducer";
import ExploitationListTable from "./ExploitationListTable";

const mapStateToProps = ExploitationListCardAdminReducer.autoMapToProps();
const mapActionsToProps = ExploitationListAdminScreenActions.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

class ExploitationListCardAdmin extends Component<Props> {
  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  public render() {
    const {exploitations, error, loading, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.EXPLOTACIONES)}
        sort={{
          column: "name",
        }}
        TaskList={TaskExploitationList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_EXPLOTACIONES),
        }}
        data={exploitations}
        error={error}
        ItemsTable={ExploitationListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_EXPLOTACIONES),
          Task: TaskExploitationDelete,
        }}
        addItem={ROUTE_ADMIN_EXPLOITATION_ADD}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ExploitationListCardAdmin as unknown as ComponentType<{}>);
