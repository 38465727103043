import { FunctionComponent } from 'react';
import FormCol from '../form/FormCol';
import FormInputOption from '../form/FormInputOption';

interface Props {
    gridSize: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    label: string
    initialValue?: string
    options: FormInputOption[]
    isLoading?: boolean
    disabled?: boolean
    onRef?: any
    onChange: (value: string) => void
}

export const FilterSelect: FunctionComponent<Props> = (
    {
        gridSize,
        label,
        initialValue,
        options,
        isLoading = false,
        disabled = false,
        onRef,
        onChange,
    }
) => {
    return (
        <FormCol md={gridSize} lg={gridSize}>
            <div className={'form-group'}>
                <label>{label}</label>
                <select
                    className={'form-control show-tick bootstrap-select'}
                    disabled={disabled}
                    ref={onRef}
                    onChange={(e) => onChange(e.target.value)}
                    value={initialValue}
                >
                    <option key={'-1'} value={'-1'}/>
                    {
                        !isLoading && options.map((option: FormInputOption, index) =>
                            <option
                                key={index}
                                value={option.value}
                            >
                                {option.name}
                            </option>
                        )
                    }
                </select>
            </div>
        </FormCol>
    );
};
