import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import ExploitationFormAdminScreenActions from './ExploitationFormAdminScreenActions';
import Exploitation from '../../../../commons/model/Exploitation';
import TaskExploitationDetail from '../../../../commons/ws/exploitation/TaskExploitationDetail';
import Law from "../../../../commons/model/Law";
import TaskLawList from "../../../../commons/ws/law/TaskLawList";

interface InitialState {
    loading: boolean,
    exploitation?: Exploitation,
    loadingLaws: boolean,
    laws: Law[],
}

const INITIAL_STATE: InitialState = {
    loading: false,
    exploitation: undefined,
    loadingLaws: false,
    laws: [],
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(TaskExploitationDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ( {
        ...oldState,
        loading: true
    } ))
    .onEvent(TaskExploitationDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ( {
        ...oldState,
        loading: false
    } ))
    .onEvent(TaskExploitationDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, exploitation: payload.data};
    })

    .onEvent(TaskLawList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ( {
        ...oldState,
        loadingLaws: true
    } ))
    .onEvent(TaskLawList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ( {
        ...oldState,
        loadingLaws: false
    } ))
    .onEvent(TaskLawList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, laws: payload.data};
    })

    .onEvent(ExploitationFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
