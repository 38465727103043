import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskReportInvalidationReasonAdd from "../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonAdd";
import TaskReportInvalidationReasonDetail from "../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonDetail";
import TaskReportInvalidationReasonEdit from "../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import {ROUTE_ADMIN_REPORT_INVALIDATION_REASONS, ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD,
  ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import ReportInvalidationReasonAdminFormCard, { ReportInvalidationReasonAdminFormData } from "../formdetail/ReportInvalidationReasonAdminFormCard";
import ReportInvalidationReasonAdminFormActions from "./ReportInvalidationReasonAdminFormActions";
import ReportInvalidationReasonAdminFormReducer from "./ReportInvalidationReasonAdminFormReducer";

const mapActionsToProps = ReportInvalidationReasonAdminFormActions.autoMapToProps();
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  ReportInvalidationReasonAdminFormReducer.autoMapToProps(),
  CurrentUserReducer.autoMapToProps(),
);

type Props = RouteComponentProps<{id: string}> & typeof mapActionsToProps & typeof mapStateToProps;

class ReportInvalidationReasonAdminFormScreen extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id) {
      // @ts-ignore
      loadItemDetailsOrGoBack(new TaskReportInvalidationReasonDetail(id), this.props);
    }
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const {id} = this.props.match.params;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.RAZONES_DE_INVALIDACION),
      url: ROUTE_ADMIN_REPORT_INVALIDATION_REASONS,
      icon: AppIcon.DASHBOARD,
    }];

    if (id) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.RAZON),
        url: generateRoute(ROUTE_ADMIN_REPORT_INVALIDATION_REASON_DETAIL, {
          id,
        } ),
        icon: AppIcon.DASHBOARD,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVA_RAZON),
        url: ROUTE_ADMIN_REPORT_INVALIDATION_REASON_ADD,
        icon: AppIcon.DASHBOARD,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderForm()}
      </ScreenCard>
    );
  }

  private onSubmit = (values: ReportInvalidationReasonAdminFormData): void => {
    const {id} = this.props.match.params;
    const exists = id !== undefined;
    const submitData: ReportInvalidationReasonAdminFormData = {
      name: values.name,
    };

    if (exists) {
      executeItemTask(
        new TaskReportInvalidationReasonEdit(id, submitData),
        () => goToRoute(ROUTE_ADMIN_REPORT_INVALIDATION_REASONS),
        I18n.tr(I18nKeys.RAZON_DE_INVALIDACION_MODIFICADA_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskReportInvalidationReasonAdd(submitData),
        () => goToRoute(ROUTE_ADMIN_REPORT_INVALIDATION_REASONS),
        I18n.tr(I18nKeys.RAZON_DE_INVALIDACION_CREADA_CORRECTAMENTE),
      );
    }
  };

  private renderForm(): React.ReactNode {
    const {loading, reportInvalidationReason} = this.props;
    let initialValues: Partial<ReportInvalidationReasonAdminFormData> | undefined;

    if (reportInvalidationReason) {
      initialValues = {
        name: reportInvalidationReason.name ? reportInvalidationReason.name : "",
      };
    }

    return (
      <ReportInvalidationReasonAdminFormCard
        parentLoading={loading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportInvalidationReasonAdminFormScreen as unknown as React.ComponentType<RouteComponentProps<{id: string}>>);
