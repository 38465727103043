import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from 'classnames';

export interface FormInputProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    type: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    min?: number,
    max?: number,
    maxLength?: number,
    showError?: boolean,
    classNameInput?: boolean,
    classForm?: string,
}

export default class FormInput extends Component<FormInputProps> {

    public render(): ReactNode {
        const {input, meta, label, placeholder, type, disabled, col, showError, max, min, maxLength, classNameInput, classForm} = this.props;
        const classFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error
        });
        return (
            <FormCol {...col} >
                <div className="form-group">
                    <div className={`${classFormLine} ${classForm}`}>
                        {label ? <label className='main-label'>{label || ''}</label> : null}
                        <input  {...input}
                                type={type} className={`form-control ${classNameInput}`} placeholder={placeholder}
                                disabled={disabled || false}
                                max={max}
                                maxLength={maxLength}
                                min={min}
                        />
                    </div>
                    <label className="error">{(meta.touched || showError) ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }

}

