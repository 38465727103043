import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskVehicleModelDelete from "../../../../commons/ws/vehicleModel/TaskVehicleModelDelete";
import TaskVehicleModelList from "../../../../commons/ws/vehicleModel/TaskVehicleModelList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_MODEL_ADD } from "../../../../routing/Routes";
import VehicleModelListAdminReducer from "./VehicleModelListAdminReducer";
import VehicleModelListTable from "./VehicleModelListTable";

const mapStateToProps = VehicleModelListAdminReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class VehicleModelListCardAdmin extends React.Component<Props> {
  public render(): React.ReactNode {
    const {loading, vehicleModels, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.MODELOS_DE_VEHICULOS)}
        sort={{
          column: "id",
        }}
        TaskList={TaskVehicleModelList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_MODELOS_DE_VEHICULOS),
        }}
        data={vehicleModels}
        error={error}
        ItemsTable={VehicleModelListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_MODELOS),
          Task: TaskVehicleModelDelete,
        }}
        addItem={ROUTE_ADMIN_VEHICLE_MODEL_ADD}
      />
    );
  }
}

export default connect(mapStateToProps)(VehicleModelListCardAdmin as unknown as React.ComponentType<void>);
