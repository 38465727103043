import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import Law, {LawData} from "../../model/Law";

export default class TaskLawEdit extends BaseGenericRequest<LawData, TaskResponse<Law>> {

    private readonly data: LawData;

    public constructor(lawId: string, lawData: LawData) {
        super(METHOD.PUT, generateRoute(Urls.URL_LAW_EDIT, {uuid: lawId}));
        this.data = lawData;
    }

    protected getRequest(): LawData {
        return this.data;
    }
}
