import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import ChatBroadcast from "../../model/ChatBroadcast";

type ChatBroadcastCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskChatBroadcastList extends BaseGenericRequest<ChatBroadcastCriteriaProps, TaskResponse<ChatBroadcast[]>> {

    private readonly data: ChatBroadcastCriteriaProps;

    public constructor(criteria: ChatBroadcastCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_CHAT_BROADCAST_LIST);
        this.data = criteria;
    }

    protected getRequest(): ChatBroadcastCriteriaProps {
        return this.data;
    }

}
