import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import CancellationReferenceType from "../../../../commons/model/CancellationReferenceType";
import LawType from "../../../../commons/model/LawType";
import TaskCancellationReferenceTypeList from "../../../../commons/ws/cancellationReferenceType/TaskCancellationReferenceTypeList";
import TaskLawTypeList from "../../../../commons/ws/lawType/TaskLawTypeList";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import FormInputOption from "../../../../components/form/FormInputOption";
import FormSelect from "../../../../components/form/FormSelect";
import FormSwitch from "../../../../components/form/FormSwitch";
import FormTextArea from "../../../../components/form/FormTextArea";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_ROUTES } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import LawFormScreenCompanyUserReducer from "../formscreen/LawFormScreenCompanyUserReducer";

const FORM_NAME = "LawCardFormAdmin";

export interface LawCardFormCompanyUserData {
  lawTypeId: string;
  articleCode: string;
  postCode: string;
  optionCode: string;
  requiredExpirationDate: boolean;
  screenText: string;
  printText: string;
  formatText: string;
  amount: string;
  cancellationAmount: string;
  cancelableReport: boolean;
  cancellationText: string;
  controllerPhotos: string;
  ticketTimeExceeded: boolean;
  cancellationCode: number;
  allowedMinutesCancelReportApp: string;
  cancellationReferenceTypeId: string;
}

interface LawCardFormCompanyUserProps {
  routeId?: string;
  readonly?: boolean;
  onSubmit: (data: LawCardFormCompanyUserData)=> void;
  initialValues?: Partial<LawCardFormCompanyUserData>;
  parentLoading?: boolean;
}

const mapStateToProps = LawFormScreenCompanyUserReducer.autoMapToProps();

type Props = InjectedFormProps<LawCardFormCompanyUserData> &
  LawCardFormCompanyUserProps &
  typeof mapStateToProps;

enum LawFormAdminFields {
  LAW_TYPE_ID = "lawTypeId",
  ARTICLE_CODE = "articleCode",
  POST_CODE = "postCode",
  OPTION_CODE = "optionCode",
  REQUIRED_EXPIRATION_DATE_CODE = "requiredExpirationDate",
  SCREEN_TEXT = "screenText",
  PRINT_TEXT = "printText",
  FORMAT_TEXT = "formatText",
  AMOUNT = "amount",
  CANCELABLE_REPORT = "cancelableReport",
  CANCELLATION_TEXT = "cancellationText",
  CONTROLLER_PHOTOS = "controllerPhotos",
  TICKET_TIME_EXCEEDED = "ticketTimeExceeded",
  CANCELLATION_CODE = "cancellationCode",
  ALLOWED_MINUTES_CANCEL_REPORT_APP = "allowedMinutesCancelReportApp",
  CANCELLATION_REFERENCE_TYPE_ID = "cancellationReferenceTypeId",
  CANCELLATION_AMOUNT = "cancellationAmount",
}

class LawCardFormCompanyUser extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskCancellationReferenceTypeList().execute();
    new TaskLawTypeList().execute();
  }

  public render(): React.ReactNode {
    const {handleSubmit,
      onSubmit,
      initialValues,
      invalid,
      lawLoading,
      pristine,
      lawTypeList,
      cancellationReferenceTypeList,
      parentLoading} = this.props;
    const lawTypeOptions: FormInputOption[] = lawTypeList.map(
      (lawType: LawType) => ( {
        value: lawType.id,
        name: lawType.name,
      } ),
    );
    const cancellationReferenceTypeOptions: FormInputOption[] =
      cancellationReferenceTypeList.map(
        (cancellationReferenceType: CancellationReferenceType) => ( {
          value: cancellationReferenceType.id,
          name: cancellationReferenceType.name,
        } ),
      );

    return (
      <Card loading={parentLoading || lawLoading}>
        <CardHeader
          title={I18n.tr(
            initialValues ? I18nKeys.ARTICULO : I18nKeys.NUEVO_ARTICULO,
          )}
        />
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.TIPO_DE_LEY)}
                name={LawFormAdminFields.LAW_TYPE_ID}
                component={FormSelect}
                blankOptionText={" "}
                options={lawTypeOptions}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.ARTICULO)}
                name={LawFormAdminFields.ARTICLE_CODE}
                component={FormInput}
                col={{
                  md: 2,
                  lg: 2,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.APARTADO)}
                name={LawFormAdminFields.POST_CODE}
                component={FormInput}
                col={{
                  md: 2,
                  lg: 2,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.OPCION)}
                name={LawFormAdminFields.OPTION_CODE}
                component={FormInput}
                col={{
                  md: 2,
                  lg: 2,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.FECHA_DE_EXPIRACION)}
                labelEnabled={I18n.tr(I18nKeys.SI)}
                labelDisabled={I18n.tr(I18nKeys.NO)}
                name={LawFormAdminFields.REQUIRED_EXPIRATION_DATE_CODE}
                component={FormSwitch}
                col={{
                  md: 2,
                  lg: 2,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.TEXTO_A_MOSTRAR_EN_PANTALLA)}
                name={LawFormAdminFields.SCREEN_TEXT}
                component={FormTextArea}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.TEXTO_DE_IMPRESION)}
                name={LawFormAdminFields.PRINT_TEXT}
                component={FormTextArea}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.FORMATO_DE_VISUALIZACION_EN_TICKET)}
                name={LawFormAdminFields.FORMAT_TEXT}
                component={FormTextArea}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.IMPORTE)}
                name={LawFormAdminFields.AMOUNT}
                component={FormInput}
                type={"number"}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.IMPORTE_ANULACION)}
                name={LawFormAdminFields.CANCELLATION_AMOUNT}
                component={FormInput}
                type={"number"}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.TEXTO_DE_ANULACION)}
                name={LawFormAdminFields.CANCELLATION_TEXT}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.ANULABLE)}
                name={LawFormAdminFields.CANCELABLE_REPORT}
                component={FormSwitch}
                labelEnabled={I18n.tr(I18nKeys.SI)}
                labelDisabled={I18n.tr(I18nKeys.NO)}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.TIEMPO_DE_TICKET_EXCEDEDIDO)}
                name={LawFormAdminFields.TICKET_TIME_EXCEEDED}
                component={FormSwitch}
                labelEnabled={I18n.tr(I18nKeys.SI)}
                labelDisabled={I18n.tr(I18nKeys.NO)}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.NUMERO_DE_FOTOGRAFIAS_PARA_CONTROLADOR)}
                name={LawFormAdminFields.CONTROLLER_PHOTOS}
                component={FormInput}
                type={"number"}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(
                  I18nKeys.MINUTOS_DE_EXCESO_PERMITIDOS_PARA_ANULACION_EN_APP,
                )}
                name={LawFormAdminFields.ALLOWED_MINUTES_CANCEL_REPORT_APP}
                component={FormInput}
                type={"number"}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.CODIGO_CANCELACION)}
                name={LawFormAdminFields.CANCELLATION_CODE}
                component={FormInput}
                type={"number"}
                col={{
                  md: 3,
                  lg: 3,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.FECHA_Y_HORA_REFERENCIA_ANULACION_APP)}
                name={LawFormAdminFields.CANCELLATION_REFERENCE_TYPE_ID}
                component={FormSelect}
                blankOptionText={" "}
                options={cancellationReferenceTypeOptions}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>

            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_ROUTES)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: LawCardFormCompanyUserData) {
  const errors: FormErrors<LawCardFormCompanyUserData> = {
  };

  if (!values.lawTypeId || values.lawTypeId === "-1")
    errors.lawTypeId = I18n.tr(I18nKeys.TIPO_DE_LEY_OBLIGATORIO);

  if (!values.articleCode || values.articleCode.length === 0)
    errors.articleCode = I18n.tr(I18nKeys.ARTICULO_OBLIGATORIO);

  if (!values.postCode || values.postCode.length === 0)
    errors.postCode = I18n.tr(I18nKeys.APARTADO_OBLIGATORIO);

  if (!values.optionCode || values.optionCode.length === 0)
    errors.optionCode = I18n.tr(I18nKeys.OPCION_OBLIGATORIA);

  if (!values.screenText || values.screenText.length === 0)
  {errors.screenText = I18n.tr(
    I18nKeys.TEXTO_A_MOSTRAR_EN_PANTALLA_OBLIGATORIO,
  );}

  if (values.screenText && values.screenText.length > 250)
    errors.screenText = I18n.tr(I18nKeys.TEXTO_SUPERADO_250_CARACTERES);

  if (!values.printText || values.printText.length === 0)
    errors.printText = I18n.tr(I18nKeys.TEXTO_DE_IMPRESION_OBLIGATORIO);

  if (values.printText && values.printText.length > 250)
    errors.printText = I18n.tr(I18nKeys.TEXTO_SUPERADO_250_CARACTERES);

  if (!values.formatText || values.formatText.length === 0)
    errors.formatText = I18n.tr(I18nKeys.FORMATO_OBLIGATORIO);

  if (!values.amount || values.amount.length === 0)
    errors.amount = I18n.tr(I18nKeys.IMPORTE_OBLIGATORIO);
  else if (+values.amount < 0)
    errors.amount = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_POSITIVO);

  if (!values.cancellationAmount || values.cancellationAmount.length === 0)
    errors.cancellationAmount = I18n.tr(I18nKeys.IMPORTE_ANULACION_OBLIGATORIO);

  if (!values.cancellationText || values.cancellationText.length === 0)
  {errors.cancellationText = I18n.tr(
    I18nKeys.TEXTO_A_LA_ANULACION_OBLIGATORIO,
  );}

  if (values.cancellationText && values.cancellationText.length > 250)
    errors.cancellationText = I18n.tr(I18nKeys.TEXTO_SUPERADO_250_CARACTERES);

  if (!values.controllerPhotos || values.controllerPhotos.length === 0)
  {errors.controllerPhotos = I18n.tr(
    I18nKeys.DEBE_INDICAR_EL_NUMERO_DE_FOTOGRAFIAS,
  );}

  if (!String(values.cancellationCode))
    errors.cancellationCode = I18n.tr(I18nKeys.CODIGO_CANCELACION_OBLIGATORIO);
  else if (+values.cancellationCode < 0)
    errors.cancellationCode = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_POSITIVO);

  if (
    !values.allowedMinutesCancelReportApp ||
    values.allowedMinutesCancelReportApp.length === 0
  )
  {errors.allowedMinutesCancelReportApp = I18n.tr(
    I18nKeys.MINUTOS_DE_EXCESO_OBLIGATORIO,
  );}
  else if (+values.allowedMinutesCancelReportApp < 0)
  {errors.allowedMinutesCancelReportApp = I18n.tr(
    I18nKeys.DEBE_SER_UN_VALOR_POSITIVO,
  );}

  if (
    !values.cancellationReferenceTypeId ||
    values.cancellationReferenceTypeId === "-1"
  )
  {errors.cancellationReferenceTypeId = I18n.tr(
    I18nKeys.TIPO_DE_CANCELACION_OBLIGATORIO,
  );}

  if (!values.cancellationAmount || values.cancellationAmount.length === 0)
    errors.cancellationAmount = I18n.tr(I18nKeys.IMPORTE_ANULACION_OBLIGATORIO);
  else if (+values.cancellationAmount < 0)
    errors.cancellationAmount = I18n.tr(I18nKeys.DEBE_SER_UN_VALOR_POSITIVO);

  return errors;
}

export default compose(
  reduxForm<LawCardFormCompanyUserData, LawCardFormCompanyUserProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(LawCardFormCompanyUser);
