import ParkingMeterType from "../../../../../commons/model/ParkingMeterType";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import TaskParkingMeterTypeList from "../../../../../commons/ws/parkingMeterType/TaskParkingMeterTypeList";
import ExploitationReportCodeSectionAdminActions from "./ExploitationReportCodeSectionAdminActions";
import ManagementProcessingType from "../../../../../commons/model/ManagementProcessingType";
import TaskManagementProcessingTypeList
    from "../../../../../commons/ws/managementProcessingType/TaskManagementProcessingTypeList";

interface InitialState {
    loading: boolean,
    parkingMeterTypeList: ParkingMeterType[],
    managementProcessingTypeList: ManagementProcessingType[],
}

const INITIAL_STATE: InitialState = {
    loading: false,
    parkingMeterTypeList: [],
    managementProcessingTypeList: []
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskParkingMeterTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskParkingMeterTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskParkingMeterTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, parkingMeterTypeList: payload.data};
    })

    .onEvent(TaskManagementProcessingTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, managementProcessingTypeList: payload.data};
    })

    .onEvent(ExploitationReportCodeSectionAdminActions.clearReducer, () => INITIAL_STATE)


    .build();
