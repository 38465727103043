import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import ParkingMeter from "../../../../commons/model/ParkingMeter";
import TaskParkingMeterDetail from "../../../../commons/ws/parkingMeter/TaskParkingMeterDetail";
import ParkingMeterFormCompanyUserScreenActions from "./ParkingMeterFormCompanyUserScreenActions";

interface InitialState {
    parkingMeterLoading: boolean,
    parkingMeter?: ParkingMeter,
}

const INITIAL_STATE: InitialState = {
    parkingMeterLoading: false,
    parkingMeter: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskParkingMeterDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        parkingMeterLoading: true
    }))
    .onEvent(TaskParkingMeterDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        parkingMeterLoading: false
    }))
    .onEvent(TaskParkingMeterDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, parkingMeter: payload.data};
        })

    .onEvent(ParkingMeterFormCompanyUserScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
