
import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_DELTA_CARS } from "../../../../routing/Routes";
import DeltaCardListCardAdmin from "./DeltaCarListCardCompanyUser";

const DeltaCarListCompanyUserScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.VEHICULO_EMBARCADO),
      url: ROUTE_COMPANY_USER_DELTA_CARS,
      icon: AppIcon.CAR,
    },
  ]}>
    <DeltaCardListCardAdmin/>
  </ScreenCard>
);

export default DeltaCarListCompanyUserScreen;
