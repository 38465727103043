import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { VehicleBrandData } from "../../../../commons/model/VehicleBrand";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskVehicleBrandAdd from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandAdd";
import TaskVehicleBrandDetail from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandDetail";
import TaskVehicleBrandEdit from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_ADMIN_VEHICLE_BRANDS, ROUTE_ADMIN_VEHICLE_BRAND_ADD,
  ROUTE_ADMIN_VEHICLE_BRAND_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import VehicleBrandCardFormAdmin, { VehicleBrandAdminData } from "../formdetail/VehicleBrandCardFormAdmin";
import VehicleBrandFormAdminScreenActions from "./VehicleBrandFormAdminScreenActions";
import VehicleBrandFormScreenAdminReducer from "./VehicleBrandFormScreenAdminReducer";

const mapStateToProps = VehicleBrandFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = VehicleBrandFormAdminScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class VehicleBrandFormAdminScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskVehicleBrandDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: VehicleBrandAdminData): void => {
    const existVehicleBrand = this.props.match.params.id !== undefined;
    const submitData: VehicleBrandData = {
      name: values.name,
      order: values.order,
      alzira_code: values.alziraCode,
    };

    if (existVehicleBrand) {
      executeItemTask(
        new TaskVehicleBrandEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_ADMIN_VEHICLE_BRANDS),
        I18n.tr(I18nKeys.MARCA_DE_VEHICULO_MODIFICADA_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskVehicleBrandAdd(submitData),
        () => goToRoute(ROUTE_ADMIN_VEHICLE_BRANDS),
        I18n.tr(I18nKeys.MARCA_DE_VEHICULO_CREADA_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const vehicleBrandId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.MARCAS_DE_VEHICULOS),
      url: ROUTE_ADMIN_VEHICLE_BRANDS,
      icon: AppIcon.CAR,
    }];

    if (vehicleBrandId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.MARCA_DE_VEHICULO),
        url: generateRoute(ROUTE_ADMIN_VEHICLE_BRAND_DETAIL, {
          id: vehicleBrandId,
        } ),
        icon: AppIcon.CAR,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVA_MARCA_DE_VEHICULO),
        url: ROUTE_ADMIN_VEHICLE_BRAND_ADD,
        icon: AppIcon.CAR,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderVehicleBrandForm()}
      </ScreenCard>
    );
  }

  private renderVehicleBrandForm(): React.ReactNode {
    const {vehicleBrandLoading, vehicleBrand} = this.props;
    let initialValues: Partial<VehicleBrandAdminData> | undefined;

    if (vehicleBrand) {
      initialValues = {
        name: vehicleBrand.name ? vehicleBrand.name : "",
        order: vehicleBrand.order || 0,
        alziraCode: vehicleBrand.alzira_code || "",
      };
    }

    return (
      <VehicleBrandCardFormAdmin
        parentLoading={vehicleBrandLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)
(VehicleBrandFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
