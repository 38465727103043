import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import UserRole from "../../../../commons/model/UserRole";
import TaskUserRoleList from "../../../../commons/ws/userRole/TaskUserRoleList";

interface InitialState {
    roleListLoading: boolean,
    roleList: UserRole[],
}

const INITIAL_STATE: InitialState = {
    roleListLoading: false,
    roleList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUserRoleList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        roleListLoading: true
    }))
    .onEvent(TaskUserRoleList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        roleListLoading: false
    }))
    .onEvent(TaskUserRoleList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, roleList: payload.data};
        })

    .build();
