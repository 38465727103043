import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import UserType from "../../../../commons/model/UserType";
import TaskUserTypeList from "../../../../commons/ws/userType/TaskUserTypeList";

interface InitialState {
    userTypeListLoading: boolean,
    userTypeList: UserType[],
}

const INITIAL_STATE: InitialState = {
    userTypeListLoading: false,
    userTypeList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUserTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        userTypeListLoading: true
    }))
    .onEvent(TaskUserTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        userTypeListLoading: false
    }))
    .onEvent(TaskUserTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, userTypeList: payload.data};
        })

    .build();
