import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import ParkingAreaType from "../../model/ParkingAreaType";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";

type ParkingAreaTypeCriteriaProps = TaskCriteriaRequest<{}>;

export default class TaskParkingAreaTypeList extends BaseGenericRequest<ParkingAreaTypeCriteriaProps, TaskResponse<ParkingAreaType[]>> {

    private readonly data: ParkingAreaTypeCriteriaProps;

    public constructor(criteria: ParkingAreaTypeCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_AREA_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): ParkingAreaTypeCriteriaProps {
        return this.data;
    }
}
