import React from "react";
import I18n from "../../../../commons/I18n/I18n";
import Street from "../../../../commons/model/Street";
import { generateRoute } from "../../../../commons/utils/Router";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_STREET_DETAIL } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface StreetListTableProps {
    data: Street[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

const goToItemDetail = (itemId: string): void => {
  goToRoute(generateRoute(ROUTE_COMPANY_USER_STREET_DETAIL, {
    id: itemId,
  } ));
};

export default class StreetListTable extends React.Component<StreetListTableProps> {
  public render(): React.ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((street: Street) => (
            <tr key={street.id}
              onClick={() => goToItemDetail(street.id)}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(street.id) : null}
              <td>
                {street.name ? street.name : ""}
              </td>
              <td>
                {street.route ? street.route.name : ""}
              </td>
              <td>
                {street.street_type ? street.street_type.name : ""}
              </td>
              <td>
                {street.parking_area_type ? street.parking_area_type.name : ""}
              </td>
              <td>
                {street.parking_spaces}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.NOMBRE)}
          columnName={"name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.RUTA)}
          columnName={"route._name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.TIPO_DE_CALLE)}
          columnName={"street_type.name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.TIPO_DE_ZONA)}
          columnName={"parking_area.type_name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.N_PLAZAS)}
          columnName={"parking_spaces"}
          {...commonProps}
        />
      </tr>
    );
  }
}
