import BaseGenericRequest from "../commons/ws/BaseGenericRequest";
import TaskResponse from "../commons/ws/TaskResponse";
import Alert from "../base/alerts/Alert";
import {FormState} from "redux-form";
import {RouteComponentProps} from "react-router";

export function executeItemTask<T>(task: BaseGenericRequest<any, TaskResponse<T>>,
                                   onSuccess?: (response: TaskResponse<T>) => void,
                                   successMessage?: string,
                                   errorMessage?: string): void {
    task.onSuccess((response) => {
        if (onSuccess) {
            onSuccess(response);
        }
        if (successMessage) {
            Alert.success(successMessage);
        }
    })
        .onFail((error) => Alert.error(errorMessage || error))
        .execute();
}

export function loadItemDetailsOrGoBack<T>(task: BaseGenericRequest<any, TaskResponse<T>>,
                                           props: RouteComponentProps,
                                           onSuccess?: (response: TaskResponse<T>) => void,
): void {
    task.onFail((error) => {
        Alert.error(error);
        props.history.goBack();
    })
        .onSuccess((response) => {
            if (onSuccess) {
                onSuccess(response)
            }
        })
        .execute();
}

export interface FormStateProp<T> {
    formState: FormState & { values?: T } | undefined
}

export function formStateAutoMapToPropsFactory<T>(formName: string): FormStateProp<T> {
    const customStateFormReducer = (state: { form: {} }, ownProps: {}): FormStateProp<T> => {
        return {
            formState: state.form[formName],
        }
    };
    return customStateFormReducer as any;
}

export type FormChangeHandler = (field: string, value: any) => void

export interface NestedFieldArrayErrors {
    [property: string]: FieldArrayErrors
}

export type FieldArrayErrors = string | string[] | NestedFieldArrayErrors | NestedFieldArrayErrors[]