import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import CollectionProcessAdminActions from "./CollectionProcessAdminActions";
import {PagerBackendProps} from "../../../../components/Pager";
import {ParkingMeterCollectionProcess} from "../../../../commons/model/ParkingMeterCollectionProcess";
import TaskParkingMeterCollectionProcessList
	from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessList";

interface CollectionProcessAdminReducerState {
	parkingMeterCollectionProcessList: Array<ParkingMeterCollectionProcess>,
	parkingMeterCollectionProcessToSend: Array<string>,
	loading: boolean,
	errors: string,
	pager: PagerBackendProps,
}

const INITIAL_STATE: CollectionProcessAdminReducerState = {
	parkingMeterCollectionProcessList: [],
	parkingMeterCollectionProcessToSend: [],
	loading: false,
	errors: '',
	pager: {
		current: 1,
		total_pages: 1,
		total_elements: 0,
	},
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
	.onEvent(CollectionProcessAdminActions.dispatchClearReducer, () => INITIAL_STATE)

	.onEvent(TaskParkingMeterCollectionProcessList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
	.onEvent(TaskParkingMeterCollectionProcessList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
	.onEvent(TaskParkingMeterCollectionProcessList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) =>
		({...oldState, parkingMeterCollectionProcessList: payload.data, errors: '', pager: payload.pager || oldState.pager}))

	.onEvent(TaskParkingMeterCollectionProcessList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, errors: payload}))

	.onEvent(CollectionProcessAdminActions.dispatchAddCollectionProcessToSendList, (oldState, payload) => {
		const newState = {...oldState};
		newState.parkingMeterCollectionProcessToSend = [...newState.parkingMeterCollectionProcessToSend, payload];
		return newState;
	})

	.onEvent(CollectionProcessAdminActions.dispatchRemoveCollectionProcessToSendList, (oldState, payload) => {
		const newState = {...oldState};
		newState.parkingMeterCollectionProcessToSend = newState.parkingMeterCollectionProcessToSend.filter(value => value !== payload);
		return newState;
	})

	.build()
