import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import ParkingMeterIncidence from "../../model/ParkingMeterIncidence";

interface ParkingMeterIncidenceCustomCriteria {
    start_date?: string,
    end_date?: string,
    controller_ids?: string[],
    type_ids?: string[],
    solved?: boolean,
}

export type ParkingMeterIncidenceCriteriaProps = TaskCriteriaRequest<ParkingMeterIncidenceCustomCriteria>

export default class TaskParkingMeterIncidenceList extends BaseGenericRequest<ParkingMeterIncidenceCriteriaProps, TaskResponse<ParkingMeterIncidence[]>> {

    private readonly data: ParkingMeterIncidenceCriteriaProps;

    public constructor(criteria: ParkingMeterIncidenceCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_METER_INCIDENCE_LIST);
        this.data = criteria;
    }

    protected getRequest(): ParkingMeterIncidenceCriteriaProps {
        return this.data;
    }

}
