import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES } from "../../../../routing/Routes";
import ParkingMeterIncidenceListCardCompanyUser from "./ParkingMeterIncidenceListCardCompanyUser";

const ParkingMeterIncidenceListCompanyUserScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.INCIDENCIAS_PARQUIMETROS),
      url: ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES,
      icon: AppIcon.PARKING_METER_INCIDENCE,
    },
  ]}>
    <ParkingMeterIncidenceListCardCompanyUser/>
  </ScreenCard>
);
  
export default ParkingMeterIncidenceListCompanyUserScreen;
