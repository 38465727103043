import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import TaskVehicleBrandList from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandList";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import FormInputOption from "../../../../components/form/FormInputOption";
import FormSelect from "../../../../components/form/FormSelect";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_VEHICLE_MODELS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import VehicleModelFormScreenAdminReducer from "../formscreen/VehicleModelFormScreenAdminReducer";

const FORM_NAME = "VehicleModelFormAdmin";

export interface VehicleModelAdminData {
    name: string;
    vehicleBrandId: string;
    order: number;
}

interface VehicleModelCardFormAdminProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: VehicleModelAdminData)=> void;
    initialValues?: Partial<VehicleModelCardFormAdminProps>;
    parentLoading?: boolean;
}

const mapStateToProps = VehicleModelFormScreenAdminReducer.autoMapToProps();

type Props =
    InjectedFormProps<VehicleModelAdminData> & VehicleModelCardFormAdminProps & typeof mapStateToProps;

enum VehicleModelFormAdminFields {
    NAME = "name",
    VEHICLE_BRAND_ID = "vehicleBrandId",
    ORDER = "order",
}

class VehicleModelCardFormAdmin extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskVehicleBrandList( {
      limit: 0,
    } ).execute();
  }

  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, vehicleModelLoading, pristine, vehicleBrands, parentLoading} = this.props;
    const vehicleBrandOptions: FormInputOption[] = vehicleBrands.map((vehicleBrand) => ( {
      name: vehicleBrand.name,
      value: vehicleBrand.id,
    } ));

    return (
      <Card loading={parentLoading || vehicleModelLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.MODELO_DE_VEHICULO : I18nKeys.NUEVO_MODELO_DE_VEHICULO)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={VehicleModelFormAdminFields.NAME}
                component={FormInput}
                col={{
                  md: 5,
                  lg: 5,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.MARCA_DE_VEHICULO)}
                name={VehicleModelFormAdminFields.VEHICLE_BRAND_ID}
                component={FormSelect}
                blankOptionText={" "}
                options={vehicleBrandOptions}
                col={{
                  md: 5,
                  lg: 5,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.ORDEN)}
                name={VehicleModelFormAdminFields.ORDER}
                component={FormInput}
                col={{
                  md: 2,
                  lg: 2,
                }}
                type={"number"}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_ADMIN_VEHICLE_MODELS)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: VehicleModelAdminData) {
  const errors: FormErrors<VehicleModelAdminData> = {
  };

  if (!values.name || values.name.length === 0) 
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  if (!values.vehicleBrandId || values.vehicleBrandId === "-1") 
    errors.vehicleBrandId = I18n.tr(I18nKeys.MODELO_OBLIGATORIO);

  if (!values.order) 
    errors.order = I18n.tr(I18nKeys.ORDEN_OBLIGATORIO);
  else if (values.order < 0 || values.order > 5) 
    errors.order = I18n.tr(I18nKeys.EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5);
  
  return errors;
}

export default compose(
  reduxForm<VehicleModelAdminData, VehicleModelCardFormAdminProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(VehicleModelCardFormAdmin);
