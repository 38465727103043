import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import Street, {StreetData} from "../../model/Street";

export default class TaskStreetAdd extends BaseGenericRequest<StreetData, TaskResponse<Street>> {

    private readonly data: StreetData;

    public constructor(data: StreetData) {
        super(METHOD.POST, Urls.URL_STREET_ADD);
        this.data = data;
    }

    protected getRequest(): StreetData {
        return this.data;
    }
}
