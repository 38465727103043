import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import {UserSimply} from "../../model/User";

type ChatContactCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskChatContactList extends BaseGenericRequest<ChatContactCriteriaProps, TaskResponse<UserSimply[]>> {

    private readonly data: ChatContactCriteriaProps;

    public constructor(criteria: ChatContactCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_CHAT_CONTACT_LIST);
        this.data = criteria;
    }

    protected getRequest(): ChatContactCriteriaProps {
        return this.data;
    }

}
