import React from "react";
import OutsideClickHandler from "react-outside-click-handler";

interface Option {
    name: string,
    value: string,
}


interface FormMultiSelectProps {
    selectHandler: (optionId: string) => void,
    options: Option[],
    selectedOptions: string[],
}

interface State {
    showOptions: boolean,
}

export default class FormMultiSelect extends React.Component<FormMultiSelectProps, State> {

    public constructor(props: FormMultiSelectProps) {
        super(props);
        this.state = {
            showOptions: false,
        }
    }

    public render(): React.ReactNode {

        const value = this.props.options.filter((option: Option) => this.props.selectedOptions.includes(option.value))
            .map((option: Option) => option.name)
            .join(', ');
        return (
            <div className={'multi-select'}  >
                <input
                    type={'text'}
                    spellCheck={false}
                    value={value}
                    onClick={() => this.setState({showOptions: true})}
                    disabled={false}
                />
                <i className="material-icons">arrow_drop_down</i>
                {this.renderOptions()}
            </div>
        )
    }

    private renderOptions(): React.ReactNode {
        if (this.state.showOptions) {
            return (
                <OutsideClickHandler
                    onOutsideClick={() => this.setState({showOptions: false})}
                >
                    <div className={'multi-select-options'} onBlur={() => console.warn('ON BLUR')}>
                        {this.props.options.map((option: Option, index: number) => {
                            return (
                                <span
                                    key={index}
                                    className={'multi-select-option'}
                                    data-active={this.props.selectedOptions.includes(option.value)}
                                    onClick={() => this.props.selectHandler(option.value)}
                                >{option.name}</span>);
                        })}
                    </div>
                </OutsideClickHandler>
            );
        }
        return null;
    }
}
