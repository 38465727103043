/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import TaskParkingAreaTypeList from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeList";
import TaskRouteList from "../../../../commons/ws/route/TaskRouteList";
import TaskStreetTypeList from "../../../../commons/ws/streetType/TaskStreetTypeList";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormDragFile from "../../../../components/form/FormDragFile";
import FormInput from "../../../../components/form/FormInput";
import FormSelect from "../../../../components/form/FormSelect";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_STREETS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import StreetCardFormCompanyUserReducer from "./StreetCardFormCompanyUserReducer";

const FORM_NAME = "StreetCardFormCompanyUser";

export interface StreetCardFormCompanyUserData {
    name: string;
    suma_code: string;
    parking_spaces: number;
    street_type_id: string;
    parking_area_type_id: string;
    route_id: string;
    kml_file: string[];
}

interface StreetCardFormCompanyUserExtProps {
    streetId?: string;
    readonly?: boolean;
    initialValues?: Partial<StreetCardFormCompanyUserData>;
    onSubmit: (data: StreetCardFormCompanyUserData)=> void;
    parentLoading: boolean;
}

const mapStateToProps = StreetCardFormCompanyUserReducer.autoMapToProps();

type Props =
    InjectedFormProps<StreetCardFormCompanyUserData> & StreetCardFormCompanyUserExtProps & typeof mapStateToProps;

enum StreetFormAdminFields {
    NAME = "name",
    SUMA_CODE = "suma_code",
    PARKING_SPACES = "parking_spaces",
    STREET_TYPE_ID = "street_type_id",
    PARKING_AREA_TYPE_ID = "parking_area_type_id",
    ROUTE_ID = "route_id",
    KML_FILE = "kml_file",
}

class StreetCardFormCompanyUser extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  public componentWillMount(): void {
    new TaskRouteList().execute();
    new TaskStreetTypeList().execute();
    new TaskParkingAreaTypeList().execute();
  }

  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, parentLoading, pristine, routeList, streetTypeList, parkingAreaTypeList} = this.props;
    const routeOptions = routeList.map((route) => ( {
      value: route.id,
      name: route.name,
    } ));
    const streetTypeOptions = streetTypeList.map((streetType) => ( {
      value: streetType.id,
      name: streetType.name,
    } ));
    const parkingAreaTypeOptions = parkingAreaTypeList.map((parkingAreaType) => ( {
      value: parkingAreaType.id,
      name: parkingAreaType.name,
    } ));

    return (
      <Card loading={ parentLoading }>
        <CardHeader
          title={ I18n.tr(initialValues ? I18nKeys.CALLE : I18nKeys.NUEVA_CALLE) }/>
        <CardBody>
          <form onSubmit={ handleSubmit(onSubmit) }>
            <Row>
              <Field
                label={ I18n.tr(I18nKeys.NOMBRE) }
                name={ StreetFormAdminFields.NAME }
                component={ FormInput }
                col={ {
                  md: 6,
                  lg: 6,
                } }
              />
              <Field
                label={ I18n.tr(I18nKeys.CODIGO_SUMA) }
                name={ StreetFormAdminFields.SUMA_CODE }
                type={ "number" }
                component={ FormInput }
                col={ {
                  md: 6,
                  lg: 6,
                } }
              />
            </Row>
            <Row>
              <Field
                label={ I18n.tr(I18nKeys.RUTA) }
                name={ StreetFormAdminFields.ROUTE_ID }
                component={ FormSelect }
                options={ routeOptions }
                blankOptionText={ " " }
                col={ {
                  md: 6,
                  lg: 6,
                } }
              />
              <Field
                label={ I18n.tr(I18nKeys.TIPO_DE_CALLE) }
                name={ StreetFormAdminFields.STREET_TYPE_ID }
                component={ FormSelect }
                options={ streetTypeOptions }
                blankOptionText={ " " }
                col={ {
                  md: 6,
                  lg: 6,
                } }
              />
            </Row>
            <Row>
              <Field
                label={ I18n.tr(I18nKeys.TIPO_DE_ZONA) }
                name={ StreetFormAdminFields.PARKING_AREA_TYPE_ID }
                component={ FormSelect }
                options={ parkingAreaTypeOptions }
                blankOptionText={ " " }
                col={ {
                  md: 6,
                  lg: 6,
                } }
              />
              <Field
                label={ I18n.tr(I18nKeys.N_PLAZAS) }
                name={ StreetFormAdminFields.PARKING_SPACES }
                component={ FormInput }
                type={ "number" }
                col={ {
                  md: 6,
                  lg: 6,
                } }
                min={ 0 }
              />
            </Row>
            <Row>
              <Field
                label={ I18n.tr(I18nKeys.FICHERO_KML) }
                name={ StreetFormAdminFields.KML_FILE }
                component={ FormDragFile }
                fileTypes={[".kml"]}
                col={ {
                  md: 12,
                  lg: 12,
                } }
              />
            </Row>
            <FormCardFooter
              invalid={ invalid }
              pristine={ pristine }
              isUpdate={ !!initialValues }
              cancelHandler={ () => goToRoute(ROUTE_COMPANY_USER_STREETS) }
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: StreetCardFormCompanyUserData) {
  const errors: FormErrors<StreetCardFormCompanyUserData> = {
  };

  if (!values.name || values.name.length === 0)
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);

  if (!values.suma_code || values.suma_code.toString().length === 0)
    errors.suma_code = I18n.tr(I18nKeys.CODIGO_SUMA_OBLIGATORIO);

  if (!values.parking_area_type_id || values.parking_area_type_id === "-1")
    errors.parking_area_type_id = I18n.tr(I18nKeys.TIPO_DE_ZONA_OBLIGATORIO);

  if (!values.street_type_id || values.street_type_id === "-1")
    errors.street_type_id = I18n.tr(I18nKeys.TIPO_DE_CALLE_OBLIGATORIO);

  if (!values.route_id || values.route_id === "-1")
    errors.route_id = I18n.tr(I18nKeys.RUTA_OBLIGATORIA);

  if (!values.parking_spaces)
    errors.parking_spaces = I18n.tr(I18nKeys.ESPACIOS_OBLIGATORIOS);

  if (!values.kml_file || values.kml_file.length === 0)
    errors.kml_file = I18n.tr(I18nKeys.FICHERO_KML_OBLIGATORIO);

  return errors;
}

export default compose(
  reduxForm<StreetCardFormCompanyUserData, StreetCardFormCompanyUserExtProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(StreetCardFormCompanyUser);
