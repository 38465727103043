import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_REPORT_INVALIDATION_REASONS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import ReportInvalidationReasonAdminFormReducer from "../formscreen/ReportInvalidationReasonAdminFormReducer";

const FORM_NAME = "ReportInvalidationReasonAdminForm";

export interface ReportInvalidationReasonAdminFormData {
    name: string;
}

interface InnerProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: ReportInvalidationReasonAdminFormData)=> void;
    initialValues?: Partial<ReportInvalidationReasonAdminFormData>;
    parentLoading?: boolean;
}

const mapStateToProps = ReportInvalidationReasonAdminFormReducer.autoMapToProps();

type Props = InjectedFormProps<ReportInvalidationReasonAdminFormData> & InnerProps & typeof mapStateToProps;

enum Fields {
    NAME = "name"
}

class ReportInvalidationReasonAdminFormCard extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit,
      onSubmit,
      initialValues,
      invalid,
      loading,
      pristine,
      parentLoading} = this.props;
    const headerCardTitle = I18n.tr(initialValues ? I18nKeys.RAZON_DE_INVALIDACION : I18nKeys.NUEVA_RAZON_DE_INVALIDACION);

    return (
      <Card loading={parentLoading || loading}>
        <CardHeader title={headerCardTitle}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.NOMBRE)}
                name={Fields.NAME}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_ADMIN_REPORT_INVALIDATION_REASONS)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: ReportInvalidationReasonAdminFormData) {
  const errors: FormErrors<ReportInvalidationReasonAdminFormData> = {
  };

  if (!values.name || values.name.length === 0) 
    errors.name = I18n.tr(I18nKeys.NOMBRE_OBLIGATORIO);
  
  return errors;
}

export default compose(
  reduxForm<ReportInvalidationReasonAdminFormData, InnerProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(ReportInvalidationReasonAdminFormCard);
