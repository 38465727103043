import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import VehicleModel from "../../../../commons/model/VehicleModel";
import TaskVehicleModelDetail from "../../../../commons/ws/vehicleModel/TaskVehicleModelDetail";
import VehicleModelFormAdminScreenActions from "./VehicleModelFormAdminScreenActions";
import VehicleBrand from "../../../../commons/model/VehicleBrand";
import TaskVehicleBrandList from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandList";

interface InitialState {
    vehicleModelLoading: boolean,
    vehicleModel?: VehicleModel,
    vehicleBrands: VehicleBrand[],
}

const INITIAL_STATE: InitialState = {
    vehicleModelLoading: false,
    vehicleModel: undefined,
    vehicleBrands: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskVehicleModelDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        vehicleModelLoading: true
    }))
    .onEvent(TaskVehicleModelDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        vehicleModelLoading: false
    }))
    .onEvent(TaskVehicleModelDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, vehicleModel: payload.data};
        })

    .onEvent(TaskVehicleBrandList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, vehicleBrands: payload.data};
    })

    .onEvent(VehicleModelFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
