import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskLawDelete from "../../../../commons/ws/law/TaskLawDelete";
import TaskLawList from "../../../../commons/ws/law/TaskLawList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_LAW_ADD } from "../../../../routing/Routes";
import LawListCompanyUserReducer from "./LawListCompanyUserReducer";
import LawListTable from "./LawListTable";

const mapStateToProps = LawListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class LawListCardCompanyUser extends React.Component<Props> {
  public render(): React.ReactNode {
    const {loading, laws, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.ARTICULOS)}
        sort={{
          column: "code",
        }}
        TaskList={TaskLawList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_ARTICULOS),
        }}
        data={laws}
        error={error}
        ItemsTable={LawListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_ARTICULOS),
          Task: TaskLawDelete,
        }}
        addItem={ROUTE_COMPANY_USER_LAW_ADD}
      />
    );
  }
}

export default connect(mapStateToProps)(LawListCardCompanyUser as unknown as React.ComponentType<void>);
