import { Checkbox } from "@material/react-checkbox";
import { debounce, isEqual } from "lodash";
import { Component, ComponentType, Fragment, ReactNode, SyntheticEvent } from "react";
import Alert from "../../base/alerts/Alert";
import I18n from "../../commons/I18n/I18n";
import { TaskGenericList } from "../../commons/ws/BaseGenericRequest";
import { TaskCriteriaRequest } from "../../commons/ws/TaskCriteriaRequest";
import { BatchResponseCode } from "../../commons/ws/TaskResponse";
import Config from "../../config/Config";
import I18nKeys from "../../I18n/I18nKeys";
import { goToRoute } from "../../utils/Router";
import { OnSortHandler, SortState } from "../../utils/Sort";
import Button from "../buttons/Button";
import Col from "../Col";
import { DropDownOption } from "../dropdown/DropDown";
import Icon from "../Icon";
import Pager, { PagerBackendProps } from "../Pager";
import Row from "../Row";
import SelectTh from "../table/SelectTh";
import Card from "./Card";
import CardBody from "./CardBody";
import CardHeader from "./CardHeader";
import CardOkCancelFooter from "./CardOkCancelFooter";

export enum SelectAction {
    NONE,
    DELETE,
    SEND
}

export interface EmptyListOptions {
    message: string;
    addHandler?: ()=> void;
}

interface CardListProps {
    loading: boolean;
    title: string;
    TaskList: TaskGenericList;
    emptyListOptions: EmptyListOptions;
    data: any[];
    error: string;
    ItemsTable: ComponentType<{
        data: any[];
        error: string;
        onSort: OnSortHandler;
        sort: SortState;
        renderSelectTd?: (itemId: string)=> ReactNode;
        addToFavorites?: boolean;
        removeFromFavorites?: boolean;
        refreshListHandler?: ()=> void;
    }>;
    sort: {
        column: string;
        desc?: boolean;
    };
    subtitle?: string;
    pager: PagerBackendProps;
    headerOptions?: DropDownOption[];
    addItem?: string;
    sendItems?: ()=> void;
    enableSend?: boolean;
    addToFavorites?: boolean;
    removeFromFavorites?: boolean;
    favoritesManagerByList?: boolean;
    deleteOption?: {
        // no puedo tipar este caso por problemas al interpretar el constructor
        Task: any;
        title: string;
    };
    customCriteria?: {[criteria: string]: any};
    customTaskParams?: Array<boolean | number | string>;
    noSearchBar?: boolean;
    headerOptionIdSelected?: number;
    footerActionSelected?: SelectAction;
    onCheckedItem?: (itemId: string)=> void;
    onUncheckedItem?: (itemId: string)=> void;
    onSendButtonClick?: ()=> void;
    sendButtonText?: string;
}

interface State {
    currentPage: number;
    headerSearch: string;
    search: string;
    sort: SortState;
    currentSelectAction: SelectAction;
    selectedItemIds: string[];
}

export default class CardList extends Component<CardListProps, State> {
  public state: State = {
    headerSearch: "",
    search: "",
    sort: {
      column: this.props.sort.column,
      asc: !this.props.sort.desc,
    },
    currentPage: 1,
    currentSelectAction: this.props.footerActionSelected ? this.props.footerActionSelected : SelectAction.NONE,
    selectedItemIds: [],
  };

  private static getBatchResponseMessage(response: BatchResponseCode): void {
    if (response["200"] && response["200"] > 0) {
      if (response["200"] === 1)
        Alert.success(`${I18n.tr(I18nKeys.ELIMINADO_CORRECTAMENTE)} ${response["200"]} ${I18n.tr(I18nKeys.ELEMENTO)}.`);
      else
        Alert.success(`${I18n.tr(I18nKeys.ELIMINADOS_CORRECTAMENTE)} ${response["200"]} ${I18n.tr(I18nKeys.ELEMENTOS)}.`);
    }

    if (response["401"] && response["401"] > 0) {
      if (response["401"] === 1)
        Alert.warning(`${I18n.tr(I18nKeys.NO_TIENES_PERMISO_PARA_ELIMINAR)} ${response["401"]} ${I18n.tr(I18nKeys.ELEMENTOS)}.`);
      else
        Alert.warning(`${I18n.tr(I18nKeys.NO_TIENES_PERMISOS_PARA_ELIMINAR)} ${response["401"]} ${I18n.tr(I18nKeys.ELEMENTOS)}.`);
    }

    if (response["404"] && response["404"] > 0) {
      if (response["404"] === 1)
        Alert.error(`${I18nKeys.NO_SE_HA_ENCONTRADO} ${response["404"]} ${I18n.tr(I18nKeys.ELEMENTO)}.`);
      else
        Alert.error(`${I18nKeys.NO_SE_HAN_ENCONTRADO} ${response["404"]} ${I18n.tr(I18nKeys.ELEMENTOS)}.`);
    }

    if (response["405"] && response["405"] > 0) {
      if (response["405"] === 1)
        Alert.error(`${I18n.tr(I18nKeys.NO_SE_PERMITE_BORRAR)} ${response["405"]} ${I18n.tr(I18nKeys.ELEMENTO)}.`);
      else
        Alert.error(`${I18n.tr(I18nKeys.NO_SE_PERMITEN_BORRAR)} ${response["405"]} ${I18n.tr(I18nKeys.ELEMENTOS)}.`);
    }
  }

  public componentWillMount(): void {
    this.getItems();
  }

  // eslint-disable-next-line camelcase
  public UNSAFE_componentWillUpdate(
    nextProps: Readonly<CardListProps>,
    nextState: Readonly<State>,
  ): void {
    const currentCriteria = this.getCriteria();
    const nextCriteria = this.getCriteria(nextState, nextProps);

    if (!isEqual(currentCriteria, nextCriteria))
      this.getItems(nextCriteria);
  }

  public componentWillUnmount(): void {
    this.onSearchDebounced.cancel();
  }

  public render(): ReactNode {
    const {loading,
      title,
      subtitle,
      headerOptions = [],
      deleteOption,
      addItem,
      sendItems,
      children,
      noSearchBar = false,
      headerOptionIdSelected,
      enableSend} = this.props;
    const {headerSearch} = this.state;
    const allOptions: DropDownOption[] = [];

    allOptions.push(...headerOptions);

    if (deleteOption) {
      allOptions.push( {
        text: I18n.tr(deleteOption.title),
        onClick: this.onShowDelete,
      } );
    }

    const cardHeaderProps = noSearchBar
      ? {
      }
      : {
        searchText: headerSearch,
        onSearchChange: (newSearch) => this.onHeaderSearch(newSearch),
        onRefresh: this.onRefresh,
      };

    return (
      <Card loading={loading}>
        <CardHeader
          {...cardHeaderProps}
          title={title}
          subtitle={subtitle}
          options={allOptions.length === 0 ? undefined : allOptions}
          onAdd={addItem ? () => goToRoute(addItem) : undefined}
          onSend={sendItems || undefined}
          disabledSendButton={enableSend}
          optionIdSelected={headerOptionIdSelected}
        >
          {children}
        </CardHeader>
        <CardBody className={"table-responsive"}>
          {this.renderCardBody()}
        </CardBody>
      </Card>
    );
  }

  private getCriteria = (nextState?: State, nextProps?: CardListProps) => {
    const {currentPage, search, sort} = nextState || this.state;
    const {customCriteria} = nextProps || this.props;
    let parentCriteriaHasChanged = false;

    if (nextProps && !isEqual(this.props.customCriteria, nextProps.customCriteria))
      parentCriteriaHasChanged = true;

    return {
      ...customCriteria,
      limit: Config.PAGER.elementsPage,
      page: parentCriteriaHasChanged ? 1 : currentPage,
      search: this.props.noSearchBar && customCriteria ? customCriteria.search : search,
      sort: sort.asc ? `+${sort.column}` : `-${sort.column}`,
    };
  };

  private getItems = (customCriteria?: TaskCriteriaRequest<{}>): void => {
    const {TaskList, customTaskParams = []} = this.props;
    const criteria = customCriteria || this.getCriteria();

    // @ts-ignore, el tipo de constructor es diferente entre clase padre e hijo
    new TaskList(criteria, ...customTaskParams).execute();
  };

  private onRefresh = (): void => {
    this.getItems();
  };

  private onSetPage = (pageNumber: number): void => {
    this.setState( {
      currentPage: pageNumber,
    } );
  };

  private onHeaderSearch = (headerSearch: string): void => {
    this.setState( {
      headerSearch,
    } );
    this.onSearchDebounced(headerSearch);
  };

  private onSearch = (search: string): void => {
    this.setState( {
      search,
      currentPage: 1,
    } );
  };

  private onSearchDebounced = debounce(this.onSearch, 1000);

  private onSort: OnSortHandler = (asc, columnName) => {
    this.setState( {
      sort: {
        column: columnName,
        asc,
      },
    } );
  };

  private onShowDelete = (): void => {
    this.setState( {
      currentSelectAction: SelectAction.DELETE,
    } );
  };

  private clearCurrentAction = (): void => {
    this.setState( {
      currentSelectAction: SelectAction.NONE,
      selectedItemIds: [],
    } );
  };

  private addToSelectedItemIds = (newItemId: string): void => {
    const newSelectedItemIds: string[] = [...this.state.selectedItemIds];

    newSelectedItemIds.push(newItemId);
    this.setState( {
      selectedItemIds: newSelectedItemIds,
    } );

    if (this.props.onCheckedItem)
      this.props.onCheckedItem(newItemId);
  };

  private removeFromSelectedItemIds = (toRemoveItemId: string): void => {
    this.setState( {
      selectedItemIds: this.state.selectedItemIds.filter(
        (selectedItemId) => selectedItemId !== toRemoveItemId),
    } );

    if (this.props.onUncheckedItem)
      this.props.onUncheckedItem(toRemoveItemId);
  };

  private onDeleteSelectedItems = (): void => {
    const {deleteOption, customTaskParams = []} = this.props;
    const {selectedItemIds} = this.state;

    if (deleteOption) {
      new deleteOption.Task(selectedItemIds, ...customTaskParams)
        .onSuccess((response) => {
          CardList.getBatchResponseMessage(response.data);
          this.getItems(undefined);
        } )
        .execute();
    }

    this.clearCurrentAction();
  };

  private renderCardBody = (): ReactNode => {
    const {sort,
      currentSelectAction,
      selectedItemIds} = this.state;
    const {data,
      error,
      emptyListOptions,
      ItemsTable,
      pager,
      loading,
      addToFavorites,
      removeFromFavorites,
      onSendButtonClick,
      sendButtonText} = this.props;

    if ((!data || data.length === 0) && !loading) {
      return (
        <div className={"empty-msg-cnt"}>
          <h4 className={"text-center m-t-30 m-b-30"}>{emptyListOptions.message}</h4>
          {
            emptyListOptions.addHandler &&
                        <a onClick={emptyListOptions.addHandler}>
                          <Icon className={"empty-msg-icon"} icon={"add_circle"}/>
                        </a>
          }
        </div>
      );
    }

    if (error)
      return <h4 className={"table-error"}>{error}</h4>;

    const showSelect = currentSelectAction !== SelectAction.NONE;
    let okTitle: string = "";
    let okHandler: ()=> void;

    switch (currentSelectAction) {
      case SelectAction.DELETE:
        okTitle = I18n.tr(I18nKeys.ELIMINAR);
        okHandler = this.onDeleteSelectedItems;
        break;
      default:
        okTitle = "None";
        okHandler = this.clearCurrentAction;
    }

    return (
      <Fragment>
        <ItemsTable
          data={data}
          error={error}
          sort={sort}
          onSort={this.onSort}
          renderSelectTd={this.renderSelectTd}
          addToFavorites={addToFavorites}
          removeFromFavorites={removeFromFavorites}
          refreshListHandler={this.getItems}
        >
          <SelectTh title={""} showSelect={showSelect}/>
        </ItemsTable>
        <Pager
          data={pager}
          onPage={(page) => this.onSetPage(page)}
        />
        {
          showSelect && currentSelectAction === SelectAction.DELETE &&
                    <CardOkCancelFooter
                      okTitle={okTitle}
                      okHandler={okHandler}
                      cancelHandler={this.clearCurrentAction}
                      okBtnDisabled={selectedItemIds.length === 0}
                    />
        }

        {
          showSelect && currentSelectAction === SelectAction.SEND &&
                        onSendButtonClick && sendButtonText &&
                    <Row>
                      <Col style={{
                        padding: "15px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}>
                        <Button
                          className={"btn btn-primary btn-lg"}
                          text={sendButtonText}
                          onClick={() => onSendButtonClick()}
                          disabled={selectedItemIds.length === 0}
                        />
                      </Col>
                    </Row>
        }
      </Fragment>
    );
  };

  private renderSelectTd = (itemId: string): ReactNode => {
    const {selectedItemIds, currentSelectAction} = this.state;
    const showSelect = currentSelectAction !== SelectAction.NONE;
    const selected = selectedItemIds.includes(itemId);
    const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
      event.stopPropagation();

      if (selected)
        return this.removeFromSelectedItemIds(itemId);

      return this.addToSelectedItemIds(itemId);
    };

    // eslint-disable-next-line multiline-ternary
    return showSelect ? (
      <td className='td-checkbox'>
        <Checkbox
          initRipple={() => null}
          unbounded={false}
          checked={selected}
          onChange={onChangeHandler}
          // @ts-ignore
          onClick={(event: SyntheticEvent<HTMLInputElement>) => event.stopPropagation()}
        />
      </td>
    ) : null;
  };
}
