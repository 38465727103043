import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from 'classnames';
import imagePlaceholder from './../../res/img/img-placeholder.jpg'

export interface FormInputImageProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    min?: number,
    max?: number,
    maxLength?: number,
    showError?: boolean,
    classNameInput?: boolean,
    classForm?: string,
}

export default class FormInputImage extends Component<FormInputImageProps> {

    public render(): ReactNode {
        const {input, meta, label, placeholder, disabled, col, showError, max, min, maxLength, classNameInput, classForm} = this.props;
        const classFormLine = classNames({
            'form-line': !disabled,
            'focused': meta.touched && meta.error,
            'error': meta.touched && meta.error
        });
        return (
            <FormCol {...col} >
                <div className="form-group">
                    <div className={`${classFormLine} ${classForm}`}>
                        {label ? <label className='main-label'>{label || ''}</label> : null}
                        <img src={input.value ? input.value : imagePlaceholder} className={'img-form-input-image'}/>
                        {!disabled &&
                            <input  {...input}
                                    type={'file'}
                                    className={`form-control ${classNameInput}`} placeholder={placeholder}
                                    disabled={disabled || false}
                                    max={max}
                                    maxLength={maxLength}
                                    min={min}
                            />
                        }
                    </div>
                    <label className="error">{(meta.touched || showError) ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }

}

