import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import Exploitation, { ExploitationData } from "../../model/Exploitation";
import { generateRoute } from "../../utils/Router";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskExploitationEdit extends BaseGenericRequest<ExploitationData, TaskResponse<Exploitation>> {
  private readonly data: ExploitationData;

  public constructor(exploitationId: string, exploitationData: ExploitationData) {
    super(METHOD.PUT, generateRoute(Urls.URL_EXPLOITATION_EDIT, {
      uuid: exploitationId,
    } ));
    this.data = exploitationData;

    this.setErrorMessage(488, I18n.tr(I18nKeys.YA_EXISTE_UN_ELEMENTO_SIMILAR));
  }

  protected getRequest(): ExploitationData {
    return this.data;
  }
}
