import {Component, ReactNode} from "react";
import {connect} from "react-redux";
import CollectionProcessDetailActions from "./CollectionProcessDetailActions";
import CollectionProcessDetailReducer from "./CollectionProcessDetailReducer";
import TaskParkingMeterCollectionProcessDetail
	from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessDetail";
import CollectionProcessDetailView from "./CollectionProcessDetailView";

interface CollectionProcessDetailDataContainerProps {
	id: string
}

const mapStateToProps = CollectionProcessDetailReducer.autoMapToProps();
const mapActionsToProps = CollectionProcessDetailActions.autoMapToProps();

type Props = CollectionProcessDetailDataContainerProps & typeof mapStateToProps & typeof mapActionsToProps;

class CollectionProcessDetailDataContainer extends Component<Props> {
	public componentWillUnmount(): void {
		this.props.dispatchClearReducer();
	}

	public componentDidMount() {
		new TaskParkingMeterCollectionProcessDetail(this.props.id).execute();
	}

	public render(): ReactNode {
		const {parkingMeterCollectionProcess, loading, errors, pager} = this.props;
		return (
			<CollectionProcessDetailView
				parkingMeterCollectionProcess={parkingMeterCollectionProcess}
				loading={loading}
				errors={errors}
				pager={pager}
			/>
		);
	}
}
export default connect(mapStateToProps, mapActionsToProps)(CollectionProcessDetailDataContainer as unknown as React.ComponentType<CollectionProcessDetailDataContainerProps>);
