import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import User from '../../model/User';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';

type UserCriteriaProps = TaskCriteriaRequest<{simply?: boolean}>

export default class TaskUserControllerList extends BaseGenericRequest<UserCriteriaProps, TaskResponse<User[]>> {

    private readonly data: UserCriteriaProps;

    public constructor(criteria: UserCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_USER_LIST);
        this.data = criteria;
    }

    protected getRequest(): UserCriteriaProps {
        return this.data;
    }
}
