import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_LAW_TYPES } from "../../../../routing/Routes";
import LawTypeListCardAdmin from "./LawTypeListCardAdmin";

const LawTypeListAdminScreen = () => (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.LEYES),
      url: ROUTE_ADMIN_LAW_TYPES,
      icon: AppIcon.LAW,
    },
  ]}>
    <LawTypeListCardAdmin/>
  </ScreenCard>
);

export default LawTypeListAdminScreen;
