import Report from '../../../../commons/model/Report';
import TaskReportList, { ReportCriteriaProps } from '../../../../commons/ws/report/TaskReportList';
import { PagerBackendProps } from '../../../../components/Pager';
import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import Config from '../../../../config/Config';
import { SortState } from '../../../../utils/Sort';
import RatificationReportListCompanyUserActions from './RatificationReportListCompanyUserActions';
import { ReportStateIds } from '../../../../commons/model/ReportState';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER STATE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const initialDataCriteria: ReportCriteriaProps = {
    limit: Config.PAGER.elementsPage,
    report_state_ids: [ReportStateIds.PUESTA],
    simply: true,
};

interface InitialState {
    data: Report[]
    dataSort: SortState
    dataPager: PagerBackendProps
    dataError: string
    dataLoading: boolean
    dataCriteria: ReportCriteriaProps
    dataItemIdsSelected: string[] | null
}

const INITIAL_STATE: InitialState = {
    data: [],
    dataSort: {asc: true, column: 'number'},
    dataPager: {current: 1, total_pages: 1, total_elements: 0},
    dataError: '',
    dataLoading: false,
    dataCriteria: {...initialDataCriteria},
    dataItemIdsSelected: null,
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER LOGIC
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const dispatchFetchDataOnSuccessLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const {data, pager} = payload;
    newState.data = data;
    newState.dataPager = pager || oldState.dataPager;
    return newState;
};

const dispatchSetSortLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    const {asc, column} = payload as SortState;
    const sort = asc ? `+${column}` : `-${column}`;
    newState.dataSort = payload;
    newState.dataCriteria = {...oldState.dataCriteria, sort};
    return newState;
};

const dispatchAddCriteriaLogic = (oldState: InitialState, payload: any): InitialState => {
    const newState: InitialState = {...oldState};
    newState.dataCriteria = {...oldState.dataCriteria, ...payload, page: 1};
    return newState;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(
        RatificationReportListCompanyUserActions.dispatchResetListReducer,
        () => ( {...INITIAL_STATE} )
    )
    .onEvent(
        RatificationReportListCompanyUserActions.dispatchAddCriteria,
        (oldState, payload) => ( dispatchAddCriteriaLogic(oldState, payload) )
    )
    .onEvent(
        RatificationReportListCompanyUserActions.dispatchClearCriteria,
        (oldState) => ( {...oldState, dataCriteria: {...initialDataCriteria}} )
    )
    .onEvent(
        RatificationReportListCompanyUserActions.dispatchSetSort,
        (oldState, payload) => ( dispatchSetSortLogic(oldState, payload) )
    )
    .onEvent(
        RatificationReportListCompanyUserActions.dispatchSetPage,
        (oldState, payload) => ( {...oldState, dataCriteria: {...oldState.dataCriteria, page: payload}} )
    )
    .onEvent(
        TaskReportList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, dataLoading: true, dataItemIdsSelected: null} )
    )
    .onEvent(
        TaskReportList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, dataLoading: false} )
    )
    .onEvent(
        TaskReportList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => ( {...oldState, dataError: payload} )
    )
    .onEvent(
        TaskReportList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( dispatchFetchDataOnSuccessLogic(oldState, payload) )
    )
    .build();
