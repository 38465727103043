import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import ParkingMeterIncidence from "../../../../commons/model/ParkingMeterIncidence";
import TaskParkingMeterIncidenceDetail
    from "../../../../commons/ws/parkingMeterIncidence/TaskParkingMeterIncidenceDetail";
import ParkingMeterIncidenceFormCompanyUserScreenActions from "./ParkingMeterIncidenceFormCompanyUserScreenActions";

interface InitialState {
    parkingMeterIncidenceLoading: boolean,
    parkingMeterIncidence?: ParkingMeterIncidence,
}

const INITIAL_STATE: InitialState = {
    parkingMeterIncidenceLoading: false,
    parkingMeterIncidence: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskParkingMeterIncidenceDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        parkingMeterIncidenceLoading: true
    }))
    .onEvent(TaskParkingMeterIncidenceDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        parkingMeterIncidenceLoading: false
    }))
    .onEvent(TaskParkingMeterIncidenceDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, parkingMeterIncidence: payload.data};
        })

    .onEvent(ParkingMeterIncidenceFormCompanyUserScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
