import BaseGenericRequest from "../BaseGenericRequest";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskDeleteResponse} from "../TaskDeleteResponse";
import {generateRoute} from "../../utils/Router";

export default class TaskUserCertDelete extends BaseGenericRequest<void, TaskDeleteResponse> {

    public constructor() {
        super(METHOD.DELETE, generateRoute(Urls.URL_USER_CERT_DELETE));
    }

}
