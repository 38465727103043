import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse, { BatchResponseCode } from "../TaskResponse";
import Urls from "../Urls";

export default class TaskReportStateCancelFile extends BaseGenericRequest<FormData, TaskResponse<BatchResponseCode>> {
  private readonly file: File;

  public constructor(file: File) {
    super(METHOD.POST, Urls.URL_REPORT_STATE_CANCEL_FILE);
    this.file = file;

    this.setErrorMessage(491, I18n.tr(I18nKeys.EL_FICHERO_EXCEDE_EL_TAMANO_MAXIMO));

    delete this.headers["Content-type"];
    this.addHeader("Content-Type", "multipart/form-data");
  }

  protected getRequest(): FormData {
    const data = new FormData();

    data.append("file", this.file);

    return data;
  }
}
