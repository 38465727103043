import { METHOD } from '@cuatroochenta/co-generic-request';
import BaseGenericRequest from '../BaseGenericRequest';
import Urls from '../Urls';
import TaskResponse from '../TaskResponse';
import { generateRoute } from '../../utils/Router';
import BlacklistRule from '../../model/BlacklistRule';

export default class TaskBlacklistRuleDetail extends BaseGenericRequest<{}, TaskResponse<BlacklistRule>> {

    public constructor(blacklistRuleId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_BLACK_LIST_RULE_DETAIL, {uuid: blacklistRuleId}));
    }
}
