import React from "react";
import I18n from "../../../../commons/I18n/I18n";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";
import { generateRoute } from "../../../../commons/utils/Router";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface ParkingMeterCollectionListTableProps {
    data: ParkingMeterCollection[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

const goToItemDetail = (itemId: string): void => {
  goToRoute(generateRoute(ROUTE_COMPANY_USER_PARKING_METER_COLLECTION_DETAIL, {
    id: itemId,
  } ));
};

export default class ParkingMeterCollectionListTable extends React.Component<ParkingMeterCollectionListTableProps> {
  public render(): React.ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((parkingMeterCollection: ParkingMeterCollection) => (
            <tr key={parkingMeterCollection.id}
              onClick={() => goToItemDetail(parkingMeterCollection.id)}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(parkingMeterCollection.id) : null}
              <td>
                {parkingMeterCollection.parking_meter ? parkingMeterCollection.parking_meter.code : ""}
              </td>
              <td>
                {parkingMeterCollection.date ? `${DateFormatter.formatDate(parkingMeterCollection.date)} ${DateFormatter.formatTime(parkingMeterCollection.date)}` : ""}
              </td>
              <td>
                {parkingMeterCollection.controller ?
                  `${parkingMeterCollection.controller.name} ${parkingMeterCollection.controller.last_name}`
                  : ""}
              </td>
              <td>
                {parkingMeterCollection.collect_id ? parkingMeterCollection.collect_id : ""}
              </td>
              <td>
                {parkingMeterCollection.cash ? parkingMeterCollection.cash : ""}
              </td>
              <td>
                {parkingMeterCollection.assistant ? parkingMeterCollection.assistant : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.PARQUIMETRO)}
          columnName={"parking_meter"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.CONTROLADOR)}
          columnName={"controller"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.ID_COLECTA)}
          columnName={"flange_number"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.EFECTIVO)}
          columnName={"cash"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.AYUDANTE)}
          columnName={"flange_number"}
          {...commonProps}
        />
      </tr>
    );
  }
}
