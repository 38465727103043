import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import ReportCancellationByFileFormScreenActions from "./ReportCancellationByFileFormScreenActions";
import TaskReportStateCancelFile from "../../../../../commons/ws/reportStateCancelFile/TaskReportStateCancelFile";

interface InitialState {
    reportCancellationFileLoading: boolean,
}

const INITIAL_STATE: InitialState = {
    reportCancellationFileLoading: false,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskReportStateCancelFile.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        parkingMeterCollectionLoading: true
    }))
    .onEvent(TaskReportStateCancelFile.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        parkingMeterCollectionLoading: false
    }))

    .onEvent(ReportCancellationByFileFormScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
