import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import { UserCertData } from "../../model/UserCert";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

interface UserCertDataResponse {
    url: string;
}

export default class TaskUserCertUpload extends BaseGenericRequest<FormData, TaskResponse<UserCertDataResponse>> {
  private readonly data: UserCertData;

  public constructor(data: UserCertData) {
    super(METHOD.POST, Urls.URL_USER_CERT_UPLOAD, true);
    this.data = data;

    this.setErrorMessage(431, I18n.tr(I18nKeys.EL_CERTIFICADO_NO_ES_VALIDO));
    this.setErrorMessage(490, I18n.tr(I18nKeys.LA_CONTRASENA_DEL_CERTIFICADO_ES_INCORRECTA));
  }

  protected getRequest(): FormData {
    const data = new FormData();

    data.append("file", this.data.file, this.data.file.name);
    data.append("override", this.data.override ? "true" : "false");
    data.append("passphrase", this.data.passphrase);
    data.append("password", this.data.password);

    return data;
  }
}
