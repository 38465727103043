import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import {ParkingMeterCollectionProcess} from "../../model/ParkingMeterCollectionProcess";

interface ParkingMeterCollectionProcessCustomCriteria {
    start_date?: string,
    end_date?: string,
    process_state_ids?: Array<string>
}

export type ParkingMeterCollectionProcessCriteriaProps = TaskCriteriaRequest<ParkingMeterCollectionProcessCustomCriteria>

export default class TaskParkingMeterCollectionProcessList extends BaseGenericRequest<ParkingMeterCollectionProcessCriteriaProps, TaskResponse<ParkingMeterCollectionProcess[]>> {

    private readonly data: ParkingMeterCollectionProcessCriteriaProps;

    public constructor(criteria: ParkingMeterCollectionProcessCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_PARKING_METER_COLLECTION_PROCESS_LIST);
        this.data = criteria;
    }

    protected getRequest(): ParkingMeterCollectionProcessCriteriaProps {
        return this.data;
    }
}
