import BaseGenericRequest from '../BaseGenericRequest';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import TaskResponse, { BatchResponseCode } from '../TaskResponse';

interface IData {
    ids: string[]
    report_cancellation_method_id: string
}

export default class TaskReportStateCancel extends BaseGenericRequest<IData, TaskResponse<BatchResponseCode>> {

    private readonly data: IData;

    public constructor(reportIds: string[], reasonId: string) {
        super(METHOD.POST, Urls.URL_REPORT_STATE_CANCEL);

        this.data = {
            ids: reportIds,
            report_cancellation_method_id: reasonId,
        };
    }

    protected getRequest(): IData {
        return this.data;
    }
}
