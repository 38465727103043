import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import TaskRouteDetail from "../../../../commons/ws/route/TaskRouteDetail";
import RouteAdminScreenActions from "./RouteCompanyUserScreenActions";
import Route from "../../../../commons/model/Route";
import TaskParkingAreaTypeList from "../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeList";
import ParkingAreaType from "../../../../commons/model/ParkingAreaType";

interface InitialState {
    routeLoading: boolean,
    route?: Route,
    parkingAreaTypeList: ParkingAreaType[],
}

const INITIAL_STATE: InitialState = {
    routeLoading: false,
    route: undefined,
    parkingAreaTypeList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskRouteDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        routeLoading: true
    }))
    .onEvent(TaskRouteDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        routeLoading: false
    }))
    .onEvent(TaskRouteDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, route: payload.data};
    })

    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        routeLoading: true
    }))
    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        routeLoading: false
    }))
    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, parkingAreaTypeList: payload.data};
    })

    .onEvent(RouteAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
