import { ActionBuilder, ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import I18n from "../../../../../commons/I18n/I18n";
import TaskReportCancellationMethodList from "../../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodList";
import TaskReportInvalidationReasonList from "../../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonList";
import { FilterSelect, selectOptionsBuilder } from "../../../../../components/filter";
import ConfirmModal from "../../../../../components/modal/ConfirmModal";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { headerOptionTexts } from "../../list/ReportListCompanyUserCard";
import { HeaderOptions } from "../../list/ReportListCompanyUserReducer";
import ReportDetailsCompanyUserActions from "../ReportDetailsCompanyUserActions";
import ReportDetailsCompanyUserReducer from "../ReportDetailsCompanyUserReducer";
import ReportDetailsCompanyUserModalActions from "./ReportDetailsCompanyUserModalActions";
import ReportDetailsCompanyUserModalReducer from "./ReportDetailsCompanyUserModalReducer";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - AUXILIARY
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const mapActionsToProps = ActionBuilder.combineActionsAutoMaps(
  ReportDetailsCompanyUserModalActions.autoMapToProps(),
  ReportDetailsCompanyUserActions.autoMapToProps(),
);
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  ReportDetailsCompanyUserModalReducer.autoMapToProps(),
  ReportDetailsCompanyUserReducer.autoMapToProps(),
);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PROPS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface InnerProps {
    show: boolean;
    headerOptionIdSelected: HeaderOptions | null;
    onClose: ()=> void;
    onConfirm: (reasonId: string)=> void;
}

type Props = InnerProps & typeof mapActionsToProps & typeof mapStateToProps;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class ReportDetailsCompanyUserModal extends React.Component<Props> {
  public componentDidMount() {
    const {cancellationMethodReasons, invalidationReasons} = this.props;
    const cancellationDataFetched = cancellationMethodReasons.length > 0;
    const invalidationDataFetched = invalidationReasons.length > 0;

    if (!cancellationDataFetched) 
      new TaskReportCancellationMethodList().execute();

    if (!invalidationDataFetched) 
      new TaskReportInvalidationReasonList().execute();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  public render() {
    const {show,
      reportOperationReasonId,
      headerOptionIdSelected} = this.props;
    const confirmDisabled = reportOperationReasonId === "-1";
    let modalTextProps = {
      title: "",
      message: "",
      textConfirmButton: "",
    };

    if (headerOptionIdSelected) {
      modalTextProps = {
        title: headerOptionTexts[HeaderOptions[headerOptionIdSelected]].modalTitle,
        message: headerOptionTexts[HeaderOptions[headerOptionIdSelected]].modalMessage,
        textConfirmButton: headerOptionTexts[HeaderOptions[headerOptionIdSelected]].buttonText,
      };
    }

    return (
      <ConfirmModal
        show={show}
        {...modalTextProps}
        confirmDisabled={confirmDisabled}
        onConfirmAction={this.handleConfirmAction}
        onClose={this.handleClose}
      >
        {
          headerOptionIdSelected === HeaderOptions.NULLIFY ? this.renderNullifyBody() : null
        }
        {
          headerOptionIdSelected === HeaderOptions.INVALIDATE ? this.renderInvalidateBody() : null
        }
      </ConfirmModal>
    );
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private renderNullifyBody = (): ReactNode => {
    const {cancellationMethodReasons,
      cancellationMethodReasonsLoading,
      dispatchChangeReportOperationReason} = this.props;
    const cancellationMethodReasonsOptions = selectOptionsBuilder.reportCancellationMethods(cancellationMethodReasons);

    return (
      <Row>
        <FilterSelect
          gridSize={12}
          label={I18n.tr(I18nKeys.ESCOJA_UN_MOTIVO)}
          isLoading={cancellationMethodReasonsLoading}
          options={cancellationMethodReasonsOptions}
          onChange={dispatchChangeReportOperationReason}
        />
      </Row>
    );
  };

  private renderInvalidateBody = (): ReactNode => {
    const {invalidationReasons,
      invalidationReasonsLoading,
      dispatchChangeReportOperationReason} = this.props;
    const invalidationReasonsOptions = selectOptionsBuilder.reportInvalidationReasons(invalidationReasons);

    return (
      <Row>
        <FilterSelect
          gridSize={12}
          label={I18n.tr(I18nKeys.ESCOJA_UN_MOTIVO)}
          isLoading={invalidationReasonsLoading}
          options={invalidationReasonsOptions}
          onChange={dispatchChangeReportOperationReason}
        />
      </Row>
    );
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  private handleConfirmAction = () => {
    this.props.onConfirm(this.props.reportOperationReasonId);
    this.handleClose();
  };

  private handleClose = () => {
    this.props.onClose();
    this.props.dispatchChangeReportOperationReason("-1");
  };
}

export default connect(mapStateToProps, mapActionsToProps)(ReportDetailsCompanyUserModal) as any;
