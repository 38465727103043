import BaseGenericRequest from '../BaseGenericRequest';
import TaskResponse from '../TaskResponse';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskCriteriaRequest } from '../TaskCriteriaRequest';
import ReportInvalidationReason from '../../model/ReportInvalidationReason';

export type ReportInvalidationCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskReportInvalidationReasonList extends BaseGenericRequest<ReportInvalidationCriteriaProps, TaskResponse<ReportInvalidationReason[]>> {

    private readonly data: ReportInvalidationCriteriaProps;

    public constructor(criteria: ReportInvalidationCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_REPORT_INVALIDATION_REASON_LIST);
        this.data = criteria;
    }

    protected getRequest(): ReportInvalidationCriteriaProps {
        return this.data;
    }
}
