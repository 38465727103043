import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import BlacklistRule, {BlacklistRuleData} from "../../model/BlacklistRule";

export default class TaskBlacklistRuleEdit extends BaseGenericRequest<BlacklistRuleData, TaskResponse<BlacklistRule>> {

    private readonly data: BlacklistRuleData;

    public constructor(blacklistRuleId: string, blacklistRuleData: BlacklistRuleData) {
        super(METHOD.PUT, generateRoute(Urls.URL_BLACK_LIST_RULE_EDIT, {uuid: blacklistRuleId}));
        this.data = blacklistRuleData;
    }

    protected getRequest(): BlacklistRuleData {
        return this.data;
    }
}
