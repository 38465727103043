import BaseGenericRequest from "../BaseGenericRequest";
import {ParkingMeterCollectionProcessSendData} from "../../model/ParkingMeterCollectionProcess";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";

export default class TaskParkingMeterCollectionProcessSend extends BaseGenericRequest<ParkingMeterCollectionProcessSendData, TaskResponse<{}>>{

    private readonly data: ParkingMeterCollectionProcessSendData;

    public constructor(data: ParkingMeterCollectionProcessSendData) {
        super(METHOD.POST, Urls.URL_PARKING_METER_COLLECTION_PROCESS_SEND);
        this.data = data;
    }

    protected getRequest(): ParkingMeterCollectionProcessSendData {
        return this.data;
    }
}
