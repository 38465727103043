import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { LawTypeData } from "../../../../commons/model/LawType";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskLawTypeAdd from "../../../../commons/ws/lawType/TaskLawTypeAdd";
import TaskLawTypeDetail from "../../../../commons/ws/lawType/TaskLawTypeDetail";
import TaskLawTypeEdit from "../../../../commons/ws/lawType/TaskLawTypeEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_LAW_TYPES, ROUTE_ADMIN_LAW_TYPE_ADD, ROUTE_ADMIN_LAW_TYPE_DETAIL } from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import { Int } from "../../../../utils/StringUtils";
import LawTypeCardFormAdmin, { LawTypeCardFormAdminData } from "../formdetail/LawTypeCardFormAdmin";
import LawTypeFormAdminScreenActions from "./LawTypeFormAdminScreenActions";
import LawTypeFormScreenAdminReducer from "./LawTypeFormScreenAdminReducer";

const mapStateToProps = LawTypeFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = LawTypeFormAdminScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class LawTypeFormAdminScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskLawTypeDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: LawTypeCardFormAdminData): void => {
    const existTypeLaw = this.props.match.params.id !== undefined;
    const submitData: LawTypeData = {
      name: values.name,
      discount_percentage: Int(values.discountPercentage),
      suma_code: values.sumaCode,
    };

    if (existTypeLaw) {
      executeItemTask(
        new TaskLawTypeEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_ADMIN_LAW_TYPES),
        I18n.tr(I18nKeys.LEY_MODIFICADA_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskLawTypeAdd(submitData),
        () => goToRoute(ROUTE_ADMIN_LAW_TYPES),
        I18n.tr(I18nKeys.LEY_CREADA_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const lawTypeId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.LEYES),
      url: ROUTE_ADMIN_LAW_TYPES,
      icon: AppIcon.LAW,
    }];

    if (lawTypeId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.LEY),
        url: generateRoute(ROUTE_ADMIN_LAW_TYPE_DETAIL, {
          id: lawTypeId,
        } ),
        icon: AppIcon.LAW,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVA_LEY),
        url: ROUTE_ADMIN_LAW_TYPE_ADD,
        icon: AppIcon.LAW,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderLawTypeForm()}
      </ScreenCard>
    );
  }

  private renderLawTypeForm(): React.ReactNode {
    const {lawTypeLoading, lawType} = this.props;
    let initialValues: Partial<LawTypeCardFormAdminData> | undefined;

    if (lawType) {
      initialValues = {
        sumaCode: lawType.suma_code ? lawType.suma_code.toString() : "",
        name: lawType.name ? lawType.name : "",
        discountPercentage: lawType.discount_percentage ? lawType.discount_percentage.toString() : "",
      };
    }
      
    return (
      <LawTypeCardFormAdmin
        initialValues={initialValues}
        parentLoading={lawTypeLoading}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)
(LawTypeFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
