
import I18n from "../../../../../commons/I18n/I18n";
import ScreenCard from "../../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../../config/Config";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_CONTROLLERS } from "../../../../../routing/Routes";
import ControllerReportCardCompanyUser from "./ControllerReportCardCompanyUser";

const ControllerReportCompanyUserScreen = () =>(
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.CONTROLADORES),
      url: ROUTE_COMPANY_USER_CONTROLLERS,
      icon: AppIcon.CONTROLLER_REPORT,
    },
  ]}>
    <ControllerReportCardCompanyUser/>
  </ScreenCard>
);

export default ControllerReportCompanyUserScreen;
