import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import SendToProccessActions from './SendToProccessActions';
import TaskReportExportProcessing from "../../../commons/ws/report/TaskReportExportProcessing";
import ParkingMeterCollection from "../../../commons/model/ParkingMeterCollection";
import {PagerBackendProps} from "../../../components/Pager";
import TaskReportExportHistoryList from "../../../commons/ws/reportexporthistory/TaskReportExportHistoryList";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER STATE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

interface InitialState {
    date: Date | null
    fileUrl: string
    loading: boolean,

    reportExportHistoryLoading: boolean,
    reportExportHistoryList: ParkingMeterCollection[],
    reportExportHistoryError: string,
    reportExportHistoryPager: PagerBackendProps,
}

const INITIAL_STATE: InitialState = {
    date: null,
    fileUrl: '',
    loading: false,

    reportExportHistoryLoading: false,
    reportExportHistoryList: [],
    reportExportHistoryError: '',
    reportExportHistoryPager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)

    .onEvent(
        TaskReportExportProcessing.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, loading: true} )
    )
    .onEvent(
        TaskReportExportProcessing.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, loading: false} )
    )

    .onEvent(TaskReportExportHistoryList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ( {...oldState, reportExportHistoryLoading: true} ))
    .onEvent(TaskReportExportHistoryList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ( {...oldState, reportExportHistoryLoading: false} ))
    .onEvent(TaskReportExportHistoryList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ( {
        ...oldState,
        reportExportHistoryList: payload.data,
        reportExportHistoryPager: payload.pager,
        reportExportHistoryError: '',
    } ))
    .onEvent(TaskReportExportHistoryList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ( {...oldState, reportExportHistoryError: payload} ))

    .onEvent(
        SendToProccessActions.dispatchResetSendToProccessReducer,
        () => ( {...INITIAL_STATE} )
    )
    .onEvent(
        SendToProccessActions.dispatchChangeDate,
        (oldState, payload) => ( {...oldState, date: payload} )
    )
    .onEvent(
        SendToProccessActions.dispatchResetDate,
        (oldState) => ( {...oldState, date: null} )
    )
    .onEvent(
        SendToProccessActions.dispatchSetFile,
        (oldState, payload) => ( {...oldState, fileUrl: payload} )
    )
    .build();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - REDUCER LOGIC
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//
