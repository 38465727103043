import React from "react";
import { Field, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import Button from "../../../../components/buttons/Button";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import Col from "../../../../components/Col";
import FormInput from "../../../../components/form/FormInput";
import FormSwitch from "../../../../components/form/FormSwitch";
import FormTextArea from "../../../../components/form/FormTextArea";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";

const FORM_NAME = "ParkingMeterCardFormCompanyUser";

export interface ParkingMeterIncidenceCardFormCompanyUserData {
    parking_meter: string;
    state: string;
    observations: string;
    date: string;
    controller: string;
    solved: boolean;
    solved_date: string;
    solved_controller: string;
}

interface ParkingMeterIncidenceCardFormExtProps {
    parkingMeterIncidenceId?: string;
    initialValues?: Partial<ParkingMeterIncidenceCardFormCompanyUserData>;
    parentLoading: boolean;
}

interface ParkingMeterIncidenceCardFormProps {
    initialValues: Partial<ParkingMeterIncidenceCardFormCompanyUserData>;
}

type Props = ParkingMeterIncidenceCardFormExtProps & ParkingMeterIncidenceCardFormProps;

enum ParkingAreaTypeFormAdminFields {
    PARKING_METER = "parking_meter",
    STATE = "state",
    OBSERVATIONS = "observations",
    DATE = "date",
    CONTROLLER = "controller",
    SOLVED = "solved",
    SOLVED_DATE = "solved_date",
    SOLVED_CONTROLLER = "solved_controller",
}

class ParkingMeterIncidenceCardFormCompanyUser extends React.Component<Props> {
  public render(): React.ReactNode {
    const {initialValues, parentLoading} = this.props;

    return (
      <Card loading={parentLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.INCIDENCIA_PARQUIMETRO : I18nKeys.NUEVA_INCIDENCIA_PARQUIMETRO)}/>
        <CardBody>
          <form>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.PARQUIMETRO)}
                name={ParkingAreaTypeFormAdminFields.PARKING_METER}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
              <Field
                label={I18n.tr(I18nKeys.ESTADO_INCIDENCIA)}
                name={ParkingAreaTypeFormAdminFields.STATE}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.FECHA)}
                name={ParkingAreaTypeFormAdminFields.DATE}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
              <Field
                label={I18n.tr(I18nKeys.CONTROLADOR)}
                name={ParkingAreaTypeFormAdminFields.CONTROLLER}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
            </Row>
            <Row>
              <Field
                labelEnabled={I18n.tr(I18nKeys.SOLUCIONADA)}
                labelDisabled={I18n.tr(I18nKeys.NO_SOLUCIONADA)}
                name={ParkingAreaTypeFormAdminFields.SOLVED}
                component={FormSwitch}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
              <Field
                label={I18n.tr(I18nKeys.FECHA_SOLUCIONADA)}
                name={ParkingAreaTypeFormAdminFields.SOLVED_DATE}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
              <Field
                label={I18n.tr(I18nKeys.SOLUCIONADA_POR_CONTROLADOR)}
                name={ParkingAreaTypeFormAdminFields.SOLVED_CONTROLLER}
                component={FormInput}
                col={{
                  md: 4,
                  lg: 4,
                }}
                disabled
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.OBSERVACIONES)}
                name={ParkingAreaTypeFormAdminFields.OBSERVATIONS}
                component={FormTextArea}
                col={{
                  md: 12,
                  lg: 12,
                }}
                disabled
              />
            </Row>
            <Row className='p-t-20'>
              <Col sm={4} md={6} lg={8}/>
              <Col sm={4} md={3} lg={2}/>
              <Col sm={4} md={3} lg={2}>
                <Button
                  text={I18n.tr(I18nKeys.CANCELAR)}
                  onClick={() => goToRoute(ROUTE_COMPANY_USER_PARKING_METER_INCIDENCES)}
                  type={"button"}
                  block={true}
                  className={"btn-lg btn-default"}
                />
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default reduxForm<ParkingMeterIncidenceCardFormCompanyUserData, ParkingMeterIncidenceCardFormExtProps>( {
  form: FORM_NAME,
  enableReinitialize: true,
} )(ParkingMeterIncidenceCardFormCompanyUser as any) as React.ComponentType<ParkingMeterIncidenceCardFormExtProps>;
