import { Component, ReactNode } from "react";
import I18n from "../../../../commons/I18n/I18n";
import Exploitation from "../../../../commons/model/Exploitation";
import { generateRoute } from "../../../../commons/utils/Router";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_EXPLOITATION_DETAIL } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface ExploitationListTableProps {
    data: Exploitation[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> ReactNode;
}

function gotoItemDetail(itemId: string): void {
  goToRoute(generateRoute(ROUTE_ADMIN_EXPLOITATION_DETAIL, {
    id: itemId,
  } ));
};
export default class ExploitationListTable extends Component<ExploitationListTableProps> {
  public render(): ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((exploitation: Exploitation) => (
            <tr key={exploitation.id}
              onClick={() => gotoItemDetail(exploitation.id)}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(exploitation.id) : null}
              <td>
                {exploitation.name}
              </td>
              <td>
                {exploitation.service_office_phone}
              </td>
              <td>
                {exploitation.town}
              </td>
              <td>
                {exploitation.province}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): ReactNode {
    const {sort, onSort, children} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.NOMBRE)}
          columnName={"name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.TELEFONO)}
          columnName={"name"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.POBLACION)}
          columnName={"town"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.PROVINCIA)}
          columnName={"province"}
          {...commonProps}
        />

      </tr>
    );
  }
}
