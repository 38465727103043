import React from "react";
import { connect } from "react-redux";
import Alert from "../../../../base/alerts/Alert";
import I18n from "../../../../commons/I18n/I18n";
import Upload from "../../../../commons/model/Upload";
import TaskParkingLossExport from "../../../../commons/ws/parkingLoss/TaskParkingLossExport";
import TaskParkingLossList, { ParkingLossCriteriaProps } from "../../../../commons/ws/parkingLoss/TaskParkingLossList";
import TaskResponse from "../../../../commons/ws/TaskResponse";
import Button from "../../../../components/buttons/Button";
import CardList from "../../../../components/card/CardList";
import Col from "../../../../components/Col";
import FormCol from "../../../../components/form/FormCol";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import ParkingLossListCompanyUserActions from "./ParkingLossListCompanyUserActions";
import ParkingLossListCompanyUserReducer from "./ParkingLossListCompanyUserReducer";
import ParkingLossListTable from "./ParkingLossListTable";

const mapActionsToProps = ParkingLossListCompanyUserActions.autoMapToProps();
const mapStateToProps = ParkingLossListCompanyUserReducer.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

interface State {
    currentCriteria: ParkingLossCriteriaProps;
}

class ParkingLossListCardCompanyUser extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      currentCriteria: {
      },
    };
  }

  public componentWillUnMount(): void {
    this.props.clearReducer();
  }

  private onStartDateSelect = (date?: Date | null): void => {
    const newCriteria: ParkingLossCriteriaProps = {
      ...this.state.currentCriteria,
      start_date: date ? date.toISOString() : undefined,
    };

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  private onEndDateSelect = (date?: Date | null): void => {
    const newCriteria: ParkingLossCriteriaProps = {
      ...this.state.currentCriteria,
    };

    if (date) {
      date.setDate(date.getDate() + 1);
      newCriteria.end_date = date.toISOString();
    } else
      delete newCriteria.end_date;

    this.setState( {
      currentCriteria: newCriteria,
    } );
  };

  public render(): React.ReactNode {
    const {loading, parkingLosses, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.PLAZAS_PERDIDAS)}
        sort={{
          column: "start_date",
        }}
        TaskList={TaskParkingLossList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_PLAZAS_PERDIDAS),
        }}
        data={parkingLosses}
        error={error}
        ItemsTable={ParkingLossListTable}
        customCriteria={this.state.currentCriteria}
      >
        {TaskParkingLossList && this.renderHeaderContent()}
      </CardList>
    );
  }

  private handleExportExcel(): void {
    const {parkingLosses} = this.props;

    new TaskParkingLossExport(parkingLosses)
      .onSuccess((response: TaskResponse<Upload>) => {
        if (response.success)
          window.open(response.data.url, "_blank");
      } )
      .onFail(Alert.error)
      .execute();
  }

  private renderHeaderContent = (): React.ReactNode => (
    <>
      <Row>
        <FormCol md={3} lg={3}>
          <div className={"form-group"}>
            <div className={"form-line"}>
              <label>{I18n.tr(I18nKeys.FECHA_INICIO)}</label>
              <input type={"date"} className="form-control"
                onChange={(e) => this.onStartDateSelect(e.target.valueAsDate)}/>
            </div>
          </div>
        </FormCol>
        <FormCol md={3} lg={3}>
          <div className={"form-group"}>
            <div className={"form-line"}>
              <label>{I18n.tr(I18nKeys.FECHA_FIN)}</label>
              <input type={"date"} className="form-control"
                onChange={(e) => this.onEndDateSelect(e.target.valueAsDate)}/>
            </div>
          </div>
        </FormCol>
      </Row>
      <Row>
        <Col sm={9} md={9} lg={9}/>
        <FormCol md={3} lg={3}>
          <Button
            text={I18n.tr(I18nKeys.EXPORTAR_EXCEL)}
            onClick={this.handleExportExcel.bind(this)}
            type={"button"}
            block={true}
            className={"btn-lg btn-default btn-export"}
            disabled={false}
          />
        </FormCol>
      </Row>
    </>
  );
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingLossListCardCompanyUser as unknown as React.ComponentType<void>);
