import {UserSimply} from "../../../commons/model/User";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import TaskChatContactList from "../../../commons/ws/chat/TaskChatContactList";
import TaskChatBroadcastList from "../../../commons/ws/chat/TaskChatBroadcastList";
import ChatBroadcast from "../../../commons/model/ChatBroadcast";
import ChatMessage from "../../../commons/model/ChatMessage";
import TaskChatMessageList from "../../../commons/ws/chat/TaskChatMessageList";
import {DateUtils} from "../../../utils/DateFormatter";

interface InitialState {
    contactList: UserSimply[],
    broadcastList: ChatBroadcast[],
    messageList: ChatMessage[],
    loading: boolean,
    error: string,
    maxDate: Date | null,
}

const INITIAL_STATE: InitialState = {
    contactList: [],
    loading: false,
    broadcastList: [],
    messageList: [],
    error: '',
    maxDate: null,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskChatContactList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskChatContactList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskChatContactList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskChatContactList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            contactList: payload.data,
        }
    })

    .onEvent(TaskChatBroadcastList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskChatBroadcastList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskChatBroadcastList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskChatBroadcastList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            broadcastList: payload.data,
        }
    })

    .onEvent(TaskChatMessageList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskChatMessageList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskChatMessageList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskChatMessageList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        const messageDates = payload.data.map((message) => message.date);
        return {
            ...oldState,
            messageList: [...oldState.messageList, ...payload.data.reverse()],
            maxDate: messageDates.length !== 0 ? DateUtils.getMaxDate(messageDates) : oldState.maxDate
        }
    })

    .build()
