export default class NumberFormatter {

    public static formatMoneyNumber(num: number): string {
        return num.toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + ' €';
    }

}
