import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskBlacklistRuleDelete from "../../../../commons/ws/blackListRule/TaskBlacklistRuleDelete";
import TaskBlacklistRuleList from "../../../../commons/ws/blackListRule/TaskBlacklistRuleList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD } from "../../../../routing/Routes";
import BlackListRuleListCompanyUserReducer from "./BlackListRuleListCompanyUserReducer";
import BlackListRuleListTable from "./BlackListRuleListTable";

const mapStateToProps = BlackListRuleListCompanyUserReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class BlackListRuleListCardCompanyUser extends React.Component<Props> {
  public render(): React.ReactNode {
    const {loading, blackListRules, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.CONFIGURACION_DE_LISTAS_NEGRAS)}
        sort={{
          column: "id",
        }}
        TaskList={TaskBlacklistRuleList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_CONFIGURACIONES_DE_LISTAS_NEGRAS),
        }}
        data={blackListRules}
        error={error}
        ItemsTable={BlackListRuleListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_CONFIGURACIONES_DE_LISTAS_NEGRAS),
          Task: TaskBlacklistRuleDelete,
        }}
        addItem={ROUTE_COMPANY_USER_BLACK_LIST_RULE_ADD}
      />
    );
  }
}

export default connect(mapStateToProps)(BlackListRuleListCardCompanyUser as unknown as React.ComponentType<void>);
