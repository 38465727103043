import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../commons/I18n/I18n";
import TaskReportCancellationMethodDelete from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodDelete";
import TaskReportCancellationMethodList from "../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodList";
import CardList from "../../../../components/card/CardList";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD } from "../../../../routing/Routes";
import ReportCancellationMethodListCompanyUserActions from "./ReportCancellationMethodListAdminActions";
import ReportCancellationMethodListCompanyUserReducer from "./ReportCancellationMethodListAdminReducer";
import ReportCancellationMethodListTable from "./ReportCancellationMethodListTable";

const mapActionsToProps = ReportCancellationMethodListCompanyUserActions.autoMapToProps();
const mapStateToProps = ReportCancellationMethodListCompanyUserReducer.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

class ReportCancellationMethodListCardAdmin extends React.Component<Props> {
  public componentWillMount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const {loading, reportCancellationMethods, error, pager} = this.props;

    return (
      <CardList loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.METODOS_DE_CANCELACION_DE_DENUNCIA)}
        sort={{
          column: "name",
        }}
        TaskList={TaskReportCancellationMethodList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_METODOS_DE_CANCELACION_DE_DENUNCIA),
        }}
        data={reportCancellationMethods}
        error={error}
        ItemsTable={ReportCancellationMethodListTable}
        deleteOption={{
          title: I18n.tr(I18nKeys.ELIMINAR_METODOS),
          Task: TaskReportCancellationMethodDelete,
        }}
        addItem={ROUTE_ADMIN_REPORT_CANCELLATION_METHOD_ADD}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportCancellationMethodListCardAdmin as unknown as React.ComponentType<void>);
