import { ReduxStorageUtils } from "@cuatroochenta/co-redux-builders";
import { ReactNode } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import UnloggedScreen from "../../../base/screens/UnloggedScreen";
import I18n from "../../../commons/I18n/I18n";
import TaskLogin from "../../../commons/ws/login/TaskLogin";
import TaskRecoverPassword from "../../../commons/ws/login/TaskRecoverPassword";
import Button from "../../../components/buttons/Button";
import Card from "../../../components/card/Card";
import CardBody from "../../../components/card/CardBody";
import Col from "../../../components/Col";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";
import I18nKeys from "../../../I18n/I18nKeys";
import imgLogo from "../../../res/img/logo.png";
import { ROUTE_HOME } from "../../../routing/Routes";
import AuthManager, { changeUserPassword } from "../../../utils/AuthManager";
import { executeItemTask } from "../../../utils/FormUtils";
import { goToRoute } from "../../../utils/Router";
import EditPasswordModal, { EditPasswordModalData } from "../../admin/users/formdetail/EditPasswordModal";
import AuthActions from "./LoginActions";
import AuthReducer from "./LoginReducer";
import RecoverPasswordModal from "./RecoverPasswordModal";

const mapStateToProps = AuthReducer.autoMapToProps();
const mapActionsToProps = AuthActions.autoMapToProps();

interface State {
    showRecoverPwd: boolean
    rememberMe: boolean
    showErrorMsg: boolean
    showEditPasswordModal: boolean
    errorResponseMsg: string
    errorPassword: string
}

type Props = typeof mapActionsToProps & typeof mapStateToProps;

const onRecoverPassword = (email: string): void => {
  executeItemTask(
    new TaskRecoverPassword(email),
    () => goToRoute(ROUTE_HOME),
    I18n.tr(I18nKeys.TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA),
    I18n.tr(I18nKeys.USUARIO_NO_ENCONTRADO),
  );
};

const CODE_PASSWORD_EXPIRED = 410;
class LoginScreen extends UnloggedScreen<Props, State> {
  public state: State = {
    showRecoverPwd: false,
    rememberMe: false,
    showErrorMsg: false,
    showEditPasswordModal: false,
    errorResponseMsg: "",
    errorPassword: ""
  };

  // eslint-disable-next-line class-methods-use-this
  public componentDidMount() {
    document.body.classList.add("login-page");
  }

  // eslint-disable-next-line class-methods-use-this
  public componentWillUnmount() {
    document.body.classList.remove("login-page");
  }

  private onShowRecoverPwd = (): void => {
    this.setState( {
      showRecoverPwd: true,
    } );
  };

  private onHideRecoverPwdModal = (): void => {
    this.setState( {
      showRecoverPwd: false,
    } );
  };

  private onRememberMe = (): void => {
    this.setState( {
      rememberMe: !this.state.rememberMe,
    } );
  };

  private setErrorResponseMsg = (errorResponseMsg: string = ""): void => {
    this.setState( {
      errorResponseMsg,
    } );
  };

  private onShowErrorMsg = (): void => {
    this.setState( {
      showErrorMsg: true,
    } );
  };

  private onHideErrorMsg = (): void => {
    this.setState( {
      showErrorMsg: false,
    } );
  };

  private doLogin = () => {
    const {email, password, allowLogin, setToken} = this.props;

    if (!allowLogin) {
      this.onShowErrorMsg()
      return
    }

    new TaskLogin(email, password)
      .onSuccess((response) => {
        if (response.success) {
          setToken(response.data)
          const isPasswordExpired = response.code == CODE_PASSWORD_EXPIRED
          console.log(isPasswordExpired)
          if (isPasswordExpired) {
            this.setState({
              showEditPasswordModal: true
            })
            return
          }else{
            AuthManager.setAuthToken(response.data, this.state.rememberMe);
            ReduxStorageUtils.dispatch(push(ROUTE_HOME));
          }
        }
      } )
      .onFail((response) => {
        this.onShowErrorMsg();
        this.setErrorResponseMsg(response);
      } )
      .execute();

  };

  private handleKeyboard = (event) => {
    if (event.key === "Enter") 
      this.doLogin();
  };

  private onHideEditPasswordModal = (): void => {
    this.setState( {
      showEditPasswordModal: false,
    } );
  };

  private onChangePassword = (passwordData: EditPasswordModalData): void => {
    console.log(passwordData.password)
    changeUserPassword(
      passwordData.password,
      undefined,
      async (response) => {
        const isRepeated = response.code == 204
        if (isRepeated) {
          this.setState({
            errorPassword: I18n.tr(I18nKeys.NO_REPEATABLE_PASSWORD),
          });
          this.showSamePasswordAsPreviousError()
          return
        }else{
        this.clearSamePasswordAsPreviousError();
        AuthManager.setAuthToken(this.props.token, this.state.rememberMe);
        ReduxStorageUtils.dispatch(push(ROUTE_HOME));
      }
      },
      this.props.token);
  };

  private showSamePasswordAsPreviousError = (): void => {
    this.setState({
      errorPassword:  I18n.tr(I18nKeys.NO_REPEATABLE_PASSWORD),
    });
  }

  private clearSamePasswordAsPreviousError = (): void => {
    this.setState({
      errorPassword: ""
    })
  }

  public renderScreen(): ReactNode {
    const {isValidPassword, isValidUser, allowLogin} = this.props;
    const {showErrorMsg, showEditPasswordModal, errorResponseMsg} = this.state;

    return (
      <div className="login-box">
        <div className="logo">
          <a>
            <img src={imgLogo} alt="Pavapark" className="img-responsive center-block m-b-15"/>
          </a>
          <small>{I18n.tr(I18nKeys.MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE)}</small>
        </div>
        <Card loading={this.props.loading}>
          <CardBody>
            <div className="msg">{I18n.tr(I18nKeys.ABRIR_SESION)}</div>
            <div className="input-group">
              <span className="input-group-addon"><Icon icon={"person"}/></span>
              <div className="form-line">
                <input
                  type="text"
                  className="form-control"
                  placeholder={I18n.tr(I18nKeys.EMAIL)}
                  required={true}
                  autoFocus={true}
                  value={this.props.email}
                  onChange={(e) => {
                    this.onHideErrorMsg();
                    this.props.setUsername(e.target.value);
                  }}
                  onKeyPress={this.handleKeyboard}
                />
              </div>
            </div>
            {showErrorMsg &&
                        <p className={"error-message"}>{isValidUser
                          ? "" :
                          I18n.tr(I18nKeys.EL_EMAIL_NO_ES_VALIDO)}
                        </p>
            }

            <div className="input-group">
              <span className="input-group-addon"><Icon icon={"lock"}/></span>
              <div className="form-line">
                <input
                  type="password"
                  className="form-control"
                  placeholder={I18n.tr(I18nKeys.CONTRASENA)}
                  required={true}
                  value={this.props.password}
                  onChange={(e) => {
                    this.onHideErrorMsg();
                    this.props.setPassword(e.target.value);
                  }}
                  onKeyPress={this.handleKeyboard}
                />
              </div>
            </div>
            {showErrorMsg && isValidUser &&
                        <p className={"error-message"}>
                          {isValidPassword ? "" : I18n.tr(I18nKeys.INTRODUCE_TU_CONTRASENA)}
                          {errorResponseMsg.length !== 0 ? errorResponseMsg : ""}
                        </p>
            }

            <Row>
              <Col xs={8} className={"p-t-5 p-l-20"}>
                <input type="checkbox"
                  name="rememberme"
                  id="rememberme"
                  className="filled-in chk-col-blue-grey"
                  checked={this.state.rememberMe}
                  onChange={() => this.onRememberMe()}
                />
                <label htmlFor="rememberme">{I18n.tr(I18nKeys.RECUERDAME)}</label>
              </Col>
              <Col xs={4}>
                <Button
                  className={"bg-blue-grey"}
                  text={I18n.tr(I18nKeys.LOGIN)}
                  onClick={this.doLogin}
                  block={true}
                  disabled={!allowLogin && showErrorMsg}
                />
              </Col>
            </Row>
            <Row className={"m-b-5 m-t-15"}>
              <Col xs={12} className={"align-right"}>
                <a onClick={() => this.onShowRecoverPwd()}>{I18n.tr(I18nKeys.OLVIDASTE_TU_CONTRASENA)}</a>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <RecoverPasswordModal show={this.state.showRecoverPwd}
          onClose={this.onHideRecoverPwdModal}
          onSubmit={onRecoverPassword}
        />
        <EditPasswordModal 
          show={showEditPasswordModal}
          onClose={this.onHideEditPasswordModal}          
          onSubmit={this.onChangePassword}
          forceChange={true}
          errorPassword={this.state.errorPassword}
          onPasswordChange={this.clearSamePasswordAsPreviousError}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(LoginScreen);
