import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import LicensePlateMask from "../../../../commons/model/LicensePlateMask";
import VehicleBrand from "../../../../commons/model/VehicleBrand";
import VehicleColor from "../../../../commons/model/VehicleColor";
import VehicleModel from "../../../../commons/model/VehicleModel";
import VehicleType from "../../../../commons/model/VehicleType";
import TaskVehicleLicenseplatemaskList from "../../../../commons/ws/vehicle/TaskVehicleLicenseplatemaskList";
import TaskVehicleBrandList from "../../../../commons/ws/vehicleBrand/TaskVehicleBrandList";
import TaskVehicleColorList from "../../../../commons/ws/vehicleColor/TaskVehicleColorList";
import TaskVehicleModelList from "../../../../commons/ws/vehicleModel/TaskVehicleModelList";
import TaskVehicleTypeList from "../../../../commons/ws/vehicleType/TaskVehicleTypeList";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import FormInput from "../../../../components/form/FormInput";
import FormInputOption from "../../../../components/form/FormInputOption";
import FormSelect from "../../../../components/form/FormSelect";
import FormSwitch from "../../../../components/form/FormSwitch";
import FormTextArea from "../../../../components/form/FormTextArea";
import Row from "../../../../components/Row";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_VEHICLES } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import VehicleFormScreenCompanyUserReducer from "../formscreen/VehicleFormScreenCompanyUserReducer";

const FORM_NAME = "VehicleFormCompanyUser";

export interface VehicleCompanyUserData {
    licensePlate: string;
    licensePlateMaskId: string;
    special: boolean;
    description: string;
    vehicleBrandId: string;
    vehicleModelId: string;
    vehicleTypeId: string;
    vehicleColorId: string;
}

interface VehicleCardFormCompanyUserProps {
    routeId?: string;
    readonly?: boolean;
    onSubmit: (data: VehicleCompanyUserData)=> void;
    initialValues?: Partial<VehicleCardFormCompanyUserProps>;
    parentLoading?: boolean;
}

const mapStateToProps = VehicleFormScreenCompanyUserReducer.autoMapToProps();

type Props =
    InjectedFormProps<VehicleCardFormCompanyUserProps> & VehicleCardFormCompanyUserProps & typeof mapStateToProps;

enum VehicleFormCompanyUserFields {
    LICENSE_PLATE = "licensePlate",
    LICENSE_PLATE_MASK_ID = "licensePlateMaskId",
    SPECIAL = "special",
    DESCRIPTION = "description",
    VEHICLE_BRAND_ID = "vehicleBrandId",
    VEHICLE_MODEL_ID = "vehicleModelId",
    VEHICLE_TYPE_ID = "vehicleTypeId",
    VEHICLE_COLOR_ID = "vehicleColorId",
}

class VehicleCardFormCompanyUser extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  public async componentWillMount() {
    await new TaskVehicleBrandList( {
      limit: 0,
    } ).execute();
    // new TaskVehicleModelList({
    //     limit: 0
    // }).execute();
    await updateModelsBySelectedBrand();
    new TaskVehicleColorList().execute();
    new TaskVehicleTypeList().execute();
    new TaskVehicleLicenseplatemaskList().execute();
  }

  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, vehicleLoading, pristine, vehicleModels, vehicleBrands, vehicleColors, vehicleTypes,
      vehicleLicensePlateMasks, parentLoading} = this.props;
    const vehicleModelOptions: FormInputOption[] = vehicleModels.map((vehicleModel: VehicleModel) => ( {
      value: vehicleModel.id,
      name: vehicleModel.name,
    } ));
    const vehicleBrandOptions: FormInputOption[] = vehicleBrands.map((vehicleBrand: VehicleBrand) => ( {
      value: vehicleBrand.id,
      name: vehicleBrand.name,
    } ));
    const vehicleTypeOptions: FormInputOption[] = vehicleTypes.map((vehicleType: VehicleType) => ( {
      value: vehicleType.id,
      name: vehicleType.name,
    } ));
    const vehicleColorOptions: FormInputOption[] = vehicleColors.map((vehicleColor: VehicleColor) => ( {
      value: vehicleColor.id,
      name: vehicleColor.name,
    } ));
    const licensePlateMaskOptions: FormInputOption[] = vehicleLicensePlateMasks.map((licensePlateMask: LicensePlateMask) => ( {
      value: licensePlateMask.id,
      name: licensePlateMask.name,
    } ));

    return (
      <Card loading={parentLoading || vehicleLoading}>
        <CardHeader
          title={I18n.tr(initialValues ? I18nKeys.VEHICULO : I18nKeys.NUEVO_VEHICULO)}/>
        <CardBody>
          {/*
                    // @ts-ignores */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.MATRICULA)}
                name={VehicleFormCompanyUserFields.LICENSE_PLATE}
                component={FormInput}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.MASCARA)}
                name={VehicleFormCompanyUserFields.LICENSE_PLATE_MASK_ID}
                component={FormSelect}
                blankOptionText={" "}
                options={licensePlateMaskOptions}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.ESPECIAL)}
                name={VehicleFormCompanyUserFields.SPECIAL}
                component={FormSwitch}
                labelEnabled={I18n.tr(I18nKeys.SI)}
                labelDisabled={I18n.tr(I18nKeys.NO)}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.DESCRIPCION)}
                name={VehicleFormCompanyUserFields.DESCRIPTION}
                component={FormTextArea}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.MARCA)}
                name={VehicleFormCompanyUserFields.VEHICLE_BRAND_ID}
                component={FormSelect}
                blankOptionText={" "}
                options={vehicleBrandOptions}
                col={{
                  md: 6,
                  lg: 6,
                }}
                onChange={() => updateModelsBySelectedBrand()}
              />
              <Field
                label={I18n.tr(I18nKeys.MODELO)}
                name={VehicleFormCompanyUserFields.VEHICLE_MODEL_ID}
                component={FormSelect}
                blankOptionText={" "}
                options={vehicleModelOptions}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.TIPO)}
                name={VehicleFormCompanyUserFields.VEHICLE_TYPE_ID}
                component={FormSelect}
                blankOptionText={" "}
                options={vehicleTypeOptions}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.COLOR)}
                name={VehicleFormCompanyUserFields.VEHICLE_COLOR_ID}
                component={FormSelect}
                blankOptionText={" "}
                options={vehicleColorOptions}
                col={{
                  md: 6,
                  lg: 6,
                }}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_VEHICLES)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

async function updateModelsBySelectedBrand(retryNumber = 0) {
  const brand: any = {
    select: document.getElementsByName(VehicleFormCompanyUserFields.VEHICLE_BRAND_ID)[0] as any,
  };

  brand.option = brand.select.options[brand.select.selectedIndex];
  brand.value = brand.option.value;
  const MAX_TRIES = 3;

  if (brand.value === "-1" && retryNumber <= MAX_TRIES) {
    setTimeout(() => updateModelsBySelectedBrand(retryNumber + 1), 10);

    return;
  }

  await new TaskVehicleModelList( {
    brand_ids: brand.value,
  } as any).execute();
  brand.option.selected = true;

  const modelSelect = document.getElementsByName(VehicleFormCompanyUserFields.VEHICLE_MODEL_ID)[0] as any;

  if (modelSelect.selectedIndex === -1)
  {for (const option of modelSelect.options) {
    if (option.value !== "-1") {
      option.selected = true;
      break;
    }
  }}
}

function validate(values: VehicleCompanyUserData) {
  const errors: FormErrors<VehicleCompanyUserData> = {
  };

  if (!values.licensePlate || values.licensePlate.length === 0)
    errors.licensePlate = I18n.tr(I18nKeys.MATRICULA_OBLIGATORIA);

  if (!values.licensePlateMaskId || values.licensePlateMaskId === "-1")
    errors.licensePlateMaskId = I18n.tr(I18nKeys.MASCARA_OBLIGATORIA);

  if (!values.description || values.description.length === 0)
    errors.description = I18n.tr(I18nKeys.DESCRIPCION_OBLIGATORIA);

  if (!values.vehicleBrandId || values.vehicleBrandId === "-1")
    errors.vehicleBrandId = I18n.tr(I18nKeys.MARCA_OBLIGATORIA);

  if (!values.vehicleModelId || values.vehicleModelId === "-1")
    errors.vehicleModelId = I18n.tr(I18nKeys.MODELO_OBLIGATORIO);

  if (!values.vehicleTypeId || values.vehicleTypeId === "-1")
    errors.vehicleTypeId = I18n.tr(I18nKeys.TIPO_OBLIGATORIO);

  if (!values.vehicleColorId || values.vehicleColorId === "-1")
    errors.vehicleColorId = I18n.tr(I18nKeys.COLOR_OBLIGATORIO);

  return errors;
}

export default compose(
  reduxForm<VehicleCompanyUserData, VehicleCardFormCompanyUserProps>( {
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  } ),
  connect(mapStateToProps),
)(VehicleCardFormCompanyUser);
