import { Component, ReactNode } from "react";
import Alert from "../../../../base/alerts/Alert";
import I18n from "../../../../commons/I18n/I18n";
import ParkingMeterCollection from "../../../../commons/model/ParkingMeterCollection";
import { ParkingMeterCollectionProcessAddData } from "../../../../commons/model/ParkingMeterCollectionProcess";
import TaskParkingMeterCollectionList from "../../../../commons/ws/parkingMeterCollection/TaskParkingMeterCollectionList";
import TaskParkingMeterCollectionProcessAdd from "../../../../commons/ws/parkingmetercollectionprocess/TaskParkingMeterCollectionProcessAdd";
import CardList, { SelectAction } from "../../../../components/card/CardList";
import { PagerBackendProps } from "../../../../components/Pager";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_COLLECTION_PROCESS } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import CollectionProcessBaseModal from "../modal/CollectionProcessBaseModal";
import CollectionProcessAddTable from "./CollectionProcessAddTable";

interface CollectionProcessAddViewProps {
    parkingMeterCollections: ParkingMeterCollection[];
    loading: boolean;
    errors: string;
    pager: PagerBackendProps;
}

interface CollectionProcessAddViewState {
    showAddCollectionsModal: boolean;
    showErrorModal: boolean;
    selectedItemIds: string [];
}

export default class CollectionProcessAddView extends Component<CollectionProcessAddViewProps, CollectionProcessAddViewState> {
  constructor(props: Readonly<CollectionProcessAddViewProps>) {
    super(props);
    this.state = {
      showAddCollectionsModal: false,
      showErrorModal: false,
      selectedItemIds: [],
    };
  }

  private handleOnCheckedItem = (newItemId: string): void => {
    const newSelectedItemIds: string[] = [...this.state.selectedItemIds];

    newSelectedItemIds.push(newItemId);
    this.setState( {
      selectedItemIds: newSelectedItemIds,
    } );
  };

  private handleOnUncheckedItem = (toRemoveItemId: string): void => {
    this.setState( {
      selectedItemIds: this.state.selectedItemIds.filter(
        (selectedItemId) => selectedItemId !== toRemoveItemId),
    } );
  };

  private handleOnAddCollectionProcess(code: number): void {
    if (code === 200) {
      goToRoute(ROUTE_ADMIN_COLLECTION_PROCESS);
      setTimeout(() => {
        Alert.success(I18n.tr(I18nKeys.COLECTAS_PARQUIMETROS_ANADIDAS_CORRECTAMENTE));
      }, 1);
    } else if (code === 205)
    {this.setState( {
      showErrorModal: true,
    } );}
  }

  public render(): ReactNode {
    const {parkingMeterCollections, loading, errors, pager} = this.props;

    return (
      <>
        <CardList loading={loading}
          pager={pager}
          title={I18n.tr(I18nKeys.SELECCIONAR_COLECTAS_PARQUIMETROS)}
          sort={{
            column: "id",
          }}
          TaskList={TaskParkingMeterCollectionList}
          customCriteria={{
            not_in_collection_process: true,
          }}
          emptyListOptions={{
            message: I18n.tr(I18nKeys.NO_EXISTEN_COLECTAS_PARQUIMETROS),
          }}
          data={parkingMeterCollections}
          error={errors}
          noSearchBar={true}
          footerActionSelected={SelectAction.SEND}
          ItemsTable={CollectionProcessAddTable}
          onCheckedItem={this.handleOnCheckedItem}
          onUncheckedItem={this.handleOnUncheckedItem}
          onSendButtonClick={() => this.setState( {
            showAddCollectionsModal: true,
          } )}
          sendButtonText={I18n.tr(I18nKeys.SIGUIENTE)}
        />

        <CollectionProcessBaseModal
          title={I18n.tr(I18nKeys.PROCESO_RECAUDACION)}
          message={I18n.tr(I18nKeys.INTRODUZCA_IMPORTE_TOTAL_CLOUD_PARQUIMETRO)}
          inputType={"number"}
          onConfirmAction={(totalCash) => {
            this.setState( {
              showAddCollectionsModal: false,
            } );

            const submitData: ParkingMeterCollectionProcessAddData = {
              parking_meter_collection_ids: this.state.selectedItemIds,
              total_cash: Number(totalCash),
            };

            new TaskParkingMeterCollectionProcessAdd(submitData)
              .onSuccess((response) => this.handleOnAddCollectionProcess(response.code))
              .onFail(() => setTimeout(() => {
                Alert.error(I18n.tr(I18nKeys.GENERIC_WS_ERROR));
              }, 1))
              .execute();
          }}
          show={this.state.showAddCollectionsModal}
          onClose={() => this.setState( {
            showAddCollectionsModal: false,
          } )}
        />

        <CollectionProcessBaseModal
          error={true}
          title={I18n.tr(I18nKeys.PROCESO_RECAUDACION)}
          message={I18n.tr(I18nKeys.ERROR_SUMA_COLECTAS)}
          onConfirmAction={() => {
            this.setState( {
              showErrorModal: false,
            } );
            goToRoute(ROUTE_ADMIN_COLLECTION_PROCESS);
          }}
          show={this.state.showErrorModal}
          onClose={() => this.setState( {
            showErrorModal: false,
          } )}
        />
      </>
    );
  }
}
