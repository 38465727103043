import React from "react";
import I18n from "../../../../../commons/I18n/I18n";
import ReportCancellationLog from "../../../../../commons/model/ReportCancellationLog";
import Th from "../../../../../components/table/Th";
import I18nKeys from "../../../../../I18n/I18nKeys";
import DateFormatter from "../../../../../utils/DateFormatter";
import { OnSortHandler, SortState } from "../../../../../utils/Sort";

interface ParkingMeterListTableProps {
    data: ReportCancellationLog[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
}

export default class ReportCancelLogListTable extends React.Component<ParkingMeterListTableProps> {
  public render(): React.ReactNode {
    const {data} = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((reportCancellationLog: ReportCancellationLog) => (
            <tr key={reportCancellationLog.id}>
              <td>
                {DateFormatter.formatDatetime(reportCancellationLog.date)}
              </td>
              <td>
                {reportCancellationLog.report_number}
              </td>
              <td>
                {reportCancellationLog.result_description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        <Th text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.DENUNCIA)}
          columnName={"report_number"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.OBSERVACIONES)}
          columnName={"result_code"}
          {...commonProps}
        />
      </tr>
    );
  }
}
