import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskParkingMeterIncidenceDetail from "../../../../commons/ws/parkingMeterIncidence/TaskParkingMeterIncidenceDetail";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_METERS, ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import ParkingMeterIncidenceCardFormCompanyUser, { ParkingMeterIncidenceCardFormCompanyUserData } from "../formdetail/ParkingMeterIncidenceCardFormCompanyUser";
import ParkingMeterIncidenceFormCompanyUserScreenActions from "./ParkingMeterIncidenceFormCompanyUserScreenActions";
import ParkingMeterIncidenceFormScreenCompanyUserReducer from "./ParkingMeterIncidenceFormScreenCompanyUserReducer";

const mapStateToProps = ParkingMeterIncidenceFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = ParkingMeterIncidenceFormCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class ParkingMeterIncidenceFormCompanyUserScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskParkingMeterIncidenceDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const parkingMeterId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.INCIDENCIAS_PARQUIMETROS),
      url: ROUTE_COMPANY_USER_PARKING_METERS,
      icon: AppIcon.PARKING_METER_INCIDENCE,
    }];

    if (parkingMeterId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.INCIDENCIA_PARQUIMETRO),
        url: generateRoute(ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL, {
          id: parkingMeterId,
        } ),
        icon: AppIcon.PARKING_METER_INCIDENCE,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderParkingMeterForm()}
      </ScreenCard>
    );
  }

  private renderParkingMeterForm(): React.ReactNode {
    const {parkingMeterIncidenceLoading, parkingMeterIncidence} = this.props;
    let initialValues: ParkingMeterIncidenceCardFormCompanyUserData | undefined;

    if (parkingMeterIncidence) {
      initialValues = {
        parking_meter: parkingMeterIncidence.parking_meter ? parkingMeterIncidence.parking_meter.code : "",
        state: parkingMeterIncidence.type ? parkingMeterIncidence.type.name : "",
        date: parkingMeterIncidence.date ? DateFormatter.formatDatetime(parkingMeterIncidence.date) : "",
        controller: parkingMeterIncidence.controller ?
          `${parkingMeterIncidence.controller.name} ${parkingMeterIncidence.controller.last_name}`
          : "",
        solved: parkingMeterIncidence.solved ? parkingMeterIncidence.solved : false,
        solved_controller: parkingMeterIncidence.solved_controller ?
          `${parkingMeterIncidence.solved_controller.name} ${parkingMeterIncidence.solved_controller.last_name}`
          : "",
        solved_date: parkingMeterIncidence.solved_date ? DateFormatter.formatDatetime(parkingMeterIncidence.solved_date) : "",
        observations: parkingMeterIncidence.observations ? parkingMeterIncidence.observations : "",
      };
    }

    return (
      <ParkingMeterIncidenceCardFormCompanyUser
        initialValues={initialValues}
        parentLoading={parkingMeterIncidenceLoading}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterIncidenceFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
