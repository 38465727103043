import React from "react";
import Row from "../Row";
import Icon from "../Icon";
import {WrappedFieldArrayProps} from "redux-form";
import Col from "../Col";

interface CardFieldArrayProps {
    title: string,
    fieldsHeader?: React.ReactNode,
    children: React.ReactNode,
    addHandler: (newFieldValue: any) => void,
    hideRemoveWithOneOption?: boolean,
}

type Props = CardFieldArrayProps & WrappedFieldArrayProps<any>

const rowCommonClasses = 'm-l-15 m-r-15 m-t-0 m-b-0';

class CardFieldArray extends React.Component<Props> {

    public render() {
        const {children, addHandler, fieldsHeader, meta, title} = this.props;

        return (
            <div className={'fields-card'}>
                <Row className={`${rowCommonClasses} m-b-0`}>
                    <Col>
                        <h6 className={'p-l-15 m-t-20'}>{title}</h6>
                    </Col>
                </Row>
                <Row className={`${rowCommonClasses} m-b-5 p-b-0 m-r-35`}>
                    {fieldsHeader}
                </Row>
                {!meta.pristine && meta.error && (
                    <Row className={`${rowCommonClasses} p-l-15`}>
                        <label className="label-error">{meta.error}</label>
                    </Row>
                )}
                {
                    React.Children.map(children, (fieldNode, index) => {
                        return this.renderFieldRow(fieldNode, index, children && children[index].props.disabled)
                    })
                }
                <Row className={'m-l-0 m-r-0'}>
                    <a className={'icon-field-add'}
                       onClick={addHandler}>
                        <Icon icon={'add_circle'}/>
                    </a>
                </Row>
            </div>
        )
    }

    private renderFieldRow = (fieldNode: React.ReactNode, index: number, disabled: boolean): React.ReactNode => {
        const {hideRemoveWithOneOption = false, children} = this.props;

        return (
            <>
                <Row key={index} className={`${rowCommonClasses} m-b-5 p-b-0 m-r-35 fields-row`}>
                    {fieldNode}
                </Row>
                {!disabled && (hideRemoveWithOneOption && React.Children.count(children) === 1 ? null :
                    <a className={'icon-field-remove'}
                       onClick={() => {
                           this.props.fields.remove(index);
                       }}>
                        <Icon icon={'remove_circle'}/>
                    </a>)}
            </>
        )
    };
}

export default CardFieldArray;
