import { FunctionComponent } from 'react';
import { CardProps } from './typings';
import LoadingView from '../base/loading/LoadingView';

export const Card: FunctionComponent<CardProps> = (
    {
        loading = false,
        children,
    }
) => {
    return (
        <div className={'card-new'}>
            {children}
            <LoadingView loading={loading}/>
        </div>
    );
};
