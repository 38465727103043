import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import I18n from "../../../../commons/I18n/I18n";
import { LawData } from "../../../../commons/model/Law";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskLawAdd from "../../../../commons/ws/law/TaskLawAdd";
import TaskLawDetail from "../../../../commons/ws/law/TaskLawDetail";
import TaskLawEdit from "../../../../commons/ws/law/TaskLawEdit";
import { BreadcrumbItem } from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_COMPANY_USER_LAWS, ROUTE_COMPANY_USER_LAW_ADD,
  ROUTE_COMPANY_USER_LAW_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import { Int } from "../../../../utils/StringUtils";
import LawCardFormCompanyUser, { LawCardFormCompanyUserData } from "../formdetail/LawCardFormCompanyUser";
import LawFormCompanyUserScreenActions from "./LawFormCompanyUserScreenActions";
import LawFormScreenCompanyUserReducer from "./LawFormScreenCompanyUserReducer";

const mapStateToProps = LawFormScreenCompanyUserReducer.autoMapToProps();
const mapActionsToProps = LawFormCompanyUserScreenActions.autoMapToProps();

type Props = RouteComponentProps<{ id: string }> & typeof mapActionsToProps & typeof mapStateToProps;

class LawFormCompanyUserScreen extends React.Component<Props> {
  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id) 
      loadItemDetailsOrGoBack(new TaskLawDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  private onSubmit = (values: LawCardFormCompanyUserData): void => {
    const existLaw = this.props.match.params.id !== undefined;
    const submitData: LawData = {
      law_type_id: values.lawTypeId,
      article_code: values.articleCode,
      post_code: values.postCode,
      required_expiration_date: values.requiredExpirationDate ? values.requiredExpirationDate : false,
      option_code: values.optionCode,
      screen_text: values.screenText,
      print_text: values.printText,
      format_text: values.formatText,
      amount: Number(values.amount),
      cancellation_amount: Number(values.cancellationAmount),
      cancelable_report: values.cancelableReport ? values.cancelableReport : false,
      cancellation_text: values.cancellationText,
      controller_photos: Int(values.controllerPhotos),
      ticket_time_exceeded: values.ticketTimeExceeded ? values.ticketTimeExceeded : false,
      cancellation_code: values.cancellationCode,
      allowed_minutes_cancel_report_app: Int(values.allowedMinutesCancelReportApp),
      cancellation_reference_type_id: values.cancellationReferenceTypeId,
    };

    if (existLaw) {
      executeItemTask(
        new TaskLawEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_COMPANY_USER_LAWS),
        I18n.tr(I18nKeys.ARTICULO_MODIFICADO_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        new TaskLawAdd(submitData),
        () => goToRoute(ROUTE_COMPANY_USER_LAWS),
        I18n.tr(I18nKeys.ARTICULO_CREADO_CORRECTAMENTE),
      );
    }
  };

  public render(): React.ReactNode {
    const lawId = this.props.match.params.id;
    const breadcrumbs: BreadcrumbItem[] = [{
      name: I18n.tr(I18nKeys.ARTICULOS),
      url: ROUTE_COMPANY_USER_LAWS,
      icon: AppIcon.LAW,
    }];

    if (lawId) {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.ARTICULO),
        url: generateRoute(ROUTE_COMPANY_USER_LAW_DETAIL, {
          id: lawId,
        } ),
        icon: AppIcon.LAW,
      } );
    } else {
      breadcrumbs.push( {
        name: I18n.tr(I18nKeys.NUEVO_ARTICULO),
        url: ROUTE_COMPANY_USER_LAW_ADD,
        icon: AppIcon.LAW,
      } );
    }

    return (
      <ScreenCard breadcrumbs={breadcrumbs}>
        {this.renderLawForm()}
      </ScreenCard>
    );
  }

  private renderLawForm(): React.ReactNode {
    const {lawLoading, law} = this.props;
    let initialValues: Partial<LawCardFormCompanyUserData> | undefined;

    if (law) {
      initialValues = {
        lawTypeId: law.law_type ? law.law_type.id : "-1",
        // description: law.description ? law.description : '',
        articleCode: law.article_code ? law.article_code : "",
        postCode: law.post_code ? law.post_code : "",
        optionCode: law.option_code ? law.option_code : "",
        requiredExpirationDate: law.required_expiration_date || false,
        screenText: law.screen_text ? law.screen_text : "",
        printText: law.print_text ? law.print_text : "",
        formatText: law.format_text ? law.format_text : "",
        amount: law.amount ? law.amount.toString() : "0",
        cancellationAmount: law.cancellation_amount ? law.cancellation_amount.toString() : "0",
        cancelableReport: law.cancelable_report ? law.cancelable_report : false,
        cancellationText: law.cancellation_text ? law.cancellation_text : "",
        controllerPhotos: law.controller_photos ? law.controller_photos.toString() : "0",
        ticketTimeExceeded: law.ticket_time_exceeded ? law.ticket_time_exceeded : false,
        cancellationCode: law.cancellation_code ? law.cancellation_code : 0,
        allowedMinutesCancelReportApp: law.allowed_minutes_cancel_report_app ? law.allowed_minutes_cancel_report_app.toString() : "0",
        cancellationReferenceTypeId: law.cancellation_reference_type ? law.cancellation_reference_type.id : "-1",
      };
    }

    return (
      <LawCardFormCompanyUser
        parentLoading={lawLoading}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)
(LawFormCompanyUserScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
