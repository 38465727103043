import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import React from "react";
import { connect } from "react-redux";
import { Field, WrappedFieldArrayProps } from "redux-form";
import I18n from "../../../../commons/I18n/I18n";
import { UserRoleValue } from "../../../../commons/model/UserRole";
import TaskUserRoleList from "../../../../commons/ws/userRole/TaskUserRoleList";
import CardFieldArray from "../../../../components/card/CardFieldArray";
import Col from "../../../../components/Col";
import { UserRoleAutocompleteBuilder } from "../../../../components/filter/FilterTypeaheadOptions";
import FormSelect from "../../../../components/form/FormSelect";
import I18nKeys from "../../../../I18n/I18nKeys";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import UserFieldArrayReducer from "./RoleFieldArrayReducer";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
  CurrentUserReducer.autoMapToProps(),
  UserFieldArrayReducer.autoMapToProps(),
);

export interface RoleFieldArrayProps {
    showFirstOption?: boolean;
    isCurrentUser: boolean;
}

type Props = RoleFieldArrayProps & WrappedFieldArrayProps<UserRoleAutocompleteBuilder> & typeof mapStateToProps;

class RoleFieldArray extends React.Component<Props> {
  public componentWillMount(): void {
    new TaskUserRoleList().execute();

    if (this.props.showFirstOption)
      this.props.fields.push("");
  }

  public render(): React.ReactNode {
    const {fields, roleList, meta, isCurrentUser, isAdmin} = this.props;
    let userRoleOptions = roleList.map((role) => ( {
      value: role.id,
      name: role.name,
    } ));

    if (!isAdmin)
      userRoleOptions = userRoleOptions.filter((role) => role.value !== UserRoleValue.ADMIN);

    return (
      <CardFieldArray fields={fields}
        meta={meta}
        title={""}
        fieldsHeader={renderHeader()}
        addHandler={() => fields.push("")}
        hideRemoveWithOneOption={true}
      >
        {fields.map((fieldName: string, index: number) => (
          <Field
            key={index}
            name={fieldName}
            component={FormSelect}
            options={userRoleOptions}
            blankOptionText={" "}
            col={{
              className: "m-b--10 m-t--15 p-r-10",
            }}
            disabled={isCurrentUser}
            errorMessageWithoutTouch={true}
          />
        ))}
      </CardFieldArray>
    );
  }
}

function renderHeader(): React.ReactNode {
  return (
    <Col className={"m-b-0 m-l-15"}>
      <label>{I18n.tr(I18nKeys.ROLES)}</label>
    </Col>);
};

export default connect(mapStateToProps)(RoleFieldArray) as unknown as React.ComponentType<RoleFieldArrayProps>;
