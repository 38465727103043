import { ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import LoggedScreen from "../../../../base/screens/LoggedScreen";
import I18n from "../../../../commons/I18n/I18n";
import { ExploitationData } from "../../../../commons/model/Exploitation";
import { generateRoute } from "../../../../commons/utils/Router";
import TaskExploitationAdd from "../../../../commons/ws/exploitation/TaskExploitationAdd";
import TaskExploitationDetail from "../../../../commons/ws/exploitation/TaskExploitationDetail";
import TaskExploitationEdit from "../../../../commons/ws/exploitation/TaskExploitationEdit";
import Col from "../../../../components/Col";
import Row from "../../../../components/Row";
import ScreenContent from "../../../../components/screen/ScreenContent";
import Config, { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import {ROUTE_ADMIN_EXPLOITATIONS, ROUTE_ADMIN_EXPLOITATION_ADD,
  ROUTE_ADMIN_EXPLOITATION_DETAIL} from "../../../../routing/Routes";
import { executeItemTask, loadItemDetailsOrGoBack } from "../../../../utils/FormUtils";
import { goToRoute } from "../../../../utils/Router";
import { ExploitationAppConfigurationSectionAdminHelpers } from "../formdetail/cards/ExploitationAppConfigurationSectionAdmin";
import { ExploitationConnectionAppSectionAdminHelpers } from "../formdetail/cards/ExploitationConnectionAppSectionAdmin";
import { ExploitationPrintReportSectionAdminHelpers } from "../formdetail/cards/ExploitationPrintReportSectionAdmin";
import { ExploitationReportCodeSectionAdminHelpers } from "../formdetail/cards/ExploitationReportCodeSectionAdmin";
import { ExploitationWorkingScheduleSectionAdminHelper } from "../formdetail/cards/ExploitationWorkingScheduleSectionAdmin";
import ExploitationCardFormAdmin, { ExploitationCardFormAdminData } from "../formdetail/ExploitationCardFormAdmin";
import ExploitationFormAdminScreenActions from "./ExploitationFormAdminScreenActions";
import ExploitationFormAdminScreenReducer from "./ExploitationFormAdminScreenReducer";

const mapStateToProps = ExploitationFormAdminScreenReducer.autoMapToProps();
const mapActionsToProps = ExploitationFormAdminScreenActions.autoMapToProps();

type ExploitationFormAdminScreenProps = RouteComponentProps<{ id: string }>;

type Props = ExploitationFormAdminScreenProps & typeof mapActionsToProps & typeof mapStateToProps;

class ExploitationFormAdminScreen extends LoggedScreen<Props> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.addBreadcrumbScreen(I18n.tr(I18nKeys.EXPLOTACIONES), ROUTE_ADMIN_EXPLOITATIONS, AppIcon.EXPLOITATION);

    if (this.props.match.params.id) {
      this.addBreadcrumbScreen(I18n.tr(I18nKeys.EXPLOTACION), generateRoute(
        ROUTE_ADMIN_EXPLOITATION_DETAIL,
        {
          id: this.props.match.params.id,
        } ),
      AppIcon.EXPLOITATION);
    } else
      this.addBreadcrumbScreen(I18n.tr(I18nKeys.NUEVA_EXPLOTACION), ROUTE_ADMIN_EXPLOITATION_ADD, AppIcon.EXPLOITATION);
  }

  public componentWillMount(): void {
    const {id} = this.props.match.params;

    if (id)
      loadItemDetailsOrGoBack(new TaskExploitationDetail(id), this.props);
  }

  public componentWillUnmount(): void {
    this.props.clearReducer();
  }

  public renderScreen(): ReactNode {
    return (
      <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {this.renderForm()}
          </Col>
        </Row>
      </ScreenContent>
    );
  }

  private onSubmit = (values: ExploitationCardFormAdminData): void => {
    const existExploitation = this.props.match.params.id !== undefined;
    const c60 = values.generateC60
      ? {
        generate_c60: true,
        c60_application_identifier: values.c60ApplicationIdentifier,
        c60_format_type: values.c60FormatType,
        c60_emitter_province: values.c60EmitterProvince,
        c60_emitter_municipality: values.c60EmitterMunicipality,
        c60_emitter_control_digit: values.c60EmitterControlDigit,
        c60_tax: values.c60Tax,
        c60_payment_days_term: values.c60PaymentDaysTerm,
        report_print_bar_code_c60: values.reportPrintBarCodeC60,
        report_print_text_bar_code_c60: values.reportPrintTextBarCodeC60,
      }
      : {
        generate_c60: false,
        c60_application_identifier: null,
        c60_format_type: null,
        c60_emitter_province: null,
        c60_emitter_municipality: null,
        c60_emitter_control_digit: null,
        c60_tax: null,
        c60_payment_days_term: 0,
        report_print_bar_code_c60: false,
        report_print_text_bar_code_c60: values.reportPrintTextBarCodeC60,
      };
    let submitData: Partial<ExploitationData> = {
      name: values.name,
      service_office_address: values.serviceOfficeAddress,
      service_office_phone: values.serviceOfficePhone,
      service_office_email: values.serviceOfficeEmail || undefined,
      service_office_url: values.serviceOfficeUrl || undefined,
      legal_advice: values.legalAdvice || undefined,
      town: values.town,
      province: values.province,
      latitude: values.coord.lat,
      longitude: values.coord.lng,
      elk_key: values.elkKey,
      kml_file: values.kmlFile && values.kmlFile.length !== 0 ? values.kmlFile[0] : undefined,
      working_schedules: ExploitationWorkingScheduleSectionAdminHelper.getSubmitData(values),

      inactivity_report_app_time: Number(values.inactivityReportAppTime),
      inactivity_position_app_time: Number(values.inactivityPositionAppTime),
      data_driver_screen: values.dataDriverScreen ? values.dataDriverScreen : false,
      report_ratification_radius: Number(values.reportRatificationRadius),
      workday_finish_time: Number(values.workdayFinishTime),
      report_creation_time_limit: Number(values.reportCreationTimeLimit),

      code_suma: values.codeSuma,
      province_code_suma: values.provinceCodeSuma,
      town_code_suma: values.townCodeSuma,
      parking_meter_type_id: values.parkingMeterTypeId,
      cancellation_parking_meter_type_id: values.cancellationParkingMeterTypeId,
      management_processing_type_id: values.managementProcessingTypeId,
      ratification: values.ratification ? values.ratification : false,
      export_num_suma: values.exportNumSuma ? Number(values.exportNumSuma) : null,
      cancellation_process_management: values.cancellationProcessManagement, // Valor por defecto seteado en renderForm
      remote_ratification: values.remoteRatification ? values.remoteRatification : false,
      report_identification: values.reportIdentification ? values.reportIdentification : false,
      digital_sign: values.digitalSign ? values.digitalSign : false,

      report_print_image: values.reportPrintImage && values.reportPrintImage.length !== 0 ? values.reportPrintImage[0] : "",
      report_print_exploitation_name: values.reportPrintExploitationName,
      report_print_amount: values.reportPrintAmount ? values.reportPrintAmount : false,
      report_print_cancellation_amount: values.reportPrintCancellationAmount ? values.reportPrintCancellationAmount : false,
      report_print_time_exceeded: values.reportPrintTimeExceeded ? values.reportPrintTimeExceeded : false,
      report_print_text_title_1: values.reportPrintTextTitle1,
      report_print_text_title_2: values.reportPrintTextTitle2,
      report_print_text_number: values.reportPrintTextNumber,
      report_print_text_cancellation_number: values.reportPrintTextCancellationNumber,
      report_print_text_date: values.reportPrintTextDate,
      report_print_text_hour: values.reportPrintTextHour,
      report_print_text_vehicle: values.reportPrintTextVehicle,
      report_print_text_brand: values.reportPrintTextBrand,
      report_print_text_model: values.reportPrintTextModel,
      report_print_text_color: values.reportPrintTextColor,
      report_print_text_license_plate: values.reportPrintTextLicensePlate,
      report_print_text_limit_hour: values.reportPrintTextLimitHour,
      report_print_text_reported_fact: values.reportPrintTextReportedFact,
      report_print_text_place: values.reportPrintTextPlace,
      report_print_text_precept: values.reportPrintTextPrecept,
      report_print_text_amount: values.reportPrintTextAmount,
      report_print_text_cancellable_amount: values.reportPrintTextCancellableAmount,
      report_print_text_sign_1: values.reportPrintTextSign1,
      report_print_text_sign_2: values.reportPrintTextSign2,
      report_print_text_controller: values.reportPrintTextController,
      report_print_text_ratifier: values.reportPrintTextRatifier,
      report_print_text_internal_use: values.reportPrintTextInternalUse,
      report_print_text_driver_title: values.reportPrintTextDriverTitle,
      report_print_text_driver_nif: values.reportPrintTextDriverNif,
      report_print_text_driver_type: values.reportPrintTextDriverType,
      report_print_text_driver_name: values.reportPrintTextDriverName,
      report_print_text_driver_last_name: values.reportPrintTextDriverLastName,
      report_print_text_driver_address: values.reportPrintTextDriverAddress,
      report_print_text_driver_sign: values.reportPrintTextDriverSign,
      report_print_bar_code_report_id: values.reportPrintBarCodeReportId ? values.reportPrintBarCodeReportId : false,

      connection_easypark: values.connectionEasypark ? values.connectionEasypark : false,
      connection_moviltik: values.connectionMoviltik ? values.connectionMoviltik : false,
      connection_street_smart: values.connectionStreetSmart ? values.connectionStreetSmart : false,
      moviltik_username: values.connectionMoviltik && values.moviltikUsername ? values.moviltikUsername : undefined,
      moviltik_password: values.connectionMoviltik && values.moviltikPassword ? values.moviltikPassword : undefined,
      notify_crane_emails: values.notifyCraneEmails ? values.notifyCraneEmails : null,
      sos_emails: values.sosEmails ? values.sosEmails : null,
      connection_beepark: values.connectionBeepark ? values.connectionBeepark : false,
      beepark_api_token: values.connectionBeepark && values.beeparkApiToken ? values.beeparkApiToken : undefined,
      beepark_exploitation_id: values.connectionBeepark && values.beeparkExploitationId ? values.beeparkExploitationId : undefined,
      connection_delta_car: values.connectionDeltaCar ? values.connectionDeltaCar : false,
      connection_ticket_line: values.connectionTicketLine ? values.connectionTicketLine : false,
      ...c60,
    };

    if (values.connectionDeltaCar) {
      submitData = {
        ...submitData,
        delta_car_token: values.deltaCarToken,
        delta_car_law_id: values.deltaCarLawId,
        time_excess_law_id: values.timeExcessLawId,
      };
    }

    if (values.connectionTicketLine) {
      submitData = {
        ...submitData,
        ticket_line_username: values.ticketLineUsername,
        ticket_line_password: values.ticketLinePassword,
      };
    }

    if (values.connectionStreetSmart) {
      submitData = {
        ...submitData,
        street_smart_park_id: Number(values.streetSmartParkId),
      };
    }

    if (existExploitation) {
      executeItemTask(
        // @ts-ignore
        new TaskExploitationEdit(this.props.match.params.id, submitData),
        () => goToRoute(ROUTE_ADMIN_EXPLOITATIONS),
        I18n.tr(I18nKeys.EXPLOTACION_MODIFICADA_CORRECTAMENTE),
      );
    } else {
      executeItemTask(
        // @ts-ignore
        new TaskExploitationAdd(submitData),
        () => goToRoute(ROUTE_ADMIN_EXPLOITATIONS),
        I18n.tr(I18nKeys.EXPLOTACION_CREADA_CORRECTAMENTE),
      );
    }
  };

  private renderForm(): ReactNode {
    const {loading, exploitation} = this.props;
    let initialValues: Partial<ExploitationCardFormAdminData> | undefined;

    if (exploitation) {
      initialValues = {
        name: exploitation.name,
        serviceOfficeAddress: exploitation.service_office_address,
        serviceOfficePhone: exploitation.service_office_phone,
        serviceOfficeEmail: exploitation.service_office_email || "",
        serviceOfficeUrl: exploitation.service_office_url || "",
        legalAdvice: exploitation.legal_advice || "",
        town: exploitation.town,
        province: exploitation.province,
        coord: {
          lat: exploitation.latitude,
          lng: exploitation.longitude,
        },
        elkKey: exploitation.elk_key,
        kmlFile: exploitation.kml_file ? [exploitation.kml_file] : [],
        ...ExploitationWorkingScheduleSectionAdminHelper.getFormData(exploitation),
        ...ExploitationAppConfigurationSectionAdminHelpers.getFormData(exploitation),
        ...ExploitationReportCodeSectionAdminHelpers.getFormData(exploitation),
        ...ExploitationPrintReportSectionAdminHelpers.getFormData(exploitation),
        ...ExploitationConnectionAppSectionAdminHelpers.getFormData(exploitation),
      };
    } else {
      initialValues = {
        cancellationProcessManagement: true,
        coord: {
          ...Config.DEFAULT_MAP_POSITION,
        },
      };
    }

    return (
      <ExploitationCardFormAdmin
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        editMode={!!this.props.match.params.id}
        parentLoading={loading}
        exploitationId={this.props.match.params.id}
      />
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ExploitationFormAdminScreen as unknown as ComponentType<ExploitationFormAdminScreenProps>);
