import { FunctionComponent } from 'react';
import Icon from '../components/Icon';
import { CardHeaderButtonProps } from './typings';

export const CardHeaderButtonRefresh: FunctionComponent<CardHeaderButtonProps> = ({onClick}) => {
    return (
        <a className={'header-item'} onClick={onClick}>
            <Icon icon={'loop'}/>
        </a>
    );
};

export const CardHeaderButtonAssign: FunctionComponent<CardHeaderButtonProps> = ({onClick}) => {
    return (
        <a className={'header-item add'} onClick={onClick}>
            <Icon className={''} icon={'zoom_in'}/>
        </a>
    );
};

export const CardHeaderButtonAdd: FunctionComponent<CardHeaderButtonProps> = ({onClick}) => {
    return (
        <a className={'header-item add'} onClick={onClick}>
            <Icon className={'add'} icon={'add_circle'}/>
        </a>
    );
};
