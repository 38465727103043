import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import ReportCancellationMethod from '../../../../../commons/model/ReportCancellationMethod';
import TaskReportCancellationMethodList
    from '../../../../../commons/ws/reportCancellationMethod/TaskReportCancellationMethodList';
import ReportListCompanyUserModalActions from './ReportListCompanyUserModalActions';
import ReportInvalidationReason from '../../../../../commons/model/ReportInvalidationReason';
import TaskReportInvalidationReasonList
    from '../../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonList';

interface InitialState {
    reportOperationReasonId: string
    cancellationMethodReasons: ReportCancellationMethod[]
    cancellationMethodReasonsLoading: boolean
    invalidationReasons: ReportInvalidationReason[]
    invalidationReasonsLoading: boolean
}

const INITIAL_STATE: InitialState = {
    reportOperationReasonId: '-1',
    cancellationMethodReasons: [],
    cancellationMethodReasonsLoading: false,
    invalidationReasons: [],
    invalidationReasonsLoading: false,
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)

    .onEvent(
        ReportListCompanyUserModalActions.dispatchResetModalReducer,
        () => ( {...INITIAL_STATE} )
    )
    .onEvent(
        ReportListCompanyUserModalActions.dispatchChangeReportOperationReason,
        (oldState, payload) => ( {...oldState, reportOperationReasonId: payload} )
    )
    // .onEvent(
    //     TaskReportCancel.REDUX_ACTION_OBJECT_ON_POST_RESULT,
    //     (oldState) => ( {...oldState, reportOperationReasonId: '-1'} )
    // )

    // - - - CANCELLATION METHOD REASONS

    .onEvent(
        TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, cancellationMethodReasonsLoading: true} )
    )
    .onEvent(
        TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, cancellationMethodReasonsLoading: false} )
    )
    .onEvent(
        TaskReportCancellationMethodList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, cancellationMethodReasons: payload.data} )
    )

    // - - - INVALIDATION REASONS

    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, invalidationReasonsLoading: true} )
    )
    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, invalidationReasonsLoading: false} )
    )
    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {...oldState, invalidationReasons: payload.data} )
    )

    .build();
