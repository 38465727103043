import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import StreetType from "../../model/StreetType";

type StreetTypeCriteriaProps = TaskCriteriaRequest<{}>;

export default class TaskStreetTypeList extends BaseGenericRequest<StreetTypeCriteriaProps, TaskResponse<StreetType[]>> {

    private readonly data: StreetTypeCriteriaProps;

    public constructor(criteria: StreetTypeCriteriaProps = {}) {
        super(METHOD.POST, Urls.URL_STREET_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): StreetTypeCriteriaProps {
        return this.data;
    }
}
