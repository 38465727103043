import ParkingMeterCollection from "./ParkingMeterCollection";

export interface ParkingMeterCollectionProcess {
    id: string,
    real_cash: number,
    date: Date,
    collection_process_state: {
        id: CollectionProcessState,
        name: string
    },
    threshold_alert: number,
    cash_deviation_alert: number,
    message_alert: string,
    parking_meter_collections: Array<ParkingMeterCollection>
}

export interface ParkingMeterCollectionProcessAddData {
    parking_meter_collection_ids: Array<string>,
    total_cash: number
}

export interface ParkingMeterCollectionProcessCorrectionData {
    parking_meter_collection_corrections: Array<{
        id: string,
        real_cash: number
    }>,
    message_alert: string
}

export interface ParkingMeterCollectionProcessSendData {
    parking_meter_collection_process_ids : Array<string>
}

export enum CollectionProcessState {
    INCORRECT = "1",
    PENDING_TO_SEND = "2",
    SENT = "3",
    IN_REVISION = "4",
    COMPLETED = "5"
}