import { METHOD } from "@cuatroochenta/co-generic-request";
import { ParkId } from "../../model/ParkId";
import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

export default class TaskParkIdList extends BaseGenericRequest<{}, TaskResponse<ParkId[]>> {
  public constructor() {
    super(METHOD.GET, Urls.URL_STREET_SMART_PARK_ID_LIST);
  }
}