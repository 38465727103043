import { Component, ReactNode } from 'react';
import classNames from 'classnames';

export type TypeIcon = 'normal' | 'outlined' | 'two-tone' | 'round' | 'sharp';

export interface IconProps {
    icon: string
    type?: TypeIcon
    className?: string
}

export default class Icon extends Component<IconProps> {

    public render(): ReactNode {
        const {
            icon,
            type,
            children,
            className,
        } = this.props;

        const materialType: string = type && type !== 'normal' ? `-${type}` : '';
        const classComponent = classNames(`material-icons${materialType}`, className);

        return (
            <i className={classComponent}>
                {icon || children}
            </i>
        );
    }

}


