import BaseGenericRequest from '../BaseGenericRequest';
import { METHOD } from '@cuatroochenta/co-generic-request';
import Urls from '../Urls';
import { TaskDeleteResponse } from '../TaskDeleteResponse';
import { generateRoute } from '../../utils/Router';
import { TaskDeleteMultipleRequest } from '../TaskDeleteMultipleRequest';

export default class TaskReportCancellationMethodDelete extends BaseGenericRequest<{}, TaskDeleteResponse> {

    private readonly data: TaskDeleteMultipleRequest;

    public constructor(itemIdOrIds: string | string[]) {
        if ( typeof itemIdOrIds === 'string' ) {
            super(METHOD.DELETE, generateRoute(Urls.URL_REPORT_CANCELLATION_METHOD_DELETE, {uuid: itemIdOrIds}));
        } else {
            super(METHOD.DELETE, generateRoute(Urls.URL_REPORT_CANCELLATION_METHOD_DELETE_MULTIPLE));
            this.data = {ids: itemIdOrIds};
        }
    }

    protected getRequest(): TaskDeleteMultipleRequest {
        return this.data;
    }
}
