import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import { PagerBackendProps } from '../../../../components/Pager';
import ParkingAreaType from '../../../../commons/model/ParkingAreaType';
import TaskParkingAreaTypeList from '../../../../commons/ws/parkingAreaType/TaskParkingAreaTypeList';

interface InitialState {
    loading: boolean,
    parkingAreaTypes: ParkingAreaType[],
    error: string,
    pager: PagerBackendProps,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    parkingAreaTypes: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true};
    })
    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false};
    })
    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload};
    })
    .onEvent(TaskParkingAreaTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            parkingAreaTypes: payload.data,
            pager: payload.pager || oldState.pager
        };
    })

    .build();
