import I18n from "../../../../commons/I18n/I18n";
import ScreenCard from "../../../../components/screen/ScreenCard";
import { AppIcon } from "../../../../config/Config";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_ADMIN_REPORT_CANCELLATION_METHODS } from "../../../../routing/Routes";
import ReportCancellationMethodListCardAdmin from "./ReportCancellationMethodListCardAdmin";

const ReportCancellationMethodListAdminScreen = ()=> (
  <ScreenCard breadcrumbs={[
    {
      name: I18n.tr(I18nKeys.METODOS_DE_CANCELACION_DE_DENUNCIA),
      url: ROUTE_ADMIN_REPORT_CANCELLATION_METHODS,
      icon: AppIcon.REPORT_OFF,
    },
  ]}>
    <ReportCancellationMethodListCardAdmin/>
  </ScreenCard>
);
  
export default ReportCancellationMethodListAdminScreen;
