import { ReducerBuilder } from '@cuatroochenta/co-redux-builders';
import { PagerBackendProps } from '../../../../components/Pager';
import ReportInvalidationReason from '../../../../commons/model/ReportInvalidationReason';
import TaskReportInvalidationReasonList from '../../../../commons/ws/reportInvalidationReason/TaskReportInvalidationReasonList';

interface InitialState {
    loading: boolean
    reportInvalidationReasons: ReportInvalidationReason[]
    error: string
    pager: PagerBackendProps
}

const INITIAL_STATE: InitialState = {
    loading: false,
    reportInvalidationReasons: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1,
        total_elements: 0,
    },
};

export default ReducerBuilder
    .newBuilder(INITIAL_STATE)
    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE,
        (oldState) => ( {...oldState, loading: true} )
    )
    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE,
        (oldState) => ( {...oldState, loading: false} )
    )
    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_FAIL,
        (oldState, payload) => ( {...oldState, error: payload} )
    )
    .onEvent(
        TaskReportInvalidationReasonList.REDUX_ACTION_OBJECT_ON_SUCCESS,
        (oldState, payload) => ( {
            ...oldState,
            reportInvalidationReasons: payload.data,
            pager: payload.pager || oldState.pager
        } )
    )
    .build();
