import React from "react";
import Col from "../Col";

export interface FormColProps {
    xs?: number,
    sm?: number,
    md: number,
    lg: number,
    className?: string,
}

class FormCol extends React.Component<FormColProps> {

    public static defaultProps: Partial<FormColProps> = {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
    };

    public render(): React.ReactNode {
        const {xs, sm, md, lg} = this.props;
        return (
            <Col xs={xs} sm={sm} md={md} lg={lg} className={this.props.className || ''}>
                {this.props.children}
            </Col>
        );
    }
}

export default FormCol;
