import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import VehicleColor from "../../model/VehicleColor";

export default class TaskVehicleColorDetail extends BaseGenericRequest<{}, TaskResponse<VehicleColor>> {

    public constructor(vehicleColorId: string) {
        super(METHOD.GET, generateRoute(Urls.URL_VEHICLE_COLOR_DETAIL, {uuid: vehicleColorId}));
    }
}
