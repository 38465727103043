import React from "react";
import I18n from "../../../../commons/I18n/I18n";
import ParkingMeterIncidence from "../../../../commons/model/ParkingMeterIncidence";
import { generateRoute } from "../../../../commons/utils/Router";
import ActiveLabel from "../../../../components/label/ActiveLabel";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL } from "../../../../routing/Routes";
import DateFormatter from "../../../../utils/DateFormatter";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface ParkingMeterIncidenceListTableProps {
    data: ParkingMeterIncidence[];
    error: string;
    onSort: OnSortHandler;
    sort: SortState;
    renderSelectTd?: (itemId: string)=> React.ReactNode;
}

const goToItemDetail = (itemId: string): void => {
  goToRoute(generateRoute(ROUTE_COMPANY_USER_PARKING_METER_INCIDENCE_DETAIL, {
    id: itemId,
  } ));
};

export default class ParkingMeterIncidenceListTable extends React.Component<ParkingMeterIncidenceListTableProps> {
  public render(): React.ReactNode {
    const {data, renderSelectTd} = this.props;

    return (
      <table className="table table-hover table-font-small">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data && data.map((parkingMeterIncidence: ParkingMeterIncidence) => (
            <tr key={parkingMeterIncidence.id}
              onClick={() => goToItemDetail(parkingMeterIncidence.id)}
              className={"tr-cursor"}
            >
              {renderSelectTd ? renderSelectTd(parkingMeterIncidence.id) : null}
              <td>
                {parkingMeterIncidence.parking_meter ? parkingMeterIncidence.parking_meter.code : ""}
              </td>
              <td>
                {parkingMeterIncidence.type ? parkingMeterIncidence.type.name : ""}
              </td>
              <td>
                {parkingMeterIncidence.parking_meter ? parkingMeterIncidence.parking_meter.type : ""}
              </td>
              <td>
                {parkingMeterIncidence.observations ? parkingMeterIncidence.observations : ""}
              </td>
              <td>
                {parkingMeterIncidence.controller ?
                  `${parkingMeterIncidence.controller.name} ${parkingMeterIncidence.controller.last_name}`
                  : ""}
              </td>
              <td>
                {parkingMeterIncidence.date ? DateFormatter.formatDatetime(parkingMeterIncidence.date) : ""}
              </td>
              <td>
                <ActiveLabel text={parkingMeterIncidence.solved ? I18n.tr(I18nKeys.SOLUCIONADA) : I18n.tr(I18nKeys.NO_SOLUCIONADA)}
                  active={parkingMeterIncidence.solved}
                />
              </td>
              <td>
                {parkingMeterIncidence.solved_date ? DateFormatter.formatDatetime(parkingMeterIncidence.solved_date) : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const {sort, children, onSort} = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th text={I18n.tr(I18nKeys.PARQUIMETRO)}
          columnName={"parking_meter"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.TIPO_DE_INCIDENCIA)}
          columnName={"type"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.TIPO)}
          columnName={"parking_meter.type"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.OBSERVACIONES)}
          columnName={"observations"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.CONTROLADOR)}
          columnName={"controller"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.SOLUCIONADA)}
          columnName={"solved"}
          {...commonProps}
        />
        <Th text={I18n.tr(I18nKeys.FECHA_SOLUCIONADA)}
          columnName={"solved_date"}
          {...commonProps}
        />
      </tr>
    );
  }
}
