import { METHOD } from "@cuatroochenta/co-generic-request";
import I18nKeys from "../../../I18n/I18nKeys";
import I18n from "../../I18n/I18n";
import ParkingLoss from "../../model/ParkingLoss";
import BaseGenericRequest from "../BaseGenericRequest";
import { ReportCustomCriteria } from "../report/TaskReportList";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";

type TaskReportExportResponse = TaskResponse<{url: string}>;

export default class TaskParkingLossExport extends BaseGenericRequest<ReportCustomCriteria, TaskReportExportResponse> {
  private readonly data: ParkingLoss[];

  public constructor(data: ParkingLoss[]) {
    super(METHOD.POST, Urls.URL_PARKING_LOSS_EXPORT_EXCEL);
    this.data = data;

    this.setErrorMessage(404, I18n.tr(I18nKeys.NO_EXISTEN_PLAZAS_PERDIDAS_PARA_EXPORTAR));
    this.setErrorMessage(405, I18n.tr(I18nKeys.INTENTAS_EXPORTAR_MAS_DE_10000_PLAZAS_PERDIDAS));
  }

  protected getData(): ParkingLoss[] {
    return this.data;
  }
}
