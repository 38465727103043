import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import Street from "../../../../commons/model/Street";
import TaskStreetDetail from "../../../../commons/ws/street/TaskStreetDetail";
import StreetCompanyUserScreenActions from "./StreetFormCompanyUserScreenActions";

interface InitialState {
    streetLoading: boolean,
    street?: Street,
}

const INITIAL_STATE: InitialState = {
    streetLoading: false,
    street: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskStreetDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        streetLoading: true
    }))
    .onEvent(TaskStreetDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        streetLoading: false
    }))
    .onEvent(TaskStreetDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, street: payload.data};
        })

    .onEvent(StreetCompanyUserScreenActions.clearReducer, () => INITIAL_STATE)

    .build();
