import React from "react";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import I18n from "../../../../../commons/I18n/I18n";
import Card from "../../../../../components/card/Card";
import CardBody from "../../../../../components/card/CardBody";
import CardHeader from "../../../../../components/card/CardHeader";
import FormCardFooter from "../../../../../components/form/FormCardFooter";
import FormDate from "../../../../../components/form/FormDate";
import FormTextArea from "../../../../../components/form/FormTextArea";
import Row from "../../../../../components/Row";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_BROADCAST } from "../../../../../routing/Routes";
import { DateUtils } from "../../../../../utils/DateFormatter";
import { goToRoute } from "../../../../../utils/Router";

const FORM_NAME = "ChatBroadcastCardFormCompanyUser";

export interface ChatBroadcastCardFormCompanyUserData {
    message: string;
    expirationDate: Date;
}

interface ChatBroadcastCardFormCompanyUserExtProps {
    readonly?: boolean;
    initialValues?: Partial<ChatBroadcastCardFormCompanyUserData>;
    onSubmit: (data: ChatBroadcastCardFormCompanyUserData)=> void;
    parentLoading?: boolean;
}

type Props =
    ChatBroadcastCardFormCompanyUserExtProps
    & InjectedFormProps<ChatBroadcastCardFormCompanyUserData>;

enum ChatBroadcastFormAdminFields {
    MESSAGE = "message",
    EXPIRATION_DATE = "expirationDate"
}

class ChatBroadcastCardFormCompanyUser extends React.Component<Props> {
  public render(): React.ReactNode {
    const {handleSubmit, onSubmit, initialValues, invalid, parentLoading, pristine} = this.props;

    return (
      <Card loading={parentLoading}>
        <CardHeader
          title={I18n.tr(I18nKeys.NUEVO_MENSAJE)}/>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Field
                label={I18n.tr(I18nKeys.MENSAJE)}
                name={ChatBroadcastFormAdminFields.MESSAGE}
                component={FormTextArea}
                col={{
                  md: 9,
                  lg: 9,
                }}
              />
              <Field
                label={I18n.tr(I18nKeys.FECHA_EXPIRACION)}
                name={ChatBroadcastFormAdminFields.EXPIRATION_DATE}
                type={"number"}
                component={FormDate}
                col={{
                  md: 3,
                  lg: 3,
                }}
                className={"p-t-15"}
              />
            </Row>
            <FormCardFooter
              invalid={invalid}
              pristine={pristine}
              isUpdate={!!initialValues}
              cancelHandler={() => goToRoute(ROUTE_COMPANY_USER_BROADCAST)}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

function validate(values: ChatBroadcastCardFormCompanyUserData) {
  const errors: FormErrors<ChatBroadcastCardFormCompanyUserData> = {
  };

  if (!values.message || values.message.length === 0)
    errors.message = I18n.tr(I18nKeys.MENSAJE_OBLIGATORIO);

  if (!values.expirationDate)
    errors.expirationDate = I18n.tr(I18nKeys.FECHA_DE_EXPIRACION_OBLIGATORIA);
  else {
    const isExpiredDate = DateUtils.isGreaterThan(DateUtils.getCurrentDate(), values.expirationDate);

    if (isExpiredDate)
      errors.expirationDate = I18n.tr(I18nKeys.FECHA_INVALIDA);
  }

  return errors;
}

export default reduxForm<ChatBroadcastCardFormCompanyUserData, ChatBroadcastCardFormCompanyUserExtProps>( {
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
} )(ChatBroadcastCardFormCompanyUser as any) as React.ComponentType<ChatBroadcastCardFormCompanyUserExtProps>;
